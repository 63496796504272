<script>
import axios from 'axios';
import PlayerMeGetResponseDto from '../dto/response/PlayerMeGetResponseDto';
import Button from './Button.vue';
import { OtDialog, OtDialogBody, OtDialogClose, OtDialogContent, OtDialogFooter, OtDialogHeader, OtDialogTrigger } from './dialog';
import { OtTextarea } from './textarea';

export default {
    components: {
        OtButton: Button,
        OtTextarea,
        OtDialog,
        OtDialogClose,
        OtDialogBody,
        OtDialogContent,
        OtDialogFooter,
        OtDialogHeader,
        OtDialogTrigger,
    },
    data() {
        return {
            currentContent: '',
        };
    },
    mounted() {
        axios
            .get('/api/players/me')
            .then((response) => {
                this.currentContent = new PlayerMeGetResponseDto(response.data.data).getNotepad();
            });
    },
    methods: {
        save(isOpen) {
            if (isOpen) {
                return;
            }

            axios.post('/notepad', { content: this.currentContent });
        },
    },
};
</script>

<template>
    <ot-dialog @update:open="save($event)">
        <ot-dialog-trigger>
            <ot-button>{{ $t('general.notepad') }}</ot-button>
        </ot-dialog-trigger>
        <ot-dialog-content>
            <ot-dialog-header>{{ $t('general.notepad') }}</ot-dialog-header>
            <ot-dialog-body>
                <ot-textarea v-model="currentContent" />
            </ot-dialog-body>
            <ot-dialog-footer>
                <ot-dialog-close as-child>
                    <ot-button>{{ $t('general.close') }}</ot-button>
                </ot-dialog-close>
            </ot-dialog-footer>
        </ot-dialog-content>
    </ot-dialog>
</template>
