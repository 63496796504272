<script setup lang="ts">
import type { SelectTriggerProps } from 'radix-vue';
import type { HTMLAttributes } from 'vue';
import { ChevronDown } from 'lucide-vue-next';
import { SelectIcon, SelectTrigger, useForwardProps } from 'radix-vue';
import { computed } from 'vue';
import { cn } from '../utils';

const props = defineProps<SelectTriggerProps & { class?: HTMLAttributes['class'] }>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
    <select-trigger
        v-bind="forwardedProps"
        :class="cn('flex min-h-10 w-full items-center justify-between rounded-md border border-gray-700 px-3 py-2 text-sm ring-offset-white data-placeholder:text-neutral-500 focus:outline-hidden focus:ring-2 focus:ring-neutral-950 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:truncate text-start', props.class)"
    >
        <slot />
        <select-icon as-child>
            <chevron-down class="w-4 h-4 opacity-50 shrink-0" />
        </select-icon>
    </select-trigger>
</template>
