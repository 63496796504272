<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { OtHint } from './hint';

const { t } = useI18n();

const supportMail = '<a href="mailto:support@onlinetennis.net">support@onlinetennis.net</a>';
</script>

<template>
    <div class="p-4">
        <ot-hint type="warning">
            <div class="flex flex-col gap-2">
                <p>{{ t('general.general_error_message') }}</p>
                <p v-html="t('general.general_error_help', { supportMail })" />
            </div>
        </ot-hint>
    </div>
</template>
