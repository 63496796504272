<script setup lang="ts">
import axios from 'axios';
import { Globe, Shield, Trophy, User } from 'lucide-vue-next';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { OtCombobox, OtComboboxContent, OtComboboxGroup, OtComboboxItem } from './combobox';

type ItemType = 'player' | 'tournament' | 'country' | 'team';
interface Item {
    type: ItemType;
    id: number;
    text: string;
}

const { t } = useI18n();

const select = ref<Item | null>(null);
const search = ref('');
const items = ref<Item[]>([]);

watch(search, (value) => {
    if (value && value !== select.value) {
        executeSearch(value);
    }
});

async function executeSearch(value) {
    const response = await axios.get(Routing.generate('livesearch', { q: value }));
    if (response.data.q !== value) {
        return;
    }

    items.value = response.data.results;
}
function showResults() {
    window.location.href = Routing.generate('OnlinetennisBundle_searchResults', { _locale: locale, q: search.value });
}

const router = useRouter();
function selectItem(item: Item) {
    switch (item.type) {
        case 'player':
            return router.push(`/player/${item.id}`);

        case 'tournament':
            return window.location.href = Routing.generate('OnlinetennisBundle_profileTournament', { _locale: locale, id: item.id });

        case 'team':
            return window.location.href = Routing.generate('OnlinetennisBundle_profileTeam', { _locale: locale, id: item.id });

        case 'country':
            return window.location.href = Routing.generate('country', { _locale: locale, id: item.id });
    }
}
</script>

<template>
    <div>
        <ot-combobox
            v-model="select"
            v-model:search-term="search"
            :placeholder="$t('general.enterSearchstring')"
            :display-value="item => item.text"
            @submit="showResults"
        >
            <ot-combobox-content :empty-label="t('general.no_results')">
                <ot-combobox-group
                    v-for="type in ['player', 'tournament', 'team', 'country']"
                    :key="type"
                    :label="$t(`general.${type}`)"
                >
                    <ot-combobox-item
                        v-for="result in items.filter(item => item.type === type)"
                        :key="`${result.type}|${result.id}`"
                        :value="result"
                        @click="selectItem(result)"
                        class="gap-2 fill-green-700 data-highlighted:fill-green-100"
                    >
                        <trophy v-if="result.type === 'tournament'" />
                        <globe v-if="result.type === 'country'" />
                        <user v-if="result.type === 'player'" />
                        <shield v-if="result.type === 'team'" />
                        <span class="whitespace-nowrap overflow-hidden text-ellipsis">{{ result.text }}</span>
                    </ot-combobox-item>
                </ot-combobox-group>
            </ot-combobox-content>
        </ot-combobox>
    </div>
</template>
