<script>
import axios from 'axios';
import { Volume, VolumeOff } from 'lucide-vue-next';
import Avatar from '../components/Avatar.vue';
import Card from '../components/Card.vue';
import Chat from '../components/Chat.vue';
import ChatVisitors from '../components/ChatVisitors.vue';
import Comment from '../components/Comment.vue';
import Flag from '../components/Flag.vue';
import IconButton from '../components/IconButton.vue';
import LivematchStatElement from '../components/LivematchStatElement.vue';
import Sets from '../components/Sets.vue';
import Surface from '../components/Surface.vue';
import { OtTabs, OtTabsContent, OtTabsList, OtTabsTrigger } from '../components/tabs';

export default {
    components: {
        OtCard: Card,
        OtFlag: Flag,
        OtSurface: Surface,
        OtTabs,
        OtTabsContent,
        OtTabsList,
        OtTabsTrigger,
        OtChat: Chat,
        OtChatVisitors: ChatVisitors,
        OtComment: Comment,
        OtLivematchStatElement: LivematchStatElement,
        OtAvatar: Avatar,
        OtIconButton: IconButton,
        OtSets: Sets,
        Volume,
        VolumeOff,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
        isDoubles: {
            type: Boolean,
            required: true,
        },
        surface: {
            type: String,
            required: true,
        },
        setsToPlay: {
            type: Number,
            required: true,
        },
        channelId: {
            type: Number,
            required: true,
        },
        matchType: {
            type: String,
            required: true,
        },
        isTournament: {
            type: Boolean,
            required: true,
        },
        tournament: {
            type: Object,
            required: false,
        },
        participantOneId: {
            type: Number,
            required: false,
        },
        participantTwoId: {
            type: Number,
            required: false,
        },
        participantOneCountry: {
            type: Object,
            required: false,
        },
        participantTwoCountry: {
            type: Object,
            required: false,
        },
        participantOneLastname: {
            type: String,
            required: false,
        },
        participantTwoLastname: {
            type: String,
            required: false,
        },
        participantOneFirstnameInitial: {
            type: String,
            required: false,
        },
        participantTwoFirstnameInitial: {
            type: String,
            required: false,
        },
        participantOneName: {
            type: String,
            required: false,
        },
        participantTwoName: {
            type: String,
            required: false,
        },
        participantOneAvatar: {
            type: Number,
            required: false,
        },
        participantTwoAvatar: {
            type: Number,
            required: false,
        },
        user1Id: {
            type: Number,
            required: false,
        },
        user2Id: {
            type: Number,
            required: false,
        },
        user3Id: {
            type: Number,
            required: false,
        },
        user4Id: {
            type: Number,
            required: false,
        },
        user1Country: {
            type: Object,
            required: false,
        },
        user2Country: {
            type: Object,
            required: false,
        },
        user3Country: {
            type: Object,
            required: false,
        },
        user4Country: {
            type: Object,
            required: false,
        },
        user1Lastname: {
            type: String,
            required: false,
        },
        user2Lastname: {
            type: String,
            required: false,
        },
        user3Lastname: {
            type: String,
            required: false,
        },
        user4Lastname: {
            type: String,
            required: false,
        },
        user1FirstnameInitial: {
            type: String,
            required: false,
        },
        user2FirstnameInitial: {
            type: String,
            required: false,
        },
        user3FirstnameInitial: {
            type: String,
            required: false,
        },
        user4FirstnameInitial: {
            type: String,
            required: false,
        },
        racquet1: {
            type: Object,
            required: false,
        },
        racquet2: {
            type: Object,
            required: false,
        },
        racquet11: {
            type: Object,
            required: false,
        },
        racquet12: {
            type: Object,
            required: false,
        },
        racquet21: {
            type: Object,
            required: false,
        },
        racquet22: {
            type: Object,
            required: false,
        },
        shoes1: {
            type: Object,
            required: false,
        },
        shoes2: {
            type: Object,
            required: false,
        },
        shoes11: {
            type: Object,
            required: false,
        },
        shoes12: {
            type: Object,
            required: false,
        },
        shoes21: {
            type: Object,
            required: false,
        },
        shoes22: {
            type: Object,
            required: false,
        },
        isAlternativeCommentsEnabled: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            audio: false,
            audioClip: null,
            analysis: '',
            lastRallyId: 0,
            buffer: {},
            fitness: [],
            duration: '?',
            service: null,
            score: {
                sets1: null,
                sets2: null,
                games1: null,
                games2: null,
                points1: null,
                points2: null,
                sets11: null,
                sets12: null,
                sets21: null,
                sets22: null,
                sets31: null,
                sets32: null,
                sets41: null,
                sets42: null,
                sets51: null,
                sets52: null,
            },
            statistics: {
                firstServiceQuote: [],
                winningOnFirstServiceQuote: [],
                winningOnSecondServiceQuote: [],
                netPointsWonQuote: [],
                breakPointsWonQuote: [],
                aces: [],
                doubleFaults: [],
                unforcedErrors: [],
                wonPoints: [],
            },
            comments: [],
            commentAlternative: '',
            commentAlternativeRallyId: 0,
        };
    },
    computed: {
        formattedCommentAlternative() {
            let comment = this.commentAlternative;
            comment = comment.replaceAll(this.participantOneName, `<span class="text-red-500">${this.participantOneName}</span>`);
            comment = comment.replaceAll(this.participantOneLastname, `<span class="text-red-500">${this.participantOneLastname}</span>`);

            comment = comment.replaceAll(this.participantTwoName, `<span class="text-blue-500">${this.participantTwoName}</span>`);
            comment = comment.replaceAll(this.participantTwoLastname, `<span class="text-blue-500">${this.participantTwoLastname}</span>`);

            return comment;
        },
    },
    watch: {
        audio() {
            window.localStorage.setItem('audio', JSON.stringify(this.audio));
            if (this.audioClip) {
                if (this.audio) {
                    this.audioClip.play();
                }
                else {
                    this.audioClip.pause();
                }
            }
        },
    },
    mounted() {
        this.update();
        this.scouting();
        const audio = window.localStorage.getItem('audio');
        if (audio) {
            this.audio = JSON.parse(audio);
        }
    },
    methods: {
        async update() {
            const statisticsResponse = await axios.get(Routing.generate('update_livematch', { _locale: locale, matchType: this.matchType, matchId: this.id, rallyId: this.lastRallyId }));
            const data = statisticsResponse.data.data;
            if (data.rallyId) {
                this.buffer.score = data.score;
                this.buffer.statistics = data.statistics;
                this.buffer.fitness = data.fitness;
                this.buffer.service = data.service;

                if (data.rallyId !== this.lastRallyId) {
                    this.comments = data.comment;
                }

                this.duration = data.duration;
                this.lastRallyId = data.rallyId;
            }
            const analysisResponse = await axios.get(Routing.generate('analysis', { _locale: locale, matchType: this.matchType, id: this.id, rally: this.lastRallyId }));
            this.analysis = analysisResponse.data;

            if (this.commentAlternativeRallyId < this.lastRallyId && this.isAlternativeCommentsEnabled) {
                const commentAlternativeResponse = await axios.get(Routing.generate('comment_alternative', { locale, matchType: this.matchType, matchId: this.id, rallyId: this.lastRallyId }));
                const data = commentAlternativeResponse.data.data;
                if (data.comment) {
                    this.commentAlternative = data.comment;
                    this.commentAlternativeRallyId = this.lastRallyId;

                    if (this.audio && this.commentAlternative) {
                        this.audioClip = new Audio(`/comments/${locale}/${this.matchType}/${this.commentAlternativeRallyId}.mp3`);
                        this.audioClip.play();
                    }
                }
            }

            setTimeout(() => this.update(), 5000);
        },
        scouting() {
            setTimeout(async () => {
                await axios.post(Routing.generate('livematch_scout', { matchType: this.matchType, matchId: this.id }));
                this.scouting();
            }, 60000);
        },
        translateWithQuote(value) {
            if (!value) {
                return '';
            }

            const actual = value.actual;
            const all = value.all;
            const quote = all > 0 ? Math.round(actual / all * 100.0) : 0;

            return this.$t('general.match_statistik', { actual, all, quote });
        },
        colorForQuote(valueOwn, valueOpponent) {
            if (!valueOwn || !valueOpponent) {
                return '';
            }

            return this.color(valueOwn['%quote%'], valueOpponent['%quote%']);
        },
        color(valueOwn, valueOpponent) {
            if (valueOwn > valueOpponent) {
                return '#0eaa01';
            }

            if (valueOpponent > valueOwn) {
                return '#ff0000';
            }

            return '';
        },
        onCommentAnimated() {
            this.score = this.buffer.score;
            this.statistics = this.buffer.statistics;
            this.fitness = this.buffer.fitness;
            this.service = this.buffer.service;
        },
    },
};
</script>

<template>
    <div class="innerContentLivematch flex flex-wrap gap-2">
        <div class="w-full sm:w-8/12 md:w-7/12 flex flex-col gap-2">
            <div class="resultBox mb2">
                <div class="header headerSet1">1</div>
                <div class="header headerSet2">2</div>
                <div class="header headerSet3">3</div>
                <div class="header headerSet4">4</div>
                <div class="header headerSet5">5</div>
                <div class="header headerSets">Sets</div>
                <div class="header headerGames">Games</div>
                <div class="header headerPoints">Points</div>
                <template v-if="!isDoubles">
                    <div class="value player1country"><ot-flag :id="participantOneCountry.id" /></div>
                    <div class="value player1"><a :href="`#/player/${participantOneId}`">{{ participantOneFirstnameInitial }} {{ participantOneLastname }}</a></div>
                    <div class="value player2country"><ot-flag :id="participantTwoCountry.id" /></div>
                    <div class="value player2"><a :href="`#/player/${participantTwoId}`">{{ participantTwoFirstnameInitial }} {{ participantTwoLastname }}</a></div>
                </template>
                <template v-if="isDoubles">
                    <div class="value player1_1country"><ot-flag :id="user1Country.id" /></div>
                    <div class="value player1_1"><a :href="`#/player/${user1Id}`">{{ user1FirstnameInitial }} {{ user1Lastname }}</a></div>
                    <div class="value player1_2country"><ot-flag :id="user2Country.id" /></div>
                    <div class="value player1_2"><a :href="`#/player/${user2Id}`">{{ user2FirstnameInitial }} {{ user2Lastname }}</a></div>
                    <div class="value player2_1country"><ot-flag :id="user3Country.id" /></div>
                    <div class="value player2_1"><a :href="`#/player/${user3Id}`">{{ user3FirstnameInitial }} {{ user3Lastname }}</a></div>
                    <div class="value player2_2country"><ot-flag :id="user4Country.id" /></div>
                    <div class="value player2_2"><a :href="`#/player/${user4Id}`">{{ user4FirstnameInitial }} {{ user4Lastname }}</a></div>
                </template>
                <div id="service1"><img v-if="service === 1" src="../images/misc/ball.png" alt="Ball"></div>
                <div id="service2"><img v-if="service === 2" src="../images/misc/ball.png" alt="Ball"></div>
                <div class="value score set set1 row1"><ot-livematch-stat-element :value="score.sets11" /></div>
                <div class="value score set set2 row1"><ot-livematch-stat-element :value="score.sets21" /></div>
                <div class="value score set set3 row1"><ot-livematch-stat-element :value="score.sets31" /></div>
                <div class="value score set set4 row1"><ot-livematch-stat-element :value="score.sets41" /></div>
                <div class="value score set set5 row1"><ot-livematch-stat-element :value="score.sets51" /></div>
                <div class="value score set set1 row2"><ot-livematch-stat-element :value="score.sets12" /></div>
                <div class="value score set set2 row2"><ot-livematch-stat-element :value="score.sets22" /></div>
                <div class="value score set set3 row2"><ot-livematch-stat-element :value="score.sets32" /></div>
                <div class="value score set set4 row2"><ot-livematch-stat-element :value="score.sets42" /></div>
                <div class="value score set set5 row2"><ot-livematch-stat-element :value="score.sets52" /></div>
                <div class="value score score_sets row1"><ot-livematch-stat-element :value="score.sets1" /></div>
                <div class="value score score_sets row2"><ot-livematch-stat-element :value="score.sets2" /></div>
                <div class="value score games row1"><ot-livematch-stat-element :value="score.games1" /></div>
                <div class="value score games row2"><ot-livematch-stat-element :value="score.games2" /></div>
                <div class="value score points row1"><ot-livematch-stat-element :value="score.points1" /></div>
                <div class="value score points row2"><ot-livematch-stat-element :value="score.points2" /></div>
            </div>
            <ot-card box="boxStatistics" not-stretched>
                <ot-tabs id="livematch" default-value="match">
                    <ot-tabs-list>
                        <ot-tabs-trigger value="match">{{ $t('statistiken.statistiken_match') }}</ot-tabs-trigger>
                        <ot-tabs-trigger value="statistics">{{ $t('general.statistiken') }}</ot-tabs-trigger>
                        <ot-tabs-trigger value="player">{{ $t('general.player') }}</ot-tabs-trigger>
                        <ot-tabs-trigger value="analysis">{{ $t('internalLivematch.analysis') }}</ot-tabs-trigger>
                    </ot-tabs-list>
                    <ot-tabs-content value="match">
                        <table class="w-full">
                            <tbody>
                                <tr>
                                    <td v-text="$t('internalShowmatch.surface')" />
                                    <td><ot-surface :type="surface" style="float: left" /></td>
                                </tr>
                                <tr>
                                    <td v-text="$t('internalShowmatch.setsToPlay')" />
                                    <td>
                                        <ot-sets :sets="setsToPlay"></ot-sets>
                                    </td>
                                </tr>
                                <tr>
                                    <td v-text="$t('matchinfo.matchinfo_matchdauer')" />
                                    <td>{{ duration }}</td>
                                </tr>
                                <tr>
                                    <td v-text="$t('general.matchtype')" />
                                    <td v-text="$t(`general.${matchType}`)" />
                                </tr>
                                <template v-if="isTournament">
                                    <tr>
                                        <td v-text="$t('internalTournament.headline')" />
                                        <td>{{ tournament.name }}</td>
                                    </tr>
                                    <tr>
                                        <td v-text="$t('internalTournament.ort')" />
                                        <td><ot-flag :id="tournament.country.id" />&nbsp;{{ tournament.city }}</td>
                                    </tr>
                                    <tr>
                                        <td v-text="$t('internalTournament.art')" />
                                        <td>{{ tournament.type }}</td>
                                    </tr>
                                    <tr>
                                        <td v-text="$t('general.round')" />
                                        <td v-text="$t(`tournament.${tournament.roundNameKey}`)" />
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </ot-tabs-content>
                    <ot-tabs-content value="statistics">
                        <table class="w-full">
                            <tbody>
                                <tr v-if="!isDoubles">
                                    <td class="text-center text-red-500 font-bold">{{ participantOneName }}</td>
                                    <td />
                                    <td class="text-center text-blue-700 font-bold">{{ participantTwoName }}</td>
                                </tr>
                                <tr v-if="isDoubles">
                                    <td class="text-center text-red-500 font-bold">{{ user1Lastname }} / {{ user2Lastname }}</td>
                                    <td />
                                    <td class="text-center text-blue-700 font-bold">{{ user3Lastname }} / {{ user4Lastname }}</td>
                                </tr>
                                <tr>
                                    <td class="text-center"><ot-livematch-stat-element :value="translateWithQuote(statistics.firstServiceQuote[0])" :color="colorForQuote(statistics.firstServiceQuote[0], statistics.firstServiceQuote[1])" /></td>
                                    <td class="text-center" v-text="$t('matchinfo.matchinfo_1stservice')" />
                                    <td class="text-center"><ot-livematch-stat-element :value="translateWithQuote(statistics.firstServiceQuote[1])" :color="colorForQuote(statistics.firstServiceQuote[1], statistics.firstServiceQuote[0])" /></td>
                                </tr>
                                <tr>
                                    <td class="text-center"><ot-livematch-stat-element :value="translateWithQuote(statistics.winningOnFirstServiceQuote[0])" :color="colorForQuote(statistics.winningOnFirstServiceQuote[0], statistics.winningOnFirstServiceQuote[1])" /></td>
                                    <td class="text-center" v-text="$t('matchinfo.matchinfo_winningon1')" />
                                    <td class="text-center"><ot-livematch-stat-element :value="translateWithQuote(statistics.winningOnFirstServiceQuote[1])" :color="colorForQuote(statistics.winningOnFirstServiceQuote[1], statistics.winningOnFirstServiceQuote[0])" /></td>
                                </tr>
                                <tr>
                                    <td class="text-center"><ot-livematch-stat-element :value="translateWithQuote(statistics.winningOnSecondServiceQuote[0])" :color="colorForQuote(statistics.winningOnSecondServiceQuote[0], statistics.winningOnSecondServiceQuote[1])" /></td>
                                    <td class="text-center" v-text="$t('matchinfo.matchinfo_winningon2')" />
                                    <td class="text-center"><ot-livematch-stat-element :value="translateWithQuote(statistics.winningOnSecondServiceQuote[1])" :color="colorForQuote(statistics.winningOnSecondServiceQuote[1], statistics.winningOnSecondServiceQuote[0])" /></td>
                                </tr>
                                <tr>
                                    <td class="text-center"><ot-livematch-stat-element :value="translateWithQuote(statistics.netPointsWonQuote[0])" :color="colorForQuote(statistics.netPointsWonQuote[0], statistics.netPointsWonQuote[1])" /></td>
                                    <td class="text-center" v-text="$t('matchinfo.matchinfo_netpointswon')" />
                                    <td class="text-center"><ot-livematch-stat-element :value="translateWithQuote(statistics.netPointsWonQuote[1])" :color="colorForQuote(statistics.netPointsWonQuote[1], statistics.netPointsWonQuote[0])" /></td>
                                </tr>
                                <tr>
                                    <td class="text-center"><ot-livematch-stat-element :value="translateWithQuote(statistics.breakPointsWonQuote[0])" :color="colorForQuote(statistics.breakPointsWonQuote[0], statistics.breakPointsWonQuote[1])" /></td>
                                    <td class="text-center" v-text="$t('matchinfo.matchinfo_breakpointswon')" />
                                    <td class="text-center"><ot-livematch-stat-element :value="translateWithQuote(statistics.breakPointsWonQuote[1])" :color="colorForQuote(statistics.breakPointsWonQuote[1], statistics.breakPointsWonQuote[0])" /></td>
                                </tr>
                                <tr>
                                    <td class="text-center"><ot-livematch-stat-element :value="statistics.aces[0]" :color="color(statistics.aces[0], statistics.aces[1])" /></td>
                                    <td class="text-center" v-text="$t('matchinfo.matchinfo_asse')" />
                                    <td class="text-center"><ot-livematch-stat-element :value="statistics.aces[1]" :color="color(statistics.aces[1], statistics.aces[0])" /></td>
                                </tr>
                                <tr>
                                    <td class="text-center"><ot-livematch-stat-element :value="statistics.doubleFaults[0]" :color="color(statistics.doubleFaults[1], statistics.doubleFaults[0])" /></td>
                                    <td class="text-center" v-text="$t('matchinfo.matchinfo_doppelfehler')" />
                                    <td class="text-center"><ot-livematch-stat-element :value="statistics.doubleFaults[1]" :color="color(statistics.doubleFaults[0], statistics.doubleFaults[1])" /></td>
                                </tr>
                                <tr>
                                    <td class="text-center"><ot-livematch-stat-element :value="statistics.unforcedErrors[0]" :color="color(statistics.unforcedErrors[1], statistics.unforcedErrors[0])" /></td>
                                    <td class="text-center" v-text="$t('matchinfo.matchinfo_unforcedErrors')" />
                                    <td class="text-center"><ot-livematch-stat-element :value="statistics.unforcedErrors[1]" :color="color(statistics.unforcedErrors[0], statistics.unforcedErrors[1])" /></td>
                                </tr>
                                <tr>
                                    <td class="text-center"><ot-livematch-stat-element :value="statistics.wonPoints[0]" :color="color(statistics.wonPoints[0], statistics.wonPoints[1])" /></td>
                                    <td class="text-center" v-text="$t('matchinfo.matchinfo_gewonnenepunkte')" />
                                    <td class="text-center"><ot-livematch-stat-element :value="statistics.wonPoints[1]" :color="color(statistics.wonPoints[1], statistics.wonPoints[0])" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </ot-tabs-content>
                    <ot-tabs-content value="player">
                        <table class="w-full">
                            <template v-if="!isDoubles">
                                <tr>
                                    <td class="text-center text-red-500 font-bold">{{ participantOneName }}</td>
                                    <td />
                                    <td class="text-center text-blue-700 font-bold">{{ participantTwoName }}</td>
                                </tr>
                                <tr>
                                    <td class="flex justify-center"><ot-avatar portrait :value="participantOneAvatar" class="rounded-sm shadow-sm" /></td>
                                    <td />
                                    <td class="flex justify-center"><ot-avatar portrait :value="participantTwoAvatar" class="rounded-sm shadow-sm" /></td>
                                </tr>
                                <tr>
                                    <td class="text-center">{{ fitness[0] }}</td>
                                    <td class="text-center" v-text="$t('internalLivematch.fitness')" />
                                    <td class="text-center">{{ fitness[1] }}</td>
                                </tr>
                                <tr>
                                    <td class="flex justify-center">
                                        <div
                                            v-if="racquet1 && racquet1.id !== 0"
                                            class="equipment"
                                            :style="`background-image: url(/images/equipment/racquets/${racquet1.id}.png)`"
                                            :title="racquet1.name"
                                        />
                                        <div
                                            v-if="shoes1 && shoes1.id !== 0"
                                            class="equipment"
                                            :style="`background-image: url(/images/equipment/shoes/${shoes1.id}.png)`"
                                            :title="shoes1.name"
                                        />
                                    </td>
                                    <td class="text-center" v-text="$t('internalEquipment.headline')" />
                                    <td class="flex justify-center">
                                        <div
                                            v-if="racquet2 && racquet2.id !== 0"
                                            class="equipment"
                                            :style="`background-image: url(/images/equipment/racquets/${racquet2.id}.png)`"
                                            :title="racquet2.name"
                                        />
                                        <div
                                            v-if="shoes2 && shoes2.id !== 0"
                                            class="equipment"
                                            :style="`background-image: url(/images/equipment/shoes/${shoes2.id}.png)`"
                                            :title="shoes2.name"
                                        />
                                    </td>
                                </tr>
                            </template>
                            <template v-if="isDoubles">
                                <tr>
                                    <td class="text-center text-red-500 font-bold">{{ user1Lastname }} / {{ user2Lastname }}</td>
                                    <td />
                                    <td class="text-center text-blue-700 font-bold">{{ user3Lastname }} / {{ user4Lastname }}</td>
                                </tr>
                                <tr>
                                    <td class="text-center">{{ fitness[0] }}</td>
                                    <td class="text-center" v-text="$t('internalLivematch.fitness')" />
                                    <td class="text-center">{{ fitness[1] }}</td>
                                </tr>
                                <tr>
                                    <td class="text-center">
                                        <div
                                            v-if="racquet11 && racquet11.id !== 0"
                                            class="equipment"
                                            :style="`background-image: url(/images/equipment/racquets/${racquet11.id}.png)`"
                                            :title="racquet11.name"
                                        />
                                        <div
                                            v-if="shoes11 && shoes11.id !== 0"
                                            class="equipment"
                                            :style="`background-image: url(/images/equipment/shoes/${shoes11.id}.png);`"
                                            :title="shoes11.name"
                                        />
                                        <div
                                            v-if="racquet12 && racquet12.id !== 0"
                                            class="equipment"
                                            :style="`background-image: url(/images/equipment/racquets/${racquet12.id}.png)`"
                                            :title="racquet12.name"
                                        />
                                        <div
                                            v-if="shoes12 && shoes12.id !== 0"
                                            class="equipment"
                                            :style="`background-image: url(/images/equipment/shoes/${shoes12.id}.png);`"
                                            :title="shoes12.name"
                                        />
                                    </td>
                                    <td class="text-center" v-text="$t('internalEquipment.headline')" />
                                    <td class="text-center">
                                        <div
                                            v-if="racquet21 && racquet21.id !== 0"
                                            class="equipment"
                                            :style="`background-image: url(/images/equipment/racquets/${racquet21.id}.png)`"
                                            :title="racquet21.name"
                                        />
                                        <div
                                            v-if="shoes21 && shoes21.id !== 0"
                                            class="equipment"
                                            :style="`background-image: url(/images/equipment/shoes/${shoes21.id}.png);`"
                                            :title="shoes21.name"
                                        />
                                        <div
                                            v-if="racquet22 && racquet22.id !== 0"
                                            class="equipment"
                                            :style="`background-image: url(/images/equipment/racquets/${racquet22.id}.png)`"
                                            :title="racquet22.name"
                                        />
                                        <div
                                            v-if="shoes22 && shoes22.id !== 0"
                                            class="equipment"
                                            :style="`background-image: url(/images/equipment/shoes/${shoes22.id}.png);`"
                                            :title="shoes22.name"
                                        />
                                    </td>
                                </tr>
                            </template>
                        </table>
                    </ot-tabs-content>
                    <ot-tabs-content value="analysis">
                        <div v-html="analysis" />
                    </ot-tabs-content>
                </ot-tabs>
            </ot-card>
            <ot-card v-if="isAlternativeCommentsEnabled" legacy :height="300">
                <ot-icon-button class="absolute bottom-4 right-4" @click="audio = !audio">
                    <volume v-if="audio" />
                    <volume-off v-else />
                </ot-icon-button>
                <div v-html="formattedCommentAlternative" />
            </ot-card>
            <ot-card legacy :height="300">
                <ot-comment :comments="comments" @done="onCommentAnimated" />
            </ot-card>
        </div>
        <div class="w-full sm:w-3/12 md:w-4/12 flex flex-col gap-2">
            <ot-card legacy :height="592">
                <ot-chat :channel="channelId" :locked="true" />
            </ot-card>
            <ot-card legacy box="boxUsers" not-stretched>
                <ot-chat-visitors :channel="channelId" />
            </ot-card>
        </div>
    </div>
</template>

<style scoped>
.innerContentLivematch .resultBox {
    background: url(./../images/livematch/scoreboard.png);
    width: 426px;
    height: 80px;
    position: relative;
    shrink: 0;
    font-size: 11px;
    color: #ffffff;
}
.innerContentLivematch .resultBox .header {
    font-weight: bold;
    text-transform: uppercase;
    position: absolute;
    top: 2px;
    color: var(--color-zinc-100);
    font-size: 10px;
}
.innerContentLivematch .resultBox .headerSet1 {
    left: 10px;
}
.innerContentLivematch .resultBox .headerSet2 {
    left: 36px;
}
.innerContentLivematch .resultBox .headerSet3 {
    left: 62px;
}
.innerContentLivematch .resultBox .headerSet4 {
    left: 88px;
}
.innerContentLivematch .resultBox .headerSet5 {
    left: 114px;
}
.innerContentLivematch .resultBox .headerSets {
    left: 300px;
}
.innerContentLivematch .resultBox .headerGames {
    left: 332px;
}
.innerContentLivematch .resultBox .headerPoints {
    left: 378px;
}
.innerContentLivematch .resultBox .value {
    position: absolute;
    text-transform: uppercase;
}
.innerContentLivematch .resultBox .player1country {
    left: 136px;
    top: 20px;
}
.innerContentLivematch .resultBox .player1 {
    left: 156px;
    top: 19px;
}
.innerContentLivematch .resultBox .player2country {
    left: 136px;
    top: 52px;
}
.innerContentLivematch .resultBox .player2 {
    left: 156px;
    top: 51px;
}
.innerContentLivematch .resultBox .player1_1country {
    left: 136px;
    top: 20px;
}
.innerContentLivematch .resultBox .player1_1 {
    left: 156px;
    top: 19px;
}
.innerContentLivematch .resultBox .player1_2country {
    left: 136px;
    top: 34px;
}
.innerContentLivematch .resultBox .player1_2 {
    left: 156px;
    top: 33px;
}
.innerContentLivematch .resultBox .player2_1country {
    left: 136px;
    top: 52px;
}
.innerContentLivematch .resultBox .player2_1 {
    left: 156px;
    top: 51px;
}
.innerContentLivematch .resultBox .player2_2country {
    left: 136px;
    top: 66px;
}
.innerContentLivematch .resultBox .player2_2 {
    left: 156px;
    top: 65px;
}
.innerContentLivematch .resultBox .score {
    font-size: 22px;
    letter-spacing: 12px;
}
.innerContentLivematch .resultBox .row1 {
    top: 17px;
}
.innerContentLivematch .resultBox .row2 {
    top: 49px;
}
.innerContentLivematch .resultBox .set {
    letter-spacing: 0;
    width: 24px;
    text-align: center;
}
.innerContentLivematch .resultBox .set1 {
    right: 400px;
}
.innerContentLivematch .resultBox .set2 {
    right: 374px;
}
.innerContentLivematch .resultBox .set3 {
    right: 349px;
}
.innerContentLivematch .resultBox .set4 {
    right: 322px;
}
.innerContentLivematch .resultBox .set5 {
    right: 296px;
}
.innerContentLivematch .resultBox .score_sets {
    right: 96px;
}
.innerContentLivematch .resultBox .games {
    right: 46px;
}
.innerContentLivematch .resultBox .points {
    right: -4px;
}
.innerContentLivematch .resultBox #service1 {
    position: absolute;
    left: 282px;
    top: 20px;
}
.innerContentLivematch .resultBox #service2 {
    position: absolute;
    left: 282px;
    top: 52px;
}
.innerContentLivematch .boxStatistics table .equipment {
    float: left;
    margin-left: 1px;
    width: 24px;
    height: 24px;
}
</style>
