<script>
import ModalAchievement from './ModalAchievement.vue';
import ModalCollectingGame from './ModalCollectingGame.vue';
import ModalDailyLoginBonus from './ModalDailyLoginBonus.vue';
import ModalError from './ModalError.vue';
import ModalInfo from './ModalInfo.vue';

export default {
    components: {
        OtModalError: ModalError,
        OtModalInfo: ModalInfo,
        OtModalAchievement: ModalAchievement,
        OtModalCollectingGame: ModalCollectingGame,
        OtModalDailyLoginBonus: ModalDailyLoginBonus,
    },
    data() {
        return {
            errorMessage: null,
            infoMessage: null,
            achievementData: null,
            collectingGameMessage: null,
            dailyLoginBonusDay: null,
            showErrorMessage: false,
            showInfoMessage: false,
            showAchievementMessage: false,
            showCollectingGameMessage: false,
            showDailyLoginBonusMessage: false,
        };
    },
    mounted() {
        window.emitter.on('error', (error) => {
            this.errorMessage = this.$t(error).toString();
            this.showErrorMessage = true;
        });
        window.emitter.on('info', (info) => {
            this.infoMessage = info;
            this.showInfoMessage = true;
        });
        window.emitter.on('achievement', (achievementData) => {
            this.achievementData = achievementData;
            this.showAchievementMessage = true;
        });
        window.emitter.on('collectingGame', (collectingGameMessage) => {
            this.collectingGameMessage = collectingGameMessage;
            this.showCollectingGameMessage = true;
        });
        window.emitter.on('dailyLoginBonus', (dailyLoginBonusDay) => {
            this.dailyLoginBonusDay = dailyLoginBonusDay;
            this.showDailyLoginBonusMessage = true;
        });
    },
};
</script>

<template>
    <div>
        <ot-modal-error v-model="showErrorMessage">{{ errorMessage }}</ot-modal-error>
        <ot-modal-info v-model="showInfoMessage">{{ infoMessage }}</ot-modal-info>
        <ot-modal-achievement v-model="showAchievementMessage" :data="achievementData" />
        <ot-modal-collecting-game v-model="showCollectingGameMessage" :message="collectingGameMessage" />
        <ot-modal-daily-login-bonus v-model="showDailyLoginBonusMessage" :day="dailyLoginBonusDay" />
    </div>
</template>
