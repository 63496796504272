<script>
import axios from 'axios';
import { Smartphone } from 'lucide-vue-next';
import PlayerGetResponseDto from '../dto/response/PlayerGetResponseDto';
import Avatar from './Avatar.vue';
import Card from './Card.vue';
import Divider from './Divider.vue';
import Flag from './Flag.vue';
import Loader from './Loader.vue';

export default {
    components: {
        OtFlag: Flag,
        OtLoader: Loader,
        OtCard: Card,
        OtDivider: Divider,
        OtAvatar: Avatar,
        Smartphone,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            player: null,
        };
    },
    created() {
        axios
            .get(`/api/players/${this.id}`)
            .then(response => this.player = PlayerGetResponseDto.fromData(response.data.data));
    },
};
</script>

<template>
    <ot-card class="text-black" style="background-color: white!important;">
        <ot-loader :loading="player === null" standalone>
            <template v-if="player !== null">
                <div class="flex">
                    <div class="border-r border-gray-500">
                        <ot-avatar portrait :value="player.getAvatar()" />
                    </div>
                    <div class="m-4">
                        <div>
                            <div class="headline">
                                {{ player.getName().getFullName() }}
                                <template v-if="player.isOnline()">
                                    <smartphone v-if="player.isMobile()" class="inline text-green-700" />
                                    <div v-else class="online" />
                                </template>
                            </div>
                            <div class="body-1">
                                <ot-flag :id="player.getCountry().getId()" />
                                {{ $t(`country.country_${player.getCountry().getId()}`) }}
                            </div>
                        </div>
                    </div>
                </div>
                <ot-divider />
                <div class="m-4">
                    <div class="body-1">{{ $t('rangliste.rangliste_entrylist') }}: {{ player.getEntrylist().getPosition() }}.</div>
                    <div class="body-1">{{ $t('rangliste.rangliste_championsrace') }}: {{ player.getChampionsrace().getPosition() }}.</div>
                </div>
            </template>
        </ot-loader>
    </ot-card>
</template>

<style scoped>
.online {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    background-color: #388e3c;
}
</style>
