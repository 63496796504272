<script>
import axios from 'axios';
import Button from '../components/Button.vue';
import Card from '../components/Card.vue';
import InputGroup from '../components/InputGroup.vue';
import Loader from '../components/Loader.vue';
import { OtTextField } from '../components/text-field';

export default {
    components: {
        OtCard: Card,
        OtLoader: Loader,
        OtInputGroup: InputGroup,
        OtButton: Button,
        OtTextField,
    },
    data() {
        return {
            loading: true,
            alreadyDonated: '',
            amount: 1,
        };
    },
    created() {
        axios
            .get(Routing.generate('OnlinetennisBundle_donation', { _locale: locale }))
            .then((response) => {
                this.alreadyDonated = response.data.already_donated;
                this.loading = false;
            });
    },
    methods: {
        donate() {
            axios
                .post('/api/donations', { amount: this.amount })
                .then(() => window.emitter.emit('info', this.$t('accounting.accounting_donation', { '%s': this.amount })));
        },
    },
};
</script>

<template>
    <ot-loader :loading="loading">
        <ot-card legacy>
            <p class="mb-2">{{ alreadyDonated }}</p>
            <ot-input-group>
                <ot-text-field v-model="amount" number :label="$t('general.amount')" />
                <ot-button @click="donate()">{{ $t('finanzen.finanzen_spende_ausfuehren') }}</ot-button>
            </ot-input-group>
        </ot-card>
    </ot-loader>
</template>
