<script>
export default {
    props: {
        value: {
            required: true,
        },
    },
    computed: {
        style() {
            return {
                backgroundColor: this.color,
                width: `${(this.value / 10).toString()}px`,
            };
        },
        color() {
            if (this.value > 1000) {
                return '#4CAF50';
            }
            if (this.value > 900) {
                return '#8BC34A';
            }
            if (this.value > 800) {
                return '#CDDC39';
            }
            if (this.value > 700) {
                return '#FFC107';
            }
            if (this.value > 500) {
                return '#FF9800';
            }
            if (this.value > 300) {
                return '#FF5722';
            }

            return '#F44336';
        },
    },
};
</script>

<template>
    <div class="skillbar" :style="style" />
</template>

<style scoped>
.skillbar {
    height: 16px;
    @apply border;
    border-color: var(--color-zinc-500);
}
</style>
