<script setup lang="ts">
import type { SelectValueProps } from 'radix-vue';
import { SelectValue } from 'radix-vue';

const props = defineProps<SelectValueProps>();
</script>

<template>
    <select-value v-bind="props">
        <slot />
    </select-value>
</template>
