<script setup lang="ts">
import type { TooltipContentProps } from 'radix-vue';
import type { HTMLAttributes } from 'vue';
import { TooltipContent, TooltipPortal } from 'radix-vue';
import { cn } from '../utils';

type Props = Pick<TooltipContentProps, 'side'> & { class?: HTMLAttributes['class'] };
const props = defineProps<Props>();
</script>

<template>
    <tooltip-portal>
        <tooltip-content
            :side="side"
            :class="cn('z-50 overflow-hidden rounded-sm bg-gray-800 px-3 py-1 text-sm text-white shadow-md', props.class)"
        >
            <slot />
        </tooltip-content>
    </tooltip-portal>
</template>
