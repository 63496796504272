export default class Ranking {
    public static fromData(data) {
        return new Ranking(data.position, data.points);
    }

    private readonly position: number;
    private readonly points: number;

    constructor(position: number, points: number) {
        this.position = position;
        this.points = points;
    }

    public getPosition(): number {
        return this.position;
    }

    public getPoints(): number {
        return this.points;
    }
}
