import Country from '../../domain/country/Country';
import PlayerName from '../../domain/player/PlayerName';

export default class UsersFriendsMeListGetResponseDto {
    private readonly id: number;
    private readonly online: boolean;
    private readonly avatar: number;
    private readonly playerName: PlayerName;
    private readonly playerCountry: Country;

    public constructor(data: any) {
        this.id = data.id;
        this.online = data.is_online;
        this.avatar = data.avatar;
        this.playerName = PlayerName.fromData(data.player_name.data);
        this.playerCountry = Country.fromData(data.player_country.data);
    }

    public getId(): number {
        return this.id;
    }

    public isOnline(): boolean {
        return this.online;
    }

    public getAvatar(): number {
        return this.avatar;
    }

    public getPlayerName(): PlayerName {
        return this.playerName;
    }

    public getPlayerCountry(): Country {
        return this.playerCountry;
    }
}
