<script setup lang="ts">
import axios from 'axios';
import { Award, Banknote, BookHeart, BriefcaseBusiness, CalendarDays, ChartColumn, ChartSpline, CircleHelp, Coins, Contact, Dumbbell, Earth, HandCoins, Handshake, Landmark, ListOrdered, LogOut, Mail, Martini, MessageCircleHeart, Network, Newspaper, Settings, Shield, Shirt, SlidersHorizontal, Trophy, Users } from 'lucide-vue-next';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useLayout } from '../composables/layout';
import { OtBadge } from './badge';
import OtDivider from './Divider.vue';
import OtIconButton from './IconButton.vue';
import OtNavigationItem from './NavigationItem.vue';
import OtNavigationItemLegacy from './NavigationItemLegacy.vue';
import OtSearch from './Search.vue';
import OtTutorial from './Tutorial.vue';

const routeSettings = Routing.generate('OnlinetennisBundle_settings', { _locale: locale });
const routeNews = Routing.generate('news_list', { _locale: locale });
const numberOfUnreadMessages = ref(0);
const numberOfUnreadNews = ref(0);

const { isNavigationOpen, toolbarExtension, width } = useLayout();
const classes = computed(() => {
    const classes = [];

    classes.push(isNavigationOpen.value ? 'translate-x-0' : '-translate-x-full');
    classes.push(toolbarExtension.value ? 'top-24' : 'top-12');
    if (width.value < 1400) {
        classes.push('elevation-8');
    }

    return classes;
});

const { t } = useI18n();

const _locale = locale;

onMounted(() => {
    axios
        .get('/api-navigation')
        .then((response) => {
            numberOfUnreadMessages.value = response.data.number_of_unread_messages;
            numberOfUnreadNews.value = response.data.number_of_unread_news;
        });
});

function logout() {
    window.emitter.emit('logout');
    window.location.href = Routing.generate('logout');
}

function link(link: string, params: Record<string, string>) {
    return Routing.generate(link, params);
}
</script>

<template>
    <div
        class="fixed left-0 w-[256px] z-10 transition-transform duration-300 bg-stone-300"
        :class="classes"
        style="height: calc(100% - 48px);"
    >
        <div class="h-full overflow-y-auto">
            <ot-search class="mx-2 my-4" />
            <div class="flex justify-around">
                <ot-icon-button :href="routeSettings"><settings /></ot-icon-button>

                <ot-icon-button v-if="numberOfUnreadNews === 0" :href="routeNews"><newspaper /></ot-icon-button>
                <ot-badge v-else :content="numberOfUnreadNews">
                    <ot-icon-button :href="routeNews"><newspaper /></ot-icon-button>
                </ot-badge>

                <ot-icon-button v-if="numberOfUnreadMessages === 0" to="/messages/inbox"><mail /></ot-icon-button>
                <ot-badge v-else :content="numberOfUnreadMessages">
                    <ot-icon-button to="/messages/inbox"><mail /></ot-icon-button>
                </ot-badge>

                <ot-icon-button to="/faq"><circle-help /></ot-icon-button>
                <ot-icon-button @click="logout"><log-out /></ot-icon-button>
            </div>
            <ot-divider class="my-2" />
            <ot-tutorial />
            <nav>
                <ul>
                    <ot-navigation-item route="/calendar" :title="t('kalender.kalender_kalender')"><calendar-days class="inline" /></ot-navigation-item>
                    <ot-navigation-item route="/training" :title="t('training.training_training')"><dumbbell class="inline" /></ot-navigation-item>
                    <ot-navigation-item-legacy :link="link('OnlinetennisBundle_tactics', { _locale })" :title="t('general.taktik')"><sliders-horizontal class="inline" /></ot-navigation-item-legacy>
                    <ot-navigation-item-legacy :link="link('OnlinetennisBundle_equipment', { _locale })" :title="t('internalEquipment.headline')"><shirt class="inline" /></ot-navigation-item-legacy>
                    <ot-navigation-item route="/skilltree" :title="t('internalSkillTree.headline')"><network class="inline" /></ot-navigation-item>
                    <ot-navigation-item route="/staff" :title="t('general.staff')"><contact class="inline" /></ot-navigation-item>
                    <ot-navigation-item route="/doubles-requests" :title="t('subheader.subheader_doubles')"><users class="inline" /></ot-navigation-item>
                    <ot-navigation-item-legacy :link="link('OnlinetennisBundle_team', { _locale })" :title="t('general.team')"><shield class="inline" /></ot-navigation-item-legacy>
                    <ot-navigation-item-legacy :link="link('showmatch', { _locale })" :title="t('internalShowmatch.headline')"><handshake class="inline" /></ot-navigation-item-legacy>
                    <ot-navigation-item-legacy :link="link('user_tournament_overview', { _locale })" :title="t('general.userTournament')"><award class="inline" /></ot-navigation-item-legacy>
                    <ot-navigation-item-legacy :link="link('ranking_user_entrylist', { _locale })" :title="t('general.rankings')"><list-ordered class="inline" /></ot-navigation-item-legacy>
                    <ot-navigation-item route="/statistics" :title="t('general.statistiken')"><chart-column class="inline" /></ot-navigation-item>
                    <ot-navigation-item-legacy :link="link('otcup_standings', { _locale })" :title="t('internalOTCup.headline')"><trophy class="inline" /></ot-navigation-item-legacy>
                    <ot-navigation-item-legacy :link="link('daviscup_draw', { _locale })" :title="t('general.daviscup')"><earth class="inline" /></ot-navigation-item-legacy>
                    <ot-navigation-item route="/payment" :title="t('royal.royal_bank')"><landmark class="inline" /></ot-navigation-item>
                    <ot-navigation-item route="/balance" :title="t('finanzen.finanzen_accounting')"><coins class="inline" /></ot-navigation-item>
                    <ot-navigation-item-legacy :link="link('sponsor', { _locale })" :title="t('finanzen.finanzen_sponsor')"><banknote class="inline" /></ot-navigation-item-legacy>
                    <ot-navigation-item-legacy :link="link('OnlinetennisBundle_stockExchange', { _locale })" :title="t('finanzen.finanzen_boerse')"><chart-spline class="inline" /></ot-navigation-item-legacy>
                    <ot-navigation-item route="/donation" :title="t('finanzen.finanzen_spenden')"><hand-coins class="inline" /></ot-navigation-item>
                    <ot-navigation-item-legacy :link="link('statussymbol_show', { _locale })" :title="t('finanzen.finanzen_statussymbole')"><martini class="inline" /></ot-navigation-item-legacy>
                    <ot-navigation-item-legacy :link="link('OnlinetennisBundle_assistant', { _locale })" :title="t('general.manager')"><briefcase-business class="inline" /></ot-navigation-item-legacy>
                </ul>
                <ot-divider class="my-2" />
                <ul>
                    <ot-navigation-item route="/feedback" :title="t('general.feedback')">
                        <message-circle-heart class="inline" />
                    </ot-navigation-item>
                    <ot-navigation-item route="/suggestions" :title="t('general.suggestions')">
                        <book-heart class="inline" />
                    </ot-navigation-item>
                </ul>
            </nav>
        </div>
    </div>
</template>
