<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const store = useStore();
const { t } = useI18n();

const formattedDate = computed(() => {
    if (store.state.date.season === null) {
        return '';
    }

    return `${t('general.year')} ${store.state.date.season} ${t('general.week')} ${store.state.date.week} ${t('general.day')} ${store.state.date.day}`;
});
</script>

<template>
    <div>{{ formattedDate }}</div>
</template>
