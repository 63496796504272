<script setup lang="ts">
import type { AccordionTriggerProps } from 'radix-vue';
import type { HTMLAttributes } from 'vue';
import { ChevronDown } from 'lucide-vue-next';
import { AccordionHeader, AccordionTrigger } from 'radix-vue';
import { computed } from 'vue';
import { cn } from '../utils';

interface Props extends AccordionTriggerProps {
    class?: HTMLAttributes['class'];
}
const props = defineProps<Props>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});
</script>

<template>
    <accordion-header class="flex">
        <accordion-trigger
            v-bind="delegatedProps"
            class="flex flex-1 gap-2 items-center justify-between py-4 font-medium transition-all [&[data-state=open]>svg]:rotate-180"
        >
            <div :class="cn('w-full', props.class)">
                <slot />
            </div>
            <chevron-down class="h-4 w-4 shrink-0 transition-transform duration-300" />
        </accordion-trigger>
    </accordion-header>
</template>
