<script setup lang="ts">
import axios from 'axios';
import { onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import OtDoublesSelect from '../../components/DoublesSelect.vue';
import OtLoader from '../../components/Loader.vue';

interface Props {
    id: string;
}
const props = defineProps<Props>();

const { t } = useI18n();

const loading = ref(true);

const numberOfWeeks = ref(0);
const doubles = ref(null);
const highestEntrylistPosition = ref(null);
const titlesThisSeason = ref(null);
const titlesLastSeason = ref(null);
const titlesOverall = ref(null);
const lastSeasonsPoints = ref([]);

const compareDoublesId = ref(props.id);
const compareDoubles = ref(null);

onMounted(() => load());

async function load() {
    const { data } = await axios.get(`/api/doubles-profiles/${props.id}/statistics`);

    numberOfWeeks.value = data.data.numberOfWeeks;
    doubles.value = data.data.doubles;
    highestEntrylistPosition.value = data.data.highestEntrylistPosition;
    titlesThisSeason.value = data.data.titlesThisSeason;
    titlesLastSeason.value = data.data.titlesLastSeason;
    titlesOverall.value = data.data.titlesOverall;
    lastSeasonsPoints.value = data.data.lastSeasonsPoints;

    await loadCompareDoubles();
}

watch(compareDoublesId, () => loadCompareDoubles());
async function loadCompareDoubles() {
    const { data } = await axios.get(`/api/doubles/${compareDoublesId.value}/statistics`);

    compareDoubles.value = data.data;

    loading.value = false;
}

function getColor(value1: number, value2: number) {
    if (value1 === value2) {
        return '#000000';
    }
    else if (value1 > value2) {
        return '#6fb015';
    }
    else {
        return '#ff0000';
    }
}
</script>

<template>
    <ot-loader :loading="loading" standalone>
        <table class="striped mb-1">
            <tbody>
                <tr>
                    <td></td>
                    <td><b>{{ doubles.name }}</b></td>
                    <td>
                        <ot-doubles-select
                            :default="{ key: doubles.id, value: doubles.name }"
                            @update-doubles-id="id => compareDoublesId = id"
                        />
                    </td>
                </tr>
                <tr>
                    <td>{{ t('profil.profil_karrierebeginn') }}</td>
                    <td :style="`color: ${getColor(compareDoubles.insertDate, doubles.insertDate)}`">{{ doubles.insertDate }}</td>
                    <td :style="`color: ${getColor(doubles.insertDate, compareDoubles.insertDate)}`">{{ compareDoubles.insertDate }}</td>
                </tr>
                <tr>
                    <td>{{ t('profil.profil_el') }}</td>
                    <td :style="`color: ${getColor(compareDoubles.entrylist.position, doubles.entrylist.position)}`">{{ doubles.entrylist.position }}. ({{ doubles.entrylist.points }}&nbsp;{{ t('profil.profil_pkt') }})</td>
                    <td :style="`color: ${getColor(doubles.entrylist.position, compareDoubles.entrylist.position)}`">{{ compareDoubles.entrylist.position }}. ({{ compareDoubles.entrylist.points }}&nbsp;{{ t('profil.profil_pkt') }})</td>
                </tr>
                <tr>
                    <td>{{ t('profil.profil_cr') }}</td>
                    <td :style="`color: ${getColor(compareDoubles.championsrace.position, doubles.championsrace.position)}`">{{ doubles.championsrace.position }}. ({{ doubles.championsrace.points }}&nbsp;{{ t('profil.profil_pkt') }})</td>
                    <td :style="`color: ${getColor(doubles.championsrace.position, compareDoubles.championsrace.position)}`">{{ compareDoubles.championsrace.position }}. ({{ compareDoubles.championsrace.points }}&nbsp;{{ t('profil.profil_pkt') }})</td>
                </tr>
                <tr>
                    <td>{{ t('profil.profil_hoechstewrposition') }}</td>
                    <td :style="`color: ${getColor(compareDoubles.highestEntrylistPosition, highestEntrylistPosition)}`">{{ highestEntrylistPosition }}.</td>
                    <td :style="`color: ${getColor(highestEntrylistPosition, compareDoubles.highestEntrylistPosition)}`">{{ compareDoubles.highestEntrylistPosition }}.</td>
                </tr>
                <tr>
                    <td>{{ t('profil.profil_titeldiesesjahr') }}</td>
                    <td :style="`color: ${getColor(titlesThisSeason, compareDoubles.titlesThisSeason)}`">{{ titlesThisSeason }}</td>
                    <td :style="`color: ${getColor(compareDoubles.titlesThisSeason, titlesThisSeason)}`">{{ compareDoubles.titlesThisSeason }}</td>
                </tr>
                <tr>
                    <td>{{ t('profil.profil_titelletztesjahr') }}</td>
                    <td :style="`color: ${getColor(titlesLastSeason, compareDoubles.titlesLastSeason)}`">{{ titlesLastSeason }}</td>
                    <td :style="`color: ${getColor(compareDoubles.titlesLastSeason, titlesLastSeason)}`">{{ compareDoubles.titlesLastSeason }}</td>
                </tr>
                <tr>
                    <td>{{ t('profil.profil_titelkarriere') }}</td>
                    <td :style="`color: ${getColor(titlesOverall, compareDoubles.titlesOverall)}`">{{ titlesOverall }}</td>
                    <td :style="`color: ${getColor(compareDoubles.titlesOverall, titlesOverall)}`">{{ compareDoubles.titlesOverall }}</td>
                </tr>
                <tr>
                    <td>{{ t('profil.profil_preisgelderdiesesjahr') }}</td>
                    <td :style="`color: ${getColor(doubles.wonPrizeMoney.currentSeason, compareDoubles.wonPrizeMoney.currentSeason)}`">{{ doubles.wonPrizeMoney.currentSeason }}$</td>
                    <td :style="`color: ${getColor(compareDoubles.wonPrizeMoney.currentSeason, doubles.wonPrizeMoney.currentSeason)}`">{{ compareDoubles.wonPrizeMoney.currentSeason }}$</td>
                </tr>
                <tr>
                    <td>{{ t('profil.profil_preisgelderletztesjahr') }}</td>
                    <td :style="`color: ${getColor(doubles.wonPrizeMoney.lastSeason, compareDoubles.wonPrizeMoney.lastSeason)}`">{{ doubles.wonPrizeMoney.lastSeason }}$</td>
                    <td :style="`color: ${getColor(compareDoubles.wonPrizeMoney.lastSeason, doubles.wonPrizeMoney.lastSeason)}`">{{ compareDoubles.wonPrizeMoney.lastSeason }}$</td>
                </tr>
                <tr>
                    <td>{{ t('profil.profil_presigelderkarriere') }}</td>
                    <td :style="`color: ${getColor(doubles.wonPrizeMoney.overall, compareDoubles.wonPrizeMoney.overall)}`">{{ doubles.wonPrizeMoney.overall }}$</td>
                    <td :style="`color: ${getColor(compareDoubles.wonPrizeMoney.overall, doubles.wonPrizeMoney.overall)}`">{{ compareDoubles.wonPrizeMoney.overall }}$</td>
                </tr>
                <tr>
                    <td>{{ t('profil.profil_gespieltematches') }}</td>
                    <td :style="`color: ${getColor(doubles.matchRecord.overall.played, compareDoubles.matchRecord.overall.played)}`">{{ doubles.matchRecord.overall.played }}</td>
                    <td :style="`color: ${getColor(compareDoubles.matchRecord.overall.played, doubles.matchRecord.overall.played)}`">{{ compareDoubles.matchRecord.overall.played }}</td>
                </tr>
                <tr>
                    <td>{{ t('profil.profil_gewonnenematches') }}</td>
                    <td :style="`color: ${getColor(doubles.matchRecord.overall.victories, compareDoubles.matchRecord.overall.victories)}`">{{ doubles.matchRecord.overall.victories }}</td>
                    <td :style="`color: ${getColor(compareDoubles.matchRecord.overall.victories, doubles.matchRecord.overall.victories)}`">{{ compareDoubles.matchRecord.overall.victories }}</td>
                </tr>
                <tr>
                    <td>{{ t('profil.profil_verlorenematches') }}</td>
                    <td :style="`color: ${getColor(compareDoubles.matchRecord.overall.defeats, doubles.matchRecord.overall.defeats)}`">{{ doubles.matchRecord.overall.defeats }}</td>
                    <td :style="`color: ${getColor(doubles.matchRecord.overall.defeats, compareDoubles.matchRecord.overall.defeats)}`">{{ compareDoubles.matchRecord.overall.defeats }}</td>
                </tr>
                <tr>
                    <td>{{ t('profil.profil_siegquote') }}</td>
                    <td :style="`color: ${getColor(doubles.matchRecord.overall.quota, compareDoubles.matchRecord.overall.quota)}`">{{ doubles.matchRecord.overall.quota }}%</td>
                    <td :style="`color: ${getColor(compareDoubles.matchRecord.overall.quota, doubles.matchRecord.overall.quota)}`">{{ compareDoubles.matchRecord.overall.quota }}%</td>
                </tr>
            </tbody>
        </table>

        <div>{{ t('profil.profil_points_per_week') }}</div>
        <div>{{ t('profile_user.best18tournaments') }}</div>
        <div class="flex flex-wrap gap-1 mb-1">
            <div
                v-for="i in numberOfWeeks"
                :key="i"
                class="border border-gray-700 p-1 grow min-w-10"
            >
                <div class="text-center text-black/50 text-sm">{{ i }}</div>
                <div
                    class="text-center"
                    :class="{ 'bg-green-700': lastSeasonsPoints[i].counted }"
                >
                    <a
                        v-if="lastSeasonsPoints[i].tournament"
                        :href="`/${locale}/tournament/${lastSeasonsPoints[i].tournament.id}`"
                        :title="lastSeasonsPoints[i].tournament.name"
                    >
                        {{ lastSeasonsPoints[i].points }}
                    </a>
                    <span v-else>{{ lastSeasonsPoints[i].points }}</span>
                </div>
            </div>
        </div>
    </ot-loader>
</template>
