<script>
import Button from './Button.vue';
import { OtDialog, OtDialogBody, OtDialogContent, OtDialogFooter, OtDialogHeader } from './dialog';

export default {
    components: {
        OtButton: Button,
        OtDialog,
        OtDialogBody,
        OtDialogContent,
        OtDialogFooter,
        OtDialogHeader,
    },
    props: {
        modelValue: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            visible: false,
        };
    },
    watch: {
        modelValue(newValue) {
            this.visible = newValue;
        },
    },
    methods: {
        hide() {
            this.visible = false;
            this.$emit('input', false);
        },
    },
};
</script>

<template>
    <ot-dialog :open="visible" @update:open="$emit('input', $event)">
        <ot-dialog-content>
            <ot-dialog-header class="bg-red-700">{{ $t('general.error') }}</ot-dialog-header>
            <ot-dialog-body>
                <slot />
            </ot-dialog-body>
            <ot-dialog-footer>
                <ot-button @click="hide()" variant="secondary">{{ $t('general.close') }}</ot-button>
            </ot-dialog-footer>
        </ot-dialog-content>
    </ot-dialog>
</template>
