<script>
import PlayerProfileAchievements from '../pages/player_profile/PlayerProfileAchievements.vue';
import PlayerProfileAdmin from '../pages/player_profile/PlayerProfileAdmin.vue';
import PlayerProfileCareer from '../pages/player_profile/PlayerProfileCareer.vue';
import PlayerProfileDescription from '../pages/player_profile/PlayerProfileDescription.vue';
import PlayerProfileOverview from '../pages/player_profile/PlayerProfileOverview.vue';
import PlayerProfilePrivate from '../pages/player_profile/PlayerProfilePrivate.vue';
import PlayerProfileSkills from '../pages/player_profile/PlayerProfileSkills.vue';
import PlayerProfileStatistics from '../pages/player_profile/PlayerProfileStatistics.vue';
import PlayerProfileTournaments from '../pages/player_profile/PlayerProfileTournaments.vue';
import PlayerProfileTrophies from '../pages/player_profile/PlayerProfileTrophies.vue';
import { OtTabs, OtTabsContent, OtTabsList, OtTabsTrigger } from './tabs';

export default {
    components: {
        OtTabs,
        OtTabsContent,
        OtTabsList,
        OtTabsTrigger,
        OtPlayerProfileDescription: PlayerProfileDescription,
        OtPlayerProfilePrivate: PlayerProfilePrivate,
        OtPlayerProfileAdmin: PlayerProfileAdmin,
        OtPlayerProfileCareer: PlayerProfileCareer,
        OtPlayerProfileAchievements: PlayerProfileAchievements,
        OtPlayerProfileTournaments: PlayerProfileTournaments,
        OtPlayerProfileSkills: PlayerProfileSkills,
        OtPlayerProfileStatistics: PlayerProfileStatistics,
        OtPlayerProfileOverview: PlayerProfileOverview,
        OtPlayerProfileTrophies: PlayerProfileTrophies,
    },
    props: {
        userId: {
            type: Number,
            required: true,
        },
        roleUser: {
            type: Boolean,
            required: true,
        },
        roleMod: {
            type: Boolean,
            required: true,
        },
    },
};
</script>

<template>
    <ot-tabs id="player_profile" default-value="overview">
        <ot-tabs-list>
            <ot-tabs-trigger value="overview">{{ $t('profile_user.tab_overview') }}</ot-tabs-trigger>
            <ot-tabs-trigger value="description">{{ $t('profile_user.tab_description') }}</ot-tabs-trigger>
            <ot-tabs-trigger value="skills">{{ $t('profile_user.tab_skills') }}</ot-tabs-trigger>
            <ot-tabs-trigger value="statistics">{{ $t('profile_user.tab_statistics') }}</ot-tabs-trigger>
            <ot-tabs-trigger value="trophies">{{ $t('profile_user.tab_trophies') }}</ot-tabs-trigger>
            <ot-tabs-trigger value="grandslam">{{ $t('profile_user.tab_grandslam') }}</ot-tabs-trigger>
            <ot-tabs-trigger value="career">{{ $t('profile_user.tab_career') }}</ot-tabs-trigger>
            <ot-tabs-trigger value="private">{{ $t('profile_user.tab_private') }}</ot-tabs-trigger>
            <ot-tabs-trigger value="achievements" v-if="roleUser">{{ $t('profile_user.tab_achievements') }}</ot-tabs-trigger>
            <ot-tabs-trigger value="admin" v-if="roleMod">{{ $t('profile_user.tab_admin') }}</ot-tabs-trigger>
        </ot-tabs-list>
        <ot-tabs-content value="overview">
            <ot-player-profile-overview :id="userId" />
        </ot-tabs-content>
        <ot-tabs-content value="description">
            <ot-player-profile-description :id="userId" />
        </ot-tabs-content>
        <ot-tabs-content value="skills">
            <ot-player-profile-skills :id="userId" />
        </ot-tabs-content>
        <ot-tabs-content value="statistics">
            <ot-player-profile-statistics :id="userId" />
        </ot-tabs-content>
        <ot-tabs-content value="trophies">
            <ot-player-profile-trophies :id="userId" />
        </ot-tabs-content>
        <ot-tabs-content value="grandslam">
            <ot-player-profile-tournaments :id="userId" />
        </ot-tabs-content>
        <ot-tabs-content value="career">
            <ot-player-profile-career :id="userId" />
        </ot-tabs-content>
        <ot-tabs-content value="private">
            <ot-player-profile-private :id="userId" />
        </ot-tabs-content>
        <ot-tabs-content value="achievements" v-if="roleUser">
            <ot-player-profile-achievements :id="userId" />
        </ot-tabs-content>
        <ot-tabs-content value="admin" v-if="roleMod">
            <ot-player-profile-admin :id="userId" />
        </ot-tabs-content>
    </ot-tabs>
</template>
