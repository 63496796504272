<script>
import Card from './Card.vue';
import FixtureMatch from './FixtureMatch.vue';
import { OtHint } from './hint';
import Player from './Player.vue';
import Sets from './Sets.vue';
import Subheader from './Subheader.vue';
import Surface from './Surface.vue';

export default {
    components: {
        OtCard: Card,
        OtFixturesMatch: FixtureMatch,
        OtSets: Sets,
        OtSurface: Surface,
        OtPlayer: Player,
        OtSubheader: Subheader,
        OtHint,
    },
    data() {
        return {
            matches: [],
        };
    },
    created() {
        window.emitter.on('hot-matches', matches => this.matches = matches);
    },
    methods: {
        showSubheader(index) {
            if (index === 0) {
                return true;
            }

            if (this.matches[index].surface !== this.matches[index - 1].surface) {
                return true;
            }

            if (this.matches[index].sets_to_play !== this.matches[index - 1].sets_to_play) {
                return true;
            }

            if (this.matches[index].competition !== this.matches[index - 1].competition) {
                return true;
            }

            if (this.matches[index].tournament_name !== this.matches[index - 1].tournament_name) {
                return true;
            }

            if (this.matches[index].round !== this.matches[index - 1].round) {
                return true;
            }

            return false;
        },
        getSubheader(index) {
            const match = this.matches[index];

            switch (match.competition) {
                case 'tournament':
                    return `${match.tournament_name} ${this.$t(`tournament.${match.tournament_round}`)}`;

                case 'showmatch':
                    return this.$t('general.showmatch');

                case 'otcup':
                    return `${this.$t('internalOTCup.headline')} ${this.$t('internalOTCup.league')} ${match.otcup_league}`;

                case 'daviscup':
                    return `${this.$t('general.daviscup')} ${this.$t('general.league', { param1: match.daviscup_league })}`;

                case 'usertournament':
                    return `${match.tournament_name} ${match.tournament_round} (${this.$t('general.userTournament')})`;
            }

            throw new Error(`Unknown competition "${match.competition}".`);
        },
        isLinked(index) {
            const match = this.matches[index];

            return match.competition === 'tournament' || match.competition === 'usertournament';
        },
        getLink(index) {
            const match = this.matches[index];

            if (match.competition === 'tournament' || match.competition === 'usertournament') {
                return Routing.generate('OnlinetennisBundle_profileTournament', { _locale: locale, id: this.matches[index].tournament_id });
            }

            throw new Error(`Match for competition "${match.competition}" cannot be linked.`);
        },
    },
};
</script>

<template>
    <ot-card :title="$t('currentLiveMatches.headline')">
        <div
            v-if="matches.length === 0"
            class="p-4"
        >
            <ot-hint type="info">{{ $t('internalLivematch.no_matches') }}</ot-hint>
        </div>
        <template v-for="(match, index) in matches" :key="index">
            <hr
                v-if="index !== 0 && !showSubheader(index)"
                class="border-b border-gray-500"
            >
            <ot-subheader v-if="showSubheader(index)" :class="{ 'mt-4': index !== 0 }">
                <ot-surface :type="match.surface" class="mr-1" />
                <ot-sets :sets="match.sets_to_play" class="mr-1" />
                <a v-if="isLinked(index)" :href="getLink(index)">{{ getSubheader(index) }}</a>
                <template v-else>{{ getSubheader(index) }}</template>
            </ot-subheader>
            <ot-fixtures-match
                :value="match.score"
                :spectators="match.spectators"
                :match-id="match.id"
                :match-type="match.type"
            >
                <template #participant-one>
                    <template v-if="match.is_doubles">
                        <ot-player :id="match.player1.id" :country="match.country1.id">{{ match.player1.name }}</ot-player>
                        <ot-player :id="match.player2.id" :country="match.country2.id">{{ match.player2.name }}</ot-player>
                    </template>
                    <template v-else>
                        <ot-player :id="match.player1.id" :country="match.country1.id">{{ match.player1.name }}</ot-player>
                    </template>
                </template>
                <template #participant-two>
                    <template v-if="match.is_doubles">
                        <ot-player :id="match.player3.id" :country="match.country3.id">{{ match.player3.name }}</ot-player>
                        <ot-player :id="match.player4.id" :country="match.country4.id">{{ match.player4.name }}</ot-player>
                    </template>
                    <template v-else>
                        <ot-player :id="match.player2.id" :country="match.country2.id">{{ match.player2.name }}</ot-player>
                    </template>
                </template>
            </ot-fixtures-match>
        </template>
    </ot-card>
</template>
