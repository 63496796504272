<script setup lang="ts">
import axios from 'axios';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import OtCard from '../components/Card.vue';
import OtLoader from '../components/Loader.vue';
import OtPlayer from '../components/Player.vue';
import { OtTabs, OtTabsContent, OtTabsList, OtTabsTrigger } from '../components/tabs';
import OtDoublesProfileCareer from './doublesProfile/DoublesProfileCareer.vue';
import OtDoublesProfileOverview from './doublesProfile/DoublesProfileOverview.vue';
import OtDoublesProfileStatistics from './doublesProfile/DoublesProfileStatistics.vue';
import OtDoublesProfileTrophies from './doublesProfile/DoublesProfileTrophies.vue';

interface Props {
    id: string;
}
const props = defineProps<Props>();

const loading = ref(true);
const doubles = ref(null);

onMounted(() => load());

async function load() {
    const { data } = await axios.get(`/api/doubles-profiles/${props.id}`);

    doubles.value = data.data.doubles;

    loading.value = false;
}

const { t } = useI18n();
</script>

<template>
    <ot-loader :loading="loading">
        <ot-card>
            <h3 class="p-4 flex gap-2">
                <ot-player :id="doubles.player1.id" :country="doubles.player1.country.id">{{ doubles.player1.first_name }} {{ doubles.player1.last_name }}</ot-player>
                /
                <ot-player :id="doubles.player2.id" :country="doubles.player2.country.id">{{ doubles.player2.first_name }} {{ doubles.player2.last_name }}</ot-player>
            </h3>
            <ot-tabs id="doubles_profile" default-value="overview">
                <ot-tabs-list>
                    <ot-tabs-trigger value="overview">{{ t('profile_doubles.tab_overview') }}</ot-tabs-trigger>
                    <ot-tabs-trigger value="statistics">{{ t('profile_doubles.tab_statistics') }}</ot-tabs-trigger>
                    <ot-tabs-trigger value="trophies">{{ t('profile_doubles.tab_trophies') }}</ot-tabs-trigger>
                    <ot-tabs-trigger value="career">{{ t('profile_doubles.tab_career') }}</ot-tabs-trigger>
                </ot-tabs-list>
                <ot-tabs-content value="overview">
                    <ot-doubles-profile-overview :id="id" />
                </ot-tabs-content>
                <ot-tabs-content value="statistics">
                    <ot-doubles-profile-statistics :id="id" />
                </ot-tabs-content>
                <ot-tabs-content value="trophies">
                    <ot-doubles-profile-trophies :id="id" />
                </ot-tabs-content>
                <ot-tabs-content value="career">
                    <ot-doubles-profile-career :id="id" />
                </ot-tabs-content>
            </ot-tabs>
        </ot-card>
    </ot-loader>
</template>
