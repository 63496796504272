<script setup lang="ts">
import axios from 'axios';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import OtPlayer from '../../components/Player.vue';
import OtSubheader from '../../components/Subheader.vue';

interface Props {
    id: string;
}
const props = defineProps<Props>();

const { t } = useI18n();

const loading = ref(true);

const headToHeadDoubles = ref([]);

onMounted(() => load());

async function load() {
    const { data } = await axios.get(`/api/doubles-profiles/${props.id}/career`);

    headToHeadDoubles.value = data.data.headToHeadDoubles;

    loading.value = false;
}
</script>

<template>
    <div class="flex gap-3">
        <div class="grow basis-1/2" v-if="headToHeadDoubles.length > 0">
            <table class="striped">
                <tr
                    v-for="opponent in headToHeadDoubles"
                    :key="opponent.id"
                >
                    <td>
                        <ot-player :id="opponent.id1" :country="opponent.country1.id">{{ opponent.name1 }}</ot-player>
                    </td>
                    <td>
                        <ot-player :id="opponent.id2" :country="opponent.country2.id">{{ opponent.name2 }}</ot-player>
                    </td>
                    <td>({{ opponent.entrylist_position }}.)</td>
                    <td nowrap>
                        <span v-if="opponent.valueWin">{{ opponent.valueWin }}</span>
                        <span v-else>0</span>
                        :
                        <span v-if="opponent.valueLose">{{ opponent.valueLose }}</span>
                        <span v-else>0</span>
                    </td>
                </tr>
            </table>
        </div>

        <div class="flex justify-around w-full">
            <div>
                <ot-subheader>{{ t('profil.profil_weltranglistenverlauf') }}</ot-subheader>
                <img :src="`/doubles/entrylistChart/${id}`" alt="" />
            </div>

            <div>
                <ot-subheader>{{ t('profil.profil_erfolgsquotebelag') }}</ot-subheader>
                <img :src="`/doubles/surfaceChart/${id}`" alt="" width="180" height="180" />
            </div>
        </div>
    </div>
</template>
