<script setup lang="ts">
import axios from 'axios';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import OtButton from '../components/Button.vue';
import OtCard from '../components/Card.vue';
import OtLoader from '../components/Loader.vue';
import OtSubheader from '../components/Subheader.vue';
import usePayment from '../composables/payment';

const loading = ref(true);

const micropaymentProject = ref('');
const micropaymentEnableDebit = ref(false);
const micropaymentEnableSms = ref(false);

const bigpointPaymentUrl = ref('');

const multiplicator = ref(1);

onMounted(async () => {
    const { data } = await axios.get(Routing.generate('OnlinetennisBundle_showPayment', { _locale: locale }));

    micropaymentProject.value = data.micropaymentProject;
    micropaymentEnableDebit.value = data.enableMicropayment_debit;
    micropaymentEnableSms.value = data.enableMicropayment_sms;

    bigpointPaymentUrl.value = data.paymentURL;

    multiplicator.value = data.multi;

    loading.value = false;
});

const store = useStore();
const user = computed(() => store.getters.user);

function linkHandypay(amount: number) {
    return `https://billing.micropayment.de/handypay/event/?account=24283&project=${micropaymentProject.value}&theme=x1&gfx=onlinetennis&title=${user.value.id}&amount=${amount}`;
}

function linkDebit(amount: number) {
    return `https://billing.micropayment.de/lastschrift/event/?account=24283&project=${micropaymentProject.value}&theme=x1&gfx=onlinetennis&title=${user.value.id}&amount=${amount}`;
}

const { showPopup } = usePayment();
</script>

<template>
    <ot-loader :loading="loading">
        <div class="flex gap-3">
            <div class="grow basis-1/2">
                <ot-card class="p-2">
                    <img v-if="multiplicator === 2" src="./../images/payment/multi2.png" alt="Multiplicator 2" />
                    <img v-if="multiplicator === 3" src="./../images/payment/multi3.png" alt="Multiplicator 3" />
                    <template v-if="micropaymentEnableSms">
                        <ot-subheader>SMS</ot-subheader>
                        <ot-button block class="mb-2" :href="linkHandypay(199)">50.000 OT Dollar für 1,99 €</ot-button>
                        <ot-button block class="mb-2" :href="linkHandypay(299)">75.000 OT Dollar für 2,99 €</ot-button>
                    </template>

                    <template v-if="micropaymentEnableDebit">
                        <ot-subheader>Lastschrift</ot-subheader>
                        <ot-button block class="mb-2" :href="linkDebit(1000)">325.000 OT Dollar für 10,00 €</ot-button>
                        <ot-button block class="mb-2" :href="linkDebit(2500)">900.000 OT Dollar für 25,00 €</ot-button>
                        <ot-button block class="mb-2" :href="linkDebit(4900)">2.000.000 OT Dollar für 49,00 €</ot-button>
                        <ot-button block class="mb-2" :href="linkDebit(9900)">4.500.000 OT Dollar für 99,00 €</ot-button>
                    </template>

                    <ot-subheader>{{ $t('internalPayment.otdollar') }}</ot-subheader>
                    <ot-button block class="mb-2" @click="showPopup(bigpointPaymentUrl)">{{ $t('internalPayment.otdollar') }}</ot-button>

                    <ot-subheader>{{ $t('internalPayment.supporter') }}</ot-subheader>
                    <ot-button block class="mb-2" @click="showPopup(bigpointPaymentUrl)">{{ $t('internalPayment.supporter') }}</ot-button>
                </ot-card>
            </div>
            <div class="grow basis-1/2">
                <ot-card legacy>
                    <ot-subheader>{{ $t('internalPayment.supporter') }}</ot-subheader>
                    <ul class="divide-y divide-gray-400">
                        <li class="px-4 pb-2">{{ $t('internalPayment.feature_matchinfo') }}</li>
                        <li class="px-4 py-2">{{ $t('internalPayment.feature_tournamentPreview') }}</li>
                        <li class="px-4 pt-2">{{ $t('internalPayment.feature_tacticalTips') }}</li>
                    </ul>
                </ot-card>
            </div>
        </div>
    </ot-loader>
</template>
