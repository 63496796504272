<script setup lang="ts">
import type { HTMLAttributes } from 'vue';
import { X } from 'lucide-vue-next';
import { DialogClose, DialogTitle } from 'radix-vue';
import { cn } from '../utils';

const props = defineProps<{
    class?: HTMLAttributes['class'];
}>();
</script>

<template>
    <div :class="cn('flex justify-between p-4 text-center sm:rounded-t-lg bg-green-700 text-white', props.class)">
        <dialog-title class="text-xl flex flex-wrap font-weight-medium">
            <slot />
        </dialog-title>
        <dialog-close>
            <x class="w-4 h-4" />
            <span class="sr-only">Close</span>
        </dialog-close>
    </div>
</template>
