<script>
import Flag from './Flag.vue';
import PlayerTooltip from './PlayerTooltip.vue';
import { OtTooltip, OtTooltipContent, OtTooltipTrigger } from './tooltip';

export default {
    components: {
        OtFlag: Flag,
        OtPlayerTooltip: PlayerTooltip,
        OtTooltip,
        OtTooltipContent,
        OtTooltipTrigger,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
        country: {
            type: Number,
            required: true,
        },
        ignoreFriendStatus: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        isSelf() {
            if (this.ignoreFriendStatus) {
                return false;
            }

            return this.id === this.$store.state.user.id;
        },
        isFriend() {
            if (this.ignoreFriendStatus) {
                return false;
            }

            if (this.isSelf) {
                return false;
            }

            return this.$store.state.user.friends.some(friend => friend.id === this.id);
        },
    },
};
</script>

<template>
    <div class="flex gap-2 overflow-hidden whitespace-nowrap text-ellipsis">
        <ot-flag :id="country" />
        <ot-tooltip>
            <ot-tooltip-trigger>
                <a class="user_link" :class="{ self: isSelf, friend: isFriend }" :href="`#/player/${id}`"><slot /></a>
            </ot-tooltip-trigger>
            <ot-tooltip-content side="bottom" class="elevation-2 p-0">
                <ot-player-tooltip :id="id" />
            </ot-tooltip-content>
        </ot-tooltip>
    </div>
</template>

<style scoped>
.user_link.self {
    color: #F44336 !important;
}
.user_link.friend {
    color: #2196F3 !important;
}
</style>
