<script>
export default {
};
</script>

<template>
    <div>
        <router-view />
        <slot v-if="$router.currentRoute.value.matched.length === 0" />
    </div>
</template>
