<script setup lang="ts">
import type { SelectGroupProps } from 'radix-vue';
import type { HTMLAttributes } from 'vue';
import { SelectGroup } from 'radix-vue';
import { computed } from 'vue';
import { cn } from '../utils';

const props = defineProps<SelectGroupProps & { class?: HTMLAttributes['class'] }>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});
</script>

<template>
    <select-group
        v-bind="delegatedProps"
        :class="cn('p-1 w-full', props.class)"
    >
        <slot />
    </select-group>
</template>
