<script>
import axios from 'axios';
import Loader from '../../components/Loader.vue';
import OtPlayerSelect from '../../components/PlayerSelect.vue';
import Skillbar from '../../components/Skillbar.vue';

export default {
    components: {
        OtLoader: Loader,
        OtPlayerSelect,
        OtSkillbar: Skillbar,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            data: null,
            comparePlayer: null,
            skillChartUrl: Routing.generate('OnlinetennisBundle_skillChart', { id: this.id }),
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            axios
                .get(`/api/player-profiles/${this.id}/skills`)
                .then((response) => {
                    this.data = response.data.data;
                    this.compareUser(this.data.logged_in_player.id);
                });
        },
        compareUser(userId) {
            if (userId === null) {
                return;
            }

            axios
                .get(`/api/players/${userId}/skills`)
                .then((response) => {
                    this.comparePlayer = response.data.data;
                    this.loading = false;
                });
        },
        getColor(skill1, skill2) {
            if (skill1 === skill2) {
                return '#000000';
            }
            else if (skill1 > skill2) {
                return '#6fb015';
            }
            else {
                return '#ff0000';
            }
        },
    },
};
</script>

<template>
    <ot-loader :loading="loading" standalone>
        <template v-if="!loading">
            <template v-if="data.player.id !== data.logged_in_player.id">
                <p>{{ $t('general.scouting_bonus', { param1: data.scouting_bonus }) }}</p>
                <template v-if="!data.is_feature_toggle_scouting_bonus">{{ $t('general.hint_scouting_bonus_next_season') }}</template>
            </template>

            <table class="striped profile_skills">
                <tbody>
                    <tr>
                        <td />
                        <td colspan="2"><b>{{ data.player.name }}</b></td>
                        <td colspan="2">
                            <ot-player-select
                                :default="{ key: data.logged_in_player.id, value: data.logged_in_player.name }"
                                @update-player-id="compareUser($event)"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_baseshot') }}</td>
                        <td :style="`color: ${getColor(data.skills.baseshot, comparePlayer.baseshot)}`">{{ data.skills.baseshot }}</td>
                        <td><ot-skillbar :value="data.skills.baseshot" /></td>
                        <td :style="`color: ${getColor(comparePlayer.baseshot, data.skills.baseshot)}`">{{ comparePlayer.baseshot }}</td>
                        <td><ot-skillbar :value="comparePlayer.baseshot" /></td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_volley') }}</td>
                        <td :style="`color: ${getColor(data.skills.volley, comparePlayer.volley)}`">{{ data.skills.volley }}</td>
                        <td><ot-skillbar :value="data.skills.volley" /></td>
                        <td :style="`color: ${getColor(comparePlayer.volley, data.skills.volley)}`">{{ comparePlayer.volley }}</td>
                        <td><ot-skillbar :value="comparePlayer.volley" /></td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_aufschlag') }}</td>
                        <td :style="`color: ${getColor(data.skills.service, comparePlayer.service)}`">{{ data.skills.service }}</td>
                        <td><ot-skillbar :value="data.skills.service" /></td>
                        <td :style="`color: ${getColor(comparePlayer.service, data.skills.service)}`">{{ comparePlayer.service }}</td>
                        <td><ot-skillbar :value="comparePlayer.service" /></td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_schnelligkeit') }}</td>
                        <td :style="`color: ${getColor(data.skills.speed, comparePlayer.speed)}`">{{ data.skills.speed }}</td>
                        <td><ot-skillbar :value="data.skills.speed" /></td>
                        <td :style="`color: ${getColor(comparePlayer.speed, data.skills.speed)}`">{{ comparePlayer.speed }}</td>
                        <td><ot-skillbar :value="comparePlayer.speed" /></td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_spielintelligenz') }}</td>
                        <td :style="`color: ${getColor(data.skills.intelligence, comparePlayer.intelligence)}`">{{ data.skills.intelligence }}</td>
                        <td><ot-skillbar :value="data.skills.intelligence" /></td>
                        <td :style="`color: ${getColor(comparePlayer.intelligence, data.skills.intelligence)}`">{{ comparePlayer.intelligence }}</td>
                        <td><ot-skillbar :value="comparePlayer.intelligence" /></td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_schlaghaerte') }}</td>
                        <td :style="`color: ${getColor(data.skills.power, comparePlayer.power)}`">{{ data.skills.power }}</td>
                        <td><ot-skillbar :value="data.skills.power" /></td>
                        <td :style="`color: ${getColor(comparePlayer.power, data.skills.power)}`">{{ comparePlayer.power }}</td>
                        <td><ot-skillbar :value="comparePlayer.power" /></td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_schlagpraezision') }}</td>
                        <td :style="`color: ${getColor(data.skills.precision, comparePlayer.precision)}`">{{ data.skills.precision }}</td>
                        <td><ot-skillbar :value="data.skills.precision" /></td>
                        <td :style="`color: ${getColor(comparePlayer.precision, data.skills.precision)}`">{{ comparePlayer.precision }}</td>
                        <td><ot-skillbar :value="comparePlayer.precision" /></td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_kondition') }}</td>
                        <td :style="`color: ${getColor(data.skills.stamina, comparePlayer.stamina)}`">{{ data.skills.stamina }}</td>
                        <td><ot-skillbar :value="data.skills.stamina" /></td>
                        <td :style="`color: ${getColor(comparePlayer.stamina, data.skills.stamina)}`">{{ comparePlayer.stamina }}</td>
                        <td><ot-skillbar :value="comparePlayer.stamina" /></td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_motivation') }}</td>
                        <td :style="`color: ${getColor(data.skills.motivation, comparePlayer.motivation)}`">{{ data.skills.motivation }}</td>
                        <td><ot-skillbar :value="data.skills.motivation" /></td>
                        <td :style="`color: ${getColor(comparePlayer.motivation, data.skills.motivation)}`">{{ comparePlayer.motivation }}</td>
                        <td><ot-skillbar :value="comparePlayer.motivation" /></td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_matchpraxis') }}</td>
                        <td :style="`color: ${getColor(data.skills.practice, comparePlayer.practice)}`">{{ data.skills.practice }}</td>
                        <td><ot-skillbar :value="data.skills.practice" /></td>
                        <td :style="`color: ${getColor(comparePlayer.practice, data.skills.practice)}`">{{ comparePlayer.practice }}</td>
                        <td><ot-skillbar :value="comparePlayer.practice" /></td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_durchschnitt') }}</td>
                        <td :style="`color: ${getColor(data.skills.average, comparePlayer.average)}`">{{ data.skills.average }}</td>
                        <td><ot-skillbar :value="data.skills.average" /></td>
                        <td :style="`color: ${getColor(comparePlayer.average, data.skills.average)}`">{{ comparePlayer.average }}</td>
                        <td><ot-skillbar :value="comparePlayer.average" /></td>
                    </tr>
                </tbody>
            </table>

            <div>{{ $t('profil.profil_ungefaehreangaben') }}</div>
            <div>
                <div>{{ $t('profil.profil_skillverteilung') }}</div>
                <img :src="skillChartUrl" alt="">
            </div>
        </template>
    </ot-loader>
</template>

<style scoped>
table.profile_skills td {
    padding: 2px;
}
</style>
