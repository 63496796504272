<script setup lang="ts">
import type { DialogTriggerProps } from 'radix-vue';
import { DialogTrigger } from 'radix-vue';

const props = defineProps<DialogTriggerProps>();
</script>

<template>
    <dialog-trigger v-bind="props">
        <slot />
    </dialog-trigger>
</template>
