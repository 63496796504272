<script>
import Button from './Button.vue';
import { OtDialog, OtDialogBody, OtDialogContent, OtDialogFooter, OtDialogHeader } from './dialog';

export default {
    components: {
        OtButton: Button,
        OtDialog,
        OtDialogBody,
        OtDialogContent,
        OtDialogFooter,
        OtDialogHeader,
    },
    props: {
        modelValue: {
            type: Boolean,
            required: true,
        },
        data: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            visible: false,
        };
    },
    watch: {
        modelValue(newValue) {
            this.visible = newValue;
        },
    },
    methods: {
        hide() {
            $.post(Routing.generate('markAchievementMessageShown', { category: this.data.category, name: this.data.id }));
            this.visible = false;
            this.$emit('input', false);
        },
    },
};
</script>

<template>
    <ot-dialog :open="visible" @update:open="$emit('input', $event)">
        <ot-dialog-content>
            <ot-dialog-header>Achievement Unlocked!</ot-dialog-header>
            <ot-dialog-body>
                <div class="text-center">
                    <div class="text-xl font-bold">{{ data.name }}</div>
                    <img class="mx-auto my-4" :src="`/images/achievements/${data.category}/${data.id}.png`" :alt="data.name">
                    <div>{{ data.description }}</div>
                </div>
            </ot-dialog-body>
            <ot-dialog-footer>
                <ot-button @click="hide()">{{ $t('general.close') }}</ot-button>
            </ot-dialog-footer>
        </ot-dialog-content>
    </ot-dialog>
</template>
