<script>
import Background from '../components/Background.vue';
import Card from '../components/Card.vue';
import Flag from '../components/Flag.vue';
import LandingpageFooter from '../components/LandingpageFooter.vue';
import LoginForm from '../components/LoginForm.vue';
import SignupForm from '../components/SignupForm.vue';
import { OtTabs, OtTabsContent, OtTabsList, OtTabsTrigger } from '../components/tabs';

export default {
    components: {
        OtLandingpageFooter: LandingpageFooter,
        OtLoginForm: LoginForm,
        OtSignupForm: SignupForm,
        OtBackground: Background,
        OtCard: Card,
        OtTabs,
        OtTabsContent,
        OtTabsList,
        OtTabsTrigger,
        OtFlag: Flag,
    },
    props: {
        tour: {
            type: String,
            required: true,
        },
        classicHost: {
            type: String,
            required: true,
        },
        speedHost: {
            type: String,
            required: true,
        },
        rid: {
            type: String,
            required: true,
        },
        appstoreAndroid: {
            type: String,
            required: true,
        },
        appstoreIos: {
            type: String,
            required: true,
        },
        top10: {
            type: String,
            required: true,
        },
        finishedMatches: {
            type: String,
            required: true,
        },
    },
    computed: {
        parsedTop10() {
            const top10 = JSON.parse(this.top10);
            top10.sort((a, b) => a.position - b.position);

            return top10;
        },
        parsedFinishedMatches() {
            return JSON.parse(this.finishedMatches);
        },
    },
};
</script>

<template>
    <div>
        <ot-background section="landingpage" />

        <div class="container w-full max-w-full mx-auto relative pb-14">
            <div class="md:flex md:flex-wrap">
                <div class="right-column">
                    <ot-login-form :tour="tour" :host="{ classic: classicHost, speed: speedHost }" />
                </div>
                <div class="left-column">
                    <div class="hidden md:block text-center mt-2">
                        <img src="../images/logo.png" alt="Onlinetennis">
                    </div>
                    <ot-card transparent :title="$t('externalSignup.headline')" class="p-2">
                        <ot-signup-form :tour="tour" :rid="rid" :host="{ classic: classicHost, speed: speedHost }" />
                    </ot-card>
                    <ot-card class="mt-2">
                        <ot-tabs id="external_landingpage" default-value="trailer">
                            <ot-tabs-list>
                                <ot-tabs-trigger value="trailer">{{ $t('landingpage.trailer') }}</ot-tabs-trigger>
                                <ot-tabs-trigger value="description">{{ $t('landingpage.description') }}</ot-tabs-trigger>
                                <ot-tabs-trigger value="entrylist">{{ $t('landingpage.entrylist') }}</ot-tabs-trigger>
                                <ot-tabs-trigger value="matches">{{ $t('landingpage.matches') }}</ot-tabs-trigger>
                            </ot-tabs-list>
                            <ot-tabs-content value="trailer"><video src="../images/trailer.mp4" controls muted style="width: 100%; height: auto" /></ot-tabs-content>
                            <ot-tabs-content value="description">{{ $t('startseite.startseite_text') }}</ot-tabs-content>
                            <ot-tabs-content value="entrylist">
                                <table class="striped">
                                    <tr v-for="row in parsedTop10" :key="row.position">
                                        <td>{{ row.position }}.</td>
                                        <td><ot-flag :id="row.country.id" /></td>
                                        <td>{{ row.firstname }} {{ row.lastname }}</td>
                                    </tr>
                                </table>
                            </ot-tabs-content>
                            <ot-tabs-content value="matches">
                                <table>
                                    <tr v-for="(match, index) in parsedFinishedMatches" :key="index">
                                        <td><ot-flag :id="match.player1.country.id" /></td>
                                        <td>{{ match.player1.firstname }} {{ match.player1.lastname }}</td>
                                        <td>-</td>
                                        <td><ot-flag :id="match.player2.country.id" /></td>
                                        <td>{{ match.player2.firstname }} {{ match.player2.lastname }}</td>
                                        <td>{{ match.result }}</td>
                                    </tr>
                                </table>
                            </ot-tabs-content>
                        </ot-tabs>
                    </ot-card>
                    <div class="flex justify-center mt-2">
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.cunningsoft.onlinetennis&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"><img alt="Get it on Google Play" :src="appstoreAndroid"></a>
                        <!--                    <a target="_blank" href="https://itunes.apple.com/us/app/onlinetennis/id1064667588"><img alt="Get it on the App Store" :src="appstoreIos" /></a> -->
                    </div>
                </div>
            </div>
        </div>
        <ot-landingpage-footer />
    </div>
</template>

<style scoped>
@reference "../css/onlinetennis.css";

.left-column {
    margin-left: 5%;
    max-width: 90%;
    width: 100%;
    padding-right: 8px;
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    @apply md:order-1;
    @apply md:ml-[16%];
    @apply md:max-w-[25%];
}
.right-column {
    @apply ml-[5%];
    @apply md:ml-0;
    @apply max-w-[90%];
    @apply md:max-w-[50%];
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    flex-direction: column;
    @apply md:order-2;
}
</style>
