<script>
export default {
    props: {
        optionA: {
            type: String,
        },
        optionB: {
            type: String,
        },
        default: {
            type: String,
        },
        labelOptionA: {
            type: String,
        },
        labelOptionB: {
            type: String,
        },
    },
    data() {
        return {
            option: null,
        };
    },
    created() {
        this.option = this.default ? this.default : this.optionA;
    },
    methods: {
        toggle() {
            this.option = this.option === this.optionA ? this.optionB : this.optionA;
            this.$emit('toggle', this.option);
        },
    },
};
</script>

<template>
    <div class="text-sm border-2 border-zinc-500 h-[34px] p-0.5 flex cursor-pointer" @click="toggle">
        <div class="h-full flex grow shrink-0 relative">
            <div class="absolute left-0 top-0 bg-green-700 w-full h-full border-2 border-green-700" :class="[option === optionA ? 'block' : 'hidden']" />
            <div class="relative w-full px-1.5 py-[3px] whitespace-nowrap text-center" :class="{ 'text-white': option === optionA }">{{ labelOptionA }}</div>
        </div>
        <div class="h-full flex grow shrink-0 relative">
            <div class="absolute left-0 top-0 bg-green-700 w-full h-full border-2 border-green-700" :class="[option === optionB ? 'block' : 'hidden']" />
            <div class="relative w-full px-1.5 py-[3px] whitespace-nowrap text-center" :class="{ 'text-white': option === optionB }">{{ labelOptionB }}</div>
        </div>
    </div>
</template>
