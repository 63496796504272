import type MessageCreateDto from '../../dto/request/MessageCreateDto';
import axios from 'axios';
import MessagesGetResponseDto from '../../dto/response/MessagesGetResponseDto';
import MessagesListGetResponseDto from '../../dto/response/MessagesListGetResponseDto';

export default class MessageRepository {
    public find(id: string): Promise<MessagesGetResponseDto> {
        return new Promise((resolve, reject) => {
            axios
                .get(`/api/messages/${id}`)
                .then(response => resolve(new MessagesGetResponseDto(response.data.data)))
                .catch(error => reject(error));
        });
    }

    public findAll(params: { type: string }): Promise<MessagesListGetResponseDto[]> {
        return new Promise((resolve, reject) => {
            axios
                .get(`/api/messages/${params.type}`)
                .then(response => resolve(response.data.data.map(data => new MessagesListGetResponseDto(data))))
                .catch(error => reject(error));
        });
    }

    public updateStatus(id: number, status: string): Promise<void> {
        return new Promise((resolve, reject) => {
            axios
                .put(`/api/messages/${id.toString()}/status`, { status })
                .then(() => resolve())
                .catch(error => reject(error));
        });
    }

    public delete(id: number): Promise<void> {
        return new Promise((resolve, reject) => {
            axios
                .delete(`/api/messages/${id.toString()}`)
                .then(() => resolve())
                .catch(error => reject(error));
        });
    }

    public add(dto: MessageCreateDto): Promise<void> {
        return new Promise((resolve, reject) => {
            axios
                .post('/api/messages', {
                    message: dto.getMessage(),
                    receiverId: dto.getReceiverId(),
                    title: dto.getTitle(),
                })
                .then(() => resolve())
                .catch(error => reject(error));
        });
    }
}
