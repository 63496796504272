<script>
import { Plus } from 'lucide-vue-next';
import SuggestionRepository from '../domain/suggestion/SuggestionRepository';
import SuggestionCreateDto from '../dto/request/SuggestionCreateDto';
import Button from './Button.vue';
import { OtDialog, OtDialogBody, OtDialogContent, OtDialogFooter, OtDialogHeader, OtDialogTrigger } from './dialog';
import OtIconButton from './IconButton.vue';
import { OtTextField } from './text-field';
import { OtTextarea } from './textarea';

export default {
    components: {
        OtIconButton,
        OtButton: Button,
        OtTextarea,
        OtTextField,
        OtDialog,
        OtDialogBody,
        OtDialogContent,
        OtDialogFooter,
        OtDialogHeader,
        OtDialogTrigger,
        Plus,
    },
    data() {
        return {
            open: false,
            title: '',
            content: '',
        };
    },
    computed: {
        canBeSubmitted() {
            return this.title !== '' && this.content !== '';
        },
    },
    methods: {
        post() {
            if (!this.canBeSubmitted) {
                return;
            }

            this.open = false;
            new SuggestionRepository()
                .add(new SuggestionCreateDto(this.title, this.content))
                .then(() => {
                    this.title = '';
                    this.content = '';
                    this.$emit('added');
                });
        },
    },
};
</script>

<template>
    <ot-dialog v-model:open="open">
        <ot-dialog-trigger>
            <ot-icon-button>
                <plus class="text-white" />
            </ot-icon-button>
        </ot-dialog-trigger>
        <ot-dialog-content>
            <ot-dialog-header>{{ $t('general.suggestion_post') }}</ot-dialog-header>
            <ot-dialog-body>
                <ot-text-field v-model="title" :label="$t('general.suggestion_title')" />
                <ot-textarea v-model="content" :label="$t('general.suggestion_content')" />
            </ot-dialog-body>
            <ot-dialog-footer>
                <ot-button :disabled="!canBeSubmitted" @click="post">{{ $t('general.suggestion_button_post') }}</ot-button>
            </ot-dialog-footer>
        </ot-dialog-content>
    </ot-dialog>
</template>
