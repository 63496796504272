export default function usePayment() {
    function showPopup(address: string) {
        const width = 1310;
        const height = 879;
        const left = (window.innerWidth - width) / 2 + window.screenX;
        const top = (window.innerHeight - height) / 2 + window.screenY;

        const payment = window.open(address, 'Onlinetennis', `width=${width},height=${height},left=${left},top=${top}`);
        payment.focus();
    }

    return {
        showPopup,
    };
}
