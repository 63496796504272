import * as Sentry from '@sentry/vue';
import { FastForward, Globe, Rewind, Search, Shield, SkipBack, SkipForward, Star, TreePine, Trophy, User } from 'lucide-vue-next';
import mitt from 'mitt';
import { createApp } from 'vue';
import { OtAccordion, OtAccordionContent, OtAccordionItem, OtAccordionTrigger } from '../components/accordion';
import Avatar from '../components/Avatar.vue';
import Background from '../components/Background.vue';
import BrandIcon from '../components/BrandIcon.vue';
import Button from '../components/Button.vue';
import Card from '../components/Card.vue';
import Chat from '../components/Chat.vue';
import ChatContainer from '../components/ChatContainer.vue';
import ChatVisitors from '../components/ChatVisitors.vue';
import Content from '../components/Content.vue';
import CountrySelect from '../components/CountrySelect.vue';
import DashboardRankings from '../components/DashboardRankings.vue';
import { OtDialog, OtDialogBody, OtDialogClose, OtDialogContent, OtDialogFooter, OtDialogHeader, OtDialogTrigger } from '../components/dialog';
import Divider from '../components/Divider.vue';
import EquipmentIcon from '../components/EquipmentIcon.vue';
import EquipmentSelect from '../components/EquipmentSelect.vue';
import FixtureMatch from '../components/FixtureMatch.vue';
import Flag from '../components/Flag.vue';
import Footer from '../components/Footer.vue';
import FriendList from '../components/FriendList.vue';
import { OtHint } from '../components/hint';
import HotMatches from '../components/HotMatches.vue';
import InputGroup from '../components/InputGroup.vue';
import LoginForm from '../components/LoginForm.vue';
import Main from '../components/Main.vue';
import Mod from '../components/Mod.vue';
import Modal from '../components/Modal.vue';
import ModalManager from '../components/ModalManager.vue';
import Navigation from '../components/Navigation.vue';
import Notifier from '../components/Notifier.vue';
import Overlay from '../components/Overlay.vue';
import Player from '../components/Player.vue';
import PushNotificationRequest from '../components/PushNotificationRequest.vue';
import Sets from '../components/Sets.vue';
import Skillbar from '../components/Skillbar.vue';
import Subheader from '../components/Subheader.vue';
import Surface from '../components/Surface.vue';
import { OtSwitch } from '../components/switch';
import { OtTabs, OtTabsContent, OtTabsList, OtTabsTrigger } from '../components/tabs';
import TacticsSlider from '../components/TacticsSlider.vue';
import { OtTextField } from '../components/text-field';
import { OtTextarea } from '../components/textarea';
import Toggle from '../components/Toggle.vue';
import Toolbar from '../components/Toolbar.vue';
import { OtTooltip, OtTooltipContent, OtTooltipTrigger } from '../components/tooltip';
import FakeRename from '../pages_no_router/FakeRename.vue';
import Landingpage from '../pages_no_router/Landingpage.vue';
import Livematch from '../pages_no_router/Livematch.vue';
import Showmatch from '../pages_no_router/Showmatch.vue';
import UserTournament from '../pages_no_router/UserTournament.vue';
import authentication from './component/Authentication.js';
import i18n from './component/i18n.js';
import Mercure from './component/Mercure.ts';
import router from './component/router.js';
import { store } from './component/store.js';
import './component/axios.js'; // needed to apply the authentication and interceptors

window.emitter = mitt();

window.vue = createApp({
    components: {
        'ot-accordion': OtAccordion,
        'ot-accordion-content': OtAccordionContent,
        'ot-accordion-item': OtAccordionItem,
        'ot-accordion-trigger': OtAccordionTrigger,
        'ot-avatar': Avatar,
        'ot-background': Background,
        'ot-brand-icon': BrandIcon,
        'ot-button': Button,
        'ot-card': Card,
        'ot-chat': Chat,
        'ot-chat-container': ChatContainer,
        'ot-chat-visitors': ChatVisitors,
        'ot-content': Content,
        'ot-country-select': CountrySelect,
        'ot-dashboard-rankings': DashboardRankings,
        'ot-dialog': OtDialog,
        'ot-dialog-body': OtDialogBody,
        'ot-dialog-close': OtDialogClose,
        'ot-dialog-content': OtDialogContent,
        'ot-dialog-footer': OtDialogFooter,
        'ot-dialog-header': OtDialogHeader,
        'ot-dialog-trigger': OtDialogTrigger,
        'ot-divider': Divider,
        'ot-equipment-icon': EquipmentIcon,
        'ot-equipment-select': EquipmentSelect,
        'ot-fake-rename': FakeRename,
        'ot-fixture-match': FixtureMatch,
        'ot-flag': Flag,
        'ot-friend-list': FriendList,
        'ot-footer': Footer,
        'ot-hint': OtHint,
        'ot-hot-matches': HotMatches,
        'ot-input-group': InputGroup,
        'ot-login-form': LoginForm,
        'ot-landingpage': Landingpage,
        'ot-livematch': Livematch,
        'ot-main': Main,
        'ot-mod': Mod,
        'ot-modal': Modal,
        'ot-modal-manager': ModalManager,
        'ot-notifier': Notifier,
        'ot-overlay': Overlay,
        'ot-push-notification-request': PushNotificationRequest,
        'ot-toolbar': Toolbar,
        'ot-navigation': Navigation,
        'ot-player': Player,
        'ot-sets': Sets,
        'ot-showmatch': Showmatch,
        'ot-skillbar': Skillbar,
        'ot-subheader': Subheader,
        'ot-surface': Surface,
        'ot-switch': OtSwitch,
        'ot-tabs': OtTabs,
        'ot-tabs-content': OtTabsContent,
        'ot-tabs-list': OtTabsList,
        'ot-tabs-trigger': OtTabsTrigger,
        'ot-tactics-slider': TacticsSlider,
        'ot-text-field': OtTextField,
        'ot-textarea': OtTextarea,
        'ot-toggle': Toggle,
        'ot-tooltip': OtTooltip,
        'ot-tooltip-content': OtTooltipContent,
        'ot-tooltip-trigger': OtTooltipTrigger,
        'ot-user-tournament': UserTournament,
        Star,
        TreePine,
        Trophy,
        User,
        Shield,
        Globe,
        Search,
        SkipBack,
        SkipForward,
        FastForward,
        Rewind,
    },
    created() {
        if (authentication.isAuthenticated()) {
            this.$store.dispatch('loadUser');
            this.$store.dispatch('loadDate');
        }
        window.emitter.on('translations_loaded', () => {
            this.translationsLoaded = true;

            // this event is used by legacy inline javascript to trigger e.g. javascript functions that rely on the fact that elements are already shown
            this.$nextTick(() => window.dispatchEvent(new Event('page_visible')));
        });
        window.emitter.on('user_loaded', () => this.mercure = new Mercure(this.$store.getters.user.id));
        if (import.meta.env.PROD) {
            window.emitter.on('user_loaded', () => {
                Sentry.setUser({
                    id: this.$store.getters.user.id,
                    username: this.$store.getters.user.player.getFullname(),
                    useNewDrawLayout: this.useNewDrawLayout,
                });
            });
        }
        this.useNewDrawLayout = JSON.parse(window.localStorage.getItem('onlinetennis.use_new_draw_layout'));
    },
    data() {
        return {
            translationsLoaded: false,
            mercure: null,
            tournamentDrawTab: 'overview',
            useNewDrawLayout: false,
            noTeamTeamId: null,
            ranking: {
                country: undefined,
            },
        };
    },
    watch: {
        useNewDrawLayout() {
            window.localStorage.setItem('onlinetennis.use_new_draw_layout', JSON.stringify(this.useNewDrawLayout));
        },
    },
    computed: {
        visible() {
            return this.translationsLoaded;
        },
    },
    methods: {
        redirectToTeamProfile() {
            window.location = Routing.generate('OnlinetennisBundle_profileTeam', { _locale: locale, id: this.noTeamTeamId });
        },
        collectTennisball(hash) {
            window.location = Routing.generate('collecting_game', { _locale: locale, hash });
        },
    },
});

if (import.meta.env.PROD) {
    Sentry.init({
        app: window.vue,
        dsn: 'https://a1c9c62937ca47f0a0f4627adc56dc88@o406800.ingest.sentry.io/5296876',
        integrations: [
        ],
        environment: import.meta.env.MODE,
    });
}

window.vue.use(i18n);
window.vue.use(router);
window.vue.use(store);
window.vue.mount('#vue');

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => navigator.serviceWorker.register('/sw.js'));
}

authentication.listen();
