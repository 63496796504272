<script setup lang="ts">
import type { Ref } from 'vue';
import type Notification from '../dto/response/Notification';
import { nextTick, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { OtToast } from './toast';

const notifications: Ref<Notification[]> = ref([]);
const isVisible = ref(false);
const currentNotification: Ref<Notification | null> = ref(null);

const { t } = useI18n();

window.emitter.on('notification', (notification: Notification) => notifications.value.push(notification));

watch(isVisible, async () => {
    if (isVisible.value) {
        return;
    }

    if (notifications.value.length === 0) {
        return;
    }
    await nextTick();
    shiftMessage();
});

watch(notifications, () => {
    if (!isVisible.value) {
        shiftMessage();
    }
}, { deep: true });

function shiftMessage() {
    currentNotification.value = notifications.value[0];
    isVisible.value = true;
    notifications.value.shift();
}
</script>

<template>
    <ot-toast
        v-model="isVisible"
        :variant="currentNotification ? currentNotification.type : 'info'"
        :text="currentNotification ? t(`general.notification_${currentNotification.message}`, currentNotification.params) : ''"
        closable
    />
</template>
