import axios from 'axios';
import { createStore } from 'vuex';
import User from '../../dto/User.js';

export const store = createStore({
    state: {
        user: new User(),
        date: {
            season: null,
            week: null,
            day: null,
        },
    },
    getters: {
        user(state) {
            return state.user;
        },
        player(state) {
            return state.user.player;
        },
        userIsBannedForChat(state) {
            return state.user.chat.ban.isActive;
        },
        userChatBanExpireDate(state) {
            if (!state.user.chat.ban.expireDate) {
                return null;
            }

            return new Date(state.user.chat.ban.expireDate.date);
        },
    },
    mutations: {
        loadUser(state, data) {
            state.user = User.fromData(data.data);
            window.emitter.emit('user_loaded');
        },
        loadDate(state, data) {
            state.date = data;
        },
    },
    actions: {
        loadUser({ commit }) {
            axios
                .get('/api/users/me?include=player,player.country,ignored_users,friends')
                .then(response => commit('loadUser', response.data))
                .catch((error) => {
                    if (error.response.status === 401) {
                        window.location.href = '/';
                    }
                    else {
                        throw error;
                    }
                })
            ;
        },
        loadDate({ commit }) {
            axios
                .get('/api/date')
                .then(response => commit('loadDate', response.data));
        },
    },
});
