<script>
import axios from 'axios';
import Avatar from '../components/Avatar.vue';
import Loader from '../components/Loader.vue';
import Player from '../components/Player.vue';
import UsersFriendsMeListGetResponseDto from '../dto/response/UsersFriendsMeListGetResponseDto';

export default {
    components: {
        OtLoader: Loader,
        OtPlayer: Player,
        OtAvatar: Avatar,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            friends: [],
        };
    },
    async created() {
        const response = await axios.get('/api/users/me/friends');

        this.friends = response.data.data.map(data => new UsersFriendsMeListGetResponseDto(data));
        this.loading = false;
    },
};
</script>

<template>
    <ot-loader :loading="loading" standalone>
        <table class="striped">
            <tr v-for="friend in friends" :key="friend.getId()">
                <td class="px-2">
                    <div class="h-8 w-8 rounded-full overflow-hidden">
                        <ot-avatar icon :value="friend.getAvatar()" />
                    </div>
                </td>
                <td class="p-2"><ot-player :id="friend.getId()" :country="friend.getPlayerCountry().getId()" ignore-friend-status>{{ friend.getPlayerName().getFullName() }}</ot-player></td>
                <td class="p-2">
                    <div class="h-4 w-4 rounded-full" :class="friend.isOnline() ? 'bg-green-600' : 'bg-red-600'" />
                </td>
            </tr>
        </table>
    </ot-loader>
</template>
