<script setup lang="ts">
import axios from 'axios';
import { Star } from 'lucide-vue-next';
import { onMounted, ref } from 'vue';
import Notification from '../dto/response/Notification';
import OtEquipmentIcon from './EquipmentIcon.vue';
import { OtSelect, OtSelectContent, OtSelectGroup, OtSelectItem, OtSelectTrigger, OtSelectValue } from './select';
import OtSurface from './Surface.vue';

interface Props {
    competition: string;
    type: string;
}
const props = defineProps<Props>();

const modelValue = ref(null);
const list = ref([]);
onMounted(load);
async function load() {
    const { data } = await axios.get(`/api/players/me/equipment/${props.type}`);
    list.value = data.data;
    modelValue.value = list.value.find(equipment => equipment.isSetFor[props.competition])?.item.id.toString();
}

async function update() {
    await axios.put(`/api/players/me/equipment/${props.type}`, {
        competition: props.competition,
        id: Number.parseInt(modelValue.value),
    });
    window.emitter.emit('notification', Notification.success(`${props.type}_set`));
}
</script>

<template>
    <ot-select
        v-model="modelValue"
        @update:model-value="update"
    >
        <ot-select-trigger>
            <ot-select-value />
        </ot-select-trigger>
        <ot-select-content>
            <ot-select-group>
                <ot-select-item
                    v-for="equipment in list"
                    :key="equipment.item.id"
                    :value="equipment.item.id.toString()"
                >
                    <div class="flex gap-2">
                        <ot-equipment-icon :type="type" :id="equipment.item.id" :amount="equipment.amount" />
                        <div class="flex flex-col justify-center">
                            <div>
                                <ot-surface type="hardcourt"></ot-surface>
                                <star
                                    v-for="i in 5"
                                    :key="i"
                                    class="inline text-amber-700"
                                    :class="{ 'fill-amber-700': i <= equipment.item.qualityHardcourt / 30 }"
                                />
                            </div>
                            <div>
                                <ot-surface type="clay"></ot-surface>
                                <star
                                    v-for="i in 5"
                                    :key="i"
                                    class="inline text-amber-700"
                                    :class="{ 'fill-amber-700': i <= equipment.item.qualityClay / 30 }"
                                />
                            </div>
                        </div>
                        <div class="flex flex-col justify-center">
                            <div>
                                <ot-surface type="gras"></ot-surface>
                                <star
                                    v-for="i in 5"
                                    :key="i"
                                    class="inline text-amber-700"
                                    :class="{ 'fill-amber-700': i <= equipment.item.qualityGrass / 30 }"
                                />
                            </div>
                            <div>
                                <ot-surface type="carpet"></ot-surface>
                                <star
                                    v-for="i in 5"
                                    :key="i"
                                    class="inline text-amber-700"
                                    :class="{ 'fill-amber-700': i <= equipment.item.qualityCarpet / 30 }"
                                />
                            </div>
                        </div>
                        <div class="flex flex-col justify-center">{{ equipment.item.brand.name }} {{ equipment.item.name }}</div>
                    </div>
                </ot-select-item>
            </ot-select-group>
        </ot-select-content>
    </ot-select>
</template>
