<script setup lang="ts">
import axios from 'axios';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { OtAccordion, OtAccordionContent, OtAccordionItem, OtAccordionTrigger } from './accordion';
import OtButton from './Button.vue';
import OtCountrySelect from './CountrySelect.vue';
import OtDivider from './Divider.vue';
import { OtTextField } from './text-field';

const show = ref<string | string[] | undefined>('tutorial');

const supportMail = '<a href="mailto:support@onlinetennis.net">support@onlinetennis.net</a>';
const steps = [1, 18, 19, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 20, 21, 17];
const tutorial = ref<number | null>(null);
const currentStep = computed(() => steps.indexOf(tutorial.value) + 1);
const isFinished = computed(() => tutorial.value === -1);

const country = ref<number | null>(null);
const availableCountries = ref<{ name: string; id: number }[]>([]);

const routeSetCountry = Routing.generate('OnlinetennisBundle_setCountry', { locale });
const routeSetPlayerName = Routing.generate('OnlinetennisBundle_setPlayername', { locale });

const store = useStore();
const user = computed(() => store.getters.user);

const showContinueLink = computed(() => [1, 4, 9, 12, 13].includes(tutorial.value));

window.emitter.on('api_tutorial', t => tutorial.value = t);

onMounted(async () => {
    const response = await axios.get('/api/countries');
    availableCountries.value = response.data.data;
});

function updateTutorial() {
    tutorial.value = steps[currentStep.value];
    axios.post(Routing.generate('tutorial_update', { section: tutorial.value }));
}

async function finishTutorial() {
    await axios.post(Routing.generate('finishTutorial'));
    tutorial.value = -1;
}
</script>

<template>
    <div v-if="tutorial && !isFinished">
        <ot-accordion
            v-model="show"
            type="single"
            id="tutorial"
            collapsible
            class="px-5"
        >
            <ot-accordion-item value="tutorial">
                <ot-accordion-trigger>{{ $t('tutorial.title') }} {{ currentStep }} / 21</ot-accordion-trigger>
                <ot-accordion-content>
                    <div>
                        <span v-if="tutorial === 1" v-html="$t('tutorial.content_1')" />
                        <template v-if="tutorial === 18">
                            <span v-html="$t('tutorial.content_18')" />
                            <br><br>
                            <form method="post" :action="routeSetPlayerName">
                                <div class="flex flex-col gap-2">
                                    <ot-text-field name="firstname" :label="$t('externalSignup.firstname')" />
                                    <ot-text-field name="lastname" :label="$t('externalSignup.lastname')" />
                                    <ot-button submit>{{ $t('tutorial.continue') }}</ot-button>
                                </div>
                            </form>
                        </template>
                        <template v-if="tutorial === 19">
                            <span v-html="$t('tutorial.content_19', { param1: user.player.name.firstname })" />
                            <br><br>
                            <form method="post" :action="routeSetCountry">
                                <input type="hidden" name="country_id" :value="country">
                                <ot-country-select v-model="country" disallow-all />
                                <ot-button submit class="mt-2">{{ $t('tutorial.continue') }}</ot-button>
                            </form>
                        </template>
                        <span v-if="tutorial === 2" v-html="$t('tutorial.content_2')" />
                        <span v-if="tutorial === 3" v-html="$t('tutorial.content_3')" />
                        <span v-if="tutorial === 4" v-html="$t('tutorial.content_4')" />
                        <span v-if="tutorial === 5" v-html="$t('tutorial.content_5')" />
                        <span v-if="tutorial === 6" v-html="$t('tutorial.content_6')" />
                        <span v-if="tutorial === 7" v-html="$t('tutorial.content_7')" />
                        <span v-if="tutorial === 8" v-html="$t('tutorial.content_8')" />
                        <span v-if="tutorial === 9" v-html="$t('tutorial.content_9')" />
                        <span v-if="tutorial === 10" v-html="$t('tutorial.content_10')" />
                        <span v-if="tutorial === 11" v-html="$t('tutorial.content_11')" />
                        <span v-if="tutorial === 12" v-html="$t('tutorial.content_12')" />
                        <span v-if="tutorial === 13" v-html="$t('tutorial.content_13')" />
                        <span v-if="tutorial === 14" v-html="$t('tutorial.content_14')" />
                        <span v-if="tutorial === 15" v-html="$t('tutorial.content_15')" />
                        <span v-if="tutorial === 16" v-html="$t('tutorial.content_16')" />
                        <span v-if="tutorial === 20" v-html="$t('tutorial.content_20')" />
                        <span v-if="tutorial === 21" v-html="$t('tutorial.content_21')" />
                        <span v-if="tutorial === 17" v-html="$t('tutorial.content_17', { supportMail })" />
                    </div>
                    <ot-button
                        v-if="showContinueLink"
                        @click="updateTutorial()"
                    >
                        {{ $t('tutorial.continue') }}
                    </ot-button>
                    <ot-button v-if="tutorial === 17" @click="finishTutorial()">{{ $t('tutorial.finish') }}</ot-button>
                </ot-accordion-content>
            </ot-accordion-item>
        </ot-accordion>
        <ot-divider class="my-2" />
    </div>
</template>
