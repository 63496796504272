<script>
import Button from '../components/Button.vue';
import Card from '../components/Card.vue';
import MessageDataProvider from '../components/data-provider/MessageDataProvider.vue';
import Divider from '../components/Divider.vue';
import Header from '../components/Header.vue';
import MessageRepository from '../domain/message/MessageRepository';

export default {
    components: {
        OtMessageDataProvider: MessageDataProvider,
        OtCard: Card,
        OtButton: Button,
        OtDivider: Divider,
        OtHeader: Header,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    methods: {
        remove(message) {
            if (message.getStatus() === 'trashed') {
                new MessageRepository()
                    .delete(message.getId())
                    .then(() => this.$router.push('/messages/inbox'));
            }
            else {
                new MessageRepository()
                    .updateStatus(message.getId(), 'trashed')
                    .then(() => this.$router.push('/messages/inbox'));
            }
        },
    },
};
</script>

<template>
    <ot-card>
        <ot-message-data-provider :id="id" v-slot="{ data: message }" method="find">
            <div>
                <ot-header :title="message.getTitle()" />
                <div>
                    <div class="m-4 text-xs" v-html="message.getHeadline()" />
                    <div
                        class="m-4 whitespace-pre-wrap"
                        v-html="message.getContent()"
                    />
                    <ot-divider />
                    <div class="m-4 flex justify-between">
                        <ot-button
                            v-if="!message.isSystemMessage()"
                            primary
                            @click="$router.push(`/message-answer/${message.getSenderId()}?originalMessageId=${message.getId()}`)"
                        >
                            {{ $t('mail.mail_reply') }}
                        </ot-button>
                        <ot-button
                            variant="danger"
                            @click.prevent="remove(message)"
                        >
                            {{ $t('nachrichten.nachrichten_loeschen') }}
                        </ot-button>
                    </div>
                </div>
            </div>
        </ot-message-data-provider>
    </ot-card>
</template>
