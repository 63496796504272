<script>
import axios from 'axios';
import Button from './Button.vue';
import Loader from './Loader.vue';
import Player from './Player.vue';
import Sets from './Sets.vue';
import Surface from './Surface.vue';

export default {
    components: {
        OtLoader: Loader,
        OtSurface: Surface,
        OtSets: Sets,
        OtPlayer: Player,
        OtButton: Button,
    },
    data() {
        return {
            loading: true,
            requests: [],
        };
    },
    computed: {
        player() {
            return this.$store.getters.player;
        },
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            axios
                .get('/api/showmatch-requests')
                .then((response) => {
                    this.requests = response.data.data;
                    this.loading = false;
                    setTimeout(this.load, 5000);
                });
        },
        getAcceptLink(id) {
            return Routing.generate('showmatch_accept', { _locale: locale, id });
        },
        getCancelLink(id) {
            return Routing.generate('showmatch_cancel', { _locale: locale, id });
        },
    },
};
</script>

<template>
    <div>
        <ot-loader :loading="loading" standalone>
            <table>
                <tbody class="divide-y divide-gray-500">
                    <tr
                        v-for="request in requests"
                        :key="request.id"
                    >
                        <td class="px-4 py-1">
                            <ot-surface :type="request.surface" />
                        </td>
                        <td class="px-4 py-1">
                            <ot-sets :sets="request.sets" />
                        </td>
                        <td class="px-4 py-1">
                            <div class="icon" :class="{ equipment: request.is_equipment_allowed, no_equipment: !request.is_equipment_allowed }" />
                        </td>
                        <td class="px-4 py-1 text-sm">
                            <ot-player
                                :id="request.player.id"
                                :country="request.player.country.id"
                            >
                                {{ request.player.first_name }} {{ request.player.last_name }} ({{ request.player.entrylist_position }}.)
                            </ot-player>
                        </td>
                        <td class="px-4 py-1">{{ request.playing_time }}</td>
                        <td class="px-4 py-1">
                            <ot-button
                                v-if="request.player.id !== player.id"
                                :href="getAcceptLink(request.id)"
                            >
                                {{ $t('general.accept') }}
                            </ot-button>
                            <ot-button
                                v-if="request.player.id === player.id"
                                :href="getCancelLink(request.id)"
                            >
                                {{ $t('general.cancel') }}
                            </ot-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ot-loader>
    </div>
</template>

<style scoped>
.icon {
    position: relative;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
}
.equipment {
    background-image: url(./../images/showmatch/equipment.png);
}
.no_equipment {
    background-image: url(./../images/showmatch/no_equipment.png);
}
</style>
