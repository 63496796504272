import Country from '../../domain/country/Country';
import PlayerName from '../../domain/player/PlayerName';
import Ranking from '../../domain/player/Ranking';

export default class PlayerGetResponseDto {
    public static fromData(data: any): PlayerGetResponseDto {
        return new PlayerGetResponseDto(
            PlayerName.fromData(data.player_name.data),
            Country.fromData(data.country.data),
            Ranking.fromData(data.entrylist.data),
            Ranking.fromData(data.championsrace.data),
            data.is_online,
            data.is_mobile,
            data.avatar,
        );
    }

    private readonly name: PlayerName;
    private readonly country: Country;
    private readonly entrylist: Ranking;
    private readonly championsrace: Ranking;
    private readonly online: boolean;
    private readonly mobile: boolean;
    private readonly avatar: number;

    constructor(name: PlayerName, country: Country, entrylist: Ranking, championsrace: Ranking, online: boolean, mobile: boolean, avatar: number) {
        this.name = name;
        this.country = country;
        this.entrylist = entrylist;
        this.championsrace = championsrace;
        this.online = online;
        this.mobile = mobile;
        this.avatar = avatar;
    }

    public getName(): PlayerName {
        return this.name;
    }

    public getCountry(): Country {
        return this.country;
    }

    public getEntrylist(): Ranking {
        return this.entrylist;
    }

    public getChampionsrace(): Ranking {
        return this.championsrace;
    }

    public getAvatar(): number {
        return this.avatar;
    }

    public isOnline(): boolean {
        return this.online;
    }

    public isMobile(): boolean {
        return this.mobile;
    }
}
