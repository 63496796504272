<script>
export default {
    props: {
        type: {
            type: String,
            required: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            const classes = [];
            if (this.type === 'red') {
                classes.push('bg-red-500');
            }
            if (this.type === 'green') {
                classes.push('bg-green-700');
            }
            if (this.type === 'outlined') {
                classes.push('border');
                classes.push('border-gray-500');
                classes.push('font-gray-800');
            }
            else {
                classes.push('text-white');
            }
            if (this.small) {
                classes.push('h-4');
                classes.push('text-xs');
            }
            else {
                classes.push('h-8');
                classes.push('leading-5');
                classes.push('text-sm');
            }

            return classes;
        },
    },
};
</script>

<template>
    <div
        class="rounded-2xl inline-flex items-center overflow-hidden px-3"
        :class="classes"
    >
        <div class="h-full inline-flex items-center justify-between">
            <slot />
        </div>
    </div>
</template>
