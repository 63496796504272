<script>
import axios from 'axios';
import { OtAccordion, OtAccordionContent, OtAccordionItem, OtAccordionTrigger } from '../../components/accordion';
import Loader from '../../components/Loader.vue';
import Subheader from '../../components/Subheader.vue';

export default {
    components: {
        OtLoader: Loader,
        OtSubheader: Subheader,
        OtAccordion,
        OtAccordionContent,
        OtAccordionItem,
        OtAccordionTrigger,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            data: null,
            selectedCategory: 'summary',
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            axios
                .get(`/api/player-profiles/${this.id}/achievements`)
                .then((response) => {
                    this.data = response.data.data;
                    this.loading = false;
                });
        },
    },
};
</script>

<template>
    <ot-loader :loading="loading" standalone>
        <div v-if="!loading">
            <ot-subheader>{{ $t('achievements.latestAchievements') }}</ot-subheader>
            <ul style="padding-left: 0!important">
                <li
                    v-for="achievement in data.latest_achievements"
                    :key="achievement.id"
                    class="flex justify-between m-4"
                >
                    <div>
                        <div>{{ achievement.name }}</div>
                        <div class="text-sm text-gray-700">{{ achievement.insert_date }}</div>
                        <div class="text-sm text-gray-700">{{ achievement.description }}</div>
                    </div>
                    <img :src="`../../images/achievements/${achievement.category}/${achievement.id}.png`" :alt="achievement.name" class="rounded-full w-10 h-10" />
                </li>
            </ul>

            <ot-subheader>{{ $t('achievements.overview') }}</ot-subheader>
            <ot-accordion type="multiple">
                <ot-accordion-item
                    v-for="category in data.categories"
                    :key="category.key"
                    :value="category.key"
                >
                    <ot-accordion-trigger>
                        <div>
                            <div>{{ category.name }}</div>
                            <div>{{ category.number_of_achievements }} / {{ category.number_available }}</div>
                        </div>
                    </ot-accordion-trigger>
                    <ot-accordion-content
                        v-for="achievement in category.unlocked_achievements"
                        :key="achievement.id"
                        class="flex justify-between ml-4"
                    >
                        <div>
                            <div>{{ achievement.name }}</div>
                            <div class="text-sm text-gray-700">{{ achievement.insert_date }}</div>
                            <div class="text-sm text-gray-700">{{ achievement.description }}</div>
                        </div>
                        <img :src="`../../images/achievements/${category.key}/${achievement.id}.png`" :alt="achievement.name" class="rounded-full w-10 h-10" />
                    </ot-accordion-content>
                    <ot-accordion-content
                        v-for="achievement in category.locked_achievements"
                        :key="achievement.id"
                        class="flex justify-between ml-4"
                    >
                        <div>
                            <div>{{ achievement.name }}</div>
                            <div class="text-sm text-gray-700">{{ achievement.description }}</div>
                        </div>
                        <img src="../../images/achievements/unknown.png" alt="unknown" class="rounded-full w-10 h-10" />
                    </ot-accordion-content>
                </ot-accordion-item>
            </ot-accordion>
        </div>
    </ot-loader>
</template>
