import { createRouter, createWebHashHistory } from 'vue-router';

import Balance from '../../pages/Balance.vue';
import Calendar from '../../pages/Calendar.vue';
import Credits from '../../pages/Credits.vue';
import Donation from '../../pages/Donation.vue';
import DoublesProfile from '../../pages/DoublesProfile.vue';
import DoublesRequests from '../../pages/DoublesRequests.vue';
import Faq from '../../pages/Faq.vue';
import Feedback from '../../pages/Feedback.vue';
import Friends from '../../pages/Friends.vue';
import Message from '../../pages/Message.vue';
import MessageCreate from '../../pages/MessageCreate.vue';
import Messages from '../../pages/Messages.vue';
import MessagesInbox from '../../pages/MessagesInbox.vue';
import MessagesOutbox from '../../pages/MessagesOutbox.vue';
import MessagesTrash from '../../pages/MessagesTrash.vue';
import Payment from '../../pages/Payment.vue';
import PlayerProfile from '../../pages/PlayerProfile.vue';
import Skilltree from '../../pages/Skilltree.vue';
import Staff from '../../pages/Staff.vue';
import Statistics from '../../pages/Statistics.vue';
import Suggestion from '../../pages/Suggestion.vue';
import Suggestions from '../../pages/Suggestions.vue';
import Training from '../../pages/Training.vue';

const routes = [
    { path: '/balance', component: Balance },
    { path: '/credits', component: Credits },
    { path: '/calendar', component: Calendar },
    { path: '/staff', component: Staff },
    { path: '/doubles/:id', component: DoublesProfile, props: true },
    { path: '/doubles-requests', component: DoublesRequests },
    { path: '/faq', component: Faq },
    { path: '/friends', component: Friends },
    { path: '/payment', component: Payment },
    { path: '/player/:id', component: PlayerProfile, props: true },
    { path: '/training', component: Training },
    { path: '/donation', component: Donation },
    { path: '/skilltree', component: Skilltree },
    { path: '/statistics/:statistic', component: Statistics, props: true },
    { path: '/statistics', redirect: '/statistics/tournament-victories' },
    { path: '/suggestions', component: Suggestions },
    { path: '/suggestion/:id', component: Suggestion, props: true },
    { path: '/feedback', component: Feedback },
    { path: '/messages', component: Messages, children: [
        { path: 'inbox', component: MessagesInbox },
        { path: 'outbox', component: MessagesOutbox },
        { path: 'trash', component: MessagesTrash },
    ] },
    { path: '/message/:id', component: Message, props: true },
    { path: '/message-answer/:id', component: MessageCreate, props: route => ({ id: route.params.id, originalMessageId: route.query.originalMessageId }) },
    { path: '/message-create/:id', component: MessageCreate, props: route => ({ id: route.params.id, originalMessageId: null }) },
];

const router = createRouter({
    history: createWebHashHistory(),
    linkActiveClass: 'active',
    routes,
});

export default router;
