<script>
import { Star } from 'lucide-vue-next';
import Card from '../components/Card.vue';
import SuggestionDataProvider from '../components/data-provider/SuggestionDataProvider.vue';
import Header from '../components/Header.vue';
import IconButton from '../components/IconButton.vue';
import SuggestionPostDialog from '../components/SuggestionPostDialog.vue';
import SuggestionRepository from '../domain/suggestion/SuggestionRepository';

export default {
    components: {
        OtCard: Card,
        OtSuggestionDataProvider: SuggestionDataProvider,
        OtSuggestionPostDialog: SuggestionPostDialog,
        OtHeader: Header,
        OtIconButton: IconButton,
        Star,
    },
    computed: {
        language() {
            return locale;
        },
    },
    methods: {
        vote(suggestion, reload) {
            new SuggestionRepository()
                .vote(suggestion)
                .then(() => reload());
        },
        unvote(suggestion, reload) {
            new SuggestionRepository()
                .unvote(suggestion)
                .then(() => reload());
        },
        votesLeft(suggestions) {
            return 5 - suggestions.filterVoted().length;
        },
    },
};
</script>

<template>
    <ot-card>
        <ot-suggestion-data-provider v-slot="{ data: suggestions, reload }" collection method="findAll">
            <div>
                <ot-header :title="$t('general.suggestions')">
                    <ot-suggestion-post-dialog @added="reload()" />
                </ot-header>
                <p v-if="votesLeft(suggestions) > 0" class="m-4">{{ $t('general.suggestion_votes_to_cast', { '{votes}': votesLeft(suggestions) }) }}</p>
                <ul style="padding-left: 0!important;" class="divide-y divide-gray-400">
                    <li v-for="suggestion in suggestions.toArray()" :key="suggestion.getId()">
                        <router-link :to="`/suggestion/${suggestion.getId()}`" class="flex justify-between p-4 hover:bg-gray-400">
                            <div>
                                <div>{{ suggestion.getTitle() }}</div>
                                <div class="text-sm text-gray-700">{{ suggestion.getVotes() }} {{ $t('general.suggestion_votes') }}</div>
                            </div>
                            <ot-icon-button v-if="suggestion.isVoted()" @click="unvote(suggestion, reload)"><star class="text-amber-700 fill-amber-700" /></ot-icon-button>
                            <ot-icon-button v-if="!suggestion.isVoted()" @click="vote(suggestion, reload)"><star class="text-grey-500" /></ot-icon-button>
                        </router-link>
                    </li>
                </ul>
            </div>
        </ot-suggestion-data-provider>
    </ot-card>
</template>
