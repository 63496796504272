<script setup lang="ts">
import { useI18n } from 'vue-i18n';

interface Props {
    type: 'hardcourt' | 'clay' | 'carpet' | 'gras';
}
defineProps<Props>();

const { t } = useI18n();
</script>

<template>
    <div
        class="w-4 h-4 inline-block surface"
        :class="`surface_${type}`"
        :title="t(`surface.surface_${type}`)"
    />
</template>

<style>
.surface {
    background-image: url(./../images/surface.png);
}
.surface_hardcourt {
    background-position: 0 0;
}
.surface_clay {
    background-position: 0 -16px;
}
.surface_carpet {
    background-position: 0 -32px;
}
.surface_gras {
    background-position: 0 -48px;
}
</style>
