import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

const _navigationOpen = ref<boolean | null>(null);
const _chatOpen = ref<boolean | null>(null);

const width = ref(window.innerWidth);
window.onresize = () => width.value = window.innerWidth;

export function useLayout() {
    const router = useRouter();
    router.afterEach(() => _navigationOpen.value = null);

    const isNavigationOpen = computed(() => _navigationOpen.value ?? width.value >= 1400);
    const toggleNavigation = () => _navigationOpen.value = !isNavigationOpen.value;

    const isChatOpen = computed(() => _chatOpen.value ?? width.value >= 1400);
    const toggleChat = () => _chatOpen.value = !isChatOpen.value;

    const toolbarExtension = computed(() => width.value < 1400);

    const footerRows = computed(() => {
        if (width.value < 400) {
            return 4;
        }

        if (width.value < 600) {
            return 3;
        }

        if (width.value < 1100) {
            return 2;
        }

        return 1;
    });

    return {
        isNavigationOpen,
        toggleNavigation,
        isChatOpen,
        toggleChat,
        toolbarExtension,
        footerRows,
        width,
    };
}
