export default class MessageCreateDto {
    private readonly receiverId: number;
    private readonly title: string;
    private readonly message: string;

    constructor(receiverId: number, title: string, message: string) {
        this.receiverId = receiverId;
        this.title = title;
        this.message = message;
    }

    public getReceiverId(): number {
        return this.receiverId;
    }

    public getTitle(): string {
        return this.title;
    }

    public getMessage(): string {
        return this.message;
    }
}
