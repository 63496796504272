<script setup lang="ts">
import { Info, TriangleAlert } from 'lucide-vue-next';
import { computed } from 'vue';

interface Props {
    type: 'info' | 'warning';
}
const props = defineProps<Props>();

const classes = computed(() => {
    return {
        'border-blue-700 text-blue-700': props.type === 'info',
        'border-red-700 text-red-700': props.type === 'warning',
    };
});
</script>

<template>
    <div
        class="hint border-2 rounded-sm p-2"
        :class="classes"
    >
        <info v-if="type === 'info'" class="m-2" />
        <triangle-alert v-if="type === 'warning'" class="m-2" />
        <p class="m-2">
            <slot />
        </p>
    </div>
</template>
