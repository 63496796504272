<script setup lang="ts">
import type { AccordionContentProps } from 'radix-vue';
import type { HTMLAttributes } from 'vue';
import { AccordionContent } from 'radix-vue';
import { computed } from 'vue';
import { cn } from '../utils';

interface Props extends AccordionContentProps {
    class?: HTMLAttributes['class'];
}
const props = defineProps<Props>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});
</script>

<template>
    <accordion-content
        v-bind="delegatedProps"
        class="overflow-hidden transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
    >
        <div :class="cn('pb-4 pt-0', props.class)">
            <slot />
        </div>
    </accordion-content>
</template>
