<script setup lang="ts">
import { ComboboxContent, ComboboxEmpty, ComboboxViewport } from 'radix-vue';
import { cn } from '../utils';

interface Props {
    emptyLabel: string;
}
defineProps<Props>();

const style = {
    transform: 'translateX(calc(-0.5 * var(--radix-combobox-trigger-width)))', // I could not find a better way to align the content below the input field
};
</script>

<template>
    <combobox-content
        :class="cn(
            'absolute z-10 w-full mt-2 min-w-(--radix-combobox-trigger-width) bg-white overflow-hidden rounded-sm shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade',
            'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
        )"
        position="popper"
        :style="style"
    >
        <combobox-viewport class="p-[5px]">
            <combobox-empty class="text-gray-800 text-xs font-medium text-center py-2">{{ emptyLabel }}</combobox-empty>
            <div class="flex flex-col gap-2">
                <slot />
            </div>
        </combobox-viewport>
    </combobox-content>
</template>
