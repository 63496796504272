<script setup lang="ts">
import type { SelectItemProps } from 'radix-vue';
import type { HTMLAttributes } from 'vue';
import { Check } from 'lucide-vue-next';
import { SelectItem, SelectItemIndicator, SelectItemText, useForwardProps } from 'radix-vue';
import { computed } from 'vue';
import { cn } from '../utils';

const props = defineProps<SelectItemProps & { class?: HTMLAttributes['class'] }>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
    <select-item
        v-bind="forwardedProps"
        :class="cn('relative flex w-full cursor-pointer select-none items-center rounded-xs py-1.5 pl-8 pr-2 text-sm outline-hidden focus:bg-neutral-100 focus:text-neutral-900 data-disabled:pointer-events-none data-disabled:opacity-50 hover:bg-gray-200', props.class)"
    >
        <span class="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
            <select-item-indicator>
                <check class="h-4 w-4"/>
            </select-item-indicator>
        </span>

        <select-item-text>
            <slot/>
        </select-item-text>
    </select-item>
</template>
