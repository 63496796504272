import axios from 'axios';
import Authentication from './Authentication.js';

axios.defaults.baseURL = import.meta.env.VITE_API_URL;
Authentication.updateAxios();

axios.interceptors.response.use(
    (response) => {
        if (response.data.meta) {
            const meta = response.data.meta;
            if (meta.build) {
                window.emitter.emit('api_build', meta.build);
            }
            if (meta.tutorial) {
                window.emitter.emit('api_tutorial', meta.tutorial);
            }
        }

        return response;
    },
    (error) => {
        if (error.response && error.response.status === 400) {
            const e = error.response.data.error;
            if (e.key === 'validation.constraint_violation') {
                window.emitter.emit('error', 'error.validation_constraint_violation');
            }
        }
        if (error.response && error.response.status === 401) {
            window.emitter.emit('api_returned_401');
        }
        if (error.response && error.response.status === 403) {
            window.emitter.emit('api_returned_403');
        }
        if (error.response && error.response.status === 409) {
            window.emitter.emit('error', error.response.data.error.key);
        }

        return Promise.reject(error);
    },
);

export default axios;
