<script setup lang="ts">
import axios from 'axios';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import OtCard from '../components/Card.vue';
import OtLoader from '../components/Loader.vue';
import { OtTabs, OtTabsContent, OtTabsList, OtTabsTrigger } from '../components/tabs';
import usePayment from '../composables/payment';

const loading = ref(true);
const money_overall = ref(0);
const money_overall_otdollar = ref(0);
const balance_dollar = ref([]);
const balance_otdollar = ref([]);
const stock_money = ref(0);
const reduction_by_manager = ref(0);
const cost_of_living = ref(0);
const payment_url = ref('');

const store = useStore();
const player = computed(() => store.getters.player);

const { showPopup } = usePayment();

onMounted(async () => {
    const { data } = await axios.get(Routing.generate('OnlinetennisBundle_balance', { _locale: locale }));
    money_overall.value = data.money_overall;
    money_overall_otdollar.value = data.money_overall_otdollar;
    balance_dollar.value = data.balance_dollar;
    balance_otdollar.value = data.balance_otdollar;
    stock_money.value = data.stock_money;
    reduction_by_manager.value = data.reduction_by_manager;
    cost_of_living.value = data.cost_of_living;
    payment_url.value = data.payment_url;
    loading.value = false;
});
</script>

<template>
    <ot-loader :loading="loading">
        <ot-card>
            <ot-tabs id="balance" default-value="dollar">
                <ot-tabs-list>
                    <ot-tabs-trigger value="dollar">{{ $t('internalAccounting.headline') }}</ot-tabs-trigger>
                    <ot-tabs-trigger value="otdollar">{{ $t('internalAccountingOTDollar.headline') }}</ot-tabs-trigger>
                </ot-tabs-list>
                <ot-tabs-content value="dollar">
                    <p>{{ $t('finanzen.finanzen_accounting') }}: {{ money_overall.toFixed(2) }}$</p>
                    <table class="striped">
                        <tr v-for="(row, index) in balance_dollar" :key="index">
                            <td>{{ row.insertDate }}</td>
                            <td>{{ row.description }}</td>
                            <td class="text-right" :style="{ color: row.amount < 0 ? '#fc0204' : '#000000' }">{{ row.amount.toFixed(2) }}$</td>
                        </tr>
                    </table>
                    <p>{{ $t('general.cost_of_living') }}</p>
                    <table>
                        <tbody>
                            <tr>
                                <td>{{ $t('general.realmoney') }} (3% {{ $t('general.of') }} {{ parseInt(player.money.dollar).toFixed(2) }}$)</td>
                                <td class="text-right">{{ (player.money.dollar / 100 * 3).toFixed(2) }}$</td>
                            </tr>
                            <tr>
                                <td>{{ $t('internalStockExchange.subheader_shares') }} (3% {{ $t('general.of') }} {{ stock_money.toFixed(2) }}$)</td>
                                <td class="text-right">{{ (stock_money / 100 * 3).toFixed(2) }}$</td>
                            </tr>
                            <tr>
                                <td>{{ $t('staff.staff_manager') }} ({{ player.staff.manager }} {{ $t('general.stars') }})</td>
                                <td class="text-right">-{{ reduction_by_manager.toFixed(2) }}$</td>
                            </tr>
                            <tr>
                                <td><strong>{{ $t('general.overall') }}</strong></td>
                                <td class="text-right"><strong>{{ cost_of_living.toFixed(2) }}$</strong></td>
                            </tr>
                        </tbody>
                    </table>
                </ot-tabs-content>
                <ot-tabs-content value="otdollar">
                    <p>OT Dollar: {{ money_overall_otdollar.toFixed(2) }} <a style="cursor: pointer; font-weight: normal; text-decoration: underline;" @click.prevent="showPopup(payment_url)">{{ $t('finances.finances_geld_einzahlen') }}</a></p>
                    <table class="striped">
                        <tr v-for="(row, index) in balance_otdollar" :key="index">
                            <td>{{ row.insertDate }}</td>
                            <td>{{ row.description }}</td>
                            <td class="text-right" :style="{ color: row.amount < 0 ? '#fc0204' : '#000000' }">{{ row.amount.toFixed(2) }}</td>
                        </tr>
                    </table>
                </ot-tabs-content>
            </ot-tabs>
        </ot-card>
    </ot-loader>
</template>
