export default class MessagesGetResponseDto {
    private readonly id: number;
    private readonly status: string;
    private readonly headline: string;
    private readonly title: string;
    private readonly content: string;
    private readonly senderId: number | null;
    private readonly systemMessage: boolean;

    public constructor(data: any) {
        this.id = data.id;
        this.status = data.status;
        this.headline = data.headline;
        this.title = data.title;
        this.content = data.content;
        this.senderId = data.sender_id;
        this.systemMessage = data.is_system_message;
    }

    public getId(): number {
        return this.id;
    }

    public getStatus(): string {
        return this.status;
    }

    public getHeadline(): string {
        return this.headline;
    }

    public getTitle(): string {
        return this.title;
    }

    public getContent(): string {
        return this.content;
    }

    public getSenderId(): number | null {
        return this.senderId;
    }

    public isSystemMessage(): boolean {
        return this.systemMessage;
    }
}
