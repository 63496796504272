<script setup lang="ts">
import axios from 'axios';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import OtButton from '../components/Button.vue';
import OtCard from '../components/Card.vue';
import { OtDialog, OtDialogBody, OtDialogClose, OtDialogContent, OtDialogFooter, OtDialogHeader, OtDialogTrigger } from '../components/dialog';
import OtDivider from '../components/Divider.vue';
import { OtHint } from '../components/hint';
import OtInputGroup from '../components/InputGroup.vue';
import OtLoader from '../components/Loader.vue';
import OtPlayer from '../components/Player.vue';
import { OtTabs, OtTabsContent, OtTabsList, OtTabsTrigger } from '../components/tabs';
import { OtTextField } from '../components/text-field';
import { useFormatting } from '../composables/formatting';

declare global {
    interface Window {
        emitter: any;
    }
}

const { formatMoney } = useFormatting();
const { t } = useI18n();

const loading = ref(true);
const staffOnMarket = ref(null);
const staffPrices = ref(null);
const currentStaff = ref(null);
const headhunterType = ref('trainer');
const headhunterSkill = ref(5);
const headhunterDialog = ref(false);
const hireNowDialogs = ref([]);
const renewContractDialogs = ref([]);

const store = useStore();
const player = computed(() => store.getters.player);
const headhunterPrice = computed(() => Math.round(staffPrices.value[headhunterType.value] * headhunterSkill.value * 1.1));
const headhunterPriceLabel = computed(() => t('internalStaff.headhunterPrice', { param1: formatMoney(headhunterPrice.value) }));

onMounted(() => load());
window.emitter.on('staff-market-updated', () => load());

async function updateBid(staff) {
    await axios.post(`/api/staff/${staff.id}/bids`, { offer: Number.parseInt(staff.offer) });
    window.emitter.emit('info', t('staff.staff_updated'));
    await load();
}

async function hireNow(staff) {
    hireNowDialogs.value[staff.id] = false;
    if (staff.instantbuy > player.value.money.otdollar) {
        window.emitter.emit('error', 'error.not_enough_otdollar');
        return;
    }

    await axios.post(`/api/staff/${staff.id}/hires`);
    window.emitter.emit('info', t('general.staffBought'));
    await load();
}

async function hireFromHeadhunter() {
    headhunterDialog.value = false;
    if (headhunterPrice.value > player.value.money.otdollar) {
        window.emitter.emit('error', 'error.not_enough_otdollar');
        return;
    }

    await axios.post('/api/staff/headhunter', { type: headhunterType.value, skill: Number.parseInt(headhunterSkill.value) });
    window.emitter.emit('info', t('general.staffBought'));
    await load();
}

async function renewContract(staffId) {
    renewContractDialogs.value[staffId] = false;
    await axios.post(`/api/staff/${staffId}/contract`);
    window.emitter.emit('info', t('internalStaff.contractSuccessfullyRenewed'));
}

function staffRenewPrice(skill, type) {
    return skill * staffPrices.value[type];
}

function canBid(staff) {
    if (staff.offer <= 0) {
        return false;
    }

    if (staff.offer > player.value.money.dollar) {
        return false;
    }

    if (staff.bestOffer && staff.bestOffer.bid >= staff.offer) {
        return false;
    }

    if (player.value.staff[staff.type] && player.value.staff[staff.type] >= staff.skill) {
        return false;
    }

    return true;
}

function canHireDirectly(staff) {
    return !player.value.staff[staff.type] || staff.skill > player.value.staff[staff.type];
}

async function load() {
    const { data } = await axios.get(Routing.generate('onlinetennis_staff', { _locale: locale }));
    staffOnMarket.value = data.staffOnMarket;
    staffPrices.value = data.staffPrices;
    currentStaff.value = data.currentStaff;
    loading.value = false;
}
</script>

<template>
    <ot-loader :loading="loading">
        <ot-card v-if="!loading">
            <ot-tabs id="staff" default-value="market">
                <ot-tabs-list>
                    <ot-tabs-trigger value="market">{{ t('internalStaff.subheader_market') }}</ot-tabs-trigger>
                    <ot-tabs-trigger value="headhunter">{{ t('internalStaff.subheader_headhunter') }}</ot-tabs-trigger>
                    <ot-tabs-trigger value="ownstaff">{{ t('internalStaff.subheader_ownstaff') }}</ot-tabs-trigger>
                </ot-tabs-list>
                <ot-tabs-content value="market">
                    <ot-hint type="info" class="my-2">{{ t('internalStaff.staff_resetEndOfSeason') }}</ot-hint>
                    <template v-for="staff in staffOnMarket" :key="staff.id">
                        <div class="my-2">
                            <div class="flex gap-2 mb-1">
                                <div class="relative" :title="staff.description">
                                    <img
                                        :src="`/images/staff/${staff.type}${staff.skill}.png`"
                                        :alt="t(`staff.staff_${staff.type}`)"
                                        class="max-w-none"
                                    />
                                    <div class="absolute bottom-0 w-full text-center text-white/90">{{ t(`staff.staff_${staff.type}`) }}</div>
                                </div>
                                <div>
                                    <div>{{ staff.description }}</div>
                                    <div>{{ t('internalStaff.auctionExpire') }}: {{ staff.expire }}</div>
                                    <div v-if="staff.bestOffer">
                                        <span class="font-bold">{{ t('internalStaff.bestOffer') }}:</span> {{ formatMoney(staff.bestOffer.bid) }}$
                                        <ot-player :id="staff.bestOffer.player.id" :country="staff.bestOffer.player.country.id">{{ staff.bestOffer.player.name }}</ot-player>
                                    </div>
                                    <div v-else>
                                        <span class="font-bold">{{ t('internalStaff.bestOffer') }}:</span> -
                                    </div>
                                </div>
                            </div>
                            <ot-input-group class="mt-5">
                                <ot-text-field
                                    v-model="staff.offer"
                                    number
                                    :label="t('general.amount')"
                                />
                                <ot-button
                                    primary
                                    :disabled="!canBid(staff)"
                                    @click="updateBid(staff)"
                                >
                                    {{ t('internalStaff.updateBid') }}
                                </ot-button>
                            </ot-input-group>
                            <ot-dialog v-model:open="hireNowDialogs[staff.id]">
                                <ot-dialog-trigger>
                                    <ot-button primary :disabled="!canHireDirectly(staff)">{{ staff.hirenow }}</ot-button>
                                </ot-dialog-trigger>
                                <ot-dialog-content>
                                    <ot-dialog-header>{{ t('internalStaff.headline') }}</ot-dialog-header>
                                    <ot-dialog-body>
                                        {{ t('internalStaff.confirmBuyStaff', { param1: t(`staff.staff_${staff.type}`), param2: staff.instantbuy.toLocaleString() }) }}
                                    </ot-dialog-body>
                                    <ot-dialog-footer>
                                        <ot-button @click="hireNow(staff)">{{ t('general.ok') }}</ot-button>
                                        <ot-dialog-close as-child>
                                            <ot-button variant="secondary">{{ t('general.cancel') }}</ot-button>
                                        </ot-dialog-close>
                                    </ot-dialog-footer>
                                </ot-dialog-content>
                            </ot-dialog>
                        </div>
                        <ot-divider />
                    </template>
                </ot-tabs-content>

                <ot-tabs-content value="headhunter">
                    <p>{{ t('internalStaff.headhunterIntroduction') }}</p>
                    <p>{{ t('internalStaff.headhunterChoose') }}</p>
                    <ot-input-group>
                        <select v-model="headhunterType" name="type" class="mr-1">
                            <option value="trainer">{{ t('staff.staff_trainer') }}</option>
                            <option value="physio">{{ t('staff.staff_physio') }}</option>
                            <option value="teacher">{{ t('staff.staff_teacher') }}</option>
                            <option value="manager">{{ t('staff.staff_manager') }}</option>
                            <option value="doctor">{{ t('staff.staff_doctor') }}</option>
                            <option value="tactican">{{ t('staff.staff_tactican') }}</option>
                            <option value="scout">{{ t('staff.staff_scout') }}</option>
                        </select>
                        <select v-model="headhunterSkill" name="skill" class="mr-1">
                            <option value="5">5 {{ t('general.stars') }}</option>
                            <option value="4">4 {{ t('general.stars') }}</option>
                            <option value="3">3 {{ t('general.stars') }}</option>
                            <option value="2">2 {{ t('general.stars') }}</option>
                            <option value="1">1 {{ t('general.stars') }}</option>
                        </select>
                        <ot-dialog v-model:open="headhunterDialog">
                            <ot-dialog-trigger>
                                <ot-button>{{ t('internalStaff.headhunterDirectlyHire') }}</ot-button>
                            </ot-dialog-trigger>
                            <ot-dialog-content>
                                <ot-dialog-header>{{ t('internalStaff.headline') }}</ot-dialog-header>
                                <ot-dialog-body>{{ t('internalStaff.confirmHeadhunter') }}</ot-dialog-body>
                                <ot-dialog-footer>
                                    <ot-button @click="hireFromHeadhunter()">{{ t('internalStaff.headhunterDirectlyHire') }}</ot-button>
                                    <ot-dialog-close as-child>
                                        <ot-button variant="secondary">{{ t('general.cancel') }}</ot-button>
                                    </ot-dialog-close>
                                </ot-dialog-footer>
                            </ot-dialog-content>
                        </ot-dialog>
                    </ot-input-group>
                    <p>{{ t('general.price') }}: {{ headhunterPriceLabel }}</p>
                </ot-tabs-content>

                <ot-tabs-content value="ownstaff">
                    <div
                        v-for="staff in currentStaff"
                        :key="staff.id"
                        class="mb-1"
                        style="display: flex"
                    >
                        <div class="relative" :title="t(`general.${staff.descriptionKey}`)">
                            <img
                                :src="`/images/staff/${staff.type}${staff.skill}.png`"
                                :alt="t(`staff.staff_${staff.type}`)"
                                class="max-w-none"
                            />
                            <div class="absolute bottom-0 w-full text-center text-white/90">{{ t(`staff.staff_${staff.type}`) }}</div>
                        </div>
                        <div class="ml-1">
                            <p>
                                <span class="font-bold">{{ t('internalStaff.contractEnds') }}:</span> {{ t(`internalStaff.${staff.contractEndsKey}`, { param1: staff.seasonsLeft }) }}
                            </p>
                            <ot-dialog v-model:open="renewContractDialogs[staff.id]">
                                <ot-dialog-trigger>
                                    <ot-button>{{ t('internalStaff.renewContract', { param1: staffRenewPrice(staff.skill, staff.type) }) }}</ot-button>
                                </ot-dialog-trigger>
                                <ot-dialog-content>
                                    <ot-dialog-header>{{ t('internalStaff.headline') }}</ot-dialog-header>
                                    <ot-dialog-body>{{ t('internalStaff.confirmStaffContractExtention', { param1: t(`staff.staff_${staff.type}`), param2: staffRenewPrice(staff.skill, staff.type) }) }}</ot-dialog-body>
                                    <ot-dialog-footer>
                                        <ot-button @click="renewContract(staff.id)">{{ t('general.ok') }}</ot-button>
                                        <ot-dialog-close as-child>
                                            <ot-button variant="secondary">{{ t('general.cancel') }}</ot-button>
                                        </ot-dialog-close>
                                    </ot-dialog-footer>
                                </ot-dialog-content>
                            </ot-dialog>
                        </div>
                    </div>
                </ot-tabs-content>
            </ot-tabs>
        </ot-card>
    </ot-loader>
</template>
