<script>
import axios from 'axios';
import Button from './Button.vue';
import { OtTextField } from './text-field';
import Toggle from './Toggle.vue';

export default {
    components: {
        OtToggle: Toggle,
        OtButton: Button,
        OtTextField,
    },
    props: {
        host: {
            type: Object,
        },
        tour: {
            type: String,
        },
        rid: {
            type: String,
        },
    },
    data() {
        return {
            username: '',
            password: '',
            password2: '',
            existingUsernames: [],
            selectedTour: null,
        };
    },
    computed: {
        target() {
            return (this.selectedTour === 'speed' ? this.host.speed : this.host.classic) + Routing.generate('OnlinetennisBundle_signup', { _locale: locale });
        },
        termsAndConditions() {
            return this.$t('landingpage.acceptTcText', {
                param1: `<a href="${Routing.generate('OnlinetennisBundle_termsAndConditions', { _locale: locale })}" target="_blank">`,
                param2: '</a>',
            });
        },
        usernameHint() {
            if (this.username.length === 0) {
                return '';
            }

            if (this.username.length < 3) {
                return this.$t('landingpage.msgUsernameTooShort');
            }

            if (this.username.length > 25) {
                return this.$t('landingpage.msgUsernameTooLong');
            }

            if (this.username.includes(' ')) {
                return this.$t('landingpage.msgUsernameMustNotContainSpaces');
            }

            if (this.username.match(/[!,@#$%^&*?_~]/)) {
                return this.$t('landingpage.msgUsernameMustNotContainSpecialChars');
            }

            if (this.existingUsernames.includes(this.username)) {
                return this.$t('landingpage.msgUsernameExists');
            }

            return '';
        },
        passwordHint() {
            if (this.password.length === 0) {
                return '';
            }

            if (this.password.length < 4) {
                return this.$t('landingpage.msgPasswordTooShort');
            }

            if (this.password.toLowerCase() === this.username.toLowerCase()) {
                return this.$t('landingpage.msgUsernamePasswordIdentical');
            }

            if (this.passwordScore < 25) {
                return this.$t('landingpage.msgPasswordTooWeak');
            }

            if (this.password2.length === 0) {
                return this.$t('landingpage.msgEnterPasswordConfirmation');
            }

            if (this.password !== this.password2) {
                return this.$t('landingpage.msgPasswordsNotIdentical');
            }

            return '';
        },
        signupPossible() {
            return this.username !== '' && this.password !== '' && !this.usernameHint && !this.passwordHint;
        },
        passwordScore() {
            let score = 0;

            score += this.password.length * 4;

            // password has 3 numbers
            if (this.password.match(/(.*\d.*\d.*\d)/)) {
                score += 5;
            }

            // password has 2 symbols
            if (this.password.match(/(.*[!,@#$%^&*?_~].*[!,@#$%^&*?_~])/)) {
                score += 5;
            }

            // password has upper and lower chars
            if (this.password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
                score += 10;
            }

            // password has number and chars
            if (this.password.match(/([a-z])/i) && this.password.match(/(\d)/)) {
                score += 15;
            }

            // password has number and symbol
            if (this.password.match(/([!,@#$%^&*?_~])/) && this.password.match(/(\d)/)) {
                score += 15;
            }

            // password has char and symbol
            if (this.password.match(/([!@#$%^&*?_~])/) && this.password.match(/([a-z])/i)) {
                score += 15;
            }

            // password is just a numbers or chars
            if (this.password.match(/^\w+$/) || this.password.match(/^\d+$/)) {
                score -= 10;
            }

            return Math.max(0, Math.min(100, score));
        },
    },
    watch: {
        async username() {
            const response = await axios.get(Routing.generate('OnlinetennisBundle_checkUsernameExists', { username: this.username }));
            if (response.data.count > 0) {
                this.existingUsernames.push(response.data.username);
            }
        },
    },
    created() {
        this.selectedTour = this.tour;
    },
    methods: {
        toggleTour(option) {
            this.selectedTour = option;
        },
    },
};
</script>

<template>
    <form id="signupForm" method="post" :action="target">
        <label>
            <span v-show="usernameHint" class="text-xs text-red-500">{{ usernameHint }}</span>
            <ot-text-field v-model="username" :label="$t('externalSignup.username')" name="username" />
        </label>

        <label>
            <span v-show="passwordHint" class="text-xs text-red-500">{{ passwordHint }}</span>
            <ot-text-field v-model="password" :label="$t('login.login_password')" password class="mb-1" name="password" />
            <ot-text-field v-model="password2" :label="$t('login.login_password')" password name="passwordConfirmation" />
        </label>

        <ot-toggle option-a="classic" option-b="speed" :label-option-a="$t('general.classic_tour')" :label-option-b="$t('general.speed_tour')" :default="selectedTour" @toggle="toggleTour" />

        <ot-button submit block :disabled="!signupPossible" class="mt-2 mb-1">{{ $t('landingpage.freeSignup') }}</ot-button>
        <div class="text-xs" v-html="termsAndConditions" />

        <input type="hidden" name="rid" :value="rid">
    </form>
</template>
