<script>
import axios from 'axios';
import FixtureMatch from './FixtureMatch.vue';
import Loader from './Loader.vue';
import Player from './Player.vue';
import Sets from './Sets.vue';
import Subheader from './Subheader.vue';
import Surface from './Surface.vue';

export default {
    components: {
        OtLoader: Loader,
        OtFixtureMatch: FixtureMatch,
        OtPlayer: Player,
        OtSets: Sets,
        OtSurface: Surface,
        OtSubheader: Subheader,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
        opponentId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            matches: [],
        };
    },
    computed: {
        isPremium() {
            return this.$store.getters.user.premium.isActive;
        },
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            if (!this.isPremium) {
                return;
            }

            axios
                .get(`/${locale}/head-to-head-showmatches/${this.opponentId}/${this.id}`)
                .then((response) => {
                    this.matches = response.data;
                    this.loading = false;
                });
        },
    },
};
</script>

<template>
    <div>
        <ot-loader v-if="isPremium" :loading="loading">
            <template
                v-for="match in matches"
                :key="match.id"
            >
                <ot-subheader>
                    <ot-surface :type="match.surface" class="mr-1" />
                    <ot-sets :sets="match.sets_to_play" class="mr-1" />
                </ot-subheader>
                <ot-fixture-match
                    :value="match.score"
                    :match-id="match.id"
                    match-type="showmatch"
                >
                    <template #participant-one>
                        <ot-player
                            :id="match.player1.id"
                            :country="match.player1.country.id"
                        >
                            {{ match.player1.name }}
                        </ot-player>
                    </template>
                    <template #participant-two>
                        <ot-player
                            :id="match.player2.id"
                            :country="match.player2.country.id"
                        >
                            {{ match.player2.name }}
                        </ot-player>
                    </template>
                </ot-fixture-match>
            </template>
        </ot-loader>
        <div v-else>{{ $t('general.noSupporterAccount') }}</div>
    </div>
</template>
