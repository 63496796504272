import Country from '../../domain/country/Country';
import PlayerName from '../../domain/player/PlayerName';

export default class ChannelMessageListGetResponse {
    public static fromData(data) {
        return new ChannelMessageListGetResponse(
            data.id,
            data.content,
            new Date(data.date.date),
            data.author_id,
            PlayerName.fromData(data.author_name.data),
            Country.fromData(data.author_country.data),
            data.author_is_online,
            data.author_is_mobile,
        );
    }

    private readonly id: number;
    private readonly content: string;
    private readonly date: Date;
    private readonly authorId: number;
    private readonly authorName: PlayerName;
    private readonly authorCountry: Country;
    private readonly authorIsOnline: boolean;
    private readonly authorIsMobile: boolean;

    constructor(id: number, content: string, date: Date, authorId: number, authorName: PlayerName, authorCountry: Country, authorIsOnline: boolean, authorIsMobile: boolean) {
        this.id = id;
        this.content = content;
        this.date = date;
        this.authorId = authorId;
        this.authorName = authorName;
        this.authorCountry = authorCountry;
        this.authorIsOnline = authorIsOnline;
        this.authorIsMobile = authorIsMobile;
    }

    public getId(): number {
        return this.id;
    }

    public getContent(): string {
        return this.content;
    }

    public getAuthorId(): number {
        return this.authorId;
    }

    public getAuthorName(): PlayerName {
        return this.authorName;
    }

    public getAuthorCountry(): Country {
        return this.authorCountry;
    }

    public isAuthorOnline(): boolean {
        return this.authorIsOnline;
    }

    public isAuthorMobile(): boolean {
        return this.authorIsMobile;
    }

    public getDate(): Date {
        return this.date;
    }
}
