<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import OtFlag from './Flag.vue';
import { OtSelect, OtSelectContent, OtSelectGroup, OtSelectItem, OtSelectTrigger, OtSelectValue } from './select';

interface Props {
    modelValue: number | undefined;
    disallowAll?: boolean;
}
interface Emits {
    (event: 'update:modelValue', value: number): void;
}

withDefaults(defineProps<Props>(), {
    disallowAll: false,
});
defineEmits<Emits>();

const { t } = useI18n();
</script>

<template>
    <ot-select
        :model-value="modelValue?.toString() ?? '-'"
        @update:model-value="$emit('update:modelValue', $event !== '-' ? parseInt($event) : undefined)"
    >
        <ot-select-trigger>
            <ot-select-value />
        </ot-select-trigger>
        <ot-select-content>
            <ot-select-group v-if="!disallowAll">
                <ot-select-item value="-">{{ t('general.all') }}</ot-select-item>
            </ot-select-group>
            <ot-select-group>
                <ot-select-item
                    v-for="id in 196"
                    :key="id"
                    :value="id.toString()"
                >
                    <ot-flag :id="id" class="mr-2" />{{ t(`country.country_${id}`) }}
                </ot-select-item>
            </ot-select-group>
        </ot-select-content>
    </ot-select>
</template>
