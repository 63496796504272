<script setup lang="ts">
import type { Ref } from 'vue';
import axios from 'axios';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { OtAccordion, OtAccordionContent, OtAccordionItem, OtAccordionTrigger } from '../../components/accordion';
import OtFlag from '../../components/Flag.vue';
import OtLoader from '../../components/Loader.vue';
import OtSubheader from '../../components/Subheader.vue';

interface Props {
    id: string;
}
const props = defineProps<Props>();

const loading = ref(true);

interface Country {
    id: number;
    tag: string;
}
interface Tournament {
    id: number;
    name: string;
    is_national_championship: boolean;
    season: number;
    country: Country;
}

const wonTournaments: Ref<Record<string, Tournament[]>> = ref({});
const seasonsEntrylist = ref([]);
const seasonsChampionsrace = ref([]);

onMounted(() => load());

async function load() {
    const { data } = await axios.get(`/api/doubles-profiles/${props.id}/trophies`);
    wonTournaments.value = data.data.wonTournaments;
    seasonsEntrylist.value = data.data.seasonsEntrylist;
    seasonsChampionsrace.value = data.data.seasonsChampionsrace;

    loading.value = false;
}

function getTournamentLink(id: number) {
    return Routing.generate('OnlinetennisBundle_profileTournament', { _locale: locale, id });
}

const { t } = useI18n();
</script>

<template>
    <ot-loader :loading="loading" standalone>
        <div class="flex gap-3">
            <div class="grow">
                <ot-subheader>{{ t('profil.profil_gewonneneturniere') }}</ot-subheader>
                <ot-accordion type="multiple" class="w-full">
                    <ot-accordion-item
                        v-for="(tournaments, category) in wonTournaments"
                        :key="category"
                        :value="category"
                    >
                        <ot-accordion-trigger class="flex justify-between items-center">
                            <span>{{ category }}</span>
                            <span class="text-xs">({{ tournaments.length }})</span>
                        </ot-accordion-trigger>
                        <ot-accordion-content
                            v-for="tournament in tournaments"
                            :key="tournament.id"
                        >
                            <ot-flag :id="tournament.country.id" />
                            <a :href="getTournamentLink(tournament.id)">{{ tournament.name }} '{{ tournament.season }}</a>
                        </ot-accordion-content>
                    </ot-accordion-item>
                </ot-accordion>
            </div>
            <div class="grow">
                <ot-subheader>{{ t('profil.profil_seasonStandings') }}</ot-subheader>
                <p class="font-medium">{{ t('profil.profil_el') }}</p>
                <ul>
                    <li
                        v-for="season in seasonsEntrylist"
                        :key="season"
                    >{{ season }}</li>
                </ul>
                <p class="font-medium">{{ t('profil.profil_cr') }}</p>
                <ul>
                    <li
                        v-for="season in seasonsChampionsrace"
                        :key="season"
                    >{{ season }}</li>
                </ul>
            </div>
        </div>
    </ot-loader>
</template>
