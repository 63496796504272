<script>
import OtBrandIcon from './BrandIcon.vue';
import Button from './Button.vue';
import Card from './Card.vue';
import Modal from './Modal.vue';
import { OtTextField } from './text-field';
import Toggle from './Toggle.vue';

export default {
    components: {
        OtCard: Card,
        OtModal: Modal,
        OtToggle: Toggle,
        OtButton: Button,
        OtTextField,
        OtBrandIcon,
    },
    props: {
        host: {
            type: Object,
        },
        tour: {
            type: String,
        },
    },
    data() {
        return {
            username: '',
            password: '',
            selectedTour: null,
            route: {
                forgotPassword: Routing.generate('OnlinetennisBundle_forgetPassword', { _locale: locale }),
                googleLogin: Routing.generate('hwi_oauth_service_redirect', { service: 'google' }),
            },
        };
    },
    computed: {
        target() {
            return (this.selectedTour === 'speed' ? this.host.speed : this.host.classic) + Routing.generate('loginCheck');
        },
    },
    created() {
        this.selectedTour = this.tour;
    },
    methods: {
        toggleTour(option) {
            this.selectedTour = option;
        },
    },
};
</script>

<template>
    <div class="flex flex-wrap mb-2">
        <ot-card transparent legacy not-stretched class="grow mb-2">
            <form method="post" :action="target">
                <div class="flex flex-wrap gap-2 items-baseline">
                    <ot-text-field v-model="username" name="_username" :label="$t('externalSignup.username')" label-color="#eeeeee" />
                    <ot-text-field v-model="password" name="_password" :label="$t('login.login_password')" label-color="#eeeeee" password />
                    <ot-toggle option-a="classic" option-b="speed" :label-option-a="$t('general.classic_tour')" :label-option-b="$t('general.speed_tour')" :default="selectedTour" @toggle="toggleTour" />
                    <ot-button submit>{{ $t('login.login') }}</ot-button>
                    <ot-modal :label="$t('general.forgot_password')">
                        <form id="forgotPasswordForm" method="post" :action="route.forgotPassword">
                            <div class="description">{{ $t('password.password_enter_address') }}</div>
                            <ot-text-field name="email" :label="$t('externalSignup.anmeldung_email')" />
                            <ot-button submit>{{ $t('general.send') }}</ot-button>
                        </form>
                    </ot-modal>
                </div>
            </form>
        </ot-card>
        <ot-card transparent legacy not-stretched class="flex flex-wrap gap-2 w-full">
            <ot-button :href="route.googleLogin" class="w-full sm:w-auto"><ot-brand-icon brand="google" class="mr-2" /> <span>Login with Google</span></ot-button>
        </ot-card>
    </div>
</template>
