export default class FeedbackUpdateDto {
    private readonly overall: number;
    private readonly fun: number;
    private readonly graphics: number;
    private readonly usability: number;
    private readonly community: number;
    private readonly support: number;

    constructor(overall: number, fun: number, graphics: number, usability: number, community: number, support: number) {
        this.overall = overall;
        this.fun = fun;
        this.graphics = graphics;
        this.usability = usability;
        this.community = community;
        this.support = support;
    }

    public getOverall(): number {
        return this.overall;
    }

    public getFun(): number {
        return this.fun;
    }

    public getGraphics(): number {
        return this.graphics;
    }

    public getUsability(): number {
        return this.usability;
    }

    public getCommunity(): number {
        return this.community;
    }

    public getSupport(): number {
        return this.support;
    }
}
