<script>
export default {
};
</script>

<template>
    <hr class="border-gray-500">
</template>

<style scoped>

</style>
