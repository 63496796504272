export default class SuggestionCommentCreateDto {
    private readonly suggestionId: string;
    private readonly content: string;

    constructor(suggestionId: string, content: string) {
        this.suggestionId = suggestionId;
        this.content = content;
    }

    public getSuggestionId(): string {
        return this.suggestionId;
    }

    public getContent(): string {
        return this.content;
    }
}
