<script setup lang="ts">
import type { DialogDescriptionProps } from 'radix-vue';
import type { HTMLAttributes } from 'vue';
import { DialogDescription, useForwardProps } from 'radix-vue';
import { computed } from 'vue';
import { cn } from '../utils';

const props = defineProps<DialogDescriptionProps & { class?: HTMLAttributes['class'] }>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
    <dialog-description
        v-bind="forwardedProps"
        :class="cn('text-sm text-muted-foreground p-4', props.class)"
    >
        <slot />
    </dialog-description>
</template>
