<script>
import { format, sub } from 'date-fns';
import Button from '../components/Button.vue';
import Card from '../components/Card.vue';
import InputGroup from '../components/InputGroup.vue';
import OpenShowmatches from '../components/OpenShowmatches.vue';
import Player from '../components/Player.vue';
import OtPlayerSelect from '../components/PlayerSelect.vue';
import Sets from '../components/Sets.vue';
import Surface from '../components/Surface.vue';
import { OtTabs, OtTabsContent, OtTabsList, OtTabsTrigger } from '../components/tabs';

export default {
    components: {
        OtCard: Card,
        OtOpenShowmatches: OpenShowmatches,
        OtInputGroup: InputGroup,
        OtPlayerSelect,
        OtSurface: Surface,
        OtPlayer: Player,
        OtButton: Button,
        OtTabs,
        OtTabsContent,
        OtTabsTrigger,
        OtTabsList,
        OtSets: Sets,
    },
    props: {
        playingTimes: {
            type: Array,
            required: true,
        },
        listOfMatches: {
            type: Array,
            required: true,
        },
        dayToShow: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            opponent: undefined,
            linkShowmatchCreate: Routing.generate('showmatch_create', { _locale: locale }),
        };
    },
    methods: {
        format,
        sub,
        changeSkills() {
            if ($('#skillMin').val() === '0' && $('#skillMax').val() === '0') {
                $('#opponent').attr('disabled', '');
            }
            else {
                $('#opponent').attr('disabled', 'disabled');
            }
        },
        linkLivematch(matchId) {
            return Routing.generate('OnlinetennisBundle_livematch', { matchType: 'showmatch', id: matchId });
        },
    },
};
</script>

<template>
    <div class="flex flex-wrap">
        <div class="w-full md:w-5/12 p-2">
            <ot-card not-stretched>
                <ot-tabs id="showmatch" default-value="quick-match">
                    <ot-tabs-list>
                        <ot-tabs-trigger value="quick-match">{{ $t('internalShowmatch.quick_match') }}</ot-tabs-trigger>
                        <ot-tabs-trigger value="extended">{{ $t('internalShowmatch.extended_settings') }}</ot-tabs-trigger>
                    </ot-tabs-list>
                    <ot-tabs-content value="quick-match">
                        <form id="newQuickShowmatch" method="post" :action="linkShowmatchCreate">
                            <input type="hidden" name="allowEquipment" value="on">
                            <label>
                                {{ $t('internalShowmatch.surface') }}
                                <ot-input-group>
                                    <select name="surface">
                                        <option value="HARDCOURT" v-text="$t('surface.surface_hardcourt')" />
                                        <option value="CLAY" v-text="$t('surface.surface_clay')" />
                                        <option value="CARPET" v-text="$t('surface.surface_carpet')" />
                                        <option value="GRAS" v-text="$t('surface.surface_grass')" />
                                    </select>
                                    <ot-button submit primary>{{ $t('internalShowmatch.createShowmatch') }}</ot-button>
                                </ot-input-group>
                            </label>
                        </form>
                    </ot-tabs-content>
                    <ot-tabs-content value="extended">
                        <form method="post" :action="linkShowmatchCreate">
                            <label>
                                {{ $t('internalShowmatch.surface') }}
                                <select name="surface">
                                    <option value="HARDCOURT" v-text="$t('surface.surface_hardcourt')" />
                                    <option value="CLAY" v-text="$t('surface.surface_clay')" />
                                    <option value="CARPET" v-text="$t('surface.surface_carpet')" />
                                    <option value="GRAS" v-text="$t('surface.surface_grass')" />
                                </select>
                            </label>

                            <label>
                                {{ $t('internalShowmatch.playingTime') }}
                                <select name="playingTime">
                                    <option>-</option>
                                    <option
                                        v-for="playingTime in playingTimes"
                                        :key="playingTime.key"
                                        :value="playingTime.key"
                                    >{{ playingTime.value }}</option>
                                </select>
                            </label>

                            <label>
                                {{ $t('internalShowmatch.setsToPlay') }}
                                <select id="setsToPlay" name="setsToPlay">
                                    <option value="1">{{ $t('general.best_of', { param1: '1' }) }}</option>
                                    <option value="3" selected="selected">{{ $t('general.best_of', { param1: '3' }) }}</option>
                                    <option value="5">{{ $t('general.best_of', { param1: '5' }) }}</option>
                                </select>
                            </label>

                            <label>
                                {{ $t('internalShowmatch.skillMin') }}
                                <select name="skillMin" @change="changeSkills();">
                                    <option value="0">-</option>
                                    <option value="150">150</option>
                                    <option value="200">200</option>
                                    <option value="250">250</option>
                                    <option value="300">300</option>
                                    <option value="350">350</option>
                                    <option value="400">400</option>
                                    <option value="450">450</option>
                                    <option value="500">500</option>
                                    <option value="550">550</option>
                                    <option value="600">600</option>
                                    <option value="650">650</option>
                                    <option value="700">700</option>
                                    <option value="750">750</option>
                                    <option value="800">800</option>
                                    <option value="840">840</option>
                                    <option value="870">870</option>
                                    <option value="900">900</option>
                                    <option value="930">930</option>
                                    <option value="950">950</option>
                                </select>
                            </label>

                            <label class="labelSkillMax">
                                {{ $t('internalShowmatch.skillMax') }}
                                <select name="skillMax" @change="changeSkills();">
                                    <option value="0">-</option>
                                    <option value="150">150</option>
                                    <option value="200">200</option>
                                    <option value="250">250</option>
                                    <option value="300">300</option>
                                    <option value="350">350</option>
                                    <option value="400">400</option>
                                    <option value="450">450</option>
                                    <option value="500">500</option>
                                    <option value="550">550</option>
                                    <option value="600">600</option>
                                    <option value="650">650</option>
                                    <option value="700">700</option>
                                    <option value="750">750</option>
                                    <option value="800">800</option>
                                    <option value="840">840</option>
                                    <option value="870">870</option>
                                    <option value="900">900</option>
                                    <option value="930">930</option>
                                    <option value="950">950</option>
                                </select>
                            </label>

                            <label class="block my-2">
                                <input type="checkbox" class="w-5 h-5 align-text-bottom" name="allowEquipment" checked="checked">
                                {{ $t('internalShowmatch.equipment') }}
                            </label>

                            <label>
                                {{ $t('internalShowmatch.personalInvitation') }}
                                <ot-player-select class="my-2" v-model="opponent" />
                                <input type="hidden" name="opponentID" v-if="opponent" :value="opponent.key" />
                            </label>

                            <ot-button submit primary>{{ $t('internalShowmatch.createShowmatch') }}</ot-button>
                        </form>
                    </ot-tabs-content>
                </ot-tabs>
            </ot-card>

            <ot-card legacy :title="$t('internalShowmatch.openMatchesHeader')" not-stretched class="mt-5">
                <ot-open-showmatches />
            </ot-card>
        </div>
        <div class="w-full md:w-7/12 p-2">
            <ot-card legacy :title="$t('internalShowmatch.matchesHeader')">
                <form method="post">
                    <ot-input-group class="mb-1">
                        <select name="dayToShow">
                            <option
                                v-for="dayOffset in 31"
                                :key="dayOffset"
                                :value="format(sub(new Date(), { days: dayOffset - 1 }), 'yyyy-MM-dd')"
                                :selected="format(sub(new Date(), { days: dayOffset - 1 }), 'yyyy-MM-dd') === dayToShow"
                            >
                                {{ format(sub(new Date(), { days: dayOffset - 1 }), 'dd.MM.yyyy') }}
                            </option>
                        </select>
                        <ot-button submit primary>{{ $t('internalShowmatch.showDay') }}</ot-button>
                    </ot-input-group>
                </form>
                <table class="striped">
                    <tr v-for="match in listOfMatches" :key="match.id">
                        <td><ot-surface :type="match.surface" /></td>
                        <td><ot-sets :sets="match.setsToPlay"></ot-sets></td>
                        <td><div class="icon" :class="!match.isEquipmentAllowed ? 'no_equipment' : 'equipment'" /></td>
                        <td><ot-player :id="match.user1.id" :country="match.user1.country.id">{{ match.user1.name }}</ot-player></td>
                        <td><ot-player :id="match.user2.id" :country="match.user2.country.id">{{ match.user2.name }}</ot-player></td>
                        <td><a :href="linkLivematch(match.id)">{{ match.isFinished ? match.score : (match.isRunning ? $t('internalLivematch.nowLive') : match.playingTime) }}</a></td>
                    </tr>
                </table>
            </ot-card>
        </div>
    </div>
</template>
