<script setup lang="ts">
import axios from 'axios';
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import OtCard from '../components/Card.vue';
import OtCountrySelect from '../components/CountrySelect.vue';
import OtFlag from '../components/Flag.vue';
import OtLoader from '../components/Loader.vue';
import OtPlayer from '../components/Player.vue';
import { OtSelect, OtSelectContent, OtSelectGroup, OtSelectItem, OtSelectTrigger, OtSelectValue } from '../components/select';
import OtSurface from '../components/Surface.vue';
import { OtSwitch } from '../components/switch';

interface Props {
    statistic: string;
}
const props = defineProps<Props>();

const router = useRouter();

const onlyFriends = ref(false);
const country = ref(undefined);
const availableTournamentTypes = {
    ATP: ['Olympics', 'Grand Slam', 'Finals', 'ATP Masters 1000', 'ATP 500', 'ATP 250', 'Tennis Masters Series', 'International Series Gold', 'International Series'],
    CHALLENGER: ['Challenger 150', 'Challenger 125', 'Challenger 100', 'Challenger 75', 'Challenger 50', 'Challenger 37.5+H', 'Challenger 35+H', 'Challenger 25+H'],
    FUTURE: ['Future 15+H', 'Future 10'],
    OTHER: ['Nachwuchsrunde', 'Entry-Tournament', 'Meisterschaft', 'User Tournament'],
};
const tournamentTypes = reactive(JSON.parse(JSON.stringify(availableTournamentTypes)));
const surfaces = ref(['hardcourt', 'clay', 'gras', 'carpet']);

const data = ref([]);
const loading = ref(false);

watch([onlyFriends, country, tournamentTypes, surfaces], () => loadData());
watch(() => props.statistic, () => loadData());
onMounted(() => loadData());

const { t } = useI18n();
const entityType = computed(() => {
    switch (props.statistic) {
        case 'tournament-victories':
        case 'quote':
        case 'match-victories-hardcourt':
        case 'match-victories-clay':
        case 'match-victories-grass':
        case 'match-victories-carpet':
        case 'aces':
        case 'double-faults':
        case 'points':
            return 'player';

        case 'tournament-victories-doubles':
            return 'doubles';

        case 'nations-ranking':
            return 'country';

        default:
            throw new Error(`Unknown statistic: ${props.statistic}`);
    }
});
const valueHeadline = computed(() => {
    switch (props.statistic) {
        case 'tournament-victories':
        case 'tournament-victories-doubles':
        case 'match-victories-hardcourt':
        case 'match-victories-clay':
        case 'match-victories-grass':
        case 'match-victories-carpet':
            return t('general.victories');

        case 'quote':
            return t('statistiken.statistiken_quote');

        case 'aces':
            return t('matchinfo.matchinfo_asse');

        case 'double-faults':
            return t('matchinfo.matchinfo_doppelfehler');

        case 'points':
            return t('matchinfo.matchinfo_gewonnenepunkte');

        case 'nations-ranking':
            return t('general.points');

        default:
            throw new Error(`Unknown statistic: ${props.statistic}`);
    }
});
const showTournamentTypeSelection = computed(() => {
    switch (props.statistic) {
        case 'tournament-victories':
        case 'tournament-victories-doubles':
            return true;

        default:
            return false;
    }
});
const showSurfaceSelection = computed(() => {
    switch (props.statistic) {
        case 'tournament-victories':
        case 'tournament-victories-doubles':
            return true;

        default:
            return false;
    }
});
const showFriendSelection = computed(() => {
    switch (props.statistic) {
        case 'nations-ranking':
            return false;

        default:
            return true;
    }
});
const showCountrySelection = computed(() => {
    switch (props.statistic) {
        case 'nations-ranking':
            return false;

        default:
            return true;
    }
});

async function loadData() {
    loading.value = true;

    const params: Record<string, unknown> = {};
    if (showTournamentTypeSelection.value) {
        params.tournamentTypes = tournamentTypes;
    }
    if (showSurfaceSelection.value) {
        params.surfaces = surfaces.value;
    }
    if (showFriendSelection.value) {
        params.onlyFriends = onlyFriends.value;
    }
    if (showCountrySelection.value) {
        params.countries = country.value ? [country.value] : undefined;
    }
    const response = await axios.get(`/api/statistics/${props.statistic}`, { params });

    data.value = response.data.data;
    loading.value = false;
}

function toggleTournamentType(type: string) {
    tournamentTypes[type] = tournamentTypes[type].length === availableTournamentTypes[type].length ? [] : availableTournamentTypes[type];
}
</script>

<template>
    <ot-card class="p-4">
        <div class="mb-4">
            <label>
                <span class="text-sm ml-2">Statistik</span>
                <ot-select
                    :model-value="statistic"
                    @update:model-value="router.push(`/statistics/${$event}`)"
                >
                    <ot-select-trigger>
                        <ot-select-value />
                    </ot-select-trigger>
                    <ot-select-content>
                        <ot-select-group>
                            <ot-select-item value="tournament-victories">{{ t('statistiken.statistiken_turniersiege') }}</ot-select-item>
                            <ot-select-item value="tournament-victories-doubles">{{ t('statistiken.statistiken_turniersiege') }} ({{ t('general.doubles') }})</ot-select-item>
                            <ot-select-item value="quote">{{ t('statistiken.statistiken_quote') }}</ot-select-item>
                            <ot-select-item value="match-victories-hardcourt">{{ t('statistiken.statistiken_siege_hardcourt') }}</ot-select-item>
                            <ot-select-item value="match-victories-clay">{{ t('statistiken.statistiken_siege_sand') }}</ot-select-item>
                            <ot-select-item value="match-victories-grass">{{ t('statistiken.statistiken_siege_rasen') }}</ot-select-item>
                            <ot-select-item value="match-victories-carpet">{{ t('statistiken.statistiken_siege_teppich') }}</ot-select-item>
                            <ot-select-item value="nations-ranking">{{ t('statistiken.statistiken_nationenwertung') }}</ot-select-item>
                            <ot-select-item value="aces">{{ t('statistiken.statistiken_asse') }}</ot-select-item>
                            <ot-select-item value="double-faults">{{ t('statistiken.statistiken_doppelfehler') }}</ot-select-item>
                            <ot-select-item value="points">{{ t('statistiken.statistiken_punkte') }}</ot-select-item>
                        </ot-select-group>
                    </ot-select-content>
                </ot-select>
            </label>
        </div>

        <div
            v-if="showCountrySelection"
            class="mb-4"
        >
            <label class="text-sm">
                <span class="text-sm ml-2">{{ t('statistiken.statistiken_nation') }}</span>
                <ot-country-select v-model="country" />
            </label>
        </div>

        <div
            v-if="showFriendSelection"
            class="mb-4"
        >
            <ot-switch :label="t('general.only_friends')" v-model:checked="onlyFriends" />
        </div>

        <div
            v-if="showTournamentTypeSelection"
            class="flex flex-wrap gap-4 mb-4"
        >
            <div
                v-for="(categories, type) in availableTournamentTypes"
                :key="type"
            >
                <div
                    class="text-sm text-gray-800 py-1 cursor-pointer"
                    @click="toggleTournamentType(type)"
                >{{ t(`statistiken.tournament_type_${type.toLowerCase()}`) }}</div>
                <div class="flex flex-col">
                    <label
                        v-for="category in categories"
                        :key="category"
                    >
                        <input type="checkbox" class="w-5 h-5 align-text-bottom" :value="category" v-model="tournamentTypes[type]" />
                        <span class="text-sm ml-2">{{ category }}</span>
                    </label>
                </div>
            </div>
        </div>

        <div
            v-if="showSurfaceSelection"
            class="flex flex-col mb-4"
        >
            <label>
                <input type="checkbox" class="w-5 h-5 align-text-bottom mr-2" value="hardcourt" v-model="surfaces" />
                <ot-surface type="hardcourt" class="relative top-0.5" />
                <span class="text-sm ml-1">{{ t('surface.surface_hardcourt') }}</span>
            </label>
            <label>
                <input type="checkbox" class="w-5 h-5 align-text-bottom mr-2" value="clay" v-model="surfaces" />
                <ot-surface type="clay" class="relative top-0.5" />
                <span class="text-sm ml-1">{{ t('surface.surface_clay') }}</span>
            </label>
            <label>
                <input type="checkbox" class="w-5 h-5 align-text-bottom mr-2" value="gras" v-model="surfaces" />
                <ot-surface type="gras" class="relative top-0.5" />
                <span class="text-sm ml-1">{{ t('surface.surface_grass') }}</span>
            </label>
            <label>
                <input type="checkbox" class="w-5 h-5 align-text-bottom mr-2" value="carpet" v-model="surfaces" />
                <ot-surface type="carpet" class="relative top-0.5" />
                <span class="text-sm ml-1">{{ t('surface.surface_carpet') }}</span>
            </label>
        </div>

        <ot-loader :loading="loading" standalone>
            <table>
                <thead>
                    <tr>
                        <th class="px-4 py-1 text-sm text-left font-medium"></th>
                        <th class="px-4 py-1 text-sm text-left font-medium">
                            <template v-if="entityType === 'player'">{{ t('general.player') }}</template>
                            <template v-if="entityType === 'country'">{{ t('statistiken.statistiken_nation') }}</template>
                        </th>
                        <th class="px-4 py-1 text-sm text-left font-medium">{{ valueHeadline }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(row, index) in data"
                        :key="index"
                        class="odd:bg-gray-400"
                    >
                        <td class="px-4 py-1 w-4">{{ row.position }}.</td>
                        <td class="px-4 py-1">
                            <ot-player
                                v-if="entityType === 'player'"
                                :id="row.player.id"
                                :country="row.player.country.id"
                            >
                                {{ row.player.name }}
                            </ot-player>
                            <div
                                v-if="entityType === 'doubles'"
                                class="flex"
                            >
                                <ot-player
                                    :id="row.doubles.player1.id"
                                    :country="row.doubles.player1.country.id"
                                >
                                    {{ row.doubles.player1.name }}
                                </ot-player>
                                <span>&nbsp;/&nbsp;</span>
                                <ot-player
                                    :id="row.doubles.player2.id"
                                    :country="row.doubles.player2.country.id"
                                >
                                    {{ row.doubles.player2.name }}
                                </ot-player>
                            </div>
                            <div v-if="entityType === 'country'">
                                <ot-flag :id="row.country.id" />
                                <span :title="row.country.details" class="ml-2">{{ t(`country.country_${row.country.id}`) }}</span>
                            </div>
                        </td>
                        <td class="px-4 py-1">{{ row.value }}</td>
                    </tr>
                </tbody>
            </table>
        </ot-loader>
    </ot-card>
</template>
