<script>
import Modal from './Modal.vue';

export default {
    components: {
        OtModal: Modal,
    },
};
</script>

<template>
    <div class="flex justify-evenly items-center bg-green-700 text-white z-0 py-2 fixed left-0 right-0 bottom-0">
        <a href="http://forum.onlinetennis.net" target="_blank" class="text-white">Forum</a>
        <ot-modal :label="$t('externalImprint.headline')">
            <h5>{{ $t("externalImprint.contact") }}</h5>
            <div><a href="mailto: support@onlinetennis.net">support@onlinetennis.net</a></div>
            <h5>{{ $t("externalImprint.responsible") }}</h5>
            <div><a href="https://www.cunningsoft.de" target="_blank"><img src="../images/cunningsoft.jpg" alt="Cunningsoft"></a></div>
            Cunningsoft<br>
            Daniel Mecke<br>
            Bartensteiner Weg 31<br>
            22049 Hamburg<br>
            <a href="https://www.cunningsoft.de" target="_blank">https://www.cunningsoft.de</a>
            <h5>{{ $t('externalImprint.photos') }}</h5>
            <ul style="list-style-type: none; padding: 0">
                <li>&copy; <a target="_blank" href="https://de.123rf.com/profile_agencyby">agencyby / 123RF Stockfoto</a></li>
                <li>&copy; <a target="_blank" href="https://de.123rf.com/profile_andreypopov">andreypopov / 123RF Stockfoto</a></li>
                <li>&copy; <a target="_blank" href="https://de.123rf.com/profile_betochagas">betochagas / 123RF Lizenzfreie Bilder</a></li>
                <li>&copy; <a target="_blank" href="https://de.123rf.com/profile_boggy22">boggy22 / 123RF Lizenzfreie Bilder</a></li>
                <li>&copy; <a target="_blank" href="https://de.123rf.com/profile_dutourdumonde">dutourdumonde / 123RF Lizenzfreie Bilder</a></li>
                <li>&copy; <a target="_blank" href="https://de.123rf.com/profile_fikmik">fikmik / 123RF Lizenzfreie Bilder</a></li>
                <li>&copy; <a target="_blank" href="https://de.123rf.com/profile_grinvalds">grinvalds / 123RF Lizenzfreie Bilder</a></li>
                <li>&copy; <a target="_blank" href="https://de.123rf.com/profile_leon123">leon123 / 123RF Lizenzfreie Bilder</a></li>
                <li>&copy; <a target="_blank" href="https://de.123rf.com/profile_limonzest">limonzest / 123RF Stockfoto</a></li>
                <li>&copy; <a target="_blank" href="https://de.123rf.com/profile_ljupco">ljupco / 123RF Stockfoto</a></li>
                <li>&copy; <a target="_blank" href="https://de.123rf.com/profile_luckybusiness">luckybusiness / 123RF Lizenzfreie Bilder</a></li>
                <li>&copy; <a target="_blank" href="https://de.123rf.com/profile_mikedesign">mikedesign / 123RF Lizenzfreie Bilder</a></li>
                <li>&copy; <a target="_blank" href="https://de.123rf.com/profile_nickp37">nickp37 / 123RF Stockfoto</a></li>
                <li>&copy; <a target="_blank" href="https://de.123rf.com/profile_nyul">nyul / 123RF Stockfoto</a></li>
                <li>&copy; <a target="_blank" href="https://de.123rf.com/profile_olegdudko">olegdudko / 123RF Lizenzfreie Bilder</a></li>
                <li>&copy; <a target="_blank" href="https://de.123rf.com/profile_sumners">sumners / 123RF Stockfoto</a></li>
                <li>&copy; <a target="_blank" href="https://de.123rf.com/profile_velvetocean">velvetocean / 123RF Stockfoto</a></li>
            </ul>
        </ot-modal>
        <a href="http://www.liga-manager.de" target="_blank">Liga-Manager.de</a>
        <a href="https://mixpanel.com/f/partner" target="_blank"><img src="https://cdn.mxpnl.com/site_media/images/partner/badge_light.png" alt="Mobile Analytics"></a>
    </div>
</template>
