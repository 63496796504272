<script>
import Fixture from './Fixture.vue';

export default {
    components: {
        OtFixture: Fixture,
    },
    props: {
        value: {
            type: String,
            required: true,
        },
        spectators: {
            type: Number,
            required: false,
            default: 0,
        },
        matchId: {
            type: Number,
            required: true,
        },
        matchType: {
            type: String,
            required: true,
        },
        vertical: {
            type: Boolean,
            default: false,
        },
        hideLink: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        link() {
            if (this.hideLink) {
                return null;
            }

            return Routing.generate('OnlinetennisBundle_livematch', { _locale: locale, matchType: this.matchType, id: this.matchId });
        },
    },
};
</script>

<template>
    <ot-fixture :link="link()" :value="value" :spectators="spectators" :vertical="vertical">
        <template #participant-one>
            <div class="flex items-center truncate">
                <div>
                    <slot name="participant-one" />
                </div>
                <div v-if="$slots['participant-one-extra']" class="flex ml-2">
                    <slot name="participant-one-extra" />
                </div>
            </div>
        </template>
        <template #participant-two>
            <div class="flex items-center truncate">
                <div>
                    <slot name="participant-two" />
                </div>
                <div v-if="$slots['participant-two-extra']" class="flex ml-2">
                    <slot name="participant-two-extra" />
                </div>
            </div>
        </template>
    </ot-fixture>
</template>
