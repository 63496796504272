<script>
import FeedbackRepository from '../../domain/feedback/FeedbackRepository';
import DataProvider from './DataProvider.vue';

export default {
    mixins: [DataProvider],
    methods: {
        getRepository() {
            return new FeedbackRepository();
        },
    },
};
</script>
