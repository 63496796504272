<script setup lang="ts">
import type { AccordionItemProps } from 'radix-vue';
import type { HTMLAttributes } from 'vue';
import { AccordionItem, useForwardProps } from 'radix-vue';
import { computed } from 'vue';

interface Props extends AccordionItemProps {
    class?: HTMLAttributes['class'];
}
const props = defineProps<Props>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
    <accordion-item
        v-bind="forwardedProps"
        :class="props.class"
    >
        <slot />
    </accordion-item>
</template>
