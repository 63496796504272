<script>
import { h } from 'vue';
import Loader from '../Loader.vue';
import LoadingError from '../LoadingError.vue';

export default {
    props: {
        method: {
            type: String,
            required: true,
        },
        collection: {
            type: Boolean,
            required: false,
            default: false,
        },
        id: {
            type: String,
            required: false,
        },
        params: {
            type: Object,
            required: false,
            default: () => {},
        },
        includes: {
            type: Array,
            required: false,
            default: () => [],
        },
        callback: {
            type: Function,
            required: false,
        },
        modifier: {
            type: Function,
            required: false,
        },
        interval: {
            type: Number,
            required: false,
        },
    },
    data() {
        return {
            data: null,
            loading: true,
            error: false,
            reloadHandle: null,
            errorReloadHandle: null,
        };
    },
    created() {
        this.load();
    },
    unmounted() {
        clearTimeout(this.reloadHandle);
        clearTimeout(this.errorReloadHandle);
        this.reloadHandle = undefined;
        this.errorReloadHandle = undefined;
    },
    methods: {
        load() {
            clearTimeout(this.reloadHandle);
            clearTimeout(this.errorReloadHandle);
            const callback = (data) => {
                if (this.modifier) {
                    data = this.modifier(data);
                }
                this.data = data;
                this.loading = false;
                this.error = false;
                if (this.callback) {
                    this.callback(data);
                }
                if (this.interval) {
                    this.reloadHandle = setTimeout(this.load, this.interval);
                }
            };
            const errorHandler = () => {
                this.error = true;
                this.loading = false;
                this.errorReloadHandle = setTimeout(this.load, 10000);
            };
            if (this.collection) {
                if (this.params) {
                    this.getRepository()[this.method](this.params, this.includes).then(callback).catch(errorHandler);
                }
                else {
                    this.getRepository()[this.method](this.includes).then(callback).catch(errorHandler);
                }
            }
            else {
                if (this.params) {
                    this.getRepository()[this.method](this.params, this.includes).then(callback).catch(errorHandler);
                }
                else {
                    this.getRepository()[this.method](this.id, this.includes).then(callback).catch(errorHandler);
                }
            }
        },
    },
    render() {
        if (this.loading) {
            return h(Loader, { loading: this.loading, standalone: true });
        }

        if (this.error) {
            return h(LoadingError);
        }

        return this.$slots.default({
            data: this.data,
            reload: this.load,
        });
    },
};
</script>
