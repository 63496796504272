<script setup lang="ts">
import { SliderRoot, SliderThumb, SliderTrack } from 'radix-vue';
import { computed } from 'vue';

interface Props {
    modelValue: number;
    min: number;
    max: number;
    step: number;
    showLabels: boolean;
}
interface Emits {
    'update:modelValue': (value: number) => void;
}

const props = withDefaults(defineProps<Props>(), {
    showLabels: false,
});
defineEmits<Emits>();

const ticks = computed(() => Array.from({ length: Math.floor((props.max - props.min) / props.step) + 1 }, (_, i) => props.min + i * props.step));
</script>

<template>
    <slider-root
        :model-value="[modelValue]"
        class="relative flex items-center select-none touch-none w-full h-5"
        :min="min"
        :max="max"
        :step="step"
        @update:model-value="$emit('update:modelValue', $event[0])"
    >
        <slider-track class="bg-green-600 relative grow rounded-full h-1.5 mx-2">
            <div class="flex justify-between">
                <div
                    v-for="tick in ticks"
                    :key="tick"
                    class="w-0.5 h-0.5 rounded-full mt-0.5 flex justify-center text-sm"
                    :class="{ 'bg-white': tick !== min && tick !== max }"
                >
                    <div v-if="showLabels" class="mt-3">{{ tick }}</div>
                </div>
            </div>
        </slider-track>
        <slider-thumb
            class="block w-5 h-5 bg-green-700 shadow-[0_2px_10px] shadow-black/50 rounded-full hover:bg-green-600 focus:outline-hidden transition-all duration-200"
            aria-label="Slider thumb"
        />
    </slider-root>
</template>
