<script>
import axios from 'axios';

import Button from '../components/Button.vue';
import Card from '../components/Card.vue';
import Loader from '../components/Loader.vue';
import Player from '../components/Player.vue';

export default {
    components: {
        OtCard: Card,
        OtLoader: Loader,
        OtPlayer: Player,
        OtButton: Button,
    },
    data() {
        return {
            loading: true,
            friends: [],
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            axios
                .get('/api/players/me/friends')
                .then((response) => {
                    this.friends = response.data.data;
                    this.loading = false;
                });
        },
        removeFriend(id) {
            axios
                .delete(`/api/players/me/friends/${id}`)
                .then(() => this.load());
        },
    },
};
</script>

<template>
    <ot-loader :loading="loading">
        <ot-card legacy>
            <table>
                <thead>
                    <tr class="border-b border-gray-500">
                        <th class="px-4 py-1 text-sm text-left font-medium">{{ $t('friendslist.friendslist_name') }}</th>
                        <th class="px-4 py-1 text-sm text-left font-medium">{{ $t('friendslist.friendslist_entrylist') }}</th>
                        <th class="px-4 py-1 text-sm text-left font-medium">{{ $t('friendslist.friendslist_championsrace') }}</th>
                        <th class="px-4 py-1 text-sm text-left font-medium" />
                    </tr>
                </thead>
                <tbody class="divide-y divide-gray-500">
                    <tr
                        v-for="friend in friends"
                        :key="friend.id"
                    >
                        <td class="px-4 py-1">
                            <ot-player
                                :id="friend.id"
                                :country="friend.country.data.id"
                                ignore-friend-status
                            >
                                {{ friend.player_name.data.first_name }} {{ friend.player_name.data.last_name }}
                            </ot-player>
                        </td>
                        <td class="px-4 py-1">{{ friend.entrylist.data.points }} ({{ friend.entrylist.data.position }}.)</td>
                        <td class="px-4 py-1">{{ friend.championsrace.data.points }} ({{ friend.championsrace.data.position }}.)</td>
                        <td class="px-4 py-1">
                            <ot-button variant="danger" @click="removeFriend(friend.id)">{{ $t('general.remove') }}</ot-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ot-card>
    </ot-loader>
</template>
