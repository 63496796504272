<script setup lang="ts">
import axios from 'axios';
import { computed, ref } from 'vue';
import { useLayout } from '../composables/layout';
import OtChat from './Chat.vue';

interface Props {
    channel: number;
}
const props = defineProps<Props>();

const currentChannel = ref(props.channel);

const { isChatOpen, toolbarExtension, width } = useLayout();
const classes = computed(() => {
    const classes = [];

    classes.push(isChatOpen.value ? 'translate-x-0' : 'translate-x-full');
    classes.push(toolbarExtension.value ? 'top-24' : 'top-12');
    if (width.value < 1400) {
        classes.push('elevation-8');
    }

    return classes;
});

function changeChannel(id) {
    currentChannel.value = id;
    axios.post(Routing.generate('change_chat_channel', { id }));
}
</script>

<template>
    <div
        class="fixed right-0 b-0 w-[256px] z-10 transform transition-transform duration-300 bg-stone-300"
        :class="classes"
        :style="`height: calc(100% - ${(toolbarExtension ? 2 : 1) * 48}px)`"
    >
        <ot-chat
            :channel="currentChannel"
            @change-channel="changeChannel($event)"
        />
    </div>
</template>
