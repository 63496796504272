<script setup lang="ts">
import type { SwitchRootEmits, SwitchRootProps } from 'radix-vue';
import type { HTMLAttributes } from 'vue';
import { SwitchRoot, SwitchThumb, useForwardPropsEmits } from 'radix-vue';
import { computed } from 'vue';
import { cn } from '../utils';

interface Props extends SwitchRootProps {
    label: string;
    class?: HTMLAttributes['class'];
}
const props = defineProps<Props>();

const emits = defineEmits<SwitchRootEmits>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
    <label :class="cn('flex gap-2 items-center', props.class)">
        <switch-root
            v-bind="forwarded"
            class="peer inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-green-700 data-[state=unchecked]:bg-gray-600"
        >
            <switch-thumb :class="cn('pointer-events-none block h-5 w-5 rounded-full bg-white shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5')" />
        </switch-root>
        <span class="text-sm">{{ props.label }}</span>
    </label>
</template>
