<script setup lang="ts">
import { ref } from 'vue';
import { OtSlider } from './slider';
import OtSubheader from './Subheader.vue';
import { OtTextField } from './text-field';

interface Props {
    name: string;
    label: string;
    value: number;
    step: number;
}
const props = defineProps<Props>();

const val = ref(props.value);
</script>

<template>
    <div>
        <ot-subheader>{{ label }}</ot-subheader>
        <input type="hidden" :name="name" :value="val">
        <div class="flex gap-2 items-center">
            <ot-slider
                v-model="val"
                :min="0"
                :max="100"
                :step="step"
            />
            <ot-text-field
                v-model="val"
                number
                class="w-24"
            />
        </div>
    </div>
</template>
