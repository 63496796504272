<script>
import axios from 'axios';

import Loader from '../../components/Loader.vue';

export default {
    components: {
        OtLoader: Loader,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            data: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            axios
                .get(`/api/player-profiles/${this.id}/private-life`)
                .then((response) => {
                    this.data = response.data.data;
                    this.loading = false;
                });
        },
    },
};
</script>

<template>
    <ot-loader :loading="loading" standalone>
        <div class="flex justify-around gap-5">
            <div>
                <div>{{ $t('finanzen.finanzen_verkehrsmittel') }}:</div>
                <div><img :src="`/images/statussymbole/verkehrsmittel_${data.vehicle}.jpg`" :alt="$t(`finanzen.finanzen_verkehrsmittel_${data.vehicle}`)"></div>
            </div>
            <div>
                <div>{{ $t('profil.profil_sponsor') }}:</div>
                <img v-if="data.sponsor_treaty" :src="`/images/sponsoren/${data.sponsor_treaty.sponsor.image}`" :alt="data.sponsor_treaty.sponsor.name" border="0">
                <img v-else src="../../images/sponsoren/kein_sponsor.png" alt="" />
            </div>
            <div>
                <div>{{ $t('finanzen.finanzen_wohnort') }}:</div>
                <div><img :src="`/images/statussymbole/wohnung_${data.residence}.jpg`" :alt="$t(`finanzen.finanzen_wohnort_${data.residence}`)"></div>
            </div>
        </div>
    </ot-loader>
</template>
