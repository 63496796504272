import type SuggestionListGetResponseDto from './SuggestionListGetResponseDto';

export default class SuggestionListGetResponseDtoList {
    private readonly elements: SuggestionListGetResponseDto[];

    public constructor(elements: SuggestionListGetResponseDto[] = []) {
        this.elements = elements;
    }

    public toArray(): SuggestionListGetResponseDto[] {
        return this.elements;
    }

    public filterVoted(): SuggestionListGetResponseDtoList {
        return new SuggestionListGetResponseDtoList(this.elements.filter(element => element.isVoted()));
    }

    public get length(): number {
        return this.elements.length;
    }
}
