<script>
import { Star } from 'lucide-vue-next';
import Button from '../components/Button.vue';
import Card from '../components/Card.vue';
import SuggestionDataProvider from '../components/data-provider/SuggestionDataProvider.vue';
import Divider from '../components/Divider.vue';
import Header from '../components/Header.vue';
import IconButton from '../components/IconButton.vue';
import Player from '../components/Player.vue';
import Subheader from '../components/Subheader.vue';
import { OtTextarea } from '../components/textarea';
import SuggestionCommentRepository from '../domain/suggestion/SuggestionCommentRepository';
import SuggestionRepository from '../domain/suggestion/SuggestionRepository';
import SuggestionCommentCreateDto from '../dto/request/SuggestionCommentCreateDto';

export default {
    components: {
        OtSuggestionDataProvider: SuggestionDataProvider,
        OtPlayer: Player,
        OtCard: Card,
        OtButton: Button,
        OtDivider: Divider,
        OtHeader: Header,
        OtSubheader: Subheader,
        OtIconButton: IconButton,
        OtTextarea,
        Star,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            title: '',
            content: '',
        };
    },
    methods: {
        updateTitle(suggestion) {
            this.title = suggestion.getTitle();
        },
        vote(suggestion, reload) {
            new SuggestionRepository()
                .vote(suggestion)
                .then(() => reload());
        },
        unvote(suggestion, reload) {
            new SuggestionRepository()
                .unvote(suggestion)
                .then(() => reload());
        },
        post(reload) {
            new SuggestionCommentRepository()
                .add(new SuggestionCommentCreateDto(this.id, this.content))
                .then(() => reload());
            this.content = '';
        },
    },
};
</script>

<template>
    <ot-card legacy style="padding: 0!important;">
        <ot-suggestion-data-provider :id="id" v-slot="{ data: suggestion, reload }" method="find" :callback="updateTitle">
            <div>
                <ot-header :title="title">
                    <ot-icon-button v-if="suggestion.isVoted()" @click="unvote(suggestion, reload)">
                        <star class="text-amber-700 fill-amber-700" />
                    </ot-icon-button>
                    <ot-icon-button v-if="!suggestion.isVoted()" @click="vote(suggestion, reload)">
                        <star class="text-gray-500" />
                    </ot-icon-button>
                </ot-header>
                <h3 class="m-4 text-xl">{{ $t('general.suggestion_authored_by') }}: {{ suggestion.getAuthorName().getFullName() }}</h3>
                <div class="m-4">
                    <p v-html="suggestion.getContent().replace(/\n/g, '<br />')" />
                    <div class="text-xs">{{ $t('general.suggestion_voted_by') }}: {{ suggestion.getVoterNames().map(voter => voter.getFullName()).join(', ') }}</div>
                </div>
                <ot-subheader class="mt-2">{{ $t('general.suggestion_comments') }}</ot-subheader>
                <template v-for="(suggestionComment, index) in suggestion.getComments()" :key="index">
                    <div class="m-4">
                        <div>
                            <div>
                                <ot-player
                                    :id="suggestionComment.getAuthorId()"
                                    :country="suggestionComment.getAuthorCountry().getId()"
                                >
                                    {{ suggestionComment.getAuthorName().getFullName() }}
                                </ot-player>
                            </div>
                            <div class="text-sm">{{ suggestionComment.getCreatedAt().toLocaleString() }}</div>
                        </div>
                        <div v-html="suggestionComment.getContent().replace(/\n/g, '<br />')" />
                    </div>
                    <ot-divider v-if="index < suggestion.getComments().length - 1" />
                </template>
                <div class="p-4">
                    <ot-textarea v-model="content" :label="$t('general.suggestion_message')" />
                    <ot-button @click="post(reload)">{{ $t('general.suggestion_button_post_comment') }}</ot-button>
                </div>
            </div>
        </ot-suggestion-data-provider>
    </ot-card>
</template>
