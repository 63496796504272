<script setup lang="ts">
import { useRouter } from 'vue-router';
import OtCard from '../components/Card.vue';
import OtHeader from '../components/Header.vue';
import { OtTabs, OtTabsList, OtTabsTrigger } from '../components/tabs';

const router = useRouter();
router.push('/messages/inbox');
</script>

<template>
    <ot-card>
        <ot-header :title="$t('internalMail.headline')" />
        <ot-tabs default-value="inbox">
            <ot-tabs-list>
                <ot-tabs-trigger value="inbox" @click="router.push('/messages/inbox')">{{ $t('nachrichten.inbox') }}</ot-tabs-trigger>
                <ot-tabs-trigger value="outbox" @click="router.push('/messages/outbox')">{{ $t('nachrichten.outbox') }}</ot-tabs-trigger>
                <ot-tabs-trigger value="trash" @click="router.push('/messages/trash')">{{ $t('nachrichten.trash') }}</ot-tabs-trigger>
            </ot-tabs-list>
        </ot-tabs>
        <router-view />
    </ot-card>
</template>
