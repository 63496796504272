<script>
import axios from 'axios';
import CalendarTournamentRow from '../components/CalendarTournamentRow.vue';
import Card from '../components/Card.vue';
import Flag from '../components/Flag.vue';
import Loader from '../components/Loader.vue';
import Surface from '../components/Surface.vue';
import { OtTabs, OtTabsContent, OtTabsList, OtTabsTrigger } from '../components/tabs';

export default {
    components: {
        OtLoader: Loader,
        OtCard: Card,
        OtSurface: Surface,
        OtFlag: Flag,
        OtCalendarTournamentRow: CalendarTournamentRow,
        OtTabs,
        OtTabsContent,
        OtTabsList,
        OtTabsTrigger,
    },
    data() {
        return {
            loading: true,
            dataLoading: false,
            date: {
                season: this.$store.state.date.season,
                week: this.$store.state.date.week,
            },
            listOfTournamentsSingles: [],
            listOfTournamentsDoubles: [],
            listOfTournamentsUserTournament: [],
            showPlayableTournamentsOnlySingles: true,
            showPlayableTournamentsOnlyUserTournament: true,
            myTournamentsSingles: [],
            myTournamentsDoubles: [],
            myTournamentsUserTournament: [],
            closedWeeks: [],
            weekDescriptionDate: '',
            weekDescriptionSignups: '',
            listOfOtCupFixtures: [],
            otCupOpponents: [],
            listOfDavisCupFixtures: [],
            davisCupOpponents: [],
            maxSeason: null,
            numberOfWeeks: null,
        };
    },
    computed: {
        seasons() {
            const seasons = [];
            for (let i = 1; i <= this.maxSeason; i++) {
                seasons.push(i);
            }
            return seasons;
        },
        weeks() {
            const weeks = [];
            for (let i = 1; i <= this.numberOfWeeks; i++) {
                weeks.push(i);
            }
            return weeks;
        },
        player() {
            return this.$store.getters.player;
        },
        tournamentsSingles() {
            if (!this.showPlayableTournamentsOnlySingles) {
                return this.listOfTournamentsSingles;
            }

            const tournamentTypes = {};
            if (this.listOfTournamentsSingles.ATP) {
                tournamentTypes.ATP = this.listOfTournamentsSingles.ATP.filter(tournament => tournament.is_playable);
            }
            if (this.listOfTournamentsSingles.CHALLENGER) {
                tournamentTypes.CHALLENGER = this.listOfTournamentsSingles.CHALLENGER.filter(tournament => tournament.is_playable);
            }
            if (this.listOfTournamentsSingles.FUTURE) {
                tournamentTypes.FUTURE = this.listOfTournamentsSingles.FUTURE.filter(tournament => tournament.is_playable);
            }
            if (this.listOfTournamentsSingles.JUNIOR) {
                tournamentTypes.JUNIOR = this.listOfTournamentsSingles.JUNIOR.filter(tournament => tournament.is_playable);
            }
            if (this.listOfTournamentsSingles.ENTRY) {
                tournamentTypes.ENTRY = this.listOfTournamentsSingles.ENTRY.filter(tournament => tournament.is_playable);
            };

            return tournamentTypes;
        },
        tournamentsDoubles() {
            return this.listOfTournamentsDoubles;
        },
        tournamentsUserTournament() {
            if (!this.showPlayableTournamentsOnlyUserTournament) {
                return this.listOfTournamentsUserTournament;
            }

            const tournamentTypes = {};
            if (this.listOfTournamentsUserTournament.USER) {
                tournamentTypes.USER = this.listOfTournamentsUserTournament.USER.filter(tournament => tournament.is_playable);
            }

            return tournamentTypes;
        },
    },
    created() {
        this.initialLoad(() => {
            this.date.season = this.$store.state.date.season;
            this.date.week = this.$store.state.date.week;
            this.reloadData();
        });
    },
    methods: {
        changeWeek(week) {
            this.date.week = week;
            this.reloadData();
        },
        calendarTitleSingles(week) {
            return this.myTournamentsSingles[week] && this.myTournamentsSingles[week].has_missed_cut ? this.$t('internalCalendar.missedCut') : null;
        },
        calendarTitleDoubles(week) {
            return this.myTournamentsDoubles[week] && this.myTournamentsDoubles[week].has_missed_cut ? this.$t('internalCalendar.missedCut') : null;
        },
        calendarTitleUserTournaments(week) {
            return this.myTournamentsUserTournament[week] && this.myTournamentsUserTournament[week].has_missed_cut ? this.$t('internalCalendar.missedCut') : null;
        },
        linkCountry(id) {
            return Routing.generate('country', { id, _locale: locale });
        },
        linkTournament(tournamentId, mode) {
            return Routing.generate('OnlinetennisBundle_profileTournament', { id: tournamentId, mode, _locale: locale });
        },
        linkTeam(teamId) {
            return Routing.generate('OnlinetennisBundle_profileTeam', { id: teamId, _locale: locale });
        },
        linkOtCupFixture(fixtureId) {
            return Routing.generate('otcup_fixture', { id: fixtureId, _locale: locale });
        },
        linkDavisCupDraw(league) {
            return Routing.generate('daviscup_draw', { league, _locale: locale });
        },
        linkDavisCupFixture(fixtureId) {
            return Routing.generate('daviscup_fixture', { id: fixtureId, _locale: locale });
        },
        reloadData() {
            this.dataLoading = true;
            axios
                .get(`/api/calendar/${this.date.season}/${this.date.week}`)
                .then((response) => {
                    this.listOfTournamentsSingles = response.data.data.listOfTournamentsSingles;
                    this.listOfTournamentsDoubles = response.data.data.listOfTournamentsDoubles;
                    this.listOfTournamentsUserTournament = response.data.data.listOfTournamentsUserTournament;
                    this.myTournamentsSingles = response.data.data.myTournamentsSingles;
                    this.myTournamentsDoubles = response.data.data.myTournamentsDoubles;
                    this.myTournamentsUserTournament = response.data.data.myTournamentsUserTournament;
                    this.closedWeeks = response.data.data.closedWeeks;
                    this.weekDescriptionDate = response.data.data.weekDescriptionDate;
                    this.weekDescriptionSignups = response.data.data.weekDescriptionSignups;
                    this.listOfOtCupFixtures = response.data.data.listOfOtCupFixtures;
                    this.otCupOpponents = response.data.data.otCupOpponents;
                    this.listOfDavisCupFixtures = response.data.data.listOfDavisCupFixtures;
                    this.davisCupOpponents = response.data.data.davisCupOpponents;
                    this.maxSeason = response.data.data.maxSeason;
                    this.numberOfWeeks = response.data.data.numberOfWeeks;
                    this.loading = false;
                    this.dataLoading = false;
                });
        },
        initialLoad(callback) {
            if (this.$store.state.date.season) {
                callback();
            }
            else {
                setTimeout(() => this.initialLoad(callback), 100);
            }
        },
    },
};
</script>

<template>
    <ot-loader :loading="loading">
        <ot-card legacy class="mb-4">
            <select v-model="date.season" name="season" @change="reloadData()">
                <option v-for="season in seasons" :key="season" :value="season">{{ $t('kalender.kalender_jahr') }} {{ season }}</option>
            </select>
        </ot-card>
        <ot-card>
            <ot-tabs id="calendar" default-value="singles">
                <ot-tabs-list>
                    <ot-tabs-trigger value="singles">{{ $t('internalTactics.tab_singles') }}</ot-tabs-trigger>
                    <ot-tabs-trigger value="doubles">{{ $t('internalTactics.tab_doubles') }}</ot-tabs-trigger>
                    <ot-tabs-trigger value="user-tournament">{{ $t('internalTactics.tab_userTournament') }}</ot-tabs-trigger>
                    <ot-tabs-trigger value="otcup">{{ $t('internalOTCup.headline') }}</ot-tabs-trigger>
                    <ot-tabs-trigger value="daviscup">{{ $t('general.daviscup') }}</ot-tabs-trigger>
                </ot-tabs-list>
                <ot-tabs-content value="singles">
                    <div class="weeks">
                        <div
                            v-for="week in weeks" :key="week" class="week" :class="[
                                `week${week}`,
                                date.week === week ? 'selectedWeek' : '',
                                $store.state.date.week === week ? 'currentWeek' : '',
                                myTournamentsSingles[week] && myTournamentsSingles[week].has_missed_cut ? 'cutMissed' : '',
                                closedWeeks[week] ? 'closedWeek' : '',
                            ]" :title="calendarTitleSingles(week)" @click="changeWeek(week)"
                        >
                            <h5 class="text-sm font-bold">{{ $t('general.week') }} {{ week }}</h5>
                            <div v-if="myTournamentsSingles[week]" class="flex justify-center items-center gap-2">
                                <ot-flag
                                    :id="myTournamentsSingles[week].country.id"
                                    :title="myTournamentsSingles[week].name"
                                    :link="linkTournament(myTournamentsSingles[week].id, 'singles')"
                                />
                                <ot-surface :type="myTournamentsSingles[week].surface" />
                            </div>
                        </div>
                    </div>
                    <ot-loader :loading="dataLoading" standalone>
                        <p>{{ weekDescriptionDate }} - {{ weekDescriptionSignups }}</p>

                        <label>
                            <input v-model="showPlayableTournamentsOnlySingles" type="checkbox">
                            {{ $t('internalCalendar.showPlayableTournamentsOnly') }}
                        </label>
                        <table class="striped">
                            <template v-for="(tournaments, type) in tournamentsSingles">
                                <template v-if="type !== 'USER'">
                                    <tr v-if="tournaments" style="text-align: left; font-weight: bold;" :key="type">
                                        <td colspan="10">{{ type }}</td>
                                    </tr>
                                    <ot-calendar-tournament-row v-for="tournament in tournaments" :key="tournament.id" :tournament="tournament" :season="date.season" :week="date.week" type="singles" @update="reloadData()" />
                                </template>
                            </template>
                        </table>
                    </ot-loader>
                </ot-tabs-content>
                <ot-tabs-content value="doubles">
                    <div class="weeks">
                        <div
                            v-for="week in weeks" :key="week" class="week" :class="[
                                `week${week}`,
                                date.week === week ? 'selectedWeek' : '',
                                $store.state.date.week === week ? 'currentWeek' : '',
                                myTournamentsDoubles[week] && myTournamentsDoubles[week].has_missed_cut ? 'cutMissed' : '',
                                closedWeeks[week] ? 'closedWeek' : '',
                            ]" :title="calendarTitleDoubles(week)" @click="changeWeek(week)"
                        >
                            <h5 class="text-sm font-bold">{{ $t('general.week') }} {{ week }}</h5>
                            <div v-if="myTournamentsDoubles[week]" class="flex justify-center items-center gap-2">
                                <ot-flag
                                    :id="myTournamentsDoubles[week].country.id"
                                    :title="myTournamentsDoubles[week].name"
                                    :link="linkTournament(myTournamentsDoubles[week].id, 'doubles')"
                                />
                                <ot-surface :type="myTournamentsDoubles[week].surface" />
                            </div>
                        </div>
                    </div>
                    <ot-loader :loading="dataLoading" standalone>
                        <p>{{ weekDescriptionDate }} - {{ weekDescriptionSignups }}</p>

                        <table class="striped">
                            <template v-for="(tournaments, type) in tournamentsDoubles">
                                <tr v-if="tournaments" class="text-left" style="font-weight: bold;" :key="type">
                                    <td colspan="10">{{ type }}</td>
                                </tr>
                                <ot-calendar-tournament-row v-for="tournament in tournaments" :key="tournament.id" :tournament="tournament" :season="date.season" :week="date.week" :show-actions="player.hasDoubles" type="doubles" @update="reloadData()" />
                            </template>
                        </table>
                    </ot-loader>
                </ot-tabs-content>
                <ot-tabs-content value="user-tournament">
                    <div class="weeks">
                        <div
                            v-for="week in weeks" :key="week" class="week" :class="[
                                `week${week}`,
                                date.week === week ? 'selectedWeek' : '',
                                $store.state.date.week === week ? 'currentWeek' : '',
                                myTournamentsUserTournament[week] && myTournamentsUserTournament[week].has_missed_cut ? 'cutMissed' : '',
                                closedWeeks[week] ? 'closedWeek' : '',
                            ]" :title="calendarTitleUserTournaments(week)" @click="changeWeek(week)"
                        >
                            <h5 class="text-sm font-bold">{{ $t('general.week') }} {{ week }}</h5>
                            <div v-if="myTournamentsUserTournament[week]" class="flex justify-center items-center gap-2">
                                <ot-flag
                                    :id="myTournamentsUserTournament[week].country.id"
                                    :title="myTournamentsUserTournament[week].name"
                                    :link="linkTournament(myTournamentsUserTournament[week].id, 'singles')"
                                />
                                <ot-surface :type="myTournamentsUserTournament[week].surface" />
                            </div>
                        </div>
                    </div>
                    <ot-loader :loading="dataLoading" standalone>
                        <p>{{ weekDescriptionDate }} - {{ weekDescriptionSignups }}</p>

                        <label>
                            <input v-model="showPlayableTournamentsOnlyUserTournament" type="checkbox">
                            {{ $t('internalCalendar.showPlayableTournamentsOnly') }}
                        </label>
                        <table class="striped">
                            <template v-for="(tournaments, type) in tournamentsUserTournament">
                                <template v-if="type === 'USER'">
                                    <ot-calendar-tournament-row v-for="tournament in tournaments" :key="type + tournament.id" :tournament="tournament" :season="date.season" :week="date.week" type="usertournament" @update="reloadData()" />
                                </template>
                            </template>
                        </table>
                    </ot-loader>
                </ot-tabs-content>
                <ot-tabs-content value="otcup">
                    <div class="weeks">
                        <div
                            v-for="week in weeks" :key="week" class="week" :class="[
                                `week${week}`,
                                date.week === week ? 'selectedWeek' : '',
                                $store.state.date.week === week ? 'currentWeek' : '',
                                closedWeeks[week] ? 'closedWeek' : '',
                            ]" @click="changeWeek(week)"
                        >
                            <h5 class="text-sm font-bold">{{ $t('general.week') }} {{ week }}</h5>
                            <div class="absolute left-[17px] bottom-[3px]">
                                <a v-if="otCupOpponents[week]" :href="linkTeam(otCupOpponents[week].id)"><img :src="`/teamLogo/${otCupOpponents[week].id}/32`" :alt="otCupOpponents[week].name" :title="otCupOpponents[week].name"></a>
                            </div>
                        </div>
                    </div>
                    <ot-loader :loading="dataLoading" standalone>
                        <table class="striped">
                            <tbody>
                                <tr style="text-align: left; font-weight: bold;">
                                    <td colspan="4">{{ $t('internalOTCup.headline') }}</td>
                                </tr>
                                <template v-for="(leagues, league) in listOfOtCupFixtures" :key="league">
                                    <template v-for="(divisions, division) in leagues" :key="division">
                                        <tr style="text-align: left; font-weight: bold;">
                                            <td colspan="5"><a :href="`otCup?league=${league}&division=${division}`">{{ $t('internalOTCup.league') }} {{ league }} / {{ division }}</a></td>
                                        </tr>
                                        <tr
                                            v-for="fixture in divisions"
                                            :key="fixture.id"
                                            :class="{ highlight: player.hasTeam && ((fixture.team1 && fixture.team1.id === player.team.id) || (fixture.team2 && fixture.team2.id === player.team.id)) }"
                                        >
                                            <td width="40px" align="center" nowrap="nowrap"><a :href="linkOtCupFixture(fixture.id)">{{ fixture.points1 }} : {{ fixture.points2 }}</a></td>
                                            <td align="center"><ot-surface v-if="fixture.is_surface_fixed" :type="fixture.surface" /></td>
                                            <td align="left"><a v-if="fixture.team1" :href="linkTeam(fixture.team1.id)">{{ fixture.team1.name }}</a></td>
                                            <td align="center">-</td>
                                            <td align="left"><a v-if="fixture.team2" :href="linkTeam(fixture.team2.id)">{{ fixture.team2.name }}</a></td>
                                        </tr>
                                    </template>
                                </template>
                            </tbody>
                        </table>
                    </ot-loader>
                </ot-tabs-content>
                <ot-tabs-content value="daviscup">
                    <div class="weeks">
                        <div
                            v-for="week in weeks" :key="week" class="week" :class="[
                                `week${week}`,
                                date.week === week ? 'selectedWeek' : '',
                                $store.state.date.week === week ? 'currentWeek' : '',
                                closedWeeks[week] ? 'closedWeek' : '',
                            ]" @click="changeWeek(week)"
                        >
                            <h5 class="text-sm font-bold">{{ $t('general.week') }} {{ week }}</h5>
                            <div v-for="(opponent, index) in davisCupOpponents[week]" :key="index" class="ml-1.5 -mb-1 float-left">
                                <a :href="linkCountry(opponent.id)"><ot-flag :id="opponent.country.id" /></a>
                            </div>
                        </div>
                    </div>
                    <ot-loader :loading="dataLoading" standalone>
                        <table class="striped">
                            <tbody>
                                <tr style="text-align: left; font-weight: bold;">
                                    <td colspan="4">{{ $t('general.daviscup') }}</td>
                                </tr>
                                <template v-for="(fixture, index) in listOfDavisCupFixtures" :key="index">
                                    <tr v-if="index > 0 && fixture.league !== listOfDavisCupFixtures[index - 1].league" class="text-left" style="font-weight: bold;">
                                        <td colspan="4"><a :href="linkDavisCupDraw(fixture.league)">{{ $t('internalOTCup.league') }} {{ fixture.league }}</a></td>
                                    </tr>
                                    <tr :class="{ hightlight: (fixture.team1 && fixture.team1.country.id === player.country.id) || (fixture.team2 && fixture.team2.country.id === player.country.id) }">
                                        <td width="40px" align="center" nowrap="nowrap"><a :href="linkDavisCupFixture(fixture.id)">{{ fixture.points1 }} : {{ fixture.points2 }}</a></td>
                                        <td align="left"><a v-if="fixture.team1" :href="linkCountry(fixture.team1.country.id)"><ot-flag :id="fixture.team1.country.id" />&nbsp;{{ fixture.team1.name }}</a></td>
                                        <td align="center">-</td>
                                        <td align="left"><a v-if="fixture.team2" :href="linkCountry(fixture.team2.country.id)"><ot-flag :id="fixture.team2.country.id" />&nbsp;{{ fixture.team2.name }}</a></td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </ot-loader>
                </ot-tabs-content>
            </ot-tabs>
        </ot-card>
    </ot-loader>
</template>

<style scoped>
@reference "../css/onlinetennis.css";

.weeks {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px;
}
.week {
    position: relative;
    flex-basis: 80px;
    height: 72px;
    cursor: pointer;
    @apply border-2;
    @apply border-neutral-900;
    @apply bg-zinc-100;
    margin: 4px;
}
.week h5 {
    margin: 0;
    padding: 2px 4px;
    width: 100%;
    @apply bg-green-700;
    @apply text-white;
}
.currentWeek h5 {
    @apply bg-blue-700;
}
.selectedWeek {
    @apply border-2;
    @apply border-blue-700;
}
.closedWeek {
    @apply bg-neutral-300;
}
.cutMissed {
    @apply bg-red-700;
}
</style>
