<script>
import axios from 'axios';

import Card from '../components/Card.vue';
import Loader from '../components/Loader.vue';

export default {
    components: {
        OtCard: Card,
        OtLoader: Loader,
    },
    data() {
        return {
            loading: true,
            data: {},
        };
    },
    created() {
        axios
            .get(Routing.generate('OnlinetennisBundle_credits', { _locale: locale }))
            .then((response) => {
                this.data = response.data;
                this.loading = false;
            });
    },
};
</script>

<template>
    <ot-loader :loading="loading">
        <ot-card legacy>
            <table>
                <tbody>
                    <tr v-for="(admin, index) in data.admins" :key="index">
                        <td><img :src="admin.avatar_image" :alt="admin.user.player.name"></td>
                        <td>
                            <div>{{ admin.user.player.name }}</div>
                        </td>
                        <td>{{ admin.language }}</td>
                    </tr>
                    <tr>
                        <td colspan="3">&nbsp;</td>
                    </tr>
                    <tr>
                        <td colspan="3">{{ $t('impressum.impressum_weiterer_dank') }}</td>
                    </tr>
                    <tr>
                        <td colspan="3">Christoph Hartmann, Stefan Lippert, Sebastian Lagrell, Philipp Müller, Sven Buchholz, Ced Solansko, Matia Lagos, Sergio Paiva, Lalos Biondi, Andre Siqueira, Emmanuel Santos, Michal Muchajer, Marco Panato, Orian Gissler, Konstantin Michenko, Sipko Riemersma, Joacim Svensson, Maurice Lingenfelder, Karsten Nies, Philipp Jonuscheit, Andreas Voß, Kalle Trossvik, Juan Ignacio Herrero, Oskar Azemon, Antonio Derriaste, Benjamin Lichtenberger, Federico Rojas, Sébastien Tremblay, Luiz Fellipe Tezza Bastos Cruz, Ivo Forster, Daniel Scarcella, Tobias Schreiber, Diego Frutos, Francisco Salau, Werner-Markus Frey, Sven Kammann, Tino Lomboy, Mariano Merlo, Adrian Kowalski, Oliver Schmidt, Pierre Krämer, Uros Rogic, Bastien Lobbrecht, Slawa Strauch, Slawek Marel, Diego Rencelj, Andrew Hill, Marjan Djordjevic, Filipe Lessa, Michel Biagi, Jonatas Cesario, Lukasz Antolik, Jacek Sikorski, Luca Dalamar, Arkadiusz Szymczak, Mateusz Swift, Walter Diharce, Maximo Sabbatini, Marc Bräunlein, Hartmut Pfeiffer, Marc Garza</td>
                    </tr>
                </tbody>
            </table>
        </ot-card>
    </ot-loader>
</template>
