<script>
import axios from 'axios';
import Flag from './Flag.vue';

export default {
    components: {
        OtFlag: Flag,
    },
    props: {
        channel: {
            type: Number,
        },
    },
    data() {
        return {
            players: [],
        };
    },
    mounted() {
        this.update();
    },
    methods: {
        async update() {
            const response = await axios.get(Routing.generate('channel_visitors_list', { id: this.channel }));
            this.players = response.data.players;
            setTimeout(this.update, 5000);
        },
    },
};
</script>

<template>
    <div>
        <div>{{ $t('internalLivematch.usersCurrentlyInChat', { param1: players.length }) }}</div>
        <ul>
            <li v-for="player in players" :key="player.id">
                <ot-flag :id="player.country.id" />
                <a :href="`#/player/${player.id}`">{{ player.name }}</a>
            </li>
        </ul>
    </div>
</template>
