<script>
import axios from 'axios';
import { OtAccordion, OtAccordionContent, OtAccordionItem, OtAccordionTrigger } from '../components/accordion';
import Card from '../components/Card.vue';
import Loader from '../components/Loader.vue';

export default {
    components: {
        OtAccordionTrigger,
        OtAccordion,
        OtAccordionItem,
        OtAccordionContent,
        OtCard: Card,
        OtLoader: Loader,
    },
    data() {
        return {
            loading: true,
            categories: [],
            active: [],
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            this.loading = true;
            axios
                .get(Routing.generate('api_faq'))
                .then((response) => {
                    this.categories = response.data.data;
                    this.loading = false;
                });
        },
        answer(faq) {
            return faq.answer.replace(/\n/g, '<br />');
        },
    },
};
</script>

<template>
    <ot-loader :loading="loading">
        <ot-card legacy>
            <ot-accordion v-model="active" collapsible class="mx-2">
                <ot-accordion-item
                    v-for="category in categories"
                    :key="category.name"
                    :value="category.name"
                >
                    <ot-accordion-trigger>{{ category.name }}</ot-accordion-trigger>
                    <ot-accordion-content>
                        <div
                            v-for="(faq, index) in category.faq"
                            :key="index"
                            class="mb-4"
                        >
                            <h4 class="font-medium">{{ faq.question }}</h4>
                            <div v-html="answer(faq)" />
                        </div>
                    </ot-accordion-content>
                </ot-accordion-item>
            </ot-accordion>
        </ot-card>
    </ot-loader>
</template>
