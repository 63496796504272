import axios from 'axios';
import { createI18n } from 'vue-i18n';

let language = 'en_US';
switch (locale) {
    case 'bs':
        language = 'bs_BA';
        break;

    case 'cs':
        language = 'cs_CZ';
        break;

    case 'de':
        language = 'de_DE';
        break;

    case 'en':
        language = 'en_US';
        break;

    case 'es':
        language = 'es_ES';
        break;

    case 'fr':
        language = 'fr_FR';
        break;

    case 'hr':
        language = 'hr_HR';
        break;

    case 'hu':
        language = 'hu_HU';
        break;

    case 'it':
        language = 'it_IT';
        break;

    case 'lt':
        language = 'lt_LT';
        break;

    case 'nl':
        language = 'nl_NL';
        break;

    case 'pl':
        language = 'pl_PL';
        break;

    case 'pt':
        language = 'pt_BR'; // pt_PT cannot be chosen this way
        break;

    case 'ro':
        language = 'ro_RO';
        break;

    case 'ru':
        language = 'ru_RU';
        break;

    case 'sk':
        language = 'sk_SK';
        break;

    case 'sr':
        language = 'sr_CS';
        break;

    case 'sv':
        language = 'sv_SE';
        break;

    case 'tr':
        language = 'tr_TR';
        break;
}

const i18n = createI18n({
    locale: language,
    fallbackLocale: 'en_US',
    legacy: false,
});

export default i18n;

const loadedLanguages = [];

function loadLanguageAsync(lang, isFallback) {
    if (loadedLanguages.includes(lang)) {
        if (!isFallback) {
            i18n.locale = lang;
        }

        return;
    }

    axios
        .get(`/api/i18n/${lang}?${__BUILD_HASH__}`)
        .then((response) => {
            i18n.global.setLocaleMessage(lang, response.data);
            window.emitter.emit('translations_loaded');
            loadedLanguages.push(lang);

            if (!isFallback) {
                i18n.locale = lang;
            }
        });
}

loadLanguageAsync('en_US', true);
loadLanguageAsync(language, false);
