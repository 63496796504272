<script>
import Button from './Button.vue';
import { OtDialog, OtDialogBody, OtDialogContent, OtDialogFooter, OtDialogHeader } from './dialog';

export default {
    components: {
        OtButton: Button,
        OtDialog,
        OtDialogBody,
        OtDialogContent,
        OtDialogFooter,
        OtDialogHeader,
    },
    props: {
        modelValue: {
            type: Boolean,
            required: true,
        },
        day: {
            type: Number,
        },
    },
    data() {
        return {
            visible: false,
        };
    },
    watch: {
        modelValue(newValue) {
            this.visible = newValue;
        },
    },
    methods: {
        hide() {
            this.visible = false;
            this.$emit('input', false);
        },
    },
};
</script>

<template>
    <ot-dialog :open="visible" @update:open="$emit('input', $event)">
        <ot-dialog-content>
            <ot-dialog-header>{{ $t('general.daily_login_bonus_title') }}</ot-dialog-header>
            <ot-dialog-body>
                <div class="p-0 h-[447px] overflow-hidden">
                    <div style="width: 900px; height: 430px;" :style="`background: no-repeat url('/bundles/main/images/dailyLoginBonus/day_${day}.jpg')`">
                        <div class="day day1">{{ $t('general.daily_login_bonus_day', { param1: '1' }) }}</div>
                        <div class="day day2">{{ $t('general.daily_login_bonus_day', { param1: '2' }) }}</div>
                        <div class="day day3">{{ $t('general.daily_login_bonus_day', { param1: '3' }) }}</div>
                        <div class="day day4">{{ $t('general.daily_login_bonus_day', { param1: '4' }) }}</div>
                        <div class="day day5">{{ $t('general.daily_login_bonus_day', { param1: '5' }) }}</div>
                        <div class="absolute left-[80px] top-[356px] w-[740px] text-center" v-text="$t('general.daily_login_bonus_description')" />
                    </div>
                </div>
            </ot-dialog-body>
            <ot-dialog-footer>
                <ot-button @click="hide()">{{ $t('general.close') }}</ot-button>
            </ot-dialog-footer>
        </ot-dialog-content>
    </ot-dialog>
</template>

<style scoped>
.day {
    position: absolute;
    top: 320px;
    font-size: 24px;
    width: 150px;
    text-align: center;
}
.day1 {
    left: 60px;
}
.day2 {
    left: 218px;
}
.day3 {
    left: 376px;
}
.day4 {
    left: 534px;
}
.day5 {
    left: 692px;
}
</style>
