<script>
import axios from 'axios';
import Button from '../components/Button.vue';
import Card from '../components/Card.vue';
import Loader from '../components/Loader.vue';

export default {
    components: {
        OtButton: Button,
        OtCard: Card,
        OtLoader: Loader,
    },
    data() {
        return {
            loading: true,
            hasDoublesPartner: null,
            hasActiveTournaments: null,
            doublesPartnerName: null,
            doublesPartnerLink: null,
            doublesRequests: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            this.loading = true;
            axios
                .get(Routing.generate('doubles', { _locale: locale }))
                .then((response) => {
                    this.hasDoublesPartner = response.data.has_doubles_partner;
                    this.hasActiveTournaments = response.data.has_active_tournaments;
                    this.doublesPartnerName = response.data.doubles_partner_name;
                    this.doublesPartnerLink = response.data.doubles_partner_link;
                    this.doublesRequests = response.data.doubles_requests;
                    this.loading = false;
                });
        },
        cancel() {
            this.loading = true;
            axios
                .delete(Routing.generate('api_players_doublespartnerships'))
                .then(() => this.load())
                .catch(() => this.load());
        },
        accept(playerId) {
            this.loading = true;
            axios
                .post(Routing.generate('doubles_accept', { id: playerId }))
                .then(() => this.load())
                .catch(() => this.load());
        },
        deny(playerId) {
            this.loading = true;
            axios
                .post(Routing.generate('doubles_deny', { id: playerId }))
                .then(() => this.load())
                .catch(() => this.load());
        },
    },
};
</script>

<template>
    <ot-loader :loading="loading">
        <ot-card legacy :title="$t('subheader.subheader_doubles')">
            <template v-if="hasDoublesPartner">
                <div>{{ $t('taktik.taktik_current_partner') }}: <a :href="doublesPartnerLink">{{ doublesPartnerName }}</a></div>
                <ot-button variant="danger" :disabled="hasActiveTournaments" @click="cancel()">{{ $t('taktik.taktik_cancel_partnership') }}</ot-button>
            </template>
            <div v-else>
                <table class="striped">
                    <tr v-for="request in doublesRequests" :key="request.id">
                        <td>
                            <a :to="`/player-profile/${request.id}`">{{ request.name }}</a>
                        </td>
                        <td>
                            <ot-button @click="deny(request.id)">{{ $t('taktik.taktik_deny_partnership') }}</ot-button>
                        </td>
                        <td>
                            <ot-button @click="accept(request.id)">{{ $t('taktik.taktik_start_partnership') }}</ot-button>
                        </td>
                    </tr>
                </table>
            </div>
        </ot-card>
    </ot-loader>
</template>
