<script>
import axios from 'axios';
import Avatar from '../../components/Avatar.vue';
import Button from '../../components/Button.vue';
import Loader from '../../components/Loader.vue';
import Player from '../../components/Player.vue';

export default {
    components: {
        OtButton: Button,
        OtLoader: Loader,
        OtPlayer: Player,
        OtAvatar: Avatar,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            data: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            axios
                .get(`/api/player-profiles/${this.id}/overview`)
                .then((response) => {
                    this.data = response.data.data;
                    this.loading = false;
                });
        },
        getLink(link) {
            switch (link) {
                case 'apply_for_team':
                    return Routing.generate('OnlinetennisBundle_teamApply', { id: this.data.player.team.id, _locale: locale });

                case 'team_profile':
                    return Routing.generate('OnlinetennisBundle_profileTeam', { id: this.data.player.team.id, _locale: locale });

                case 'send_team_invitation':
                    return Routing.generate('OnlinetennisBundle_teamSendInvitation', { userId: this.data.player.id, _locale: locale });

                case 'change_avatar':
                    return Routing.generate('avatar_change', { _locale: locale });
            }
        },
        requestDoublesPartnership(id) {
            axios
                .post(Routing.generate('doubles_request', { _locale: locale, id }))
                .then(() => this.load());
        },
    },
};
</script>

<template>
    <ot-loader :loading="loading" standalone>
        <template v-if="!loading">
            <div class="flex gap-2">
                <div class="grow">
                    <div class="mb-2">
                        <h4>{{ $t('profil.profil_aktuellerstatus') }}:</h4>
                        <dl>
                            <dt>{{ $t('general.singles') }}:</dt>
                            <dd v-html="data.singles_status" />

                            <dt>{{ $t('general.doubles') }}:</dt>
                            <dd v-html="data.doubles_status" />

                            <dt>{{ $t('general.userTournament') }}:</dt>
                            <dd v-html="data.user_tournament_status" />
                        </dl>
                    </div>
                    <div class="mb-2">
                        <h4>{{ $t('general.profil_aktuellesteam') }}:</h4>
                        <template v-if="data.is_granted_user">
                            <template v-if="data.player.team">
                                <a
                                    style="float:left;"
                                    :href="getLink('team_profile')"
                                >{{ data.player.team.name }}</a>
                                <ot-button
                                    v-if="!data.logged_in_player.has_team"
                                    :href="getLink('apply_for_team')"
                                >{{ $t('internalTeam.button_apply') }}</ot-button>
                            </template>
                            <template v-else>
                                <div style="float:left">{{ $t('general.profil_none') }}</div>
                                <ot-button
                                    v-if="data.logged_in_player.has_team && data.logged_in_player.is_team_captain && !data.is_own_profile"
                                    :href="getLink('send_team_invitation')"
                                >{{ $t('internalTeam.button_sendInvitation') }}</ot-button>
                            </template>
                        </template>
                    </div>
                    <div class="mb-2" style="clear: both">
                        <h4>{{ $t('profile_user.currentStaff') }}</h4>
                        <ul>
                            <li v-for="(s, index) in data.staff" :key="index" style="float: left; margin-right: 8px">
                                <div
                                    :style="`width: 60px; height: 60px; background-image: url(/images/staff/${s.type}${s.skill}.png); background-size: contain; color: #ffffff; position: relative`"
                                    :title="$t('general', s.description_key)"
                                >
                                    <div
                                        style="position: absolute; bottom: 0; width: 100%; font-size: 10px; font-family: Verdana; text-align: center;"
                                    >
                                        {{ $t(`staff.staff_${s.type}`) }}
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="grow">
                    <div class="mb-2">
                        <h4>{{ $t('profil.doublesPartner') }}</h4>
                        <div
                            v-for="doubles in data.all_doubles"
                            :key="doubles.id"
                        >
                            <ot-player
                                :id="doubles.partner.id"
                                :country="doubles.partner.country.id"
                            >
                                {{ doubles.partner.name }}
                            </ot-player>
                            <router-link :to="`/doubles/${doubles.id}`">{{ $t('general.doublesProfile') }}</router-link>
                            <template v-if="data.player.has_doubles && doubles.id === data.player.doubles.id">
                                ({{ $t('general.current') }})
                            </template>
                        </div>
                        <div v-if="data.all_doubles.length === 0">{{ $t('general.profil_noone') }}</div>
                        <ot-button
                            v-if="data.is_granted_user && !data.logged_in_player.has_doubles && !data.player.has_doubles && !data.is_own_profile && !data.has_requested_doubles_partnership"
                            @click="requestDoublesPartnership(data.player.id)"
                            primary
                        >
                            {{ $t('profil.profil_ask_for_partnership') }}
                        </ot-button>
                    </div>
                    <ot-avatar body :value="data.player.avatar" class="rounded-xl border border-gray-500" />
                    <div v-if="data.is_own_profile" style="text-align: center; width: 400px" class="mt-2">
                        <ot-button :href="getLink('change_avatar')">{{ $t('profile_user.change_avatar') }}</ot-button>
                    </div>
                </div>
            </div>
            <div class="mb-2">
                <h4>{{ $t('profile_user.latestVisitors') }}</h4>
                <ot-player
                    v-for="visitor in data.latest_visitors"
                    :id="visitor.id"
                    :key="visitor.id"
                    :country="visitor.country.id"
                >
                    {{ visitor.name }}
                </ot-player>
                <div>{{ $t('profile_user.visitorsSentence', { param1: data.visitors_overall, param2: data.visitors_overall_position, param3: data.visitors_last_month, param4: data.visitors_last_month_position }) }}</div>
            </div>
        </template>
    </ot-loader>
</template>
