<script setup lang="ts">
import type { ComboboxRootEmits, ComboboxRootProps } from 'radix-vue';
import { ChevronDown } from 'lucide-vue-next';
import { ComboboxAnchor, ComboboxInput, ComboboxPortal, ComboboxRoot, ComboboxTrigger, useForwardPropsEmits } from 'radix-vue';

interface Props extends ComboboxRootProps {
    placeholder?: string;
}
interface Emits extends ComboboxRootEmits {
    submit: void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const forwarded = useForwardPropsEmits(props, emits);
</script>

<template>
    <combobox-root
        v-bind="forwarded"
        class="relative"
    >
        <combobox-anchor class="w-full inline-flex items-center justify-between border border-gray-900 rounded-sm px-[15px] text-[13px] leading-none h-[35px] gap-[5px] text-green-700 data-placeholder:text-green-600 outline-hidden">
            <combobox-input
                class="bg-transparent! outline-hidden w-full text-gray-900 h-full selection:bg-green-700 placeholder-gray-700"
                :placeholder="placeholder"
                @keyup.enter="emits('submit')"
            />
            <combobox-trigger>
                <chevron-down class="h-4 w-4 text-gray-900" />
            </combobox-trigger>
        </combobox-anchor>
        <combobox-portal>
            <slot />
        </combobox-portal>
    </combobox-root>
</template>
