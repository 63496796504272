import type FeedbackUpdateDto from '../../dto/request/FeedbackUpdateDto';
import axios from 'axios';
import Feedback from './Feedback';

export default class FeedbackRepository {
    public find(): Promise<Feedback> {
        return new Promise((resolve, reject) => {
            axios
                .get('/api/users/me/feedback')
                .then(response => resolve(Feedback.fromData(response.data.data)))
                .catch(error => reject(error));
        });
    }

    public update(feedback: FeedbackUpdateDto): Promise<void> {
        return new Promise((resolve, reject) => {
            axios
                .put('/api/users/me/feedback', {
                    community: feedback.getCommunity(),
                    fun: feedback.getFun(),
                    graphics: feedback.getGraphics(),
                    overall: feedback.getOverall(),
                    support: feedback.getSupport(),
                    usability: feedback.getUsability(),
                })
                .then(() => resolve())
                .catch(error => reject(error));
        });
    }
}
