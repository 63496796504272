<script>
import axios from 'axios';

import Loader from '../../components/Loader.vue';

export default {
    components: {
        OtLoader: Loader,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            data: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            axios
                .get(`/api/player-profiles/${this.id}/description/${locale}`)
                .then((response) => {
                    this.data = response.data.data;
                    this.loading = false;
                });
        },
    },
};
</script>

<template>
    <ot-loader :loading="loading" standalone>
        <template v-if="!loading">
            <p v-html="data.description" />
            <p v-html="data.pressReleaseHtml" />
        </template>
    </ot-loader>
</template>
