import type SuggestionCommentCreateDto from '../../dto/request/SuggestionCommentCreateDto';
import axios from 'axios';

export default class SuggestionCommentRepository {
    public add(dto: SuggestionCommentCreateDto): Promise<void> {
        return new Promise((resolve, reject) => {
            axios
                .post(`/api/suggestions/${dto.getSuggestionId()}/comments`, {
                    content: dto.getContent(),
                })
                .then(() => resolve())
                .catch(error => reject(error));
        });
    }
}
