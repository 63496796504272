import axios from 'axios';
import platform from 'platform';

export class Authentication {
    constructor() {
        this.apiKey = null;
        this.load();
        this.checkForNewApiKey();
    }

    listen() {
        window.emitter.on('logout', () => this.clearApiKey());
        window.emitter.on('api_returned_401', () => this.clearApiKey());
    }

    setApiKey(apiKey) {
        this.apiKey = apiKey;
        window.localStorage.setItem('onlinetennis.api_key', this.apiKey);
        this.updateAxios();
    }

    clearApiKey() {
        this.apiKey = null;
        window.localStorage.removeItem('onlinetennis.api_key');
    }

    getApiKey() {
        return this.apiKey;
    }

    updateAxios() {
        if (this.isAuthenticated()) {
            axios.defaults.headers.common['X-Auth-Token'] = `Bearer ${this.apiKey}`;
            axios.defaults.headers.common['X-Platform'] = platform.description;
        }
    }

    isAuthenticated() {
        this.load();
        return this.apiKey !== null;
    }

    load() {
        const apiKey = window.localStorage.getItem('onlinetennis.api_key');
        if (apiKey !== null) {
            this.apiKey = apiKey;
        }
    }

    checkForNewApiKey() {
        if (typeof generatedApiKey === 'undefined') {
            return;
        }

        this.setApiKey(generatedApiKey);
    }
}

const authentication = new Authentication();

export default authentication;
