<script>
import axios from 'axios';
import { Medal } from 'lucide-vue-next';
import Loader from '../../components/Loader.vue';

export default {
    components: {
        OtLoader: Loader,
        Medal,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            data: null,
        };
    },
    computed: {
        grandSlamSeasons() {
            return [...this.data.grandSlamSeasons].sort((a, b) => b.season - a.season);
        },
        mastersSeasons() {
            return [...this.data.mastersSeasons].sort((a, b) => b.season - a.season);
        },
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            axios
                .get(`/api/player-profiles/${this.id}/tournaments`)
                .then((response) => {
                    this.data = response.data.data;
                    this.loading = false;
                });
        },
        getTournamentUrl(id) {
            return Routing.generate('OnlinetennisBundle_profileTournament', { _locale: locale, id });
        },
        getRoundName(tournament) {
            return tournament.is_winner ? this.$t('tournament.round_roundsLeft_0') : this.$t(`tournament.${tournament.name_key}`);
        },
    },
};
</script>

<template>
    <ot-loader :loading="loading" standalone>
        <template v-if="!loading">
            <table class="striped">
                <thead>
                    <tr>
                        <th />
                        <th />
                        <th><img src="../../images/tournaments/3032.png" alt="Australian Open" class="grandslam polaroid"></th>
                        <th><img src="../../images/tournaments/3057.png" alt="French Open" class="grandslam polaroid"></th>
                        <th><img src="../../images/tournaments/3062.png" alt="Wimbledon" class="grandslam polaroid"></th>
                        <th><img src="../../images/tournaments/3075.png" alt="US Open" class="grandslam polaroid"></th>
                        <th />
                    </tr>
                    <tr>
                        <th>Season</th>
                        <th>Olympic Tournament</th>
                        <th>Australian Open</th>
                        <th>French Open</th>
                        <th>Wimbledon</th>
                        <th>US Open</th>
                        <th>Win-Loss</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(tournament, index) in grandSlamSeasons" :key="index">
                        <td>'{{ tournament.season }}</td>
                        <td v-if="tournament.olympictournament" :class="`round_${tournament.olympictournament.round}`">
                            <a :href="getTournamentUrl(tournament.olympictournament.tournamentId)">
                                <medal v-if="tournament.olympictournament.medal" :style="`color: ${tournament.olympictournament.medal_color}`" />
                                <template v-else>{{ getRoundName(tournament.olympictournament) }}</template>
                            </a>
                        </td>
                        <td v-else>-</td>
                        <td v-if="tournament.australianopen" :class="`round_${tournament.australianopen.round}`">
                            <a :href="getTournamentUrl(tournament.australianopen.tournamentId)">{{ getRoundName(tournament.australianopen) }}</a>
                        </td>
                        <td v-else>-</td>
                        <td v-if="tournament.frenchopen" :class="`round_${tournament.frenchopen.round}`">
                            <a :href="getTournamentUrl(tournament.frenchopen.tournamentId)">{{ getRoundName(tournament.frenchopen) }}</a>
                        </td>
                        <td v-else>-</td>
                        <td v-if="tournament.wimbledon" :class="`round_${tournament.wimbledon.round}`">
                            <a :href="getTournamentUrl(tournament.wimbledon.tournamentId)">{{ getRoundName(tournament.wimbledon) }}</a>
                        </td>
                        <td v-else>-</td>
                        <td v-if="tournament.usopen" :class="`round_${tournament.usopen.round}`">
                            <a :href="getTournamentUrl(tournament.usopen.tournamentId)">{{ getRoundName(tournament.usopen) }}</a>
                        </td>
                        <td v-else>-</td>
                        <td>
                            <template v-if="tournament.win">{{ tournament.win }}</template>
                            <template v-else>0</template>
                            -
                            <template v-if="tournament.loss">{{ tournament.loss }}</template>
                            <template v-else>0</template>
                        </td>
                    </tr>
                    <tr>
                        <td>Win-Loss</td>
                        <td>{{ data.grandSlamSeasonsWinLoss.olympictournament.win }} - {{ data.grandSlamSeasonsWinLoss.olympictournament.loss }}</td>
                        <td>{{ data.grandSlamSeasonsWinLoss.australianopen.win }} - {{ data.grandSlamSeasonsWinLoss.australianopen.loss }}</td>
                        <td>{{ data.grandSlamSeasonsWinLoss.frenchopen.win }} - {{ data.grandSlamSeasonsWinLoss.frenchopen.loss }}</td>
                        <td>{{ data.grandSlamSeasonsWinLoss.wimbledon.win }} - {{ data.grandSlamSeasonsWinLoss.wimbledon.loss }}</td>
                        <td>{{ data.grandSlamSeasonsWinLoss.usopen.win }} - {{ data.grandSlamSeasonsWinLoss.usopen.loss }}</td>
                        <td />
                    </tr>
                </tbody>
            </table>
            <table class="striped masters">
                <thead>
                    <tr>
                        <th />
                        <th><img src="../../images/tournaments/3045.png" alt="BNP Paribas Open" class="masters polaroid"></th>
                        <th><img src="../../images/tournaments/3046.png" alt="Sony Open Tennis" class="masters polaroid"></th>
                        <th><img src="../../images/tournaments/3049.png" alt="Monte-Carlo Rolex Masters" class="masters polaroid"></th>
                        <th><img src="../../images/tournaments/3051.png" alt="Internazionali BNL d'Italia" class="masters polaroid"></th>
                        <th><img src="../../images/tournaments/3072.png" alt="Coupe Rogers" class="masters polaroid"></th>
                        <th><img src="../../images/tournaments/3082.png" alt="Shanghai Rolex Masters" class="masters polaroid"></th>
                        <th><img src="../../images/tournaments/3090.png" alt="BNP Paribas Masters" class="masters polaroid"></th>
                        <th><img src="../../images/tournaments/6610.png" alt="Western & Southern Open" class="masters polaroid"></th>
                        <th><img src="../../images/tournaments/6617.png" alt="Mutua Madrid Open" class="masters polaroid"></th>
                        <th />
                    </tr>
                    <tr>
                        <th>Season</th>
                        <th>BNP Paribas Open</th>
                        <th>Sony Open Tennis</th>
                        <th>Monte-Carlo Rolex Masters</th>
                        <th>Internazionali BNL d'Italia</th>
                        <th>Mutua Madrid Open</th>
                        <th>Coupe Rogers</th>
                        <th>Western & Southern Open</th>
                        <th>Shanghai Rolex Masters</th>
                        <th>BNP Paribas Masters</th>
                        <th>Win-Loss</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(tournament, index) in mastersSeasons" :key="index">
                        <td>'{{ tournament.season }}</td>
                        <td v-if="tournament.indianwells" :class="`round_${tournament.indianwells.round}`">
                            <a :href="getTournamentUrl(tournament.indianwells.tournamentId)">{{ getRoundName(tournament.indianwells) }}</a>
                        </td>
                        <td v-else>-</td>
                        <td v-if="tournament.miami" :class="`round_${tournament.miami.round}`">
                            <a :href="getTournamentUrl(tournament.miami.tournamentId)">{{ getRoundName(tournament.miami) }}</a>
                        </td>
                        <td v-else>-</td>
                        <td v-if="tournament.montecarlo" :class="`round_${tournament.montecarlo.round}`">
                            <a :href="getTournamentUrl(tournament.montecarlo.tournamentId)">{{ getRoundName(tournament.montecarlo) }}</a>
                        </td>
                        <td v-else>-</td>
                        <td v-if="tournament.rome" :class="`round_${tournament.rome.round}`">
                            <a :href="getTournamentUrl(tournament.rome.tournamentId)">{{ getRoundName(tournament.rome) }}</a>
                        </td>
                        <td v-else>-</td>
                        <td v-if="tournament.madrid" :class="`round_${tournament.madrid.round}`">
                            <a :href="getTournamentUrl(tournament.madrid.tournamentId)">{{ getRoundName(tournament.madrid) }}</a>
                        </td>
                        <td v-else>-</td>
                        <td v-if="tournament.toronto" :class="`round_${tournament.toronto.round}`">
                            <a :href="getTournamentUrl(tournament.toronto.tournamentId)">{{ getRoundName(tournament.toronto) }}</a>
                        </td>
                        <td v-else>-</td>
                        <td v-if="tournament.cincinnati" :class="`round_${tournament.cincinnati.round}`">
                            <a :href="getTournamentUrl(tournament.cincinnati.tournamentId)">{{ getRoundName(tournament.cincinnati) }}</a>
                        </td>
                        <td v-else>-</td>
                        <td v-if="tournament.shanghai" :class="`round_${tournament.shanghai.round}`">
                            <a :href="getTournamentUrl(tournament.shanghai.tournamentId)">{{ getRoundName(tournament.shanghai) }}</a>
                        </td>
                        <td v-else>-</td>
                        <td v-if="tournament.paris" :class="`round_${tournament.paris.round}`">
                            <a :href="getTournamentUrl(tournament.paris.tournamentId)">{{ getRoundName(tournament.paris) }}</a>
                        </td>
                        <td v-else>-</td>
                        <td>
                            <template v-if="tournament.win">{{ tournament.win }}</template>
                            <template v-else>0</template>
                            -
                            <template v-if="tournament.loss">{{ tournament.loss }}</template>
                            <template v-else>0</template>
                        </td>
                    </tr>
                    <tr>
                        <td>Win-Loss</td>
                        <td>{{ data.mastersSeasonsWinLoss.indianwells.win }} - {{ data.mastersSeasonsWinLoss.indianwells.loss }}</td>
                        <td>{{ data.mastersSeasonsWinLoss.miami.win }} - {{ data.mastersSeasonsWinLoss.miami.loss }}</td>
                        <td>{{ data.mastersSeasonsWinLoss.montecarlo.win }} - {{ data.mastersSeasonsWinLoss.montecarlo.loss }}</td>
                        <td>{{ data.mastersSeasonsWinLoss.rome.win }} - {{ data.mastersSeasonsWinLoss.rome.loss }}</td>
                        <td>{{ data.mastersSeasonsWinLoss.madrid.win }} - {{ data.mastersSeasonsWinLoss.madrid.loss }}</td>
                        <td>{{ data.mastersSeasonsWinLoss.toronto.win }} - {{ data.mastersSeasonsWinLoss.toronto.loss }}</td>
                        <td>{{ data.mastersSeasonsWinLoss.cincinnati.win }} - {{ data.mastersSeasonsWinLoss.cincinnati.loss }}</td>
                        <td>{{ data.mastersSeasonsWinLoss.shanghai.win }} - {{ data.mastersSeasonsWinLoss.shanghai.loss }}</td>
                        <td>{{ data.mastersSeasonsWinLoss.paris.win }} - {{ data.mastersSeasonsWinLoss.paris.loss }}</td>
                        <td />
                    </tr>
                </tbody>
            </table>
        </template>
    </ot-loader>
</template>

<style scoped>
tr:nth-of-type(odd) {
    background: none;
    td {
        background-color: rgba(200, 200, 200, 0.5);
    }
}
tr:nth-of-type(even) {
    background: none;
    td {
        background-color: rgba(230, 230, 230, 0.5);
    }
}
tr {
    td {
        text-align: center;
    }
    .round_1 {
        background-color: rgba(0, 0, 255, 0.1) !important;
    }
    .round_2 {
        background-color: rgba(0, 0, 255, 0.2) !important;
    }
    .round_3 {
        background-color: rgba(0, 0, 255, 0.3) !important;
    }
    .round_4 {
        background-color: rgba(0, 0, 255, 0.4) !important;
    }
    .round_5 {
        background-color: rgba(0, 0, 255, 0.6) !important;
        a{
            color: #eeeeee;
        }
    }
    .round_6 {
        background-color: rgba(0, 0, 255, 0.7) !important;
        a{
            color: #eeeeee;
        }
    }
    .round_7 {
        background-color: rgba(0, 0, 255, 0.8) !important;
        a{
            color: #eeeeee;
        }
    }
    .round_8 {
        background-color: rgba(0, 0, 255, 0.9) !important;
        a{
            color: #eeeeee;
        }
    }
}
.grandslam.polaroid {
    width: 150px;
    height: 150px;
}
.masters.polaroid {
    width: 75px;
    height: 75px;
}
</style>
