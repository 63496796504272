<script>
const mapping = {
    1: 'eg',
    2: 'gq',
    3: 'et',
    4: 'af',
    5: 'al',
    6: 'dz',
    7: 'ad',
    8: 'ao',
    9: 'ag',
    10: 'ar',
    11: 'am',
    12: 'az',
    13: 'au',
    14: 'bs',
    15: 'bh',
    16: 'bd',
    17: 'bb',
    18: 'be',
    19: 'bz',
    20: 'bj',
    21: 'bt',
    22: 'bo',
    23: 'ba',
    24: 'bw',
    25: 'br',
    26: 'bn',
    27: 'bg',
    28: 'bf',
    29: 'bi',
    30: 'cl',
    31: 'cn',
    32: 'cr',
    33: 'dk',
    34: 'cd',
    35: 'de',
    36: 'dj',
    37: 'dm',
    38: 'do',
    39: 'ec',
    40: 'sv',
    41: 'ci',
    42: 'er',
    43: 'ee',
    44: 'fj',
    45: 'fi',
    46: 'fr',
    47: 'ga',
    48: 'gm',
    49: 'ge',
    50: 'gh',
    51: 'gr',
    52: 'gd',
    53: 'gb',
    54: 'gt',
    55: 'gy',
    56: 'gn',
    57: 'gw',
    58: 'ht',
    59: 'hn',
    60: 'in',
    61: 'id',
    62: 'ir',
    63: 'iq',
    64: 'is',
    65: 'il',
    66: 'it',
    67: 'jm',
    68: 'jp',
    69: 'ye',
    70: 'jo',
    71: 'qa',
    72: 'kh',
    73: 'cm',
    74: 'ca',
    75: 'cv',
    76: 'kz',
    77: 'ke',
    78: 'kg',
    79: 'ki',
    80: 'co',
    81: 'km',
    82: 'hr',
    83: 'cu',
    84: 'kw',
    85: 'la',
    86: 'lv',
    87: 'lb',
    88: 'ls',
    89: 'lr',
    90: 'ly',
    91: 'li',
    92: 'lt',
    93: 'lu',
    94: 'mg',
    95: 'mw',
    96: 'my',
    97: 'mv',
    98: 'ml',
    99: 'mt',
    100: 'ma',
    101: 'mh',
    102: 'mr',
    103: 'mu',
    104: 'mk',
    105: 'mx',
    106: 'fm',
    107: 'md',
    108: 'mc',
    109: 'mn',
    110: 'mz',
    111: 'mm',
    112: 'na',
    113: 'nr',
    114: 'np',
    115: 'nl',
    116: 'nz',
    117: 'ni',
    118: 'ne',
    119: 'ng',
    120: 'kr',
    121: 'no',
    122: 'om',
    123: 'at',
    124: 'tp',
    125: 'pk',
    126: 'pw',
    127: 'pa',
    128: 'pg',
    129: 'py',
    130: 'pe',
    131: 'ph',
    132: 'pl',
    133: 'pt',
    134: 'ie',
    135: 'cg',
    136: 'ro',
    137: 'ru',
    138: 'rw',
    139: 'kn',
    140: 'lc',
    141: 'vc',
    142: 'sb',
    143: 'zm',
    144: 'ws',
    145: 'sm',
    146: 'st',
    147: 'sa',
    148: 'sh',
    149: 'se',
    150: 'ch',
    151: 'sn',
    152: 'rs',
    153: 'sc',
    154: 'sl',
    155: 'sg',
    156: 'zw',
    157: 'sk',
    158: 'si',
    159: 'so',
    160: 'za',
    161: 'kp',
    162: 'es',
    163: 'lk',
    164: 'sd',
    165: 'sr',
    166: 'sz',
    167: 'sy',
    168: 'tw',
    169: 'tj',
    170: 'tz',
    171: 'th',
    172: 'tg',
    173: 'to',
    174: 'tt',
    175: 'cz',
    176: 'td',
    177: 'tn',
    178: 'tr',
    179: 'tm',
    180: 'tv',
    181: 'ug',
    182: 'ua',
    183: 'hu',
    184: 'us',
    185: 'uy',
    186: 'uz',
    187: 'vu',
    188: 'va',
    189: 've',
    190: 'ae',
    191: 'vn',
    192: 'by',
    193: 'cf',
    194: 'cy',
    195: 'me',
    196: 'ck',
};

export default {
    props: {
        id: {
            required: true,
            type: Number,
        },
        title: {
            required: false,
            type: String,
        },
        link: {
            required: false,
            type: String,
        },
    },
    computed: {
        generatedLink() {
            if (this.link) {
                return this.link;
            }

            return Routing.generate('country', { _locale: locale, id: this.id });
        },
        generatedTitle() {
            if (this.id === 0) {
                return '';
            }

            return this.$t(`country.country_${this.id}`);
        },
        className() {
            return `flag21x14_${mapping[this.id]}`;
        },
    },
};
</script>

<template>
    <span class="relative top-0.5">
        <a v-if="id !== 0" :href="generatedLink" class="flag21x14" :class="className" :title="generatedTitle" />
    </span>
</template>

<style>
.flag {
    width: 30px;
    height: 20px;
    background-image: url(./../images/flags.png);
    display: inline-block;
}
.flag_ad { background-position: 0 0; }
.flag_ae { background-position: 0 -20px; }
.flag_af { background-position: 0 -40px; }
.flag_ag { background-position: 0 -60px; }
.flag_al { background-position: 0 -80px; }
.flag_am { background-position: 0 -100px; }
.flag_ao { background-position: 0 -120px; }
.flag_ar { background-position: 0 -140px; }
.flag_at { background-position: 0 -160px; }
.flag_au { background-position: 0 -180px; }
.flag_az { background-position: 0 -200px; }
.flag_ba { background-position: 0 -220px; }
.flag_bb { background-position: 0 -240px; }
.flag_bd { background-position: 0 -260px; }
.flag_be { background-position: 0 -280px; }
.flag_bf { background-position: 0 -300px; }
.flag_bg { background-position: 0 -320px; }
.flag_bh { background-position: 0 -340px; }
.flag_bi { background-position: 0 -360px; }
.flag_bj { background-position: 0 -380px; }
.flag_bn { background-position: 0 -400px; }
.flag_bo { background-position: 0 -420px; }
.flag_br { background-position: 0 -440px; }
.flag_bs { background-position: 0 -460px; }
.flag_bt { background-position: 0 -480px; }
.flag_bw { background-position: 0 -500px; }
.flag_by { background-position: 0 -520px; }
.flag_bz { background-position: 0 -540px; }
.flag_ca { background-position: 0 -560px; }
.flag_cd { background-position: 0 -580px; }
.flag_cf { background-position: 0 -600px; }
.flag_cg { background-position: 0 -620px; }
.flag_ch { background-position: 0 -640px; }
.flag_ci { background-position: 0 -660px; }
.flag_ck { background-position: 0 -680px; }
.flag_cl { background-position: 0 -700px; }
.flag_cm { background-position: 0 -720px; }
.flag_cn { background-position: 0 -740px; }
.flag_co { background-position: 0 -760px; }
.flag_cr { background-position: 0 -780px; }
.flag_cu { background-position: 0 -800px; }
.flag_cv { background-position: 0 -820px; }
.flag_cy { background-position: 0 -840px; }
.flag_cz { background-position: 0 -860px; }
.flag_de { background-position: 0 -880px; }
.flag_dj { background-position: 0 -900px; }
.flag_dk { background-position: 0 -920px; }
.flag_dm { background-position: 0 -940px; }
.flag_do { background-position: 0 -960px; }
.flag_dz { background-position: 0 -980px; }
.flag_ec { background-position: 0 -1000px; }
.flag_ee { background-position: 0 -1020px; }
.flag_eg { background-position: 0 -1040px; }
.flag_er { background-position: 0 -1060px; }
.flag_es { background-position: 0 -1080px; }
.flag_et { background-position: 0 -1100px; }
.flag_fi { background-position: 0 -1120px; }
.flag_fj { background-position: 0 -1140px; }
.flag_fm { background-position: 0 -1160px; }
.flag_fr { background-position: 0 -1180px; }
.flag_ga { background-position: 0 -1200px; }
.flag_gb { background-position: 0 -1220px; }
.flag_gd { background-position: 0 -1240px; }
.flag_ge { background-position: 0 -1260px; }
.flag_gh { background-position: 0 -1280px; }
.flag_gm { background-position: 0 -1300px; }
.flag_gn { background-position: 0 -1320px; }
.flag_gq { background-position: 0 -1340px; }
.flag_gr { background-position: 0 -1360px; }
.flag_gt { background-position: 0 -1380px; }
.flag_gw { background-position: 0 -1400px; }
.flag_gy { background-position: 0 -1420px; }
.flag_hn { background-position: 0 -1440px; }
.flag_hr { background-position: 0 -1460px; }
.flag_ht { background-position: 0 -1480px; }
.flag_hu { background-position: 0 -1500px; }
.flag_id { background-position: 0 -1520px; }
.flag_ie { background-position: 0 -1540px; }
.flag_il { background-position: 0 -1560px; }
.flag_in { background-position: 0 -1580px; }
.flag_iq { background-position: 0 -1600px; }
.flag_ir { background-position: 0 -1620px; }
.flag_is { background-position: 0 -1640px; }
.flag_it { background-position: 0 -1660px; }
.flag_jm { background-position: 0 -1680px; }
.flag_jo { background-position: 0 -1700px; }
.flag_jp { background-position: 0 -1720px; }
.flag_ke { background-position: 0 -1740px; }
.flag_kg { background-position: 0 -1760px; }
.flag_kh { background-position: 0 -1780px; }
.flag_ki { background-position: 0 -1800px; }
.flag_km { background-position: 0 -1820px; }
.flag_kn { background-position: 0 -1840px; }
.flag_kp { background-position: 0 -1860px; }
.flag_kr { background-position: 0 -1880px; }
.flag_kw { background-position: 0 -1900px; }
.flag_kz { background-position: 0 -1920px; }
.flag_la { background-position: 0 -1940px; }
.flag_lb { background-position: 0 -1960px; }
.flag_lc { background-position: 0 -1980px; }
.flag_li { background-position: 0 -2000px; }
.flag_lk { background-position: 0 -2020px; }
.flag_lr { background-position: 0 -2040px; }
.flag_ls { background-position: 0 -2060px; }
.flag_lt { background-position: 0 -2080px; }
.flag_lu { background-position: 0 -2100px; }
.flag_lv { background-position: 0 -2120px; }
.flag_ly { background-position: 0 -2140px; }
.flag_ma { background-position: 0 -2160px; }
.flag_mc { background-position: 0 -2180px; }
.flag_md { background-position: 0 -2200px; }
.flag_me { background-position: 0 -2220px; }
.flag_mg { background-position: 0 -2240px; }
.flag_mh { background-position: 0 -2260px; }
.flag_mk { background-position: 0 -2280px; }
.flag_ml { background-position: 0 -2300px; }
.flag_mm { background-position: 0 -2320px; }
.flag_mn { background-position: 0 -2340px; }
.flag_mr { background-position: 0 -2360px; }
.flag_mt { background-position: 0 -2380px; }
.flag_mu { background-position: 0 -2400px; }
.flag_mv { background-position: 0 -2420px; }
.flag_mw { background-position: 0 -2440px; }
.flag_mx { background-position: 0 -2460px; }
.flag_my { background-position: 0 -2480px; }
.flag_mz { background-position: 0 -2500px; }
.flag_na { background-position: 0 -2520px; }
.flag_ne { background-position: 0 -2540px; }
.flag_ng { background-position: 0 -2560px; }
.flag_ni { background-position: 0 -2580px; }
.flag_nl { background-position: 0 -2600px; }
.flag_no { background-position: 0 -2620px; }
.flag_np { background-position: 0 -2640px; }
.flag_nr { background-position: 0 -2660px; }
.flag_nz { background-position: 0 -2680px; }
.flag_om { background-position: 0 -2700px; }
.flag_pa { background-position: 0 -2720px; }
.flag_pe { background-position: 0 -2740px; }
.flag_pg { background-position: 0 -2760px; }
.flag_ph { background-position: 0 -2780px; }
.flag_pk { background-position: 0 -2800px; }
.flag_pl { background-position: 0 -2820px; }
.flag_pt { background-position: 0 -2840px; }
.flag_pw { background-position: 0 -2860px; }
.flag_py { background-position: 0 -2880px; }
.flag_qa { background-position: 0 -2900px; }
.flag_ro { background-position: 0 -2920px; }
.flag_rs { background-position: 0 -2940px; }
.flag_ru { background-position: 0 -2960px; }
.flag_rw { background-position: 0 -2980px; }
.flag_sa { background-position: 0 -3000px; }
.flag_sb { background-position: 0 -3020px; }
.flag_sc { background-position: 0 -3040px; }
.flag_sd { background-position: 0 -3060px; }
.flag_se { background-position: 0 -3080px; }
.flag_sg { background-position: 0 -3100px; }
.flag_sh { background-position: 0 -3120px; }
.flag_si { background-position: 0 -3140px; }
.flag_sk { background-position: 0 -3160px; }
.flag_sl { background-position: 0 -3180px; }
.flag_sm { background-position: 0 -3200px; }
.flag_sn { background-position: 0 -3220px; }
.flag_so { background-position: 0 -3240px; }
.flag_sr { background-position: 0 -3260px; }
.flag_st { background-position: 0 -3280px; }
.flag_sv { background-position: 0 -3300px; }
.flag_sy { background-position: 0 -3320px; }
.flag_sz { background-position: 0 -3340px; }
.flag_td { background-position: 0 -3360px; }
.flag_tg { background-position: 0 -3380px; }
.flag_th { background-position: 0 -3400px; }
.flag_tj { background-position: 0 -3420px; }
.flag_tm { background-position: 0 -3440px; }
.flag_tn { background-position: 0 -3460px; }
.flag_to { background-position: 0 -3480px; }
.flag_tp { background-position: 0 -3500px; }
.flag_tr { background-position: 0 -3520px; }
.flag_tt { background-position: 0 -3540px; }
.flag_tv { background-position: 0 -3560px; }
.flag_tw { background-position: 0 -3580px; }
.flag_tz { background-position: 0 -3600px; }
.flag_ua { background-position: 0 -3620px; }
.flag_ug { background-position: 0 -3640px; }
.flag_us { background-position: 0 -3660px; }
.flag_uy { background-position: 0 -3680px; }
.flag_uz { background-position: 0 -3700px; }
.flag_va { background-position: 0 -3720px; }
.flag_vc { background-position: 0 -3740px; }
.flag_ve { background-position: 0 -3760px; }
.flag_vn { background-position: 0 -3780px; }
.flag_vu { background-position: 0 -3800px; }
.flag_ws { background-position: 0 -3820px; }
.flag_ye { background-position: 0 -3840px; }
.flag_za { background-position: 0 -3860px; }
.flag_zm { background-position: 0 -3880px; }
.flag_zw { background-position: 0 -3900px; }

.flag21x14 {
    background: url(./../images/flags21x14.png) no-repeat top left;
    width: 21px;
    height: 14px;
    display: inline-block;
}
.flag21x14_ad { background-position: 0 0; }
.flag21x14_ae { background-position: 0 -15px; }
.flag21x14_af { background-position: 0 -30px; }
.flag21x14_ag { background-position: 0 -45px; }
.flag21x14_al { background-position: 0 -60px; }
.flag21x14_am { background-position: 0 -75px; }
.flag21x14_ao { background-position: 0 -90px; }
.flag21x14_ar { background-position: 0 -105px; }
.flag21x14_at { background-position: 0 -120px; }
.flag21x14_au { background-position: 0 -135px; }
.flag21x14_az { background-position: 0 -150px; }
.flag21x14_ba { background-position: 0 -165px; }
.flag21x14_bb { background-position: 0 -180px; }
.flag21x14_bd { background-position: 0 -195px; }
.flag21x14_be { background-position: 0 -210px; }
.flag21x14_bf { background-position: 0 -225px; }
.flag21x14_bg { background-position: 0 -240px; }
.flag21x14_bh { background-position: 0 -255px; }
.flag21x14_bi { background-position: 0 -270px; }
.flag21x14_bj { background-position: 0 -285px; }
.flag21x14_bn { background-position: 0 -300px; }
.flag21x14_bo { background-position: 0 -315px; }
.flag21x14_br { background-position: 0 -330px; }
.flag21x14_bs { background-position: 0 -345px; }
.flag21x14_bt { background-position: 0 -360px; }
.flag21x14_bw { background-position: 0 -375px; }
.flag21x14_by { background-position: 0 -390px; }
.flag21x14_bz { background-position: 0 -405px; }
.flag21x14_ca { background-position: 0 -420px; }
.flag21x14_cd { background-position: 0 -435px; }
.flag21x14_cf { background-position: 0 -450px; }
.flag21x14_cg { background-position: 0 -465px; }
.flag21x14_ch { background-position: 0 -480px; }
.flag21x14_ci { background-position: 0 -495px; }
.flag21x14_ck { background-position: 0 -510px; }
.flag21x14_cl { background-position: 0 -525px; }
.flag21x14_cm { background-position: 0 -540px; }
.flag21x14_cn { background-position: 0 -555px; }
.flag21x14_co { background-position: 0 -570px; }
.flag21x14_cr { background-position: 0 -585px; }
.flag21x14_cu { background-position: 0 -600px; }
.flag21x14_cv { background-position: 0 -615px; }
.flag21x14_cy { background-position: 0 -630px; }
.flag21x14_cz { background-position: 0 -645px; }
.flag21x14_de { background-position: 0 -660px; }
.flag21x14_dj { background-position: 0 -675px; }
.flag21x14_dk { background-position: 0 -690px; }
.flag21x14_dm { background-position: 0 -705px; }
.flag21x14_do { background-position: 0 -720px; }
.flag21x14_dz { background-position: 0 -735px; }
.flag21x14_ec { background-position: 0 -750px; }
.flag21x14_ee { background-position: 0 -765px; }
.flag21x14_eg { background-position: 0 -780px; }
.flag21x14_er { background-position: 0 -795px; }
.flag21x14_es { background-position: 0 -810px; }
.flag21x14_et { background-position: 0 -825px; }
.flag21x14_fi { background-position: 0 -840px; }
.flag21x14_fj { background-position: 0 -855px; }
.flag21x14_fm { background-position: 0 -870px; }
.flag21x14_fr { background-position: 0 -885px; }
.flag21x14_ga { background-position: 0 -900px; }
.flag21x14_gb { background-position: 0 -915px; }
.flag21x14_gd { background-position: 0 -930px; }
.flag21x14_ge { background-position: 0 -945px; }
.flag21x14_gh { background-position: 0 -960px; }
.flag21x14_gm { background-position: 0 -975px; }
.flag21x14_gn { background-position: 0 -990px; }
.flag21x14_gq { background-position: 0 -1005px; }
.flag21x14_gr { background-position: 0 -1020px; }
.flag21x14_gt { background-position: 0 -1035px; }
.flag21x14_gw { background-position: 0 -1050px; }
.flag21x14_gy { background-position: 0 -1065px; }
.flag21x14_hn { background-position: 0 -1080px; }
.flag21x14_hr { background-position: 0 -1095px; }
.flag21x14_ht { background-position: 0 -1110px; }
.flag21x14_hu { background-position: 0 -1125px; }
.flag21x14_id { background-position: 0 -1140px; }
.flag21x14_ie { background-position: 0 -1155px; }
.flag21x14_il { background-position: 0 -1170px; }
.flag21x14_in { background-position: 0 -1185px; }
.flag21x14_iq { background-position: 0 -1200px; }
.flag21x14_ir { background-position: 0 -1215px; }
.flag21x14_is { background-position: 0 -1230px; }
.flag21x14_it { background-position: 0 -1245px; }
.flag21x14_jm { background-position: 0 -1260px; }
.flag21x14_jo { background-position: 0 -1275px; }
.flag21x14_jp { background-position: 0 -1290px; }
.flag21x14_ke { background-position: 0 -1305px; }
.flag21x14_kg { background-position: 0 -1320px; }
.flag21x14_kh { background-position: 0 -1335px; }
.flag21x14_ki { background-position: 0 -1350px; }
.flag21x14_km { background-position: 0 -1365px; }
.flag21x14_kn { background-position: 0 -1380px; }
.flag21x14_kp { background-position: 0 -1395px; }
.flag21x14_kr { background-position: 0 -1410px; }
.flag21x14_kw { background-position: 0 -1425px; }
.flag21x14_kz { background-position: 0 -1440px; }
.flag21x14_la { background-position: 0 -1455px; }
.flag21x14_lb { background-position: 0 -1470px; }
.flag21x14_lc { background-position: 0 -1485px; }
.flag21x14_li { background-position: 0 -1500px; }
.flag21x14_lk { background-position: 0 -1515px; }
.flag21x14_lr { background-position: 0 -1530px; }
.flag21x14_ls { background-position: 0 -1545px; }
.flag21x14_lt { background-position: 0 -1560px; }
.flag21x14_lu { background-position: 0 -1575px; }
.flag21x14_lv { background-position: 0 -1590px; }
.flag21x14_ly { background-position: 0 -1605px; }
.flag21x14_ma { background-position: 0 -1620px; }
.flag21x14_mc { background-position: 0 -1635px; }
.flag21x14_md { background-position: 0 -1650px; }
.flag21x14_me { background-position: 0 -1665px; }
.flag21x14_mg { background-position: 0 -1680px; }
.flag21x14_mh { background-position: 0 -1695px; }
.flag21x14_mk { background-position: 0 -1710px; }
.flag21x14_ml { background-position: 0 -1725px; }
.flag21x14_mm { background-position: 0 -1740px; }
.flag21x14_mn { background-position: 0 -1755px; }
.flag21x14_mr { background-position: 0 -1770px; }
.flag21x14_mt { background-position: 0 -1785px; }
.flag21x14_mu { background-position: 0 -1800px; }
.flag21x14_mv { background-position: 0 -1815px; }
.flag21x14_mw { background-position: 0 -1830px; }
.flag21x14_mx { background-position: 0 -1845px; }
.flag21x14_my { background-position: 0 -1860px; }
.flag21x14_mz { background-position: 0 -1875px; }
.flag21x14_na { background-position: 0 -1890px; }
.flag21x14_ne { background-position: 0 -1905px; }
.flag21x14_ng { background-position: 0 -1920px; }
.flag21x14_ni { background-position: 0 -1935px; }
.flag21x14_nl { background-position: 0 -1950px; }
.flag21x14_no { background-position: 0 -1965px; }
.flag21x14_np { background-position: 0 -1980px; }
.flag21x14_nr { background-position: 0 -1995px; }
.flag21x14_nz { background-position: -22px 0; }
.flag21x14_om { background-position: -22px -15px; }
.flag21x14_pa { background-position: -22px -30px; }
.flag21x14_pe { background-position: -22px -45px; }
.flag21x14_pg { background-position: -22px -60px; }
.flag21x14_ph { background-position: -22px -75px; }
.flag21x14_pk { background-position: -22px -90px; }
.flag21x14_pl { background-position: -22px -105px; }
.flag21x14_pt { background-position: -22px -120px; }
.flag21x14_pw { background-position: -22px -135px; }
.flag21x14_py { background-position: -22px -150px; }
.flag21x14_qa { background-position: -22px -165px; }
.flag21x14_ro { background-position: -22px -180px; }
.flag21x14_rs { background-position: -22px -195px; }
.flag21x14_ru { background-position: -22px -210px; }
.flag21x14_rw { background-position: -22px -225px; }
.flag21x14_sa { background-position: -22px -240px; }
.flag21x14_sb { background-position: -22px -255px; }
.flag21x14_sc { background-position: -22px -270px; }
.flag21x14_sd { background-position: -22px -285px; }
.flag21x14_se { background-position: -22px -300px; }
.flag21x14_sg { background-position: -22px -315px; }
.flag21x14_sh { background-position: -22px -330px; }
.flag21x14_si { background-position: -22px -345px; }
.flag21x14_sk { background-position: -22px -360px; }
.flag21x14_sl { background-position: -22px -375px; }
.flag21x14_sm { background-position: -22px -390px; }
.flag21x14_sn { background-position: -22px -405px; }
.flag21x14_so { background-position: -22px -420px; }
.flag21x14_sr { background-position: -22px -450px; }
.flag21x14_st { background-position: -22px -465px; }
.flag21x14_sv { background-position: -22px -480px; }
.flag21x14_sy { background-position: -22px -495px; }
.flag21x14_sz { background-position: -22px -510px; }
.flag21x14_td { background-position: -22px -525px; }
.flag21x14_tg { background-position: -22px -540px; }
.flag21x14_th { background-position: -22px -555px; }
.flag21x14_tj { background-position: -22px -570px; }
.flag21x14_tm { background-position: -22px -585px; }
.flag21x14_tn { background-position: -22px -600px; }
.flag21x14_to { background-position: -22px -615px; }
.flag21x14_tp { background-position: -22px -630px; }
.flag21x14_tr { background-position: -22px -645px; }
.flag21x14_tt { background-position: -22px -660px; }
.flag21x14_tv { background-position: -22px -675px; }
.flag21x14_tw { background-position: -22px -690px; }
.flag21x14_tz { background-position: -22px -705px; }
.flag21x14_ua { background-position: -22px -720px; }
.flag21x14_ug { background-position: -22px -735px; }
.flag21x14_us { background-position: -22px -750px; }
.flag21x14_uy { background-position: -22px -765px; }
.flag21x14_uz { background-position: -22px -780px; }
.flag21x14_va { background-position: -22px -795px; }
.flag21x14_vc { background-position: -22px -810px; }
.flag21x14_ve { background-position: -22px -825px; }
.flag21x14_vn { background-position: -22px -840px; }
.flag21x14_vu { background-position: -22px -855px; }
.flag21x14_ws { background-position: -22px -870px; }
.flag21x14_ye { background-position: -22px -885px; }
.flag21x14_za { background-position: -22px -900px; }
.flag21x14_zm { background-position: -22px -915px; }
.flag21x14_zw { background-position: -22px -930px; }
</style>
