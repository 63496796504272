<script>
export default {
    props: {
        comments: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            line: 0,
        };
    },
    watch: {
        comments() {
            this.line = 0;
            const commentDuration = 500;
            const handler = setInterval(() => {
                this.line++;
                if (this.line >= this.comments.length - 1) {
                    clearInterval(handler);
                    this.$emit('done');
                }
            }, commentDuration);
        },
    },
};
</script>

<template>
    <div>
        <div
            v-for="(comment, index) in comments"
            :key="index"
            :class="index > line ? 'hidden' : ''"
            v-html="comment"
        />
    </div>
</template>

<style scoped>

</style>
