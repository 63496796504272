import Country from '../country/Country';
import PlayerName from '../player/PlayerName';

export default class SuggestionComment {
    public static fromData(data: any): SuggestionComment {
        return new SuggestionComment(
            data.author_id,
            new PlayerName(data.author_name.first_name, data.author_name.last_name),
            Country.fromData(data.author_country),
            data.content_original,
            data.content_translated,
            new Date(data.created_at),
        );
    }

    private readonly authorId: number;
    private readonly authorName: PlayerName;
    private readonly authorCountry: Country;
    private readonly contentOriginal: string;
    private readonly contentTranslated: string;
    private readonly createdAt: Date;

    constructor(authorId: number, authorName: PlayerName, authorCountry: Country, contentOriginal: string, contentTranslated: string, createdAt: Date) {
        this.authorId = authorId;
        this.authorName = authorName;
        this.authorCountry = authorCountry;
        this.contentOriginal = contentOriginal;
        this.contentTranslated = contentTranslated;
        this.createdAt = createdAt;
    }

    public getAuthorId(): number {
        return this.authorId;
    }

    public getAuthorName(): PlayerName {
        return this.authorName;
    }

    public getAuthorCountry(): Country {
        return this.authorCountry;
    }

    public getContent(): string {
        return this.contentTranslated;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }
}
