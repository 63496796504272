import type SuggestionCreateDto from '../../dto/request/SuggestionCreateDto';
import axios from 'axios';
import SuggestionGetResponseDto from './SuggestionGetResponseDto';
import SuggestionListGetResponseDto from './SuggestionListGetResponseDto';
import SuggestionListGetResponseDtoList from './SuggestionListGetResponseDtoList';

declare let locale: string;

export default class SuggestionRepository {
    public find(id: string): Promise<SuggestionGetResponseDto> {
        return new Promise((resolve, reject) => {
            axios
                .get(`/api/suggestions/${id}/${locale}`)
                .then(response => resolve(new SuggestionGetResponseDto(response.data.data)))
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public findAll(): Promise<SuggestionListGetResponseDtoList> {
        return new Promise((resolve, reject) => {
            axios
                .get(`/api/suggestions/${locale}`)
                .then(response => resolve(new SuggestionListGetResponseDtoList(response.data.data.map(data => new SuggestionListGetResponseDto(data)))))
                .catch(error => reject(error));
        });
    }

    public add(dto: SuggestionCreateDto): Promise<void> {
        return new Promise((resolve, reject) => {
            axios
                .post('/api/suggestions', {
                    content: dto.getContent(),
                    title: dto.getTitle(),
                })
                .then(() => resolve())
                .catch(error => reject(error));
        });
    }

    public vote(suggestion: SuggestionGetResponseDto): Promise<void> {
        return new Promise((resolve, reject) => {
            axios
                .post(`/api/suggestions/${suggestion.getId()}/votes`)
                .then(() => resolve())
                .catch(error => reject(error));
        });
    }

    public unvote(suggestion: SuggestionGetResponseDto): Promise<void> {
        return new Promise((resolve, reject) => {
            axios
                .delete(`/api/suggestions/${suggestion.getId()}/votes`)
                .then(() => resolve())
                .catch(error => reject(error));
        });
    }
}
