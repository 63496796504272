<script>
export default {
    data() {
        return {
            show: false,
        };
    },
    created() {
        window.emitter.on('show_overlay', () => this.show = true);
        window.emitter.on('hide_overlay', () => this.show = false);
    },
};
</script>

<template>
    <div v-if="show" class="overlay" />
</template>

<style scoped>
.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.7);
}
</style>
