<script>
import { Clock, DollarSign, Globe, Trophy } from 'lucide-vue-next';
import GameDate from './GameDate.vue';
import GameTime from './GameTime.vue';
import PlayerInfoBox from './PlayerInfoBox.vue';

export default {
    components: {
        OtGamedate: GameDate,
        OtGametime: GameTime,
        OtPlayerInfoBox: PlayerInfoBox,
        Clock,
        DollarSign,
        Globe,
        Trophy,
    },
    computed: {
        user() {
            return this.$store.getters.user;
        },
        player() {
            return this.$store.getters.player;
        },
        username() {
            if (!this.user.id) {
                return '';
            }

            return `${this.user.username} (ID: ${this.user.id})`;
        },
        dollar() {
            return `${this.player.money.dollar.toLocaleString([], { minimumFractionDigits: 2 })} $`;
        },
        otdollar() {
            return `${this.player.money.otdollar.toLocaleString()} OT Dollar`;
        },
        isXs() {
            return this.width < 600;
        },
    },
    created() {
        this.width = window.innerWidth;
    },
    mounted() {
        window.addEventListener('resize', () => this.width = window.innerWidth);
    },
};
</script>

<template>
    <div class="flex">
        <ot-player-info-box class="flex-grow-1">
            <template v-slot:icon><clock /></template>
            <template v-slot:upper><ot-gamedate /></template>
            <template v-slot:lower><ot-gametime /></template>
        </ot-player-info-box>
        <ot-player-info-box v-if="!this.isXs" :title="$t('profil.profil_el')" class="flex-grow-1">
            <template v-slot:icon><globe /></template>
            <template v-slot:upper>{{ player.ranking.entrylist.position }}.</template>
            <template v-slot:lower>{{ player.ranking.entrylist.points }} {{ $t('general.points') }}</template>
        </ot-player-info-box>
        <ot-player-info-box v-if="!this.isXs" :title="$t('profil.profil_cr')" class="flex-grow-1">
            <template v-slot:icon><trophy /></template>
            <template v-slot:upper>{{ player.ranking.championsrace.position }}.</template>
            <template v-slot:lower>{{ player.ranking.championsrace.points }} {{ $t('general.points') }}</template>
        </ot-player-info-box>
        <ot-player-info-box class="flex-grow-1">
            <template v-slot:icon><dollar-sign /></template>
            <template v-slot:upper>{{ dollar }}</template>
            <template v-slot:lower>{{ otdollar }}</template>
        </ot-player-info-box>
    </div>
</template>
