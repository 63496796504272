<script>
import axios from 'axios';
import Loader from '../../components/Loader.vue';
import OtPlayerSelect from '../../components/PlayerSelect.vue';

export default {
    components: {
        OtLoader: Loader,
        OtPlayerSelect,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            data: null,
            comparePlayer: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            axios
                .get(`/api/player-profiles/${this.id}/statistics`)
                .then((response) => {
                    this.data = response.data.data;
                    this.compareUser(this.data.logged_in_player.id);
                });
        },
        compareUser(userId) {
            if (userId === null) {
                return;
            }

            axios
                .get(`/api/players/${userId}/statistics`)
                .then((response) => {
                    this.comparePlayer = response.data.data;
                    this.loading = false;
                });
        },
        getColor(skill1, skill2) {
            if (skill1 === skill2) {
                return '#000000';
            }
            else if (skill1 > skill2) {
                return '#6fb015';
            }
            else {
                return '#ff0000';
            }
        },
        formatDate(atomDate) {
            return new Date(atomDate).toLocaleDateString();
        },
        formatNumber(integer) {
            return integer.toLocaleString();
        },
    },
};
</script>

<template>
    <ot-loader :loading="loading" standalone>
        <template v-if="!loading">
            <table class="striped">
                <tbody>
                    <tr>
                        <td />
                        <td><b>{{ data.player.name }}</b></td>
                        <td>
                            <ot-player-select
                                :default="{ key: data.logged_in_player.id, value: data.logged_in_player.name }"
                                @update-player-id="compareUser($event)"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_karrierebeginn') }}</td>
                        <td :style="`color: ${getColor(comparePlayer.insert_date, data.player.insert_date)}`">{{ formatDate(data.player.insert_date) }}</td>
                        <td :style="`color: ${getColor(data.player.insert_date, comparePlayer.insert_date)}`">{{ formatDate(comparePlayer.insert_date) }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_el') }}</td>
                        <td :style="`color: ${getColor(comparePlayer.entrylist.position, data.player.entrylist.position)}`">{{ data.player.entrylist.position }}. ({{ data.player.entrylist.points }}&nbsp;{{ $t('profil.profil_pkt') }})</td>
                        <td :style="`color: ${getColor(data.player.entrylist.position, comparePlayer.entrylist.position)}`">{{ comparePlayer.entrylist.position }}. ({{ comparePlayer.entrylist.points }}&nbsp;{{ $t('profil.profil_pkt') }})</td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_cr') }}</td>
                        <td :style="`color: ${getColor(comparePlayer.championsrace.position, data.player.championsrace.position)}`">{{ data.player.championsrace.position }}. ({{ data.player.championsrace.points }}&nbsp;{{ $t('profil.profil_pkt') }})</td>
                        <td :style="`color: ${getColor(data.player.championsrace.position, comparePlayer.championsrace.position)}`">{{ comparePlayer.championsrace.position }}. ({{ comparePlayer.championsrace.points }}&nbsp;{{ $t('profil.profil_pkt') }})</td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_hoechstewrposition') }}</td>
                        <td :style="`color: ${getColor(comparePlayer.entrylist.best_position, data.player.entrylist.best_position)}`">{{ data.player.entrylist.best_position }}.</td>
                        <td :style="`color: ${getColor(data.player.entrylist.best_position, comparePlayer.entrylist.best_position)}`">{{ comparePlayer.entrylist.best_position }}.</td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_titeldiesesjahr') }}</td>
                        <td :style="`color: ${getColor(data.player.titles.current_season, comparePlayer.titles.current_season)}`">{{ data.player.titles.current_season }}</td>
                        <td :style="`color: ${getColor(comparePlayer.titles.current_season, data.player.titles.current_season)}`">{{ comparePlayer.titles.current_season }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_titelletztesjahr') }}</td>
                        <td :style="`color: ${getColor(data.player.titles.last_season, comparePlayer.titles.last_season)}`">{{ data.player.titles.last_season }}</td>
                        <td :style="`color: ${getColor(comparePlayer.titles.last_season, data.player.titles.last_season)}`">{{ comparePlayer.titles.last_season }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_titelkarriere') }}</td>
                        <td :style="`color: ${getColor(data.player.titles.overall, comparePlayer.titles.overall)}`">{{ data.player.titles.overall }}</td>
                        <td :style="`color: ${getColor(comparePlayer.titles.overall, data.player.titles.overall)}`">{{ comparePlayer.titles.overall }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_titeldiesesjahr') }} ({{ $t('general.userTournament') }})</td>
                        <td :style="`color: ${getColor(data.player.user_titles.current_season, comparePlayer.user_titles.current_season)}`">{{ data.player.user_titles.current_season }}</td>
                        <td :style="`color: ${getColor(comparePlayer.user_titles.current_season, data.player.user_titles.current_season)}`">{{ comparePlayer.user_titles.current_season }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_titelletztesjahr') }} ({{ $t('general.userTournament') }})</td>
                        <td :style="`color: ${getColor(data.player.user_titles.last_season, comparePlayer.user_titles.last_season)}`">{{ data.player.user_titles.last_season }}</td>
                        <td :style="`color: ${getColor(comparePlayer.user_titles.last_season, data.player.user_titles.last_season)}`">{{ comparePlayer.user_titles.last_season }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_titelkarriere') }} ({{ $t('general.userTournament') }})</td>
                        <td :style="`color: ${getColor(data.player.user_titles.overall, comparePlayer.user_titles.overall)}`">{{ data.player.user_titles.overall }}</td>
                        <td :style="`color: ${getColor(comparePlayer.user_titles.overall, data.player.user_titles.overall)}`">{{ comparePlayer.user_titles.overall }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_preisgelderdiesesjahr') }}</td>
                        <td :style="`color: ${getColor(data.player.won_prize_money.current_season, comparePlayer.won_prize_money.current_season)}`">{{ formatNumber(data.player.won_prize_money.current_season) }}$</td>
                        <td :style="`color: ${getColor(comparePlayer.won_prize_money.current_season, data.player.won_prize_money.current_season)}`">{{ formatNumber(comparePlayer.won_prize_money.current_season) }}$</td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_preisgelderletztesjahr') }}</td>
                        <td :style="`color: ${getColor(data.player.won_prize_money.last_season, comparePlayer.won_prize_money.last_season)}`">{{ formatNumber(data.player.won_prize_money.last_season) }}$</td>
                        <td :style="`color: ${getColor(comparePlayer.won_prize_money.last_season, data.player.won_prize_money.last_season)}`">{{ formatNumber(comparePlayer.won_prize_money.last_season) }}$</td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_presigelderkarriere') }}</td>
                        <td :style="`color: ${getColor(data.player.won_prize_money.overall, comparePlayer.won_prize_money.overall)}`">{{ formatNumber(data.player.won_prize_money.overall) }}$</td>
                        <td :style="`color: ${getColor(comparePlayer.won_prize_money.overall, data.player.won_prize_money.overall)}`">{{ formatNumber(comparePlayer.won_prize_money.overall) }}$</td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_gespieltematches') }}</td>
                        <td :style="`color: ${getColor(data.player.match_record.played, comparePlayer.match_record.played)}`">{{ data.player.match_record.played }}</td>
                        <td :style="`color: ${getColor(comparePlayer.match_record.played, data.player.match_record.played)}`">{{ comparePlayer.match_record.played }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_gewonnenematches') }}</td>
                        <td :style="`color: ${getColor(data.player.match_record.victories, comparePlayer.match_record.victories)}`">{{ data.player.match_record.victories }}</td>
                        <td :style="`color: ${getColor(comparePlayer.match_record.victories, data.player.match_record.victories)}`">{{ comparePlayer.match_record.victories }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_verlorenematches') }}</td>
                        <td :style="`color: ${getColor(comparePlayer.match_record.defeats, data.player.match_record.defeats)}`">{{ data.player.match_record.defeats }}</td>
                        <td :style="`color: ${getColor(data.player.match_record.defeats, comparePlayer.match_record.defeats)}`">{{ comparePlayer.match_record.defeats }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('profil.profil_siegquote') }}</td>
                        <td :style="`color: ${getColor(data.player.match_record.quota, comparePlayer.match_record.quota)}`">{{ data.player.match_record.quota }}%</td>
                        <td :style="`color: ${getColor(comparePlayer.match_record.quota, data.player.match_record.quota)}`">{{ comparePlayer.match_record.quota }}%</td>
                    </tr>
                </tbody>
            </table>
        </template>
    </ot-loader>
</template>
