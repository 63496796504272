<script>
import axios from 'axios';

import Button from '../components/Button.vue';
import Card from '../components/Card.vue';
import Loader from '../components/Loader.vue';

export default {
    components: {
        OtLoader: Loader,
        OtCard: Card,
        OtButton: Button,
    },
    data() {
        return {
            loading: true,
            skills: [],
            skillInfoToShow: null,
            overallSkillPoints: null,
            maximumSkillPoints: null,
            assignedSkillPoints: null,
            availableSkillPoints: null,
            availableStudyPoints: null,
            resetPrice: null,
            requiredStudyPointsForNextSkillPoint: null,
            priceForNextSkillPoint: null,
            buyableSkillPointsLeft: null,
        };
    },
    computed: {
        player() {
            return this.$store.getters.player;
        },
        formattedResetPrice() {
            if (this.resetPrice === null) {
                return '0';
            }

            return this.resetPrice.toLocaleString();
        },
    },
    created() {
        this.load();
    },
    methods: {
        upgrade(skill) {
            if (!skill.is_upgradable) {
                return;
            }

            if (this.availableSkillPoints === 0) {
                window.emitter.emit('error', 'internalSkillTree.noMoreSkillPointsAvailable');
                return;
            }

            if (confirm(this.$t('internalSkillTree.confirmLevelUp', { param1: skill.name }))) {
                axios
                    .patch(`/api/skilltree/skills/${skill.id}`)
                    .then(() => this.load());
            }
        },
        reset() {
            if (this.resetPrice > this.player.money.otdollar) {
                window.emitter.emit('error', 'error.not_enough_otdollar');
                return;
            }

            if (confirm(this.$t('internalSkillTree.confirmReset', { param1: this.formattedResetPrice }))) {
                axios
                    .patch('/api/players/me/skilltree')
                    .then(() => this.load());
            }
        },
        buySkillPoint() {
            if (this.priceForNextSkillPoint > this.player.money.otdollar) {
                window.emitter.emit('error', 'error.not_enough_otdollar');
                return;
            }

            if (confirm(this.$t('internalSkillTree.confirmBuySkillPoint', { param1: this.priceForNextSkillPoint.toLocaleString(), param2: this.buyableSkillPointsLeft }))) {
                axios
                    .post('/api/players/me/skilltree/skillpoints')
                    .then(() => this.load());
            }
        },
        getTypePhrase(condition) {
            if (condition.needed_skill_points > 1) {
                return this.$t(`internalSkillTree.${condition.type_phrase_plural_key}`, { param1: condition.needed_skill_points });
            }

            return this.$t(`internalSkillTree.${condition.type_phrase_singular_key}`);
        },
        getDescriptionForLevel(skill, level) {
            return this.$t(`internalSkillTree.skillDescription${skill.id}`, { param1: skill.effect * level });
        },
        load() {
            axios
                .get('/api/skilltree/skills')
                .then((response) => {
                    this.skills = response.data.data;
                    axios
                        .get(`/api/players/${this.player.id}/skilltree`)
                        .then((response) => {
                            this.overallSkillPoints = response.data.data.overall_skill_points;
                            this.maximumSkillPoints = response.data.data.maximum_skill_points;
                            this.assignedSkillPoints = response.data.data.assigned_skill_points;
                            this.availableSkillPoints = response.data.data.available_skill_points;
                            this.availableStudyPoints = response.data.data.available_study_points;
                            this.resetPrice = response.data.data.reset_price;
                            this.requiredStudyPointsForNextSkillPoint = response.data.data.required_study_points_for_next_skill_point;
                            this.priceForNextSkillPoint = response.data.data.price_for_next_skill_point;
                            this.buyableSkillPointsLeft = response.data.data.buyable_skill_points_left;
                            this.loading = false;
                        });
                });
        },
    },
};
</script>

<template>
    <ot-loader :loading="loading">
        <ot-card legacy>
            <div>
                <span>{{ $t('internalSkillTree.studypoints') }}</span>
                <template v-if="overallSkillPoints < maximumSkillPoints">
                    <span>{{ availableStudyPoints }} / {{ requiredStudyPointsForNextSkillPoint }}</span>
                    <ot-button
                        primary
                        @click="buySkillPoint()"
                    >
                        {{ $t('internalSkillTree.buySkillPoint', { param1: priceForNextSkillPoint.toLocaleString() }) }}
                    </ot-button>
                </template>
                <span v-else>{{ $t('internalSkillTree.maximumSkillPointsReached') }}</span>
            </div>
            <div>{{ $t('internalSkillTree.availableSkillPoints') }} {{ availableSkillPoints }}</div>
        </ot-card>

        <div class="flex gap-2 my-2">
            <div class="w-8/12">
                <ot-card legacy>
                    <div class="overflow-auto h-[334px] relative">
                        <div class="skillTree w-[1320px] h-[311px] absolute left-1.5 top-1.5">
                            <div
                                v-for="skill in skills"
                                :key="skill.id"
                                :id="`skill${skill.id}`"
                                class="absolute w-24 h-24"
                                :class="{ 'cursor-pointer': skill.is_upgradable }"
                                :style="`background-image: url(/images/internalSkillTree/skills/${skill.id}${!skill.is_available ? '_disabled' : ''}.png)`"
                                @mouseover="skillInfoToShow = skill"
                                @mouseout="skillInfoToShow = null"
                                @click="upgrade(skill)"
                            >
                                <div
                                    class="absolute left-0 top-[72px] w-full text-center"
                                    :class="[skill.is_available ? 'text-zinc-100' : 'text-stone-800']"
                                >
                                    {{ skill.level }} / {{ skill.maximum_level }}
                                </div>
                            </div>
                        </div>
                    </div>
                </ot-card>
            </div>
            <div class="w-4/12">
                <ot-card legacy>
                    <div
                        v-for="skill in skills"
                        :key="skill.id"
                        v-show="skillInfoToShow && skill.id === skillInfoToShow.id"
                    >
                        <div>{{ skill.name }}</div>
                        <img
                            :src="`/images/internalSkillTree/skills/${skill.id}${skill.is_available ? '_disabled' : ''}.png`"
                            :alt="skill.name"
                        >
                        <div>{{ skill.level }} / {{ skill.maximum_level }}</div>
                        <div v-if="skill.level > 0">
                            <strong>{{ $t('internalSkillTree.currentLevel') }}</strong> {{ getDescriptionForLevel(skill, skill.level) }}
                        </div>
                        <div v-if="!skill.is_on_maximum_level">
                            <strong>{{ $t('internalSkillTree.nextLevel') }}</strong> {{ getDescriptionForLevel(skill, skill.level + 1) }}
                        </div>
                        <div v-if="skill.condition">
                            {{ getTypePhrase(skill.condition) }}
                            <ul>
                                <li v-for="s in skill.condition.skills" :key="s.id">
                                    <img
                                        :src="`/images/internalSkillTree/skills/small/${s.id}.png`"
                                        :alt="s.name"
                                        style="vertical-align: bottom"
                                    >
                                    {{ s.name }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </ot-card>
            </div>
        </div>

        <ot-card legacy>
            <div>{{ $t('internalSkillTree.assignedSkillPoints') }} {{ assignedSkillPoints }} / {{ maximumSkillPoints }}</div>
            <div>
                <ot-button
                    primary
                    @click="reset()"
                >
                    {{ $t('internalSkillTree.resetSkillTree', { param1: formattedResetPrice }) }}
                </ot-button>
            </div>
        </ot-card>
    </ot-loader>
</template>

<style scoped>
.skillTree {
    background-image: url(./../images/skilltree/arrows.png);
}
#skill1 {
    left: 0;
    top: 0;
}
#skill2 {
    left: 0;
    top: 100px;
}
#skill3 {
    left: 136px;
    top: 100px;
}
#skill4 {
    left: 0;
    top: 200px;
}
#skill5 {
    left: 136px;
    top: 0;
}
#skill6 {
    left: 136px;
    top: 200px;
}
#skill7 {
    left: 272px;
    top: 0;
}
#skill8 {
    left: 272px;
    top: 100px;
}
#skill9 {
    left: 272px;
    top: 200px;
}
#skill10 {
    left: 408px;
    top: 0;
}
#skill11 {
    left: 408px;
    top: 100px;
}
#skill12 {
    left: 408px;
    top: 200px;
}
#skill13 {
    left: 544px;
    top: 0;
}
#skill14 {
    left: 680px;
    top: 0;
}
#skill15 {
    left: 680px;
    top: 100px;
}
#skill16 {
    left: 680px;
    top: 200px;
}
#skill17 {
    left: 816px;
    top: 100px;
}
#skill18 {
    left: 816px;
    top: 200px;
}
#skill19 {
    left: 952px;
    top: 0;
}
#skill20 {
    left: 952px;
    top: 100px;
}
#skill21 {
    left: 952px;
    top: 200px;
}
#skill22 {
    left: 1088px;
    top: 0;
}
#skill23 {
    left: 1224px;
    top: 100px;
}
</style>
