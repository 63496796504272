<script>
export default {
    props: {
        value: {
            type: [String, Number],
            required: false,
        },
        color: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            animate: false,
        };
    },
    computed: {
        calculatedColor() {
            if (this.color) {
                return this.color;
            }

            return 'inherit';
        },
    },
    watch: {
        value() {
            this.animate = true;
            setTimeout(() => this.animate = false, 2000);
        },
    },
};
</script>

<template>
    <div :class="{ 'animate-pulse': animate }" :style="{ color: calculatedColor }">
        {{ value }}
    </div>
</template>

<style scoped>

</style>
