import Message from './Message.js';

export default class Channel {
    constructor() {
        this.messages = [];
    }

    static fromData(data) {
        const channel = new Channel();
        channel.id = data.id;
        channel.name = data.name;
        channel.numberOfVisitors = data.number_of_visitors;

        if (data.messages) {
            channel.messages = data.messages.data.map(data => Message.fromData(data));
        }

        return channel;
    }
}
