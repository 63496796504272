<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import OtButton from '../components/Button.vue';
import OtCard from '../components/Card.vue';
import { OtTextField } from '../components/text-field';
import { OtTextarea } from '../components/textarea';
import { useFormatting } from '../composables/formatting';

interface Props {
    tournament: { id: number; name: string; city: string; description: string; country: { id: number }; participants: number; surface: { type: string }; setsToWin: number; hasPassword: boolean; password: string | null; prizeMoneyType: string; startingFee: number };
    listOfCountries: { ID: number; name: string }[];
}
const props = defineProps<Props>();

const visibility = ref(props.tournament.hasPassword ? 'private' : 'public');
const password = ref(props.tournament.password);
const prizeMoneyType = ref(props.tournament.prizeMoneyType);
const participants = ref(props.tournament.participants);
const startingFee = ref(props.tournament.startingFee);
const setsToWin = ref(props.tournament.setsToWin);

const store = useStore();
const isPremium = computed(() => store.getters.user.isPremium());

const routeTournamentEdit = computed(() => `/${locale}/userTournament/${props.tournament.id}`);

const { t } = useI18n();

const currentIncome = { dollar: 0, otdollar: 0 };

const { formatMoney } = useFormatting();

const currentPriceOtDollar = computed(() => {
    let currentPriceOtDollar = 0;
    if (!isPremium.value) {
        if ($('input[name=surface]:checked', '#tournamentForm').val() === 'GRAS') {
            currentPriceOtDollar += 50000;
        }
        if (setsToWin.value === 3) {
            currentPriceOtDollar += 50000;
        }
        if (visibility.value === 'private') {
            currentPriceOtDollar += 50000;
        }
        if (participants.value === 16) {
            currentPriceOtDollar += 50000;
        }
    }
    if (participants.value === 32) {
        currentPriceOtDollar += 200000;
    }
    if (participants.value === 64) {
        currentPriceOtDollar += 500000;
    }

    return currentPriceOtDollar;
});

const prizeMoneySuffix = computed(() => prizeMoneyType.value === 'OTDOLLAR' ? ' OT Dollar' : '$');
const prizeMoneyOverall = computed(() => participants.value * startingFee.value * 1000);
const prizeMoneyFinal64 = computed(() => {
    switch (participants.value) {
        case 4:
        case 8:
        case 16:
        case 32:
            return 0;
        case 64:
            return Math.floor(prizeMoneyOverall.value * 0.0059375);
    }
    throw new Error(`invalid number of participants: ${participants.value}`);
});
const prizeMoneyFinal32 = computed(() => {
    switch (participants.value) {
        case 4:
        case 8:
        case 16:
            return 0;
        case 32:
        case 64:
            return Math.floor(prizeMoneyOverall.value * 0.01);
    }
    throw new Error(`invalid number of participants: ${participants.value}`);
});
const prizeMoneyFinal16 = computed(() => {
    switch (participants.value) {
        case 4:
        case 8:
            return 0;
        case 16:
            return Math.floor(prizeMoneyOverall.value * 0.025);
        case 32:
            return Math.floor(prizeMoneyOverall.value * 0.02);
        case 64:
            return Math.floor(prizeMoneyOverall.value * 0.015);
    }
    throw new Error(`invalid number of participants: ${participants.value}`);
});
const prizeMoneyQuarterFinal = computed(() => {
    switch (participants.value) {
        case 4:
            return 0;
        case 8:
            return Math.floor(prizeMoneyOverall.value * 0.037);
        case 16:
        case 32:
            return Math.floor(prizeMoneyOverall.value * 0.04);
        case 64:
            return Math.floor(prizeMoneyOverall.value * 0.035);
    }
    throw new Error(`invalid number of participants: ${participants.value}`);
});
const prizeMoneySemiFinal = computed(() => {
    switch (participants.value) {
        case 4:
            return Math.floor(prizeMoneyOverall.value * 0.05);
        case 8:
            return Math.floor(prizeMoneyOverall.value * 0.076);
        case 16:
            return Math.floor(prizeMoneyOverall.value * 0.07);
        case 32:
            return Math.floor(prizeMoneyOverall.value * 0.06);
        case 64:
            return Math.floor(prizeMoneyOverall.value * 0.05);
    }
    throw new Error(`invalid number of participants: ${participants.value}`);
});
const prizeMoneyFinal = computed(() => {
    switch (participants.value) {
        case 4:
            return Math.floor(prizeMoneyOverall.value * 0.3);
        case 8:
            return Math.floor(prizeMoneyOverall.value * 0.2);
        case 16:
            return Math.floor(prizeMoneyOverall.value * 0.15);
        case 32:
            return Math.floor(prizeMoneyOverall.value * 0.1);
        case 64:
            return Math.floor(prizeMoneyOverall.value * 0.08);
    }
    throw new Error(`invalid number of participants: ${participants.value}`);
});
const prizeMoneyWinner = computed(() => {
    switch (participants.value) {
        case 4:
            return Math.floor(prizeMoneyOverall.value * 0.5);
        case 8:
            return Math.floor(prizeMoneyOverall.value * 0.4);
        case 16:
            return Math.floor(prizeMoneyOverall.value * 0.25);
        case 32:
            return Math.floor(prizeMoneyOverall.value * 0.2);
        case 64:
            return Math.floor(prizeMoneyOverall.value * 0.15);
    }
    throw new Error(`invalid number of participants: ${participants.value}`);
});
</script>

<template>
    <form method="post" :action="routeTournamentEdit" id="tournamentForm">
        <div class="flex flex-wrap gap-2 items-stretch">
            <ot-card legacy not-stretched :title="$t('userTournament.general')">
                <ot-text-field :label="t('userTournament.name')" name="name" :model-value="tournament.name"></ot-text-field>
                <ot-text-field :label="t('userTournament.city')" name="city" :model-value="tournament.city"></ot-text-field>
                <label>
                    {{ t('general.breadcrumbCountry') }}
                    <select name="country">
                        <option
                            v-for="country in listOfCountries"
                            :key="country.ID"
                            :value="country.ID"
                            :selected="country.ID === tournament.country.id"
                        >{{ country.name }}</option>
                    </select>
                </label>
                <ot-textarea :label="t('userTournament.description')" name="description" :model-value="tournament.description"></ot-textarea>
            </ot-card>
            <ot-card legacy not-stretched :title="$t('internalTournament.teilnehmer')">
                <ul>
                    <li>
                        <label>
                            <input type="radio" name="participants" v-model="participants" :value="4" class="w-5 h-5 align-text-bottom">
                            4
                        </label>
                    </li>
                    <li>
                        <label>
                            <input type="radio" name="participants" v-model="participants" :value="8" class="w-5 h-5 align-text-bottom">
                            8
                        </label>
                    </li>
                    <li>
                        <label>
                            <input type="radio" name="participants" v-model="participants" :value="16" class="w-5 h-5 align-text-bottom">
                            16{{ !isPremium ? '(+ 50.000 OT Dollar)' : '' }} <img src="../images/misc/premium.png" alt="Supporter Account" :title="$t('userTournament.freeWithSupporter')" class="premium inline" />
                        </label>
                    </li>
                    <li>
                        <label>
                            <input type="radio" name="participants" v-model="participants" :value="32" class="w-5 h-5 align-text-bottom">
                            32 (+ 200.000 OT Dollar)
                        </label>
                    </li>
                    <li>
                        <label>
                            <input type="radio" name="participants" v-model="participants" :value="64" class="w-5 h-5 align-text-bottom">
                            64 (+ 500.000 OT Dollar)
                        </label>
                    </li>
                </ul>
            </ot-card>
            <ot-card legacy not-stretched :title="$t('internalShowmatch.surface')">
                <ul>
                    <li>
                        <label>
                            <input type="radio" name="surface" value="HARDCOURT" :checked="tournament.surface.type === 'HARDCOURT'" class="w-5 h-5 align-text-bottom">
                            <span class="surface surface_hardcourt" title="Hardcourt" />
                            {{ t('surface.surface_hardcourt') }}
                        </label>
                    </li>
                    <li>
                        <label>
                            <input type="radio" name="surface" value="CLAY" :checked="tournament.surface.type === 'CLAY'" class="w-5 h-5 align-text-bottom">
                            <span class="surface surface_clay" title="Clay" />
                            {{ t('surface.surface_clay') }}
                        </label>
                    </li>
                    <li>
                        <label>
                            <input type="radio" name="surface" value="CARPET" :checked="tournament.surface.type === 'CARPET'" class="w-5 h-5 align-text-bottom">
                            <span class="surface surface_carpet" title="Carpet" />
                            {{ t('surface.surface_carpet') }}
                        </label>
                    </li>
                    <li>
                        <label>
                            <input type="radio" name="surface" value="GRAS" :checked="tournament.surface.type === 'GRAS'" class="w-5 h-5 align-text-bottom">
                            <span class="surface surface_gras" title="Gras" />
                            {{ t('surface.surface_grass') }}{{ !isPremium ? '(+ 50.000 OT Dollar)' : '' }} <img src="../images/misc/premium.png" alt="Supporter Account" :title="$t('userTournament.freeWithSupporter')" class="premium inline" />
                        </label>
                    </li>
                </ul>
            </ot-card>
            <ot-card legacy not-stretched :title="$t('internalShowmatch.setsToPlay')">
                <ul>
                    <li>
                        <label>
                            <input type="radio" name="setsToWin" :value="1" v-model="setsToWin" class="w-5 h-5 align-text-bottom">
                            {{ t('general.best_of') }} 1
                        </label>
                    </li>
                    <li>
                        <label>
                            <input type="radio" name="setsToWin" :value="2" v-model="setsToWin" class="w-5 h-5 align-text-bottom">
                            {{ t('general.best_of') }} 3
                        </label>
                    </li>
                    <li>
                        <label>
                            <input type="radio" name="setsToWin" :value="3" v-model="setsToWin" class="w-5 h-5 align-text-bottom">
                            {{ t('general.best_of') }} 5{{ !isPremium ? ' (+ 50.000 OT Dollar)' : '' }} <img src="../images/misc/premium.png" alt="Supporter Account" :title="$t('userTournament.freeWithSupporter')" class="premium inline" />
                        </label>
                    </li>
                </ul>
            </ot-card>
            <ot-card legacy not-stretched :title="$t('userTournament.visibility')">
                <ul>
                    <li>
                        <label>
                            <input type="radio" name="visibility" class="w-5 h-5 align-text-bottom" value="public" v-model="visibility">
                            {{ t('userTournament.visibility_public') }}
                        </label>
                    </li>
                    <li>
                        <label>
                            <input type="radio" name="visibility" class="w-5 h-5 align-text-bottom" value="private" v-model="visibility">
                            {{ t('userTournament.visibility_private') }}{{ !isPremium ? '(+ 50.000 OT Dollar)' : '' }} <img src="../images/misc/premium.png" alt="Supporter Account" :title="$t('userTournament.freeWithSupporter')" class="premium inline" />
                        </label>
                    </li>
                </ul>
                <div :class="{ hidden: visibility === 'public' }">
                    <ot-text-field :label="t('settings.settings_password')" name="password" v-model="password" />
                </div>
            </ot-card>
            <ot-card legacy not-stretched :title="$t('userTournament.prizeMoney')">
                <ul>
                    <li>
                        <label>
                            <input type="radio" name="prizeMoneyType" class="w-5 h-5 align-text-bottom" value="NONE" v-model="prizeMoneyType">
                            -
                        </label>
                    </li>
                    <li>
                        <label>
                            <input type="radio" name="prizeMoneyType" class="w-5 h-5 align-text-bottom" value="DOLLAR" v-model="prizeMoneyType">
                            Dollar
                        </label>
                    </li>
                    <li>
                        <label>
                            <input type="radio" name="prizeMoneyType" class="w-5 h-5 align-text-bottom" value="OTDOLLAR" v-model="prizeMoneyType">
                            OT Dollar
                        </label>
                    </li>
                </ul>
                <div v-show="prizeMoneyType !== 'NONE'">
                    <ot-text-field number :label="t('userTournament.startingFee')" name="startingFee" id="startingFee" v-model="startingFee" class="w-80"></ot-text-field>
                    <table>
                        <tbody>
                            <tr>
                                <td>{{ $t('tournament.round_roundsLeft_0') }}</td>
                                <td>{{ formatMoney(prizeMoneyWinner) }} {{ prizeMoneySuffix }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('tournament.round_roundsLeft_1') }}</td>
                                <td>{{ formatMoney(prizeMoneyFinal) }} {{ prizeMoneySuffix }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('tournament.round_roundsLeft_2') }}</td>
                                <td>{{ formatMoney(prizeMoneySemiFinal) }} {{ prizeMoneySuffix }}</td>
                            </tr>
                            <tr v-if="participants >= 8">
                                <td>{{ $t('tournament.round_roundsLeft_3') }}</td>
                                <td>{{ formatMoney(prizeMoneyQuarterFinal) }} {{ prizeMoneySuffix }}</td>
                            </tr>
                            <tr v-if="participants >= 16">
                                <td>
                                    <template v-if="participants === 16">{{ $t('tournament.round_round_1') }}</template>
                                    <template v-if="participants === 32">{{ $t('tournament.round_round_2') }}</template>
                                    <template v-if="participants === 64">{{ $t('tournament.round_round_3') }}</template>
                                </td>
                                <td>{{ formatMoney(prizeMoneyFinal16) }} {{ prizeMoneySuffix }}</td>
                            </tr>
                            <tr v-if="participants >= 32">
                                <td>
                                    <template v-if="participants === 32">{{ $t('tournament.round_round_1') }}</template>
                                    <template v-if="participants === 64">{{ $t('tournament.round_round_2') }}</template>
                                </td>
                                <td>{{ formatMoney(prizeMoneyFinal32) }} {{ prizeMoneySuffix }}</td>
                            </tr>
                            <tr v-if="participants >= 64">
                                <td>{{ $t('tournament.round_round_1') }}</td>
                                <td>{{ formatMoney(prizeMoneyFinal64) }} {{ prizeMoneySuffix }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ot-card>
            <ot-card legacy not-stretched>
                <div>{{ t('userTournament.currentPrice') }} {{ formatMoney(0) }}$ / {{ formatMoney(currentPriceOtDollar) }} OT Dollar</div>
                <div>{{ t('userTournament.currentPossibleIncome') }} {{ formatMoney(currentIncome.dollar) }}$ / {{ formatMoney(currentIncome.otdollar) }} OT Dollar</div>
                <div>{{ $t('userTournament.paymentInfo') }}</div>
                <ot-button submit>{{ t('general.save') }}</ot-button>
            </ot-card>
        </div>
    </form>
</template>
