<script>
export default {
    props: {
        href: {
            type: String,
        },
        to: {
            type: String,
        },
    },
    computed: {
        is() {
            if (this.href) {
                return 'a';
            }

            if (this.to) {
                return 'router-link';
            }

            return 'div';
        },
    },
};
</script>

<template>
    <component
        :is="is"
        :href="href"
        :to="to"
        class="h-9 w-9 cursor-pointer inline-flex relative items-center justify-center before:opacity-0 hover:before:opacity-10 before:w-full before:h-full before:rounded-full before:absolute before:left-0 before:top-0 before:bg-black"
    >
        <slot />
    </component>
</template>
