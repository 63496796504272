<script>
import MessageRepository from '../../domain/message/MessageRepository';
import DataProvider from './DataProvider.vue';

export default {
    mixins: [DataProvider],
    methods: {
        getRepository() {
            return new MessageRepository();
        },
    },
};
</script>
