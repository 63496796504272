<script setup lang="ts">
import { computed } from 'vue';
import { useLayout } from '../composables/layout';
import OtChatContainer from './ChatContainer.vue';
import OtContent from './Content.vue';
import OtFooter from './Footer.vue';
import OtNavigation from './Navigation.vue';
import OtPushNotificationRequest from './PushNotificationRequest.vue';

interface Props {
    channelId: number;
}
defineProps<Props>();

const { isNavigationOpen, isChatOpen, toolbarExtension, width, footerRows } = useLayout();
const classes = computed(() => {
    const classes = [];
    if (isNavigationOpen.value && width.value >= 1400) {
        classes.push('translate-x-[256px]');
    }

    classes.push(toolbarExtension.value ? 'pt-24' : 'pt-12');

    switch (footerRows.value) {
        case 1:
            classes.push('pb-12');
            break;
        case 2:
            classes.push('pb-24');
            break;
        case 3:
            classes.push('pb-36');
            break;
        case 4:
            classes.push('pb-48');
            break;
    }

    return classes.join(' ');
});
const styles = computed(() => {
    const styles = [];
    if (width.value > 1400) {
        styles.push(`width: calc(100% - ${isNavigationOpen.value ? 256 : 0}px - ${isChatOpen.value ? 256 : 0}px)`);
    }

    return styles.join('; ');
});
</script>

<template>
    <div class="h-full">
        <ot-navigation></ot-navigation>
        <main
            class="flex flex-col h-full grow z-0 transition-all duration-300"
            :class="classes"
            :style="styles"
        >
            <ot-push-notification-request></ot-push-notification-request>
            <div class="p-4">
                <ot-content>
                    <slot />
                </ot-content>
            </div>
            <ot-footer></ot-footer>
        </main>
        <ot-chat-container :channel="channelId"></ot-chat-container>
    </div>
</template>
