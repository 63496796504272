<script>
import axios from 'axios';
import OtButton from '../../components/Button.vue';
import Loader from '../../components/Loader.vue';

export default {
    components: {
        OtButton,
        OtLoader: Loader,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            data: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            axios
                .get(`/api/player-profiles/${this.id}/admin`)
                .then((response) => {
                    this.data = response.data.data;
                    this.loading = false;
                });
        },
        reportAsFake() {
            axios
                .post(Routing.generate('report_fake', { id: this.id, reason: '' }))
                .then(response => window.emitter.emit('info', response.data));
        },
        banTemporarily(minutes) {
            axios
                .post(Routing.generate('OnlinetennisBundle_temporarilyBanUser', { id: this.id, minutes }))
                .then(() => window.emitter.emit('info', `user successfully banned for ${minutes} minutes`));
        },
    },
};
</script>

<template>
    <ot-loader :loading="loading" standalone>
        <template v-if="!loading">
            <h3>Fake</h3>
            <ot-button @click="reportAsFake()">report as fake</ot-button>

            <h3>Temporary Banning</h3>
            <div class="flex gap-2">
                <ot-button @click="banTemporarily(30)">ban 30 minutes</ot-button>
                <ot-button @click="banTemporarily(120)">ban 2 hours</ot-button>
                <ot-button @click="banTemporarily(300)">ban 5 hours</ot-button>
                <ot-button @click="banTemporarily(720)">ban 12 hours</ot-button>
                <ot-button @click="banTemporarily(1440)">ban 1 day</ot-button>
            </div>
            <div>only to be used when a user keeps on making trouble after reporting a chat message</div>

            <h3>Admin Panel</h3>
            <a :href="data.admin_link" target="_blank">look up in the admin panel</a>
        </template>
    </ot-loader>
</template>
