<script setup lang="ts">
import { MessageSquareText, Menu as OtMenu, Star } from 'lucide-vue-next';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useLayout } from '../composables/layout';
import OtFlag from './Flag.vue';
import OtPlayerInfoBox from './PlayerInfoBox.vue';
import OtToolbarPlayerData from './ToolbarPlayerData.vue';
import { OtTooltip, OtTooltipContent, OtTooltipTrigger } from './tooltip';

const store = useStore();
const { t } = useI18n();
const { toggleNavigation, toggleChat, toolbarExtension, width } = useLayout();

const homeLink = Routing.generate('OnlinetennisBundle_home', { _locale: locale });
const user = computed(() => store.getters.user);
const player = computed(() => store.getters.player);
const hasPremium = computed(() => user.value.premium.isActive);
const premiumExpireDate = computed(() => t('general.supporterAccountTime', { param1: user.value.premium.expireDate.toLocaleDateString([], { timeZone: 'Europe/Berlin' }) }));
const username = computed(() => user.value.id ? `${user.value.username} (ID: ${user.value.id})` : '');
const logoSource = computed(() => width.value < 720 ? '/images/logo_toolbar_s.png' : '/images/logo_toolbar_l.png');
</script>

<template>
    <header class="fixed top-0 left-0 right-0 bg-green-700 z-10">
        <div class="flex justify-between">
            <div class="flex">
                <div class="w-12 flex justify-center items-center cursor-pointer" @click="toggleNavigation">
                    <ot-menu color="white" />
                </div>
                <a :href="homeLink"><img :src="logoSource" alt="Onlinetennis" /></a>
            </div>
            <div class="flex">
                <ot-player-info-box inverted>
                    <template v-slot:upper>
                        <ot-flag :id="player.country.id" class="mr-2" />
                        <a class="ml user_link" :href="`#/player/${player.id}`">{{ player.name.firstname }} {{ player.name.lastname }}</a>
                    </template>
                    <template v-slot:lower>
                        <ot-tooltip v-if="hasPremium">
                            <ot-tooltip-trigger>
                                <star class="inline w-4 h-4 mb-1" />
                            </ot-tooltip-trigger>
                            <ot-tooltip-content side="bottom">{{ premiumExpireDate }}</ot-tooltip-content>
                        </ot-tooltip>
                        {{ username }}
                    </template>
                </ot-player-info-box>
                <ot-toolbar-player-data v-if="!toolbarExtension" />
                <div class="w-12 flex justify-center items-center shrink-0 chat-icon cursor-pointer" :class="{ 'bg-stone-300': !toolbarExtension }" @click="toggleChat">
                    <message-square-text :color="toolbarExtension ? 'white' : undefined" />
                </div>
            </div>
        </div>
        <div v-if="toolbarExtension">
            <ot-toolbar-player-data />
        </div>
    </header>
</template>
