<script setup lang="ts">
import type { Ref } from 'vue';
import axios from 'axios';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import OtCard from './Card.vue';
import OtLoader from './Loader.vue';
import OtPlayer from './Player.vue';
import { OtTabs, OtTabsContent, OtTabsList, OtTabsTrigger } from './tabs';
import OtToggle from './Toggle.vue';

interface Props {
    isNewcomer: boolean;
}
defineProps<Props>();

const mode: Ref<'singles' | 'doubles'> = ref('singles');

const { t } = useI18n();

const loading = ref(true);
const newcomerRanking = ref([]);
const entrylistSinglesRanking = ref([]);
const championsraceSinglesRanking = ref([]);
const entrylistDoublesRanking = ref([]);
const championsraceDoublesRanking = ref([]);
async function load() {
    await Promise.all([
        loadNewcomerRanking(),
        loadEntrylistSinglesRanking(),
        loadChampionsraceSinglesRanking(),
        loadEntrylistDoublesRanking(),
        loadChampionsraceDoublesRanking(),
    ]);

    loading.value = false;
}

async function loadNewcomerRanking() {
    const { data } = await axios.get('/api/ranking/singles/newcomer');
    newcomerRanking.value = data.data;
}

async function loadEntrylistSinglesRanking() {
    const { data } = await axios.get('/api/ranking/singles/entrylist');
    entrylistSinglesRanking.value = data.data;
}

async function loadChampionsraceSinglesRanking() {
    const { data } = await axios.get('/api/ranking/singles/championsrace');
    championsraceSinglesRanking.value = data.data;
}

async function loadEntrylistDoublesRanking() {
    const { data } = await axios.get('/api/ranking/doubles/entrylist');
    entrylistDoublesRanking.value = data.data;
}

async function loadChampionsraceDoublesRanking() {
    const { data } = await axios.get('/api/ranking/doubles/championsrace');
    championsraceDoublesRanking.value = data.data;
}

onMounted(load);
</script>

<template>
    <ot-card>
        <template #title>
            <div class="flex justify-between items-center w-full">
                <div class="p-4">{{ t('general.rankings') }}</div>
                <ot-toggle
                    option-a="singles"
                    option-b="doubles"
                    default="singles"
                    :label-option-a="t('general.singles')"
                    :label-option-b="t('general.doubles')"
                    @toggle="mode = $event"
                    class="mr-4"
                ></ot-toggle>
            </div>
        </template>
        <ot-loader :loading="loading" standalone>
            <ot-tabs id="home_ranking" :default-value="isNewcomer && mode === 'singles' ? 'newcomer' : 'entrylist'">
                <ot-tabs-list>
                    <ot-tabs-trigger value="newcomer" v-if="isNewcomer && mode === 'singles'">{{ t('rangliste.rangliste_newcomer') }}</ot-tabs-trigger>
                    <ot-tabs-trigger value="entrylist">{{ t('rangliste.rangliste_entrylist') }}</ot-tabs-trigger>
                    <ot-tabs-trigger value="championsrace">{{ t('rangliste.rangliste_championsrace') }}</ot-tabs-trigger>
                </ot-tabs-list>
                <ot-tabs-content value="newcomer" class="p-0" v-if="isNewcomer && mode === 'singles'">
                    <table class="striped">
                        <tr
                            v-for="row in newcomerRanking"
                            :key="row.player.id"
                        >
                            <td class="p-2 text-right">{{ row.position }}.</td>
                            <td class="p-2"><ot-player :id="row.player.id" :country="row.player.country.id">{{ row.player.name }}</ot-player></td>
                            <td class="p-2 text-right">{{ row.points }}</td>
                        </tr>
                    </table>
                </ot-tabs-content>
                <ot-tabs-content value="entrylist" class="p-0">
                    <table class="striped" v-if="mode === 'singles'">
                        <tr
                            v-for="row in entrylistSinglesRanking"
                            :key="row.player.id"
                        >
                            <td class="p-2 text-right">{{ row.position }}.</td>
                            <td class="p-2"><ot-player :id="row.player.id" :country="row.player.country.id">{{ row.player.name }}</ot-player></td>
                            <td class="p-2 text-right">{{ row.points }}</td>
                        </tr>
                    </table>
                    <table class="striped" v-if="mode === 'doubles'">
                        <tr
                            v-for="row in entrylistDoublesRanking"
                            :key="row.doubles.id"
                        >
                            <td class="p-2 text-right">{{ row.position }}.</td>
                            <td class="p-2 flex gap-2">
                                <ot-player :id="row.doubles.playerOne.id" :country="row.doubles.playerOne.country.id">{{ row.doubles.playerOne.name }}</ot-player>
                                /
                                <ot-player :id="row.doubles.playerTwo.id" :country="row.doubles.playerTwo.country.id">{{ row.doubles.playerTwo.name }}</ot-player>
                            </td>
                            <td class="p-2 text-right">{{ row.points }}</td>
                        </tr>
                    </table>
                </ot-tabs-content>
                <ot-tabs-content value="championsrace" class="p-0">
                    <table class="striped" v-if="mode === 'singles'">
                        <tr
                            v-for="row in championsraceSinglesRanking"
                            :key="row.player.id"
                        >
                            <td class="p-2 text-right">{{ row.position }}.</td>
                            <td class="p-2"><ot-player :id="row.player.id" :country="row.player.country.id">{{ row.player.name }}</ot-player></td>
                            <td class="p-2 text-right">{{ row.points }}</td>
                        </tr>
                    </table>
                    <table class="striped" v-if="mode === 'doubles'">
                        <tr
                            v-for="row in championsraceDoublesRanking"
                            :key="row.doubles.id"
                        >
                            <td class="p-2 text-right">{{ row.position }}.</td>
                            <td class="p-2 flex gap-2">
                                <ot-player :id="row.doubles.playerOne.id" :country="row.doubles.playerOne.country.id">{{ row.doubles.playerOne.name }}</ot-player>
                                /
                                <ot-player :id="row.doubles.playerTwo.id" :country="row.doubles.playerTwo.country.id">{{ row.doubles.playerTwo.name }}</ot-player>
                            </td>
                            <td class="p-2 text-right">{{ row.points }}</td>
                        </tr>
                    </table>
                </ot-tabs-content>
            </ot-tabs>
        </ot-loader>
    </ot-card>
</template>
