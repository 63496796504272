import Notification from '../../dto/response/Notification';

declare let window: {
    emitter: any;
};

export default class Mercure {
    constructor(userId) {
        const url = new URL(import.meta.env.VITE_MERCURE_PUBLISH_URL);
        url.searchParams.append('topic', `${import.meta.env.VITE_API_URL}notifications/${userId}`);
        url.searchParams.append('topic', `${import.meta.env.VITE_API_URL}chat-messages`);
        url.searchParams.append('topic', `${import.meta.env.VITE_API_URL}hot-matches`);
        url.searchParams.append('topic', `${import.meta.env.VITE_API_URL}staff-market`);
        const eventSource = new EventSource(url.toString());
        eventSource.onmessage = (event) => {
            const data = JSON.parse(event.data);
            switch (data.type) {
                case 'notification':
                    window.emitter.emit('notification', Notification.info(data.data.message, data.data.params));
                    break;
                case 'chat-message':
                    window.emitter.emit('chat-message', data.data);
                    break;
                case 'hot-matches':
                    window.emitter.emit('hot-matches', data.data);
                    break;
                case 'staff-market':
                    window.emitter.emit('staff-market-updated', data.data);
                    break;
            }
        };
    }
}
