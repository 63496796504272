<script>
import Card from '../components/Card.vue';
import FeedbackDataProvider from '../components/data-provider/FeedbackDataProvider.vue';
import FeedbackSlider from '../components/FeedbackSlider.vue';
import Header from '../components/Header.vue';
import FeedbackRepository from '../domain/feedback/FeedbackRepository';
import FeedbackUpdateDto from '../dto/request/FeedbackUpdateDto';

export default {
    components: {
        OtCard: Card,
        OtFeedbackSlider: FeedbackSlider,
        OtFeedbackDataProvider: FeedbackDataProvider,
        OtHeader: Header,
    },
    data() {
        return {
            overall: 5,
            fun: 5,
            graphics: 5,
            usability: 5,
            community: 5,
            support: 5,
        };
    },
    methods: {
        loaded(feedback) {
            this.overall = feedback.getOverall();
            this.fun = feedback.getFun();
            this.graphics = feedback.getGraphics();
            this.usability = feedback.getUsability();
            this.community = feedback.getCommunity();
            this.support = feedback.getSupport();
        },
        update() {
            this.$nextTick(() => {
                new FeedbackRepository()
                    .update(new FeedbackUpdateDto(this.overall, this.fun, this.graphics, this.usability, this.community, this.support));
            });
        },
    },
};
</script>

<template>
    <ot-card>
        <ot-feedback-data-provider method="find" :callback="loaded">
            <template #default>
                <div>
                    <ot-header :title="$t('general.feedback')" />
                    <div class="flex flex-wrap p-4">
                        <div class="grow">
                            <div>
                                <p>{{ $t('general.feedback_description') }}</p>
                            </div>
                        </div>
                        <div class="grow basis-1/2">
                            <div>
                                <ot-feedback-slider v-model="overall" :label="$t('general.feedback_category_overall')" @change="update()" />
                            </div>
                        </div>
                        <div class="grow basis-1/2">
                            <ot-feedback-slider v-model="fun" :label="$t('general.feedback_category_fun')" @change="update()" />
                        </div>
                        <div class="grow basis-1/2">
                            <div>
                                <ot-feedback-slider v-model="graphics" :label="$t('general.feedback_category_graphics')" @change="update()" />
                            </div>
                        </div>
                        <div class="grow basis-1/2">
                            <div>
                                <ot-feedback-slider v-model="usability" :label="$t('general.feedback_category_usability')" @change="update()" />
                            </div>
                        </div>
                        <div class="grow basis-1/2">
                            <div>
                                <ot-feedback-slider v-model="community" :label="$t('general.feedback_category_community')" @change="update()" />
                            </div>
                        </div>
                        <div class="grow basis-1/2">
                            <div>
                                <ot-feedback-slider v-model="support" :label="$t('general.feedback_category_support')" @change="update()" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </ot-feedback-data-provider>
    </ot-card>
</template>
