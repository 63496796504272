import PlayerName from '../../domain/player/PlayerName';

export default class MessagesListGetResponseDto {
    private readonly id: number;
    private readonly status: string;
    private readonly title: string;
    private readonly content: string;
    private readonly senderId: number | null;
    private readonly receiverId: number;
    private readonly senderName: PlayerName | null;
    private readonly receiverName: PlayerName;
    private readonly systemMessage: boolean;
    private readonly date: string;

    public constructor(data: any) {
        this.id = data.id;
        this.status = data.status;
        this.title = data.title;
        this.content = data.content;
        this.senderId = data.sender_id;
        this.receiverId = data.receiver_id;
        this.senderName = data.sender_name.data === null ? null : PlayerName.fromData(data.sender_name.data);
        this.receiverName = data.receiver_name.data === null ? null : PlayerName.fromData(data.receiver_name.data);
        this.systemMessage = data.is_system_message;
        this.date = data.date;
    }

    public getId(): number {
        return this.id;
    }

    public getStatus(): string {
        return this.status;
    }

    public getTitle(): string {
        return this.title;
    }

    public getContent(): string {
        return this.content;
    }

    public getSenderId(): number | null {
        return this.senderId;
    }

    public getReceiverId(): number {
        return this.receiverId;
    }

    public getSenderName(): PlayerName | null {
        return this.senderName;
    }

    public getReceiverName(): PlayerName {
        return this.receiverName;
    }

    public isSystemMessage(): boolean {
        return this.systemMessage;
    }

    public getFormattedDate(): string {
        const date = new Date(this.date);

        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    }
}
