export default class Feedback {
    public static fromData(data: any): Feedback {
        return new Feedback(data.overall, data.fun, data.graphics, data.usability, data.community, data.support);
    }

    private readonly overall: number;
    private readonly fun: number;
    private readonly graphics: number;
    private readonly usability: number;
    private readonly community: number;
    private readonly support: number;

    public constructor(overall: number, fun: number, graphics: number, usability: number, community: number, support: number) {
        this.overall = overall;
        this.fun = fun;
        this.graphics = graphics;
        this.usability = usability;
        this.community = community;
        this.support = support;
    }

    public getOverall(): number {
        return this.overall;
    }

    public getFun(): number {
        return this.fun;
    }

    public getGraphics(): number {
        return this.graphics;
    }

    public getUsability(): number {
        return this.usability;
    }

    public getCommunity(): number {
        return this.community;
    }

    public getSupport(): number {
        return this.support;
    }
}
