<script setup lang="ts">
import type { HTMLAttributes } from 'vue';
import { cn } from '../utils';

const props = defineProps<{ class?: HTMLAttributes['class'] }>();
</script>

<template>
    <div :class="cn('flex flex-row-reverse p-4 border-t border-t-gray-500 justify-between', props.class)">
        <slot />
    </div>
</template>
