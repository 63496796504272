<script setup lang="ts">
import { computed } from 'vue';
import { useLayout } from '../composables/layout';
import OtBrandIcon from './BrandIcon.vue';
import OtButton from './Button.vue';
import OtNotepad from './Notepad.vue';

const link = {
    credits: '/credits',
    chat_channel: Routing.generate('chat_channel', { _locale: locale }),
    online: Routing.generate('online', { _locale: locale }),
    friend_show: '/friends',
    survey_list: Routing.generate('survey_list', { _locale: locale }),
    fanpages: Routing.generate('fanpages', { _locale: locale }),
};

const { footerRows } = useLayout();
const classes = computed(() => {
    const classes = [];
    switch (footerRows.value) {
        case 1:
            classes.push('grid-rows-1');
            break;
        case 2:
            classes.push('grid-rows-2');
            break;
        case 3:
            classes.push('grid-rows-3');
            break;
        case 4:
            classes.push('grid-rows-4');
            break;
    }

    return classes;
});
</script>

<template>
    <footer class="fixed bottom-0 w-full grid grid-flow-col items-center p-0 z-0 bg-green-700 min-h-12" :class="classes">
        <ot-button class="block whitespace-nowrap" :href="link.chat_channel">{{ $t('general.chat') }}</ot-button>
        <ot-button class="block whitespace-nowrap" href="http://forum.onlinetennis.net" target="_blank">{{ $t('general.forum') }}</ot-button>
        <ot-button class="block whitespace-nowrap" :href="link.online">{{ $t('online.online_weristonline') }}</ot-button>
        <ot-button class="block whitespace-nowrap" @click="$router.push(link.friend_show)">{{ $t('general.friendlist') }}</ot-button>
        <ot-button class="block whitespace-nowrap" @click="$router.push(link.credits)">{{ $t('general.game_credits') }}</ot-button>
        <ot-notepad />
        <ot-button class="block whitespace-nowrap" :href="link.survey_list">{{ $t('survey.headline') }}</ot-button>
        <ot-button class="block whitespace-nowrap" :href="link.fanpages">{{ $t('general.fanpages') }}</ot-button>
        <ot-button class="block whitespace-nowrap" href="mailto:support@onlinetennis.net">{{ $t('general.supportEmail') }}</ot-button>
        <ot-button class="block whitespace-nowrap" href="https://www.facebook.com/onlinetennis" target="_blank"><ot-brand-icon brand="facebook" /></ot-button>
    </footer>
</template>
