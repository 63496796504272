<script>
import axios from 'axios';

import Button from './Button.vue';
import Flag from './Flag.vue';
import Surface from './Surface.vue';
import { OtTextField } from './text-field';

export default {
    components: {
        OtTextField,
        OtFlag: Flag,
        OtSurface: Surface,
        OtButton: Button,
    },
    props: {
        tournament: {
            required: true,
            type: Object,
        },
        type: {
            required: true,
            type: String,
        },
        season: {
            required: true,
            type: Number,
        },
        week: {
            required: true,
            type: Number,
        },
        showActions: {
            required: false,
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            changingStatus: false,
        };
    },
    computed: {
        mode() {
            return this.type === 'doubles' ? 'doubles' : 'singles';
        },
        linkTournament() {
            return Routing.generate('OnlinetennisBundle_profileTournament', { id: this.tournament.id, mode: this.mode, _locale: locale });
        },
    },
    methods: {
        signup() {
            this.changingStatus = true;
            const route = this.type === 'doubles' ? 'tournament_signup_doubles' : 'tournament_signup_singles';

            axios
                .post(Routing.generate(route, { id: this.tournament.id, target: 'calendar', season: this.season, week: this.week, _locale: locale }))
                .then(() => {
                    this.$emit('update');
                    this.changingStatus = false;
                });
        },
        signoff() {
            this.changingStatus = true;
            const route = this.type === 'doubles' ? 'tournament_signoff_doubles' : 'tournament_signoff_singles';

            axios
                .post(Routing.generate(route, { id: this.tournament.id, target: 'calendar', season: this.season, week: this.week, _locale: locale }))
                .then(() => {
                    this.$emit('update');
                    this.changingStatus = false;
                });
        },
    },
};
</script>

<template>
    <tr>
        <td align="center"><ot-surface :type="tournament.surface" /></td>
        <td align="right"><span :style="{ color: tournament.cut_position ? '#fc0204' : '#5e9811' }">{{ tournament.number_of_announcements }}</span></td>
        <td align="center">/</td>
        <td>{{ tournament.maximum_participants }}</td>
        <td><template v-if="tournament.cut_position">{{ tournament.cut_position }}</template></td>
        <td><span v-if="type === 'usertournament' && tournament.prize_money_type === 'DOLLAR'">$</span></td>
        <td><span v-if="type === 'usertournament' && tournament.prize_money_type === 'OTDOLLAR'"><img src="../images/misc/premium.png" :alt="`${tournament.starting_fee * 1000} OT Dollar`" :title="`${tournament.starting_fee * 1000} OT Dollar`"></span></td>
        <td class="flex gap-2 p-2">
            <img v-if="tournament.participates" src="../images/me.gif" alt="">
            <img v-if="tournament.has_participating_friends" src="../images/friends.gif" :alt="tournament.participating_friends" :title="tournament.participating_friends">
        </td>
        <td align="left" class="p-2">
            <ot-flag :id="tournament.country.id" />
            <a :href="linkTournament">{{ tournament.name }}</a>
        </td>
        <td align="right">
            <template v-if="showActions && !changingStatus">
                <template v-if="tournament.is_allowed_to_sign_up">
                    <ot-text-field v-if="tournament.has_password" id="password" outline name="password" :label="$t('settings.settings_password')" />
                    <ot-button @click="signup()">{{ $t('internalTournament.melden') }}</ot-button>
                </template>
                <ot-button v-if="tournament.is_open && tournament.participates" @click="signoff()">{{ $t('internalTournament.abmelden') }}</ot-button>
            </template>
        </td>
    </tr>
</template>
