import Country from './Country.js';
import Team from './Team.js';
import User from './User.js';

export default class Player {
    constructor() {
        this.name = {};
        this.money = {
            dollar: 0,
            otdollar: 0,
        };
        this.ranking = {
            entrylist: {},
            championsrace: {},
        };
        this.staff = {};
        this.skill = {};
        this.user = null;
        this.country = new Country();
        this.team = new Team();
    }

    static fromData(data) {
        const player = new Player();
        player.id = data.id;
        player.name = {
            firstname: data.name.firstname,
            lastname: data.name.lastname,
        };
        player.money = {
            dollar: data.money.dollar,
            otdollar: data.money.otdollar,
        };
        player.avatar = data.avatar;
        player.ranking = {
            entrylist: {
                position: data.ranking.entrylist.position,
                points: data.ranking.entrylist.points,
            },
            championsrace: {
                position: data.ranking.championsrace.position,
                points: data.ranking.championsrace.points,
            },
        };
        player.staff = {
            trainer: data.staff.trainer,
            teacher: data.staff.teacher,
            doctor: data.staff.doctor,
            physio: data.staff.physio,
            scout: data.staff.scout,
            tactican: data.staff.tactican,
            manager: data.staff.manager,
        };
        player.skill = {
            average: data.skill.average,
        };
        player.hasDoubles = data.has_doubles;

        if (data.user) {
            player.user = User.fromData(data.user.data);
        }

        if (data.country) {
            player.country = Country.fromData(data.country.data);
        }

        if (data.team) {
            player.team = Team.fromData(data.team.data);
        }

        return player;
    }

    getFullname() {
        return `${this.name.firstname} ${this.name.lastname}`;
    }
}
