<script setup lang="ts">
import { X } from 'lucide-vue-next';
import { ToastAction, ToastDescription, ToastPortal, ToastProvider, ToastRoot, ToastTitle, ToastViewport } from 'radix-vue';
import { useI18n } from 'vue-i18n';
import OtIconButton from '../IconButton.vue';

interface Props {
    modelValue: boolean;
    text: string;
    variant: 'success' | 'info';
    closable?: boolean;
}
interface Emits {
    (event: 'update:modelValue', value: boolean): void;
}

withDefaults(defineProps<Props>(), {
    closable: false,
});
const emits = defineEmits<Emits>();

const { t } = useI18n();
</script>

<template>
    <toast-provider>
        <toast-root
            :open="modelValue"
            class="bg-white rounded-sm shadow-lg data-[state=open]:animate-toast-slide-in data-[state=closed]:animate-toast-hide data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=cancel]:translate-x-0 data-[swipe=cancel]:transition-[transform_200ms_ease-out] data-[swipe=end]:animate-toast-swipe-out"
            @update:open="emits('update:modelValue', $event)"
        >
            <toast-title
                class="pl-4 h-8 text-white text-sm font-semibold rounded-t w-full flex items-center justify-between"
                :class="{
                    'bg-green-700': variant === 'success',
                    'bg-blue-700': variant === 'info',
                }"
            >
                <template v-if="variant === 'success'">{{ t('general.success') }}</template>
                <template v-if="variant === 'info'">{{ t('general.info') }}</template>
                <toast-action as-child alt-text="close" v-if="closable">
                    <ot-icon-button @click="emits('update:modelValue', false)">
                        <x />
                    </ot-icon-button>
                </toast-action>
            </toast-title>
            <toast-description class="p-4">{{ text }}</toast-description>
        </toast-root>
        <toast-portal>
            <toast-viewport class="[--viewport-padding:_25px] fixed bottom-0 right-0 flex flex-col p-[var(--viewport-padding)] gap-[10px] w-[390px] max-w-[100vw] m-0 list-none z-2147483647 outline-hidden" />
        </toast-portal>
    </toast-provider>
</template>
