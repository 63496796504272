<script>
export default {
    props: {
        value: {
            type: Number,
            required: true,
        },
        body: {
            type: Boolean,
            required: false,
        },
        face: {
            type: Boolean,
            required: false,
        },
        portrait: {
            type: Boolean,
            required: false,
        },
        icon: {
            type: Boolean,
            required: false,
        },
    },
    computed: {
        classes() {
            return {
                'rounded-full': this.type === 'icon',
            };
        },
        style() {
            return {
                width: this.width,
                height: this.height,
                backgroundImage: `url("/images/avatar/${this.type}/${this.value}.jpg")`,
            };
        },
        type() {
            if (this.body) {
                return 'body';
            }

            if (this.face) {
                return 'face';
            }

            if (this.portrait) {
                return 'portrait';
            }

            if (this.icon) {
                return 'icon';
            }

            throw new Error('You must specify at least one of the following props: body, face, portrait, icon');
        },
        width() {
            switch (this.type) {
                case 'body':
                    return '400px';

                case 'face':
                    return '100px';

                case 'portrait':
                    return '100px';

                case 'icon':
                    return '32px';
            }

            throw new Error('You must specify at least one of the following props: body, face, portrait, icon');
        },
        height() {
            switch (this.type) {
                case 'body':
                    return '600px';

                case 'face':
                    return '100px';

                case 'portrait':
                    return '150px';

                case 'icon':
                    return '32px';
            }

            throw new Error('You must specify at least one of the following props: body, face, portrait, icon');
        },
    },
    created() {
        const sizes = this.body ? 1 : 0 + this.face ? 1 : 0 + this.portrait ? 1 : 0 + this.icon ? 1 : 0;

        if (sizes === 0) {
            throw new Error('You must specify at least one of the following props: body, face, portrait, icon');
        }

        if (sizes > 1) {
            throw new Error('You can only specify one of the following props: body, face, portrait, icon');
        }
    },
};
</script>

<template>
    <div :class="classes" :style="style" />
</template>
