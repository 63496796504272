import PlayerName from '../player/PlayerName';
import SuggestionComment from './SuggestionComment';

export default class SuggestionGetResponseDto {
    private readonly id: string;
    private readonly titleOriginal: string;
    private readonly titleTranslated: string;
    private readonly contentOriginal: string;
    private readonly contentTranslated: string;
    private readonly votes: number;
    private readonly voted: boolean;
    private readonly authorName: PlayerName;
    private readonly voterNames: PlayerName[];
    private readonly comments: SuggestionComment[];

    public constructor(data: any) {
        this.id = data.id;
        this.authorName = new PlayerName(data.author_name.data.first_name, data.author_name.data.last_name);
        this.titleOriginal = data.title_original;
        this.titleTranslated = data.title_translated;
        this.contentOriginal = data.content_original;
        this.contentTranslated = data.content_translated;
        this.votes = data.votes;
        this.voted = data.voted;
        this.voterNames = data.voter_names.data.map(name => new PlayerName(name.first_name, name.last_name));
        this.comments = data.comments.data.map(suggestionCommentData => SuggestionComment.fromData(suggestionCommentData));
    }

    public getId(): string {
        return this.id;
    }

    public getTitle(): string {
        return this.titleTranslated;
    }

    public getContent(): string {
        return this.contentTranslated;
    }

    public getVotes(): number {
        return this.votes;
    }

    public isVoted(): boolean {
        return this.voted;
    }

    public getAuthorName(): PlayerName {
        return this.authorName;
    }

    public getVoterNames(): PlayerName[] {
        return this.voterNames;
    }

    public getComments(): SuggestionComment[] {
        return this.comments;
    }
}
