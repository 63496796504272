<script>
import axios from 'axios';
import Button from '../components/Button.vue';
import Card from '../components/Card.vue';
import Divider from '../components/Divider.vue';
import Header from '../components/Header.vue';
import Loader from '../components/Loader.vue';
import { OtTextField } from '../components/text-field';
import { OtTextarea } from '../components/textarea';
import MessageRepository from '../domain/message/MessageRepository';
import MessageCreateDto from '../dto/request/MessageCreateDto';
import PlayerGetResponseDto from '../dto/response/PlayerGetResponseDto';

export default {
    components: {
        OtCard: Card,
        OtLoader: Loader,
        OtButton: Button,
        OtDivider: Divider,
        OtHeader: Header,
        OtTextarea,
        OtTextField,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        originalMessageId: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            originalMessageIsLoading: false,
            receiver: null,
            title: '',
            content: '',
            sending: false,
        };
    },
    computed: {
        errorsTitle() {
            return this.title === '';
        },
        errorsContent() {
            return this.content === '';
        },
        hasErrors() {
            return this.errorsTitle || this.errorsContent;
        },
        isBanned() {
            return this.$store.getters.userIsBannedForChat;
        },
        banExpireDate() {
            return this.$store.getters.userChatBanExpireDate;
        },
    },
    created() {
        this.originalMessageIsLoading = this.originalMessageId !== null;
        axios
            .get(`/api/players/${this.id}`)
            .then(response => this.receiver = PlayerGetResponseDto.fromData(response.data.data));

        if (this.originalMessageId !== null) {
            new MessageRepository()
                .find(this.originalMessageId)
                .then((message) => {
                    this.title = `Re: ${message.getTitle()}`;
                    this.content = `\n\n---\n${message.getContent()}`;
                    this.originalMessageIsLoading = false;
                });
        }
    },
    methods: {
        send() {
            if (this.hasErrors) {
                return;
            }

            this.sending = true;

            new MessageRepository()
                .add(new MessageCreateDto(Number.parseInt(this.id), this.title, this.content))
                .then(() => {
                    window.emitter.emit('info', this.$t('nachrichten.nachrichten_erfolgreichversendet'));
                    this.$router.push('/messages/inbox');
                })
            ;
        },
    },
};
</script>

<template>
    <ot-card>
        <ot-loader standalone :loading="receiver === null || originalMessageIsLoading">
            <template v-if="receiver !== null">
                <ot-header :title="`${$t('nachrichten.nachrichten_empfaenger')}: ${receiver.getName().getFullName()}`" />
                <template v-if="!isBanned">
                    <div class="m-4">
                        <ot-text-field
                            v-model="title"
                            :label="$t('nachrichten.nachrichten_betreff')"
                        />
                        <ot-textarea
                            v-model="content"
                            :label="$t('nachrichten.nachrichten_message')"
                        />
                    </div>
                    <ot-divider />
                    <div class="m-4 flex justify-end">
                        <ot-button
                            submit
                            primary
                            :disabled="hasErrors || sending"
                            @click="send()"
                        >
                            {{ $t('nachrichten.nachrichten_abschicken') }}
                        </ot-button>
                    </div>
                </template>
                <p v-if="isBanned" class="m-4">{{ $t('general.bannedUntil', { param1: banExpireDate.toLocaleString() }) }}</p>
            </template>
        </ot-loader>
    </ot-card>
</template>
