<script>
import axios from 'axios';
import { Info, X } from 'lucide-vue-next';
import Button from './Button.vue';

export default {
    components: {
        OtButton: Button,
        Info,
        X,
    },
    data() {
        return {
            show: false,
        };
    },
    created() {
        if (!('serviceWorker' in navigator)) {
            // browser does not support service workers
            return;
        }

        if (!('PushManager' in window)) {
            // browser does not support push
            return;
        }

        const askAgain = JSON.parse(window.localStorage.getItem('onlinetennis.push_denied.ask_again')) + 0;
        if (askAgain > Date.now()) {
            // closed request in the near past
            return;
        }

        this.getNotificationPermissionState()
            .then((state) => {
                if (state === 'granted') {
                    return;
                }
                if (state === 'denied') {
                    return;
                }

                this.show = true;
            });
    },
    methods: {
        enable() {
            this.show = false;
            window.emitter.emit('show_overlay');
            Notification.requestPermission().then((result) => {
                window.emitter.emit('hide_overlay');
                if (result === 'granted') {
                    this.createSubscription();
                    navigator.serviceWorker.getRegistration()
                        .then((registration) => {
                            registration.showNotification(this.$t('general.push_notification_start_title'), {
                                body: this.$t('general.push_notification_start_message'),
                                icon: 'https://www.onlinetennis.net/images/push_notification_icon.png',
                                data: {
                                    target: '/',
                                },
                            });
                        });
                }
            });
        },
        deny() {
            const count = JSON.parse(window.localStorage.getItem('onlinetennis.push_denied.count')) + 0;

            window.localStorage.setItem('onlinetennis.push_denied.ask_again', JSON.stringify(Date.now() + this.fibonacci(count) * 24 * 60 * 60 * 1000));
            window.localStorage.setItem('onlinetennis.push_denied.count', JSON.stringify(count));

            this.show = false;
        },
        getNotificationPermissionState() {
            if (navigator.permissions) {
                return navigator.permissions.query({ name: 'notifications' }).then(result => result.state);
            }

            return new Promise(resolve => resolve(Notification.permission));
        },
        createSubscription() {
            navigator.serviceWorker.getRegistration().then((registration) => {
                const subscribeOptions = {
                    userVisibleOnly: true,
                    applicationServerKey: this.urlBase64ToUint8Array('BOGG4rbdha87HEZonyjkJv3iu4FWwvwxpLlcW2KtjA2SGLr31esKTr8FxKfhYvYxAqlZpz-Wb-RIOZdIF8G07lE'),
                };

                return registration.pushManager.subscribe(subscribeOptions)
                    .then((pushSubscription) => {
                        return axios.post('/api/push-subscriptions', { subscription: pushSubscription });
                    });
            });
        },
        urlBase64ToUint8Array(base64String) {
            const padding = '='.repeat((4 - base64String.length % 4) % 4);
            const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

            const rawData = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);

            for (let i = 0; i < rawData.length; i++) {
                outputArray[i] = rawData.charCodeAt(i);
            }

            return outputArray;
        },
        fibonacci(count) {
            const index = count - 1;

            if (index <= 1) {
                return 1;
            }

            return this.fibonacci(index - 1) + this.fibonacci(index - 2);
        },
    },
};
</script>

<template>
    <div v-show="show" class="rounded-sm bg-blue-500 text-white m-4 mb-0 p-4 flex items-center shrink overflow-hidden z-0">
        <info />
        <div class="flex items-center grow">
            <div class="mx-4">{{ $t('general.push_notification_enable_text') }}</div>
            <ot-button variant="info" @click="enable()">{{ $t('general.push_notification_enable_button') }}</ot-button>
        </div>
        <x @click="deny()" />
    </div>
</template>

<style scoped>
a {
    text-decoration: underline;
}
</style>
