<script setup lang="ts">
import data from 'emoji-mart-vue-fast/data/twitter.json';
import { EmojiIndex, Picker } from 'emoji-mart-vue-fast/src';
import 'emoji-mart-vue-fast/css/emoji-mart.css';

interface Emits {
    select: (emoji: string) => void;
}
const emits = defineEmits<Emits>();

const emojiIndex = new EmojiIndex(data);
const style = {
    width: '100%',
    height: '300px',
    fontFamily: 'inherit',
    color: '#ffffff',
    background: 'transparent',
    border: 'none',
};
</script>

<template>
    <picker
        :data="emojiIndex"
        set="twitter"
        :show-preview="false"
        :show-search="false"
        :style="style"
        color="#388e3c"
        @select="emits('select', $event)"
    />
</template>

<style>
.row {
    display: flex;
}

.row > * {
    margin: auto;
}

.emoji-mart-category-label {
    background-color: #388e3c !important;
}
</style>
