<script setup lang="ts">
import axios from 'axios';
import emojione from 'emojione';
import { Info, SendHorizonal, Smile, X } from 'lucide-vue-next';
import { computed, nextTick, onUpdated, ref, watch } from 'vue';
import Country from '../domain/country/Country';
import PlayerName from '../domain/player/PlayerName';
import Channel from '../dto/Channel.js';
import ChannelMessageListGetResponse from '../dto/response/ChannelMessageListGetResponse';
import OtChatMessage from './ChatMessage.vue';
import OtEmojiPicker from './EmojiPicker.vue';
import OtIconButton from './IconButton.vue';
import { OtSelect, OtSelectContent, OtSelectGroup, OtSelectItem, OtSelectTrigger, OtSelectValue } from './select';
import { OtTextarea } from './textarea';

declare global {
    interface Window {
        emitter: any;
    }
}

interface Props {
    channel: number;
    locked?: boolean;
}
interface Emits {
    changeChannel: (channel: number) => void;
}

const props = withDefaults(defineProps<Props>(), {
    locked: false,
});
const emits = defineEmits<Emits>();

const channels = ref<Channel[]>([]);
const isBanned = ref(false);
const bannedMessage = ref('');
const message = ref('');
const showEmojiPicker = ref(false);
const messages = ref<ChannelMessageListGetResponse[]>([]);

emojione.imageTitleTag = false;
emojione.ascii = true;

const contentContainer = ref(null);
const parsedMessage = computed(() => emojione.shortnameToUnicode(message.value));

watch(() => props.channel, () => {
    messages.value = [];
});

if (!props.locked) {
    refreshChannels();
}
loadMessages();

window.emitter.on('chat-message', (message) => {
    if (message.channel_id !== props.channel) {
        return;
    }
    messages.value.push(new ChannelMessageListGetResponse(
        message.id,
        message.content,
        new Date(message.date),
        message.author_id,
        PlayerName.fromData(message.author_name),
        Country.fromData(message.author_country),
        message.author_is_online,
        message.author_is_mobile,
    ));
});
onUpdated(() => {
    scrollToBottom();
});

function channelName(channel: Channel) {
    if (!channel.name) {
        return '';
    }

    return `[${channel.numberOfVisitors}] ${channel.name}`;
}

function showChannelInfo() {
    window.location.href = Routing.generate('chat_channel_details', { _locale: locale, id: props.channel });
}

function postChatMessage() {
    if (message.value === '') {
        return;
    }

    axios.post(`/api/channels/${props.channel}/messages`, { message: emojione.toShort(message.value) });
    message.value = '';
}

function loadMessages() {
    axios
        .get(`/api/channels/${props.channel}/messages`)
        .then((response) => {
            if (response.data && response.data.data) {
                messages.value = response.data.data.map(data => ChannelMessageListGetResponse.fromData(data));
            }
        });
}

async function refreshChannels() {
    const response = await axios.get('/api/channels');
    channels.value = response.data.data.map(data => Channel.fromData(data));
    setTimeout(refreshChannels, 30000);
}

function scrollToBottom() {
    contentContainer.value.scrollTop = contentContainer.value.scrollHeight;
}

function connected(index, authorId) {
    if (!messages.value[index - 1]) {
        return false;
    }

    return authorId === messages.value[index - 1].getAuthorId();
}

function showDate(index, date) {
    if (!messages.value[index - 1]) {
        return true;
    }

    return date.toDateString() !== messages.value[index - 1].getDate().toDateString();
}

async function changeChannel(channel) {
    emits('changeChannel', channel);
    await nextTick();
    loadMessages();
}
</script>

<template>
    <div class="flex flex-col h-full divide-y divide-gray-500">
        <div
            v-if="!locked"
            class="p-2 flex gap-2"
        >
            <ot-select
                :model-value="channel.toString()"
                @update:model-value="changeChannel(parseInt($event))"
            >
                <ot-select-trigger>
                    <ot-select-value />
                </ot-select-trigger>
                <ot-select-content>
                    <ot-select-group>
                        <ot-select-item
                            v-for="channel in channels"
                            :key="channel.id"
                            :value="channel.id.toString()"
                        >
                            {{ channelName(channel) }}
                        </ot-select-item>
                    </ot-select-group>
                </ot-select-content>
            </ot-select>
            <ot-icon-button @click="showChannelInfo()">
                <info />
            </ot-icon-button>
        </div>
        <div class="grow" style="overflow-y: auto" ref="contentContainer">
            <div>
                <ot-chat-message
                    v-for="(message, index) in messages"
                    :key="message.getId()"
                    :message="message"
                    :connected="connected(index, message.getAuthorId())"
                    :show-date="showDate(index, message.getDate())"
                />
            </div>
        </div>
        <p v-if="isBanned">{{ bannedMessage }}</p>
        <div v-else>
            <ot-emoji-picker v-show="showEmojiPicker" @select="message += $event.native" />
            <div class="flex p-2 items-center gap-2">
                <ot-icon-button @click="showEmojiPicker = !showEmojiPicker">
                    <x v-if="showEmojiPicker" />
                    <smile v-if="!showEmojiPicker" />
                </ot-icon-button>
                <ot-textarea
                    v-if="!isBanned"
                    :model-value="parsedMessage"
                    :label="$t('general.message')"
                    auto-grow
                    @keydown.prevent.enter="postChatMessage"
                    @update:model-value="message = $event"
                />
                <ot-icon-button @click="postChatMessage">
                    <send-horizonal />
                </ot-icon-button>
            </div>
        </div>
    </div>
</template>
