export default class Country {
    public static fromData(data: any): Country {
        return new Country(data.id, data.tag);
    }

    private readonly id: string;
    private readonly tag: string;

    public constructor(id: string, tag: string) {
        this.id = id;
        this.tag = tag;
    }

    public getId(): string {
        return this.id;
    }

    public getTag(): string {
        return this.tag;
    }
}
