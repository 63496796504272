<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
    },
};
</script>

<template>
    <div>
        <div class="bg-green-700 text-xl flex justify-space-between p-4">
            <div class="text-white">{{ title }}</div>
            <slot />
        </div>
    </div>
</template>

<style scoped>

</style>
