import Player from './Player.js';

export default class User {
    constructor() {
        this.premium = {};
        this.chat = {
            ban: {},
        };
        this.player = new Player();
        this.ignoredUsers = [];
        this.friends = [];
    }

    static fromData(data) {
        const user = new User();
        user.id = data.id;
        user.username = data.username;
        user.isAdmin = data.is_admin;
        user.isOnline = data.is_online;
        user.premium = {
            isActive: data.premium.is_active,
            expireDate: new Date(data.premium.expire_date),
        };
        user.chat = {
            ban: {
                isActive: data.chat.ban.is_active,
                expireDate: data.chat.ban.expire_date,
            },
            channel: data.chat.channel,
        };

        if (data.player) {
            user.player = Player.fromData(data.player.data);
        }

        if (data.ignored_users) {
            user.ignoredUsers = data.ignored_users.data.map(data => User.fromData(data));
        }

        if (data.friends) {
            user.friends = data.friends.data.map(data => User.fromData(data));
        }

        return user;
    }

    isPremium() {
        if (!this.premium.isActive) {
            return false;
        }

        return this.premium.isActive;
    }
}
