export default class Notification {
    public static info(message: string, params: object = {}): Notification {
        return new Notification('info', message, params);
    }

    public static success(message: string, params: object = {}): Notification {
        return new Notification('success', message, params);
    }

    public constructor(
        readonly type: 'info' | 'success',
        readonly message: string,
        readonly params: object = {},
    ) {
    }
}
