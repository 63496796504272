<script setup lang="ts">
import { Eye } from 'lucide-vue-next';
import { useI18n } from 'vue-i18n';
import { OtTooltip, OtTooltipContent, OtTooltipTrigger } from './tooltip';

interface Props {
    value: string;
    link?: string;
    spectators?: number;
    vertical?: boolean;
}
defineProps<Props>();

const { t } = useI18n();
</script>

<template>
    <div class="flex flex-wrap justify-space-between p-2" :class="{ 'flex-col': vertical }">
        <div class="flex w-full" :class="{ 'lg:w-4/12': !vertical }">
            <slot name="participant-one" />
        </div>
        <div class="flex w-full" :class="{ 'lg:w-1/12': !vertical }">
            <div class="flex items-center" :class="{ 'lg:justify-center': !vertical }">
                <div class="text-xs">VS</div>
            </div>
        </div>
        <div class="flex w-full" :class="{ 'lg:w-4/12': !vertical }">
            <slot name="participant-two" />
        </div>
        <div class="flex w-full text-sm items-center gap-2" :class="{ 'lg:w-3/12': !vertical }">
            <ot-tooltip v-if="spectators" v-show="spectators > 0">
                <ot-tooltip-trigger>
                    <eye />
                </ot-tooltip-trigger>
                <ot-tooltip-content side="bottom">{{ `${spectators} ${t('internalRanking.spectators')}` }}</ot-tooltip-content>
            </ot-tooltip>
            <a v-if="link" :href="link">{{ value }}</a>
            <span v-else>{{ value }}</span>
        </div>
    </div>
</template>
