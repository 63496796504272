<script>
import SuggestionRepository from '../../domain/suggestion/SuggestionRepository';
import DataProvider from './DataProvider.vue';

export default {
    mixins: [DataProvider],
    methods: {
        getRepository() {
            return new SuggestionRepository();
        },
    },
};
</script>
