<script setup lang="ts">
import axios from 'axios';
import { BriefcaseMedical, Mail } from 'lucide-vue-next';
import { onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import OtButton from '../components/Button.vue';
import OtCard from '../components/Card.vue';
import OtFlag from '../components/Flag.vue';
import OtIconButton from '../components/IconButton.vue';
import OtInputGroup from '../components/InputGroup.vue';
import OtLoader from '../components/Loader.vue';
import OtPlayerProfileTabs from '../components/PlayerProfileTabs.vue';

interface Props {
    id: string;
}
const props = defineProps<Props>();

const loading = ref(true);
const user = ref(null);
const player = ref(null);
const isGrantedUser = ref(false);
const isGrantedMod = ref(false);
const isFriend = ref(false);
const isIgnored = ref(false);

onMounted(() => load());

async function load() {
    const { data } = await axios.get(`/api/player-profiles/${props.id}`);
    user.value = data.data.user;
    player.value = data.data.player;
    isGrantedUser.value = data.data.is_granted_user;
    isGrantedMod.value = data.data.is_granted_mod;
    isFriend.value = data.data.is_friend;
    isIgnored.value = data.data.is_ignored;
    loading.value = false;
}

async function addFriend() {
    await axios.post('/api/players/me/friends', { playerId: Number.parseInt(props.id) });
    await load();
}

async function removeFriend() {
    await axios.delete(`/api/players/me/friends/${props.id}`);
    await load();
}

async function toggleIgnoreUser() {
    await axios.put(Routing.generate('toggle_ignore_user', { _locale: locale, id: Number.parseInt(props.id) }));
    await load();
}

const router = useRouter();
watch(router.currentRoute, () => {
    loading.value = true;
    load();
});
</script>

<template>
    <ot-loader :loading="loading">
        <ot-card>
            <h3 class="p-4">
                <ot-flag :id="player.country.id" />
                {{ player.first_name }} {{ player.last_name }}
                <template v-if="user.is_premium">*</template>
                <span v-if="user.is_moderator" class="mod">(mod)</span>
            </h3>
            <div class="p-4 pt-0">
                <ot-input-group class="flex gap-2 mb-2">
                    <div v-if="user.is_online" class="w-5 h-5 mt-2 rounded-full bg-green-700" :title="$t('general.userOnline')" />
                    <div v-if="!user.is_online" class="w-5 h-5 mt-2 rounded-full bg-red-700" :title="$t('profile.lastOnline', { param1: user.last_action })" />
                    <ot-icon-button v-if="isGrantedUser" :href="`#/message-create/${user.id}`"><mail /></ot-icon-button>
                    <briefcase-medical v-if="player.is_injured" class="text-red-700 mt-1.5" />
                </ot-input-group>
                <div v-if="isGrantedUser" class="flex gap-2">
                    <ot-button
                        v-show="isFriend"
                        primary
                        @click="removeFriend(user.id)"
                    >
                        {{ $t('profil.profil_remove_friend') }}
                    </ot-button>
                    <ot-button
                        v-show="!isFriend"
                        primary
                        @click="addFriend(user.id)"
                    >
                        {{ $t('profil.profil_add_friend') }}
                    </ot-button>
                    <ot-button
                        primary
                        @click="toggleIgnoreUser(user.id)"
                    >
                        <template v-if="isIgnored">{{ $t('profile.profile_unignore_user') }}</template>
                        <template v-else>{{ $t('profile.profile_ignore_user') }}</template>
                    </ot-button>
                </div>
            </div>

            <ot-player-profile-tabs
                :role-user="isGrantedUser"
                :role-mod="isGrantedMod"
                :user-id="user.id"
            />
        </ot-card>
    </ot-loader>
</template>
