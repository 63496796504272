<script setup lang="ts">
import type { Ref } from 'vue';
import axios from 'axios';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { OtAccordion, OtAccordionContent, OtAccordionItem, OtAccordionTrigger } from '../../components/accordion';
import OtFlag from '../../components/Flag.vue';
import OtLoader from '../../components/Loader.vue';
import OtSubheader from '../../components/Subheader.vue';

interface Props {
    id: number;
}
const props = defineProps<Props>();

const loading = ref(true);

interface Country {
    id: number;
    tag: string;
}
interface Tournament {
    id: number;
    name: string;
    is_national_championship: boolean;
    season: number;
    country: Country;
}

const isSpeed = ref(false);
const weeks = computed(() => (isSpeed.value ? 31 : 38));
const lastSeasonsPoints = ref([]);
const teamTitles = ref([]);
const davisCupTeamTitles = ref([]);
const wonTournaments: Ref<Record<string, Tournament[]>> = ref({});
const seasonsEntrylist = ref([]);
const seasonsChampionsrace = ref([]);

const link_entrylist_chart = ref(Routing.generate('OnlinetennisBundle_entrylistChart', { id: props.id }));
const link_surface_chart = ref(Routing.generate('OnlinetennisBundle_surfaceChart', { id: props.id }));

const { t } = useI18n();

onMounted(() => load());

async function load() {
    const { data } = await axios.get(`/api/player-profiles/${props.id}/trophies`);

    isSpeed.value = data.data.is_speed;
    lastSeasonsPoints.value = data.data.last_seasons_points;
    teamTitles.value = data.data.team_titles;
    davisCupTeamTitles.value = data.data.davis_cup_team_titles;
    wonTournaments.value = data.data.won_tournaments;
    seasonsEntrylist.value = data.data.seasons_entrylist;
    seasonsChampionsrace.value = data.data.seasons_championsrace;

    loading.value = false;
}

function getTournamentLink(id: number) {
    return Routing.generate('OnlinetennisBundle_profileTournament', { _locale: locale, id });
}

function getTournamentName(tournament: Tournament) {
    return tournament.is_national_championship ? t(`country.country_${tournament.country.id}`) : tournament.name;
}

function getTeamLogoLink(id: number) {
    return Routing.generate('OnlinetennisBundle_teamLogo', { id, size: 16 });
}
</script>

<template>
    <ot-loader :loading="loading" standalone>
        <div class="flex flex-col gap-5">
            <div>
                <ot-subheader>{{ t('profil.profil_points_per_week') }}</ot-subheader>
                <p class="text-xs mb-2">{{ t('profile_user.best18tournaments') }}</p>
                <div class="flex flex-wrap gap-1 mb-1">
                    <div
                        v-for="i in weeks"
                        :key="i"
                        class="border border-gray-700 p-1 grow min-w-10"
                    >
                        <div class="text-center text-black/50 text-sm">{{ i }}</div>
                        <div
                            class="text-center"
                            :class="{ 'bg-green-700': lastSeasonsPoints[i].counted }"
                        >
                            <a
                                v-if="lastSeasonsPoints[i].tournament !== null"
                                :href="getTournamentLink(lastSeasonsPoints[i].tournament.id)"
                                :title="lastSeasonsPoints[i].tournament._name"
                            >
                                {{ lastSeasonsPoints[i].points }}
                            </a>
                            <template v-else>{{ lastSeasonsPoints[i].points }}</template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex justify-around">
                <div>
                    <ot-subheader>{{ t('profil.profil_weltranglistenverlauf') }}</ot-subheader>
                    <img :src="link_entrylist_chart" alt="" width="395px">
                </div>
                <div>
                    <ot-subheader>{{ t('profil.profil_erfolgsquotebelag') }}</ot-subheader>
                    <img :src="link_surface_chart" alt="" width="180px">
                </div>
            </div>
            <div class="flex justify-around gap-5">
                <div class="grow">
                    <ot-subheader>{{ t('profile_user.tab_trophies') }}</ot-subheader>
                    <ot-accordion type="multiple" class="w-full">
                        <ot-accordion-item
                            v-if="teamTitles.length > 0"
                            value="team"
                        >
                            <ot-accordion-trigger class="flex justify-between items-center">
                                <span>{{ t('internalOTCup.headline') }}</span>
                                <span class="text-xs">({{ teamTitles.length }})</span>
                            </ot-accordion-trigger>
                            <ot-accordion-content
                                v-for="(title, index) in teamTitles"
                                :key="index"
                            >
                                <img :src="getTeamLogoLink(title.team.id)" :alt="title.team.name" class="inline" />
                                &nbsp;
                                {{ t(`internalTeam.${title.translation_key}`, { param1: title.team.name, param2: title.param1, param3: title.param2 }) }} '{{ title.season }}
                            </ot-accordion-content>
                        </ot-accordion-item>
                        <ot-accordion-item
                            v-if="davisCupTeamTitles.length > 0"
                            value="daviscup"
                        >
                            <ot-accordion-trigger class="flex justify-between items-center">
                                <span>{{ t('general.daviscup') }}</span>
                                <span class="text-xs">({{ davisCupTeamTitles.length }})</span>
                            </ot-accordion-trigger>
                            <ot-accordion-content
                                v-for="(title, index) in davisCupTeamTitles"
                                :key="index"
                            >
                                <ot-flag :id="title.country.id" />
                                &nbsp;
                                {{ t(`general.${title.translation_key}`, { param1: `${t(`country.country_${title.country.id}`)} ${title.team_level}`, param2: title.param }) }} '{{ title.season }}
                            </ot-accordion-content>
                        </ot-accordion-item>
                        <ot-accordion-item
                            v-for="(tournaments, category) in wonTournaments"
                            :key="category"
                            :value="category"
                        >
                            <ot-accordion-trigger class="flex justify-between items-center">
                                <span>{{ category }}</span>
                                <span class="text-xs">({{ tournaments.length }})</span>
                            </ot-accordion-trigger>
                            <ot-accordion-content
                                v-for="tournament in tournaments"
                                :key="tournament.id"
                            >
                                <ot-flag :id="tournament.country.id" />
                                &nbsp;
                                <a :href="getTournamentLink(tournament.id)">{{ getTournamentName(tournament) }} '{{ tournament.season }}</a>
                            </ot-accordion-content>
                        </ot-accordion-item>
                    </ot-accordion>
                </div>
                <div class="grow">
                    <ot-subheader>{{ t('profil.profil_seasonStandings') }}</ot-subheader>
                    <p class="font-medium">{{ t('profil.profil_el') }}</p>
                    <ul>
                        <li v-for="season in seasonsEntrylist" :key="season">{{ season }}</li>
                    </ul>
                    <p class="font-medium">{{ t('profil.profil_cr') }}</p>
                    <ul>
                        <li v-for="season in seasonsChampionsrace" :key="season">{{ season }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </ot-loader>
</template>
