<script>
import Card from '../components/Card.vue';
import Chip from '../components/Chip.vue';
import MessageDataProvider from '../components/data-provider/MessageDataProvider.vue';
import { OtTooltip, OtTooltipContent, OtTooltipTrigger } from '../components/tooltip';

export default {
    components: {
        OtMessageDataProvider: MessageDataProvider,
        OtCard: Card,
        OtChip: Chip,
        OtTooltip,
        OtTooltipContent,
        OtTooltipTrigger,
    },
    computed: {
        isPremium() {
            return this.$store.getters.user.isPremium();
        },
    },
};
</script>

<template>
    <ot-card>
        <ot-message-data-provider v-if="isPremium" method="findAll" :params="{ type: 'outbox' }" collection v-slot:="{ data: messages }">
            <ul style="padding-left: 0!important;" class="divide-y divide-gray-400">
                <li v-for="message in messages" :key="message.getId()">
                    <router-link :to="`/message/${message.getId()}`" class="flex justify-between items-center p-4 hover:bg-gray-400">
                        <div>
                            <div>
                                <ot-tooltip>
                                    <ot-tooltip-trigger>{{ message.getTitle() }}</ot-tooltip-trigger>
                                    <ot-tooltip-content side="bottom" class="whitespace-pre-wrap">{{ message.getContent() }}</ot-tooltip-content>
                                </ot-tooltip>
                                <ot-chip v-if="message.getStatus() === 'new'" type="red" small class="ml-1">{{ $t('nachrichten.unread') }}</ot-chip>
                            </div>
                            <div class="text-xs">
                                <span>{{ message.getFormattedDate() }}</span>
                                &mdash;
                                <a :href="`#/player/${message.getReceiverId()}`">{{ message.getReceiverName().getFullName() }}</a>
                            </div>
                        </div>
                    </router-link>
                </li>
            </ul>
        </ot-message-data-provider>
        <p v-if="!isPremium" class="m-4">{{ $t('nachrichten.premiumNeeded') }}</p>
    </ot-card>
</template>
