<script>
import axios from 'axios';
import emojione from 'emojione';
import { Flag as FlagIcon, Languages, Smartphone, Trash2 } from 'lucide-vue-next';
import ChannelMessageListGetResponse from '../dto/response/ChannelMessageListGetResponse';
import Button from './Button.vue';
import { OtDialog, OtDialogBody, OtDialogClose, OtDialogContent, OtDialogFooter, OtDialogHeader } from './dialog';
import Flag from './Flag.vue';
import IconButton from './IconButton.vue';
import { OtTextarea } from './textarea';
import { OtTooltip, OtTooltipContent, OtTooltipTrigger } from './tooltip';

emojione.imageTitleTag = false;
emojione.ascii = true;

export default {
    components: {
        OtDialog,
        OtDialogClose,
        OtDialogBody,
        OtDialogContent,
        OtDialogFooter,
        OtDialogHeader,
        OtFlag: Flag,
        OtButton: Button,
        OtIconButton: IconButton,
        OtTooltip,
        OtTooltipContent,
        OtTooltipTrigger,
        OtTextarea,
        FlagIcon,
        Languages,
        Smartphone,
        Trash2,
    },
    props: {
        message: {
            required: true,
            type: ChannelMessageListGetResponse,
        },
        connected: {
            required: true,
            type: Boolean,
        },
        showDate: {
            required: true,
            type: Boolean,
        },
    },
    data() {
        return {
            showReportDialog: false,
            reportComment: '',
            translated: false,
            translation: null,
            isHovering: false,
        };
    },
    computed: {
        user() {
            return this.$store.getters.user;
        },
        time() {
            return this.message.getDate().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        },
        date() {
            return this.message.getDate().toLocaleDateString();
        },
        authorIsIgnored() {
            for (const user in this.user.ignoredUsers) {
                if (this.user.ignoredUsers.hasOwnProperty(user)) {
                    if (user.id === this.message.getAuthorId()) {
                        return true;
                    }
                }
            }

            return false;
        },
        content() {
            if (this.translated) {
                return this.translation;
            }

            return this.message.getContent();
        },
    },
    methods: {
        reportChatMessage() {
            axios.post(`/api/chat-messages/${this.message.getId()}/reports`, { comment: this.reportComment });
            this.showReportDialog = false;
            this.reportComment = '';
        },
        deleteMessage() {
            axios.delete(`/api/chat-messages/${this.message.getId()}`);
        },
        async translateMessage() {
            if (this.translated) {
                this.translation = null;
            }
            else {
                const { data } = await axios.get(`/api/chat-messages/${this.message.getId()}/translations/${locale}`);
                this.translation = data.data.translation;
            }
            this.translated = !this.translated;
        },
        emoji(string) {
            return emojione.shortnameToUnicode(string);
        },
    },
};
</script>

<template>
    <div class="ml-2 first-of-type:mt-0 last-of-type:mb-2">
        <div v-if="showDate" class="mt-2 text-xs text-center">{{ date }}</div>
        <div
            class="flex text-sm"
            :class="[connected && !showDate ? 'mt-0' : 'mt-2']"
        >
            <div
                class="bg-gray-300 grow border mr-2 relative"
                :class="{
                    'border-gray-100': user.id !== message.getAuthorId(),
                    'border-r-8': user.id !== message.getAuthorId(),
                    'border-l-1': user.id !== message.getAuthorId(),

                    'border-green-700': user.id === message.getAuthorId(),
                    'border-l-8': user.id === message.getAuthorId(),
                    'border-r-1': user.id === message.getAuthorId(),
                    'order-1': user.id === message.getAuthorId(),

                    'border-t-0': connected && !showDate,
                }"
                @mouseover="isHovering = true"
                @mouseleave="isHovering = false"
            >
                <div class="p-2 text-gray-800">
                    <div v-if="isHovering" class="absolute right-0 -top-3 flex gap-1 p-1 bg-gray-200 rounded-sm">
                        <ot-icon-button v-if="user.isAdmin" @click="showReportDialog = true"><flag-icon /></ot-icon-button>
                        <ot-icon-button v-if="user.isAdmin" @click="deleteMessage()"><trash-2 /></ot-icon-button>
                        <ot-icon-button @click="translateMessage()"><languages /></ot-icon-button>
                    </div>
                    <span v-if="authorIsIgnored" style="font-size: 10px; font-style: italic">
                        {{ $t('general.chat_hidden') }}
                        <a href="#" style="font-size: 10px; text-decoration: underline" onclick="$(this).parent().hide().next().show()">({{ $t('general.chat_show') }})</a>
                    </span>
                    <div v-if="!authorIsIgnored">
                        <div v-if="!connected || showDate" class="font-bold">
                            <ot-flag :id="message.getAuthorCountry().getId()" />
                            <a :href="`#/player/${message.getAuthorId()}`" class="user_link ml-1">{{ message.getAuthorName().getFullName() }}</a>
                            <ot-tooltip v-if="message.isAuthorOnline()">
                                <ot-tooltip-trigger class="ml-1">
                                    <smartphone v-if="message.isAuthorMobile()" class="text-green-700 inline" />
                                    <div v-else class="w-3 h-3 bg-green-700 rounded-full" />
                                </ot-tooltip-trigger>
                                <ot-tooltip-content side="bottom">{{ $t('general.userOnline') }}</ot-tooltip-content>
                            </ot-tooltip>
                        </div>
                        <div v-if="!connected" class="text-xs">{{ date }} {{ time }}</div>
                        <div class="text-sm" v-html="emoji(content)" />
                    </div>
                </div>
                <ot-dialog v-if="user.isAdmin" v-model:open="showReportDialog">
                    <ot-dialog-content>
                        <ot-dialog-header>Report</ot-dialog-header>
                        <ot-dialog-body>
                            <input type="hidden" name="id" :value="message.getId()">
                            <ot-textarea v-model="reportComment" :label="$t('nachrichten.nachrichten_message')" name="comment" />
                        </ot-dialog-body>
                        <ot-dialog-footer>
                            <ot-button @click="reportChatMessage()">send report</ot-button>
                            <ot-dialog-close as-child>
                                <ot-button variant="secondary">{{ $t('general', 'cancel') }}</ot-button>
                            </ot-dialog-close>
                        </ot-dialog-footer>
                    </ot-dialog-content>
                </ot-dialog>
            </div>
        </div>
    </div>
</template>
