<script>
import axios from 'axios';
import { OtAccordion, OtAccordionContent, OtAccordionItem, OtAccordionTrigger } from '../../components/accordion';
import HeadToHeadShowmatches from '../../components/HeadToHeadShowmatches.vue';
import HeadToHeadSingles from '../../components/HeadToHeadSingles.vue';
import Loader from '../../components/Loader.vue';
import Player from '../../components/Player.vue';

export default {
    components: {
        OtLoader: Loader,
        OtPlayer: Player,
        OtHeadToHeadShowmatches: HeadToHeadShowmatches,
        OtHeadToHeadSingles: HeadToHeadSingles,
        OtAccordion,
        OtAccordionContent,
        OtAccordionItem,
        OtAccordionTrigger,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            data: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            axios
                .get(`/api/player-profiles/${this.id}/career`)
                .then((response) => {
                    this.data = response.data.data;
                    this.loading = false;
                });
        },
    },
};
</script>

<template>
    <ot-loader :loading="loading" standalone>
        <template v-if="!loading">
            <h3>{{ $t('general.singles') }}</h3>
            <ot-accordion type="multiple">
                <ot-accordion-item
                    v-for="opponent in data.headToHeadSingles"
                    :key="opponent.id"
                    :value="opponent.id"
                >
                    <ot-accordion-trigger class="flex justify-between">
                        <ot-player
                            :id="opponent.id"
                            :country="opponent.country.id"
                        >
                            {{ opponent.name }}
                        </ot-player>
                        <div>
                            <template v-if="opponent.valueWin">{{ opponent.valueWin }}</template>
                            <template v-else>0</template>
                            :
                            <template v-if="opponent.valueLose">{{ opponent.valueLose }}</template>
                            <template v-else>0</template>
                        </div>
                    </ot-accordion-trigger>
                    <ot-accordion-content>
                        <ot-head-to-head-singles :id="id" :opponent-id="opponent.id" />
                    </ot-accordion-content>
                </ot-accordion-item>
            </ot-accordion>

            <h3>{{ $t('internalTactics.tab_showmatch') }}</h3>
            <ot-accordion type="multiple">
                <ot-accordion-item
                    v-for="opponent in data.headToHeadShowmatches"
                    :key="opponent.id"
                    :value="opponent.id"
                >
                    <ot-accordion-trigger class="flex justify-between">
                        <ot-player
                            :id="opponent.id"
                            :country="opponent.country.id"
                        >
                            {{ opponent.name }}
                        </ot-player>
                        <div>
                            <template v-if="opponent.valueWin">{{ opponent.valueWin }}</template>
                            <template v-else>0</template>
                            :
                            <template v-if="opponent.valueLose">{{ opponent.valueLose }}</template>
                            <template v-else>0</template>
                        </div>
                    </ot-accordion-trigger>
                    <ot-accordion-content>
                        <ot-head-to-head-showmatches :id="id" :opponent-id="opponent.id" />
                    </ot-accordion-content>
                </ot-accordion-item>
            </ot-accordion>
        </template>
    </ot-loader>
</template>
