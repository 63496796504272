<script>
export default {
    data() {
        return {
            now: new Date(),
            interval: null,
        };
    },
    computed: {
        formattedTime() {
            return this.now.toLocaleTimeString([], { timeZone: 'Europe/Berlin' });
        },
    },
    mounted() {
        this.interval = setInterval(() => this.now = new Date(), 1000);
    },
    unmounted() {
        this.interval = null;
    },
};
</script>

<template>
    <div>{{ formattedTime }}</div>
</template>
