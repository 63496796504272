<script setup lang="ts">
import axios from 'axios';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import OtButton from '../components/Button.vue';
import OtCard from '../components/Card.vue';
import { OtTextField } from '../components/text-field';

interface Props {
    oldFirstname: string;
    oldLastname: string;
}
const props = defineProps<Props>();

const firstname = ref('');
const lastname = ref('');
const comment = ref('');

const routeFakeRename = Routing.generate('fake_rename');

const { t } = useI18n();

const allowSubmit = computed(() => {
    if (firstname.value === '') {
        return false;
    }

    if (lastname.value === '') {
        return false;
    }

    if (firstname.value === props.oldFirstname && lastname.value === props.oldLastname) {
        return false;
    }

    return true;
});

const fakeForm = ref(null);
async function finalCheckName() {
    const response = await axios.get(`/checkNameBlacklisted/${firstname.value}/${lastname.value}`);
    if (response.data.isFake) {
        comment.value = t('externalFakeRename.nameNotAllowed');
    }
    else {
        fakeForm.value.submit();
    }
}
</script>

<template>
    <ot-card legacy class="m-4">
        <p class="text-center text-xl my-4">{{ t('fakeRename.fakeInfo') }}</p>
        <form ref="fakeForm" name="changename" method="post" :action="routeFakeRename">
            <table>
                <tbody>
                    <tr>
                        <td>{{ t('externalFakeRename.oldFirstname') }}</td>
                        <td><i>{{ oldFirstname }}</i></td>
                    </tr>
                    <tr>
                        <td>{{ t('externalFakeRename.oldLastname') }}</td>
                        <td><i>{{ oldLastname }}</i></td>
                    </tr>
                    <tr>
                        <td />
                        <td><ot-text-field v-model="firstname" :label="t('externalFakeRename.newFirstname')" name="firstname" /></td>
                    </tr>
                    <tr>
                        <td />
                        <td><ot-text-field v-model="lastname" :label="t('externalFakeRename.newLastname')" name="lastname" /></td>
                    </tr>
                    <tr>
                        <td colspan="2">{{ comment }}</td>
                    </tr>
                    <tr>
                        <td colspan="2" class="text-center">
                            <ot-button
                                primary
                                :disabled="!allowSubmit"
                                @click="finalCheckName()"
                            >
                                {{ t('externalFakeRename.changeData') }}
                            </ot-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    </ot-card>
</template>
