export default class SuggestionListGetResponseDto {
    private readonly id: string;
    private readonly titleOriginal: string;
    private readonly titleTranslated: string;
    private readonly votes: number;
    private readonly voted: boolean;

    public constructor(data: any) {
        this.id = data.id;
        this.titleOriginal = data.title_original;
        this.titleTranslated = data.title_translated;
        this.votes = data.votes;
        this.voted = data.voted;
    }

    public getId(): string {
        return this.id;
    }

    public getTitle(): string {
        return this.titleTranslated;
    }

    public getVotes(): number {
        return this.votes;
    }

    public isVoted(): boolean {
        return this.voted;
    }
}
