<script setup lang="ts">
import axios from 'axios';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { OtCombobox, OtComboboxContent, OtComboboxGroup, OtComboboxItem } from './combobox';

interface Item {
    key: number;
    value: string;
}
interface Props {
    default?: Item;
}
interface Emits {
    updateDoublesId: (value: number) => void;
}

const props = defineProps<Props>();
defineEmits<Emits>();

const { t } = useI18n();

const search = ref('');
const items = ref<Item[]>([]);
const select = ref<Item | null>(null);

if (props.default) {
    items.value.push(props.default);
    select.value = props.default;
}

watch(search, (value) => {
    if (value && value !== select.value) {
        query(value);
    }
});

async function query(term: string) {
    const { data } = await axios.get(`/doubles/search?term=${term}`);
    items.value = data;
}
</script>

<template>
    <ot-combobox
        v-model="select"
        v-model:search-term="search"
        :display-value="item => item.value"
    >
        <ot-combobox-content :empty-label="t('general.no_results')">
            <ot-combobox-group>
                <ot-combobox-item
                    v-for="item in items"
                    :key="item.key"
                    :value="item"
                    @click="() => $emit('updateDoublesId', item.key)"
                    class="gap-2 fill-green-700 data-highlighted:fill-green-100"
                >
                    <span class="whitespace-nowrap overflow-hidden text-ellipsis">{{ item.value }}</span>
                </ot-combobox-item>
            </ot-combobox-group>
        </ot-combobox-content>
    </ot-combobox>
</template>
