<script setup lang="ts">
import type { AccordionRootEmits, AccordionRootProps } from 'radix-vue';
import type { HTMLAttributes } from 'vue';
import { AccordionRoot } from 'radix-vue';
import { cn } from '../utils';

interface Props extends AccordionRootProps {
    class?: HTMLAttributes['class'];
    id?: string;
}
const props = defineProps<Props>();
const emits = defineEmits<AccordionRootEmits>();

if (props.id) {
    const value = JSON.parse(window.localStorage.getItem(`accordion_${props.id}`));
    if (value) {
        emits('update:modelValue', value.value);
    }
}
function update(modelValue: string | string[]) {
    emits('update:modelValue', modelValue);

    if (props.id) {
        const value = { value: modelValue };
        window.localStorage.setItem(`accordion_${props.id}`, JSON.stringify(value));
    }
}
</script>

<template>
    <accordion-root
        :type="props.type"
        :collapsible="props.collapsible"
        :model-value="props.modelValue"
        @update:model-value="update"
        :class="cn('divide-y divide-gray-500', props.class)"
    >
        <slot />
    </accordion-root>
</template>
