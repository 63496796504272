<script setup lang="ts">
import { X } from 'lucide-vue-next';
import { ref } from 'vue';
import OtCard from './Card.vue';

interface Props {
    label: string;
}
interface Emits {
    opened: () => void;
    closed: () => void;
}

defineProps<Props>();
const emits = defineEmits<Emits>();

const visible = ref(false);

function show() {
    visible.value = true;
    emits('opened');
}

function hide() {
    visible.value = false;
    emits('closed');
}
</script>

<template>
    <div>
        <a @click="show" v-text="label" />
        <div v-if="visible" class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <ot-card legacy>
                        <a class="modal-close" @click="hide"><x /></a>
                        <slot />
                    </ot-card>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
a {
    cursor: pointer;
}
.modal-mask {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
}
.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}
.modal-container {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
}
.modal-close {
    position: absolute;
    right: 0;
    top: 0;
    border-color: var(--color-zinc-100);
    @apply border-l;
    @apply border-b;
    cursor: pointer;
}
</style>
