export default class Country {
    constructor() {
        this.id = 0;
        this.tag = '';
    }

    static fromData(data) {
        const country = new Country();
        country.id = data.id;
        country.tag = data.tag;

        return country;
    }
}
