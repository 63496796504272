<script setup lang="ts">
import axios from 'axios';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import OtLoader from '../../components/Loader.vue';
import OtPlayer from '../../components/Player.vue';

interface Props {
    id: string;
}
const props = defineProps<Props>();

const { t } = useI18n();

const loading = ref(true);

const latestVisitors = ref([]);
const visitorsOverall = ref(0);
const visitorsOverallPosition = ref(0);
const visitorsLastMonth = ref(0);
const visitorsLastMonthPosition = ref(0);

onMounted(() => load());

async function load() {
    const { data } = await axios.get(`/api/doubles-profiles/${props.id}/overview`);

    latestVisitors.value = data.data.latestVisitors;
    visitorsOverall.value = data.data.visitorsOverall;
    visitorsOverallPosition.value = data.data.visitorsOverallPosition;
    visitorsLastMonth.value = data.data.visitorsLastMonth;
    visitorsLastMonthPosition.value = data.data.visitorsLastMonthPosition;

    loading.value = false;
}
</script>

<template>
    <ot-loader :loading="loading" standalone>
        <div> {{ t('profile_user.latestVisitors') }}</div>
        <table class="striped">
            <tr
                v-for="visitor in latestVisitors"
                :key="visitor.id"
            >
                <td>
                    <ot-player :id="visitor.player.id" :country="visitor.player.country.id">{{ visitor.player.name }}</ot-player>
                </td>
            </tr>
        </table>
        <div>{{ t('profile_user.visitorsSentence', { param1: visitorsOverall, param2: visitorsOverallPosition, param3: visitorsLastMonth, param4: visitorsLastMonthPosition }) }}</div>
    </ot-loader>
</template>
