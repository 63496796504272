import User from './User.js';

export default class Message {
    constructor() {
        this.author = new User();
    }

    static fromData(data) {
        const message = new Message();
        message.id = data.id;
        message.content = data.content;
        message.date = data.date;

        if (data.author) {
            message.author = User.fromData(data.author.data);
        }

        return message;
    }
}
