export default class PlayerMeGetResponseDto {
    private readonly notepad: string;

    public constructor(data: any) {
        this.notepad = data.notepad;
    }

    public getNotepad(): string {
        return this.notepad;
    }
}
