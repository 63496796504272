<script setup lang="ts">
import axios from 'axios';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import OtButton from '../components/Button.vue';
import OtCard from '../components/Card.vue';
import OtChip from '../components/Chip.vue';
import { OtHint } from '../components/hint';
import OtLoader from '../components/Loader.vue';
import { OtSelect, OtSelectContent, OtSelectGroup, OtSelectItem, OtSelectTrigger, OtSelectValue } from '../components/select';
import { OtTabs, OtTabsContent, OtTabsList, OtTabsTrigger } from '../components/tabs';
import { OtToast } from '../components/toast';
import { useLayout } from '../composables/layout';

const { width } = useLayout();
const { t } = useI18n();
const store = useStore();

const loading = ref(true);
const trainingTimes = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
const trainings = [
    { value: '0', text: t('training.training_0') },
    { value: '1', text: t('training.training_1') },
    { value: '2', text: t('training.training_2') },
    { value: '3', text: t('training.training_3') },
    { value: '4', text: t('training.training_4') },
    { value: '5', text: t('training.training_5') },
    { value: '6', text: t('training.training_6') },
    { value: '7', text: t('training.training_7') },
    { value: '8', text: t('training.training_8') },
    { value: '9', text: t('training.training_9') },
    { value: '10', text: t('training.training_10') },
];
const skills = ref([]);
const selectedTraining = ref([]);
const trainingIntensity = ref(null);
const showTrainingUpdated = ref(false);
const trainingHistory = ref([]);

const player = computed(() => store.getters.player);
const reducedTrainingMessage = computed(() => {
    if (trainingIntensity.value === null) {
        return '';
    }

    return t(`internalTraining.${trainingIntensity.value.type}`, { param1: trainingIntensity.value.intensity });
});

const isXs = computed(() => width.value < 600);

onMounted(async () => {
    const response = await axios.get('/api/training');
    skills.value = response.data.data.skills;
    selectedTraining.value = response.data.data.selectedTraining;
    trainingIntensity.value = response.data.data.trainingIntensity.data;
    trainingHistory.value = response.data.data.trainingHistory;
    loading.value = false;
});

function signed(value) {
    if (value > 0) {
        return `+${value}`;
    }

    return value;
}

async function updateTraining() {
    await axios.put('/api/training', {
        unit8: Number.parseInt(selectedTraining.value[8]),
        unit9: Number.parseInt(selectedTraining.value[9]),
        unit10: Number.parseInt(selectedTraining.value[10]),
        unit11: Number.parseInt(selectedTraining.value[11]),
        unit12: Number.parseInt(selectedTraining.value[12]),
        unit13: Number.parseInt(selectedTraining.value[13]),
        unit14: Number.parseInt(selectedTraining.value[14]),
        unit15: Number.parseInt(selectedTraining.value[15]),
        unit16: Number.parseInt(selectedTraining.value[16]),
        unit17: Number.parseInt(selectedTraining.value[17]),
        unit18: Number.parseInt(selectedTraining.value[18]),
        unit19: Number.parseInt(selectedTraining.value[19]),
    });

    showTrainingUpdated.value = true;
}
</script>

<template>
    <ot-loader :loading="loading">
        <ot-card>
            <ot-tabs v-if="!loading" id="training" default-value="skills">
                <ot-tabs-list>
                    <ot-tabs-trigger value="skills">{{ $t('internalTraining.subheader_skills') }}</ot-tabs-trigger>
                    <ot-tabs-trigger value="training">{{ $t('internalTraining.subheader_training') }}</ot-tabs-trigger>
                    <ot-tabs-trigger value="history">{{ $t('training.history') }}</ot-tabs-trigger>
                </ot-tabs-list>
                <ot-tabs-content value="skills">
                    <table>
                        <tbody class="divide-y divide-gray-500 text-sm">
                            <tr
                                v-for="(skill, index) in skills"
                                :key="index"
                            >
                                <td class="p-4">{{ $t(`training.training_${index}`) }}</td>
                                <td class="p-4">
                                    <ot-chip
                                        v-if="isXs"
                                        type="green"
                                    >
                                        {{ skill.current }}
                                    </ot-chip>
                                    <template v-else>
                                        {{ skill.current }}
                                    </template>
                                </td>
                                <td class="p-4 whitespace-nowrap">{{ skill.diff === 0 ? '+/-' : '' }} {{ signed(skill.diff) }}</td>
                                <td
                                    v-if="!isXs"
                                    class="p-4 w-full"
                                >
                                    <div class="bg-green-700/20 h-4 w-full rounded-full">
                                        <div
                                            class="bg-green-700 h-full rounded-full"
                                            :style="{ width: `${skill.current / 1100 * 100}%` }"
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="p-4">{{ $t('profil.profil_durchschnitt') }}</td>
                                <td class="p-4">
                                    <ot-chip
                                        v-if="isXs"
                                        type="green"
                                    >
                                        {{ Math.round(player.skill.average) }}
                                    </ot-chip>
                                    <template v-else>
                                        {{ Math.round(player.skill.average) }}
                                    </template>
                                </td>
                                <td class="p-4" />
                                <td
                                    v-if="!isXs"
                                    class="p-4 w-full"
                                >
                                    <div class="bg-green-700/20 h-4 w-full rounded-full">
                                        <div
                                            class="bg-green-700 h-full rounded-full"
                                            :style="{ width: `${player.skill.average / 1100 * 100}%` }"
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ot-tabs-content>
                <ot-tabs-content value="training">
                    <ot-toast v-model="showTrainingUpdated" variant="success" :text="$t('training.training_uebernommen')" />
                    <ot-select
                        v-for="trainingTime in trainingTimes"
                        :key="trainingTime"
                        v-model="selectedTraining[trainingTime]"
                    >
                        <ot-select-trigger class="mb-1">
                            <ot-select-value />
                        </ot-select-trigger>
                        <ot-select-content>
                            <ot-select-group>
                                <ot-select-item
                                    v-for="training in trainings"
                                    :key="training.value"
                                    :value="training.value"
                                >
                                    {{ training.text }}
                                </ot-select-item>
                            </ot-select-group>
                        </ot-select-content>
                    </ot-select>
                    <ot-button class="mb-2" @click="updateTraining()">{{ $t('general.submit') }}</ot-button>
                    <ot-hint type="info" class="mb-2">{{ $t('internalTraining.tennisAcademyLimitation') }}</ot-hint>
                    <h3>{{ $t('internalTraining.subheader_attentionBox') }}</h3>
                    <p>{{ reducedTrainingMessage }}</p>
                </ot-tabs-content>
                <ot-tabs-content value="history">
                    <div class="flex flex-col gap-2 divide-y divide-gray-500">
                        <div
                            v-for="(training, index) in trainingHistory"
                            :key="`history_${index}`"
                        >
                            <div class="m-2 text-lg">{{ $t('training.history_date', { param1: training.season, param2: training.week, param3: training.day }) }}</div>
                            <div class="m-2 text-sm">{{ training.trainer === 0 ? $t('training.history_no_trainer') : $t('training.history_trainer', { param1: training.trainer }) }}, {{ $t('training.history_intensity', { param1: training.intensity * 100 }) }}</div>
                            <div class="m-2">
                                <div>
                                    <template
                                        v-for="(amount, unit) in training.units"
                                        :key="`history_unit_${unit}`"
                                    >
                                        <ot-chip
                                            v-if="amount > 0"
                                            class="my-2 mr-2"
                                            type="outlined"
                                        >
                                            <div class="bg-blue-500 rounded-full text-white w-6 h-6 flex justify-center items-center text-xs mr-2 -ml-2">
                                                {{ amount }}
                                            </div>
                                            {{ $t(`training.training_${unit}`) }}
                                        </ot-chip>
                                    </template>
                                </div>
                                <div>
                                    <ot-chip
                                        v-for="(diff, skill, i) in training.diff"
                                        :key="`history_diff_${i}`"
                                        class="my-2 mr-2"
                                        type="outlined"
                                    >
                                        <div
                                            class="rounded-full text-white w-6 h-6 flex justify-center items-center text-xs -ml-2 mr-2"
                                            :class="{ 'bg-red-500': diff <= 0, 'bg-green-700': diff > 0 }"
                                        >
                                            {{ diff }}
                                        </div>
                                        {{ $t(`training.training_${skill}`) }}
                                    </ot-chip>
                                </div>
                            </div>
                        </div>
                    </div>
                </ot-tabs-content>
            </ot-tabs>
        </ot-card>
    </ot-loader>
</template>
